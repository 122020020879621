import React from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { DiscountLevel } from "shared/data/tour_schema";

type CustomDiscountPanelProps = { discountLevels: DiscountLevel[]; updateDiscountLevels: (levels: DiscountLevel[]) => void };

const CustomDiscountPanel: React.FC<CustomDiscountPanelProps> = ({ discountLevels, updateDiscountLevels }) => {
    const handlePeopleChange = (index: number, people: number) => {
        const updatedLevels = discountLevels.map((level, i) => {
            if (i === index) {
                return { ...level, people };
            }
            return level;
        });
        updateDiscountLevels(updatedLevels);
    };

    const handleDiscountChange = (index: number, discount: number) => {
        const updatedLevels = discountLevels.map((level, i) => {
            if (i === index) {
                return { ...level, discount };
            }
            return level;
        });
        updateDiscountLevels(updatedLevels);
    };

    const handleAddLevel = () => {
        const newLevel: DiscountLevel = { people: 0, discount: 0 };
        updateDiscountLevels([...discountLevels, newLevel]);
    };

    const handleRemoveLevel = (index: number) => {
        const updatedLevels = discountLevels.filter((_, i) => i !== index);
        updateDiscountLevels(updatedLevels);
    };

    return (
        <Card>
            <CardHeader>Custom Discount</CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Number of People</TableHead>
                            <TableHead>Discount (%)</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {discountLevels.map((level, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Input type="number" value={level.people} onChange={(e) => handlePeopleChange(index, parseInt(e.target.value) || 0)} />
                                </TableCell>
                                <TableCell>
                                    <Input type="number" value={level.discount} onChange={(e) => handleDiscountChange(index, parseInt(e.target.value) || 0)} />
                                </TableCell>
                                <TableCell>
                                    <Button variant="ghost" onClick={() => handleRemoveLevel(index)}>
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button className="mt-4" onClick={handleAddLevel}>
                    Add Discount Level
                </Button>
            </CardContent>
        </Card>
    );
};

export default CustomDiscountPanel;

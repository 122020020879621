import { AxiosProgressEvent } from "axios";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { instance } from "shared/api/signature";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { DragLabel } from "./components/DragLabel";
import { UploadedFiles } from "./components/UploadedPreview";
import { LoadingFiles } from "./components/LoadingPreview";
import { UploadProps, FileUploadProgress } from "./utils";

interface FileLoadingProps {
    uploadedFiles?: File[];
    setUploadedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
}

/**
 * Drag on file upload
 */

interface FileUploadProps {
    onFileSelect: (file: File | null) => void;
    uploadedFile?: File | null;
    maxFiles?: number;
    acceptedFileTypes?: string[];
}

export function Upload({ onFileSelect, uploadedFile, maxFiles = 1, acceptedFileTypes = ["image/*"] }: FileUploadProps) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                onFileSelect(acceptedFiles[0]);
            } else {
                onFileSelect(null);
            }
        },
        [onFileSelect]
    );

    const dropzone = useDropzone({
        onDrop,
        maxFiles,
        accept: acceptedFileTypes.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
        multiple: false,
    });

    const { getRootProps, getInputProps } = dropzone;

    const removeFile = () => {
        onFileSelect(null);
    };

    return (
        <div className="border p-4 rounded-md">
            <div {...getRootProps()} className="cursor-pointer">
                <input {...getInputProps()} />
                <DragLabel dropzone={dropzone} />
            </div>
            {uploadedFile && <UploadedFiles uploadedFiles={[uploadedFile]} removeFile={removeFile} />}
        </div>
    );
}

export function FileUpload({ send, link, uploadedFiles: externalUploadedFiles, setUploadedFiles: externalSetUploadedFiles }: UploadProps & FileLoadingProps) {
    const [internalUploadedFiles, internalSetUploadedFiles] = React.useState<File[]>([]);

    const uploadedFiles = externalUploadedFiles ?? internalUploadedFiles;
    const setUploadedFiles = externalSetUploadedFiles ?? internalSetUploadedFiles;

    const [filesToUpload, setFilesToUpload] = React.useState<FileUploadProgress[]>([]);

    const { toast } = useToast();

    const onUploadProgress = (progressEvent: AxiosProgressEvent, file: File, signal: AbortSignal) => {
        const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 0)) * 100);

        if (progress === 100) {
            setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, file]);
            setFilesToUpload((prevUploadProgress) => prevUploadProgress.filter((item) => item.File !== file));
            return;
        }

        setFilesToUpload((prevUploadProgress) => {
            return prevUploadProgress.map((item) => {
                if (item.File.name === file.name) {
                    return { ...item, progress, signal };
                } else return item;
            });
        });
    };

    const send_ = async (formData: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void, signal: AbortController["signal"]) => {
        return instance.post("/", formData, { onUploadProgress, signal });
    };

    const removeFile = (file: File) => {
        setFilesToUpload((prevUploadProgress) => {
            return prevUploadProgress.filter((item) => item.File !== file);
        });

        setUploadedFiles((prevUploadedFiles) => {
            return prevUploadedFiles.filter((item) => item !== file);
        });
    };

    const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
        setFilesToUpload((prevUploadProgress) => [...prevUploadProgress, ...acceptedFiles.map((file) => ({ progress: 0, File: file }))]);

        const fileUploadBatch = acceptedFiles.map((file) => {
            const formData = new FormData();
            formData.append("file", file);

            const controller = new AbortController();
            const signal = controller.signal;
            if (send) return send_(formData, (progressEvent: AxiosProgressEvent) => onUploadProgress(progressEvent, file, signal), signal);
        });

        try {
            await Promise.all(fileUploadBatch);
        } catch (error) {
            if (error instanceof Error) {
                toast({ title: "Error uploading files: " + error.message });
            } else toast({ title: "Error uploading fils" });
        }
    }, []);

    const dropzone = useDropzone({ onDrop, multiple: true });

    return (
        <div className="border">
            <DragLabel dropzone={dropzone} />
            <LoadingFiles filesToUpload={filesToUpload} removeFile={removeFile} />
            <UploadedFiles uploadedFiles={uploadedFiles} removeFile={removeFile} />
        </div>
    );
}

export function InputFile() {
    return (
        <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="picture">Picture</Label>
            <Input id="picture" type="file" onChange={(e) => console.log(e.target.files)} onInput={(e) => console.log(e.currentTarget.files)} />
        </div>
    );
}

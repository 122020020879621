import React, { useState } from "react";
import { useAppDispatch, useAppSelector, useScreenWidth } from "shared/helpers/utils";
import { Drawer, DrawerClose, DrawerContent, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer";
import { Menu, ChevronRight, XCircle, User, Settings, Mail, Shield, CreditCard, Bell, Users, Globe, MessageSquare } from "lucide-react";
import { Button } from "@/components/ui/button";
import { UserSelector, isLogged } from "entities/store/user";
import { useNavigate } from "react-router-dom";
import { BaseRoutes } from "shared/helpers/const";
import { Modal, useModal } from "shared/providers/ModalWindow";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { profilePageRoutes } from "shared/helpers/const";
import { ModalWindowProps } from "shared/providers/ModalWindow";
import { FC } from "react";

type CloseProps = { close: () => void };

const providerRoutes = [{ path: BaseRoutes["PROFILEPAGE.TEAMS"], name: "Manage Teams", icon: <Users size={20} /> }];

function UserNotLoggedHeaderContent() {
    const nav = useNavigate();
    const modal = useModal();

    const favorite = () => nav(BaseRoutes.FAVORITE);
    const login = () => modal.show(Modal.LOGIN);
    const signup = () => modal.show(Modal.SIGNUP);
    const openLanguageModal = () => modal.show(Modal.LANGUAGE);

    return (
        <div className="flex flex-col space-y-4 mt-4">
            <Button onClick={login} variant="default" className="w-full">
                Log in
            </Button>
            <Button onClick={signup} variant="outline" className="w-full">
                Sign up
            </Button>
            <div className="flex justify-between">
                <Button onClick={favorite} variant="ghost" className="flex-1">
                    Favorites
                </Button>
                <Button variant="ghost" onClick={openLanguageModal} className="flex-1">
                    <Globe size={20} />
                    <span className="ml-2">Language</span>
                </Button>
            </div>
        </div>
    );
}

function UserIsLoggedHeaderContent({ close }: CloseProps) {
    const navigate = useNavigate();
    const userRole = useAppSelector(UserSelector.role);
    const modal = useModal();

    const allRoutes = userRole === "PROVIDER" ? [...profilePageRoutes, ...providerRoutes] : profilePageRoutes;

    const handleNavigation = (path: string) => {
        navigate(path);
        close();
    };

    const role = useAppSelector(UserSelector.data)?.role == "PROVIDER" ?? false;
    const openLanguageModal = () => modal.show(Modal.LANGUAGE);

    return (
        <div className="flex flex-col space-y-2">
            <Accordion type="single" collapsible className="ml-4 border-b-0">
                <AccordionItem value="profile-settings" className="border-b-0">
                    <AccordionTrigger className="py-2 justify-start">
                        <div className="w-full flex">
                            <User size={20} />
                            <span className="ml-2">{"Profile"}</span>
                        </div>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-col space-y-2">
                            {allRoutes.map((route) => (
                                <Button key={route.path} onClick={() => handleNavigation(route.path)} variant="ghost" className="justify-start">
                                    {route.icon}
                                    <span className="ml-2">{route.name}</span>
                                </Button>
                            ))}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>

            <Button onClick={() => handleNavigation(role ? BaseRoutes.PROVIDERS_TOURS : BaseRoutes.TRAVELLERS_TOURS)} variant="ghost" className="justify-start">
                <ChevronRight size={20} />
                <span className="ml-2">Tours</span>
            </Button>
            <Button onClick={() => handleNavigation(BaseRoutes.NOTIFICATIONS)} variant="ghost" className="justify-start">
                <Bell size={20} />
                <span className="ml-2">Notifications</span>
            </Button>
            <Button onClick={() => handleNavigation(BaseRoutes.FAVORITE)} variant="ghost" className="justify-start">
                <ChevronRight size={20} />
                <span className="ml-2">Favorites</span>
            </Button>
            <Button variant="ghost" onClick={openLanguageModal} className="justify-start">
                <Globe size={20} />
                <span className="ml-2">Language</span>
            </Button>
        </div>
    );
}

import { logoutThunk } from "entities/store/user";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { LogOut } from "lucide-react";

const DefaultMenuContent = () => {
    const isLog = useAppSelector(isLogged);
    const dispatch = useAppDispatch();

    const handleLogout = async () => {
        dispatch(logoutThunk());
    };

    return (
        <Card className="w-full mb-4">
            <CardContent className="pt-6">
                <nav className="flex flex-col space-y-2">
                    <a href="#" className="text-sm text-gray-600 hover:text-gray-800 transition-colors">
                        Terms and conditions
                    </a>
                    <a href="#" className="text-sm text-gray-600 hover:text-gray-800 transition-colors">
                        Support
                    </a>
                </nav>
            </CardContent>
            {isLog && (
                <CardFooter>
                    <Button variant="outline" className="w-full bg-red-500 text-white" onClick={handleLogout}>
                        <LogOut className="mr-2 h-4 w-4" />
                        Logout
                    </Button>
                </CardFooter>
            )}
        </Card>
    );
};

function CloseMobileMenu() {
    return (
        <div className="w-full flex flex-row justify-between items-center">
            <DrawerTitle>Menu</DrawerTitle>
            <DrawerClose className="justify-self-end">
                <XCircle />
            </DrawerClose>
        </div>
    );
}

const MobileMenuTrigger = () => {
    const modal = useModal();
    const isLogged = useAppSelector(UserSelector.isLogged);
    const notificationCount = 0; // Assuming you have a notifications slice in your store

    return (
        <div className="md:hidden relative">
            <Button variant="ghost" onClick={() => modal.show(Modal.MOBILE_MENU)}>
                <Menu />
            </Button>
            {isLogged && notificationCount > 0 && (
                <Badge className="absolute -top-2 -right-2 px-2 py-1" variant="destructive">
                    {notificationCount}
                </Badge>
            )}
        </div>
    );
};
export const MobileMenuDrawer: FC<ModalWindowProps> = ({ close }) => {
    const isLogged = useAppSelector(UserSelector.isLogged);
    const navigate = useNavigate();

    return (
        <Drawer open={true} onOpenChange={close}>
            <DrawerContent className="h-screen flex flex-col content-padding space-y-2">
                <CloseMobileMenu />
                <div className="flex flex-col flex-1 justify-between">
                    <div>
                        {isLogged ? <UserIsLoggedHeaderContent close={close} /> : <UserNotLoggedHeaderContent />}
                        {isLogged && (
                            <Button
                                variant="outline"
                                className="mt-4"
                                onClick={() => {
                                    navigate(BaseRoutes.MESSAGES);
                                    close();
                                }}
                            >
                                <MessageSquare size={20} />
                                <span className="ml-2">Messages</span>
                            </Button>
                        )}
                    </div>
                    <DefaultMenuContent />
                </div>
            </DrawerContent>
        </Drawer>
    );
};

export default MobileMenuTrigger;

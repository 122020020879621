import { Accordion } from "@/components/ui/accordion";
import { BenchItemWrap, BenchItemValue } from "../utils/Bench";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { PhoneInput } from "@/Input/phone-input";
import MultiRadio from "@/components/ui/multiple-radio";
import React from "react";

function Inputs() {
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const variants = [{ value: "test" }];
    return (
        <BenchItemWrap value="inputs">
            <div className="m-4">
                <div className="w-20">
                    <MultiRadio onChange={(v) => void v} variants={variants} />
                </div>
                <div className="mb-2">
                    <p>Default input</p>
                    <Input className="w-[200px] mt-2" />
                </div>
                <Separator />
                <div className="mt-2 mb-2">
                    <p>Password</p>
                    <Input type="password" className="w-[200px] mt-2" />
                </div>
                <Separator />{" "}
                <div className="mt-2 mb-2">
                    <p>Phone</p>
                    {/* @ts-ignore */}
                    <PhoneInput value={phoneNumber} onChange={setPhoneNumber} placeholder="Enter a phone number" disabled={false} />
                </div>
            </div>
        </BenchItemWrap>
    );
}

const UiBench = ({ value }: BenchItemValue) => {
    return (
        <BenchItemWrap value={value}>
            <ul className="mb-4">
                <li>1. Inputs</li>
            </ul>

            <Accordion className="w-full" type="single" collapsible>
                <Inputs />
            </Accordion>
        </BenchItemWrap>
    );
};

export default UiBench;

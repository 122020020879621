import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { MDWIDTH } from "shared/helpers/const";
import { useScreenWidth } from "shared/helpers/utils";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { cn } from "@/lib/utils";

type PickUserProviderProps = { provider: boolean; click: (value: boolean) => void };

const PickRoleParagraph = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement> & Omit<PickUserProviderProps, "click">>(
    ({ provider, ...props }, ref) => {
        return (
            <p className={cn("text-left underline text-pretty text-xs cursor-pointer mt-4 underline-offset-4", provider ? "" : " text-pretty opacity-60")} {...props}>
                {provider ? (
                    <>
                        You creating provider profile <span className="font-bold"> [discard] </span>
                    </>
                ) : (
                    "I want to be tour provider!"
                )}
            </p>
        );
    }
);

const TooltipContentText = ({ provider }: Omit<PickUserProviderProps, "click">) => {
    return provider ? "Go back to creating traveller profile?" : "By creating a provider profile you will be able to create and sell tours";
};

const PickUserProviderPhone = ({ provider, click }: PickUserProviderProps) => {
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <PickRoleParagraph provider={provider} />
            </AlertDialogTrigger>
            <AlertDialogContent className="w-[88vw]">
                <AlertDialogHeader>
                    <AlertDialogTitle className="text-left">
                        <TooltipContentText provider={provider} />
                    </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter className="">
                    <AlertDialogAction onClick={() => click(provider)}>Continue</AlertDialogAction>
                    <AlertDialogCancel className="">Cancel</AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

const PickUserProviderDesktop = ({ provider, click }: PickUserProviderProps) => {
    return (
        <Tooltip>
            <TooltipTrigger type="button">
                <PickRoleParagraph provider={provider} onClick={() => click(provider)} />
            </TooltipTrigger>
            <TooltipContent align="start" sideOffset={-11} className="w-[250px]">
                <TooltipContentText provider={provider} />
            </TooltipContent>
        </Tooltip>
    );
};

/**
 * This is component to adjust user role
 * Traveller is most common scenario, so we don't want
 * user to think much about other possibilities
 */
const PickUserProviderRole = ({ setRole }: { setRole: (role: boolean) => void }) => {
    const [provider, setProvider] = React.useState(false);
    const click = (value: boolean) => {
        setProvider(!value);
        setRole(!value);
    };

    if (useScreenWidth() > MDWIDTH) return <PickUserProviderDesktop provider={provider} click={click} />;
    return <PickUserProviderPhone provider={provider} click={click} />;
};

export { PickUserProviderDesktop, PickUserProviderPhone };
export default PickUserProviderRole;

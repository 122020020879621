import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
type TourCheckoutHeaderProps = {
    tour_name: string;
    tour_id: string;
    traveller_info?: {
        adults: number;
        kids: number;
    };

    review_stats: {
        rating: number;
        review_count: number;
    };
};

export function TourCheckoutHeader({ tour_name, tour_id, traveller_info, review_stats }: TourCheckoutHeaderProps) {
    traveller_info = { adults: traveller_info?.adults ?? 1, kids: traveller_info?.kids ?? 0 };

    function TravellerInfo(t_i: TourCheckoutHeaderProps["traveller_info"] = { adults: 1, kids: 0 }) {
        const str_1 = t_i.adults > 1 ? t_i.adults + " adults" : "1 adult";
        const kids_str = t_i.kids > 0 ? (t_i.kids > 1 ? t_i.kids + " kids" : "1 kid") : "";

        return (
            <p className="text-muted-foreground text-sm md:text-base">
                {str_1}
                {kids_str.length > 0 && "· " + kids_str}
            </p>
        );
    }

    return (
        <header className="flex items-center justify-between mb-6 md:mb-8 lg:mb-10">
            <div>
                <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">{tour_name}</h1>
                <TravellerInfo {...traveller_info} />
            </div>
            <div className="flex items-center gap-3">
                <StarIcon className="w-5 h-5 fill-primary" />
                <span className="font-semibold">{review_stats.rating}</span>
                <span className="text-muted-foreground text-sm">({review_stats.review_count} reviews)</span>
            </div>
        </header>
    );
}

export function Checkout() {
    const tour = {};
    return (
        <div className="w-full max-w-4xl mx-auto py-8 md:py-12 lg:py-16 p-5">
            <TourCheckoutHeader tour_name="Unexpected Turkey" tour_id="1" review_stats={{ rating: 4.108, review_count: 108 }} />
            <div className="grid md:grid-cols-2 gap-8 md:gap-12">
                <div>
                    <form className="space-y-6">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="name">Name</Label>
                                <Input id="name" placeholder="Enter your name" />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="email">Email</Label>
                                <Input id="email" type="email" placeholder="Enter your email" />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="phone">Phone</Label>
                            <Input id="phone" type="tel" placeholder="Enter your phone number" />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="check-in">Check-in</Label>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button variant="outline" className="flex-col items-start w-full h-auto">
                                            <span className="font-semibold uppercase text-[0.65rem]">Check in</span>
                                            <span className="font-normal">4/2/2024</span>
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="p-0 max-w-[276px]">
                                        <Calendar />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="check-out">Check-out</Label>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button variant="outline" className="flex-col items-start w-full h-auto">
                                            <span className="font-semibold uppercase text-[0.65rem]">Check out</span>
                                            <span className="font-normal">10/2/2024</span>
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="p-0 max-w-[276px]">
                                        <Calendar />
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="guests">Guests</Label>
                            <Select>
                                <SelectTrigger className="h-auto">
                                    <SelectValue
                                        placeholder={
                                            <div className="flex flex-col items-start">
                                                <span className="font-semibold uppercase text-[0.65rem]">Guests</span>
                                                <span className="font-normal">2 adults</span>
                                            </div>
                                        }
                                    />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="1">1 adult</SelectItem>
                                    <SelectItem value="2">2 adults</SelectItem>
                                    <SelectItem value="3">2 adults + 1 child</SelectItem>
                                    <SelectItem value="other">Other</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="payment-method">Payment Method</Label>
                            <RadioGroup id="payment-method" defaultValue="card" className="flex items-center gap-4">
                                <Label htmlFor="payment-card" className="flex items-center gap-2 cursor-pointer">
                                    <RadioGroupItem id="payment-card" value="card" />
                                    <CreditCardIcon className="w-6 h-6" />
                                    Credit Card
                                </Label>
                                <Label htmlFor="payment-paypal" className="flex items-center gap-2 cursor-pointer">
                                    <RadioGroupItem id="payment-paypal" value="paypal" />
                                    <WalletCardsIcon className="w-6 h-6" />
                                    PayPal
                                </Label>
                            </RadioGroup>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="card-number">Card Number</Label>
                            <Input id="card-number" type="text" placeholder="Enter your card number" />
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="expiration-month">Expiration</Label>
                                <Select>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Month" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="1">January</SelectItem>
                                        <SelectItem value="2">February</SelectItem>
                                        <SelectItem value="3">March</SelectItem>
                                        <SelectItem value="4">April</SelectItem>
                                        <SelectItem value="5">May</SelectItem>
                                        <SelectItem value="6">June</SelectItem>
                                        <SelectItem value="7">July</SelectItem>
                                        <SelectItem value="8">August</SelectItem>
                                        <SelectItem value="9">September</SelectItem>
                                        <SelectItem value="10">October</SelectItem>
                                        <SelectItem value="11">November</SelectItem>
                                        <SelectItem value="12">December</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="expiration-year">Year</Label>
                                <Select>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Year" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="2024">2024</SelectItem>
                                        <SelectItem value="2025">2025</SelectItem>
                                        <SelectItem value="2026">2026</SelectItem>
                                        <SelectItem value="2027">2027</SelectItem>
                                        <SelectItem value="2028">2028</SelectItem>
                                        <SelectItem value="2029">2029</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="cvc">CVC</Label>
                                <Input id="cvc" type="text" placeholder="Enter your CVC" />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="space-y-6">
                    <Card>
                        <CardHeader>
                            <CardTitle>Booking Summary</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid gap-4">
                                <div className="flex items-center justify-between">
                                    <div className="text-muted-foreground">$400 x 3 nights</div>
                                    <div>$1,200</div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="text-muted-foreground">
                                        Cleaning fee <span className="text-xs">(one-time)</span>
                                    </div>
                                    <div>$130</div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="text-muted-foreground">Service fee</div>
                                    <div>$188</div>
                                </div>
                            </div>
                            <Separator className="my-4" />
                            <div className="flex items-center justify-between">
                                <div className="font-semibold">Total</div>
                                <div className="text-2xl font-bold">$1,518</div>
                            </div>
                        </CardContent>
                        <CardFooter>
                            <Button size="lg" className="w-full">
                                Complete Booking
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            </div>
        </div>
    );
}

function CreditCardIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect width="20" height="14" x="2" y="5" rx="2" />
            <line x1="2" x2="22" y1="10" y2="10" />
        </svg>
    );
}

function StarIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
        </svg>
    );
}

function WalletCardsIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect width="18" height="18" x="3" y="3" rx="2" />
            <path d="M3 9a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2" />
            <path d="M3 11h3c.8 0 1.6.3 2.1.9l1.1.9c1.6 1.6 4.1 1.6 5.7 0l1.1-.9c.5-.5 1.3-.9 2.1-.9H21" />
        </svg>
    );
}

import { useToast } from "@/components/ui/use-toast";
import type { AxiosRequestConfig } from "axios";
import { AxiosError } from "axios";
import axios from "axios";
import { useEffect, useRef } from "react";
import { TRAVELLER_API_ENDPOINTS, TravellerSearchQuerySchema, TravellerSearchResponseSchema } from "shared/types/api";
import { z } from "zod";

export const API_URL = import.meta.env.VITE_API_URL ?? "http://localhost:3001";
// export const API_URL = "http://192.168.0.106:3001" ?? "http://localhost:3001";

export const PROD = import.meta.env.PROD;

const API_ROUTES = {
    login: "/v1/auth/login",
    userSession: "/v1/auth",
    logout: "/v1/auth/logout",
    static: "/v1/static/s3",
    getUserImageRoute: "/v1/user/image",
    createBlankTour: "/v1/provider/tour",
};

const proxy_route = API_URL + API_ROUTES.static;

type status_codes =
    | "SUCCESS"
    | "CREATED"
    | "ACCEPTED"
    | "BAD_REQUEST"
    | "UNAUTHORIZED"
    | "PAYMENT_REQUIRED"
    | "FORBIDDEN"
    | "NOT_FOUND"
    | "NOT_ACCEPTABLE"
    | "TIMEOUT"
    | "CONFLICT"
    | "PRECONDITION_FAILED"
    | "UNSUPPORTED_MEDIA_TYPE"
    | "INTERNAL_SERVER_ERROR"
    | "SERVICE_UNAVAILABLE";

const ResponseCodes: Record<status_codes, number> = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NOT_ACCEPTABLE: 406,
    TIMEOUT: 408,
    CONFLICT: 409,
    PRECONDITION_FAILED: 412,
    UNSUPPORTED_MEDIA_TYPE: 415,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
};

/**
 * Response type
 */

type SuccessResponse<T> = { ok: boolean; data: T };
type ApiResponse<T> = SuccessResponse<T> | ErrorResponseType;

type ErrorResponseType = { ok: false; message: string };

class ErrorResponse {
    ok: false;
    message: string;

    constructor(message: string) {
        this.ok = false;
        this.message = message;
    }

    // Method to return the error response object
    getResponse(): ErrorResponseType {
        return { ok: this.ok, message: this.message };
    }
}

type LoaderResponse<T> = ErrorResponse | T;

export type { LoaderResponse, ErrorResponseType };
export { ResponseCodes, proxy_route, ErrorResponse };

/**
 * API REQUESTS FUNCTIONS
 */
const loginRequest = (data) => instance.post(API_ROUTES.login, data);
const getSessionDataReq = () => instance.get(API_ROUTES.userSession);
const logoutReq = () => instance.get(API_ROUTES.logout);
const getUserImage = () => instance.get(API_ROUTES.getUserImageRoute);

/**
 * Provider requuests
 */

const create_blank_tour = () => instance.post("/v1/provider/tour");
const get_tour_create_stage = (id: string) => instance.get(`/v1/provider/tour/${id}`);
const delete_tour = (id: string) => instance.delete(`/v1/provider/tours/${id}`);
const delete_image_tour = (image_location: string, tour_id: string) => instance.delete(`/v1/provider/tour/image?image_location=${image_location}&tour_id=${tour_id}`);

const provider_tours_get = (page: number = 0) => {
    return instance.get(`/v1/provider/tours?page=${page}`);
};

const tours_get = (page: number = 0, take?: number) => {
    take = take ?? 15;
    return instance.get(`/v1/main/tours`);
};

export {
    tours_get,
    delete_image_tour,
    delete_tour,
    get_tour_create_stage,
    loginRequest,
    getSessionDataReq,
    logoutReq,
    create_blank_tour as createBlankTour,
    provider_tours_get,
};

/**
 * Geolocation services
 */

const geo_request = async (value: string) => {
    try {
        // const req = await instance.get<{ ok: boolean; data: Array<ResponseGeoObject> }>("/v1/geo", { params: { location: value } });
    } catch (e) {
        console.log(e);
    }
};

export { geo_request };

/**
 * This should move to socket
 */
type LogMessageLevel = "info" | "error" | "debug" | "crit";

export const logger = (log: { level: LogMessageLevel; message: string; e?: unknown }) => {
    if (log.e && log.e instanceof Error) {
        const req = axios.post("/v1/dev/log", log);
        req.then((d) => console.log(d)).catch((e) => console.log(e));
    }
};

/**
 * Axios Instance
 */
export function getPhotoLink(route: string) {
    try {
        if (route.split(":")[0] == "blob") {
            return route;
        } else return API_URL + "/" + route;
    } catch (error) {
        return API_URL + "/" + route;
    }
}

const defaultMetadata: AxiosRequestConfig["metadata"] = {
    time: 0,
    delay: 0,
    startTime: new Date().getMilliseconds(),
    endTime: new Date().getMilliseconds(),
};

const instance = axios.create({
    baseURL: API_URL,
    timeout: 6000,
    withCredentials: true,
    headers: { "Access-Control-Allow-Origin": "*" },
    _retry: 0,
    metadata: defaultMetadata,
});

/**
 * Axios update sesssion interceptor logic
 */

let isRefreshing = false;

const requestQueue: Array<{ resolve: () => void; reject: (error: AxiosError) => void }> = [];
const refreshAccessToken = () => instance.get(`${API_URL}/v1/auth/refresh`);

const processQueue = (error: AxiosError | null) => {
    requestQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else prom.resolve();
    });

    requestQueue.length = 0;
};

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 403 && originalRequest._retry < 2) {
            originalRequest._retry++;
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    await refreshAccessToken();
                    processQueue(null);
                } catch (refreshError) {
                    if (refreshError instanceof AxiosError) processQueue(refreshError);
                } finally {
                    isRefreshing = false;
                }
            }

            return new Promise((resolve, reject) => {
                requestQueue.push({ resolve: () => resolve(undefined), reject });
            })
                .then(() => instance(originalRequest))
                .catch((err) => Promise.reject(err));
        }

        return Promise.reject(error);
    }
);

/**
 * Hooks that calculate request time and response
 */
const useRequestTimer = () => {
    const ref = useRef(false);

    useEffect(() => {
        if (!ref.current) {
            instance.interceptors.request.use(
                (req) => {
                    req.metadata!.startTime = new Date().getMilliseconds();

                    return req;
                },
                (err) => {
                    if (err instanceof AxiosError && err.config) {
                        err.config.metadata!.startTime = new Date().getMilliseconds();
                        return Promise.reject(err);
                    }
                }
            );

            instance.interceptors.response.use(
                (res) => {
                    res.config.metadata!.endTime = new Date().getMilliseconds();
                    res.config.metadata!.time = res.config.metadata!.endTime - res.config.metadata!.startTime;
                    res.config.metadata!.delay = Math.min(Math.abs(250 - res.config.metadata!.time), 250);

                    return res;
                },
                (err) => {
                    if (err instanceof AxiosError && err.config) {
                        err.config.metadata!.endTime = new Date().getMilliseconds();
                        err.config.metadata!.time = err.config.metadata!.endTime - err.config.metadata!.startTime;
                        err.config.metadata!.delay = Math.min(Math.abs(250 - err.config.metadata!.time), 250);

                        return Promise.reject(err);
                    }
                    return Promise.reject(err);
                }
            );
            ref.current = true;
        }
    }, []);

    return null;
};

/**
 * Hook dedicated to listen network connection and all coming errors and etc
 */
function useNetwork() {
    const ref = useRef(false);
    const { toast } = useToast();

    // Checking is request connection in place or not
    useEffect(() => {
        if (!ref.current) {
            instance.interceptors.response.use(
                (res) => res,
                (error) => {
                    if (!error.response) {
                        toast({ description: "Network errror happened, please connect to internet" });
                        return Promise.reject(error);
                    } else return Promise.reject(error);
                }
            );
            ref.current = true;
        }
    }, []);

    useRequestTimer();

    return null;
}

export { instance, useRequestTimer, useNetwork, API_ROUTES, getUserImage };

type TravellerSearchQuery = z.infer<typeof TravellerSearchQuerySchema>;
type TravellerSearchResponse = z.infer<typeof TravellerSearchResponseSchema>;

export const fetchSearchResults = async (filters: TravellerSearchQuery): Promise<TravellerSearchResponse> => {
    try {
        const response = await instance.get<TravellerSearchResponse>(TRAVELLER_API_ENDPOINTS.searchTours.url, {
            params: filters,
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data?.message || "An error occurred while fetching search results");
        }
        throw error;
    }
};

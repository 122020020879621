import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import type { DefaultEventsMap } from "@socket.io/component-emitter";
import type { Socket } from "socket.io-client";
import io from "socket.io-client";
import { instance } from "shared/api/signature";
import { useAppSelector } from "shared/helpers/utils";
import { UserSelector } from "entities/store/user";
import { Button } from "@/components/ui/button";

interface SocketContextType {
    isConnected: boolean;
    sendMessage: () => void;
    sessionId: string | null;
    userId: string | null;
}

const SockContext = createContext<SocketContextType | null>(null);

interface SockProviderProps {
    children: React.ReactNode;
}

interface SessionData {
    sessionId: string;
    userId: string;
}

function SockProvider({ children }: SockProviderProps) {
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        const savedSessionId = localStorage.getItem("sessionId");

        const socket: Socket = io(import.meta.env.VITE_SOCKET_URL, {
            transports: ["websocket"],
            auth: { sessionId: savedSessionId },
        });

        socketRef.current = socket;

        socket.on("connect", () => {
            console.log("Connected to WebSocket");
            setIsConnected(true);
        });

        socket.on("disconnect", () => {
            console.log("Disconnected from WebSocket");
            setIsConnected(false);
        });

        socket.on("session", ({ sessionId, userId }: SessionData) => {
            localStorage.setItem("sessionId", sessionId);
            setSessionId(sessionId);
            setUserId(userId);
        });

        socket.on("error", (error: Error) => {
            console.error("WebSocket error:", error);
        });

        socket.on("message", (data: any) => {
            console.log("Received message:", data);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const sendMessage = () => {
        if (socketRef.current) socketRef.current.emit("message", "Ping");
    };

    const value: SocketContextType = {
        isConnected,
        sendMessage,
        sessionId,
        userId,
    };

    return <SockContext.Provider value={value}>{children}</SockContext.Provider>;
}

export default SockProvider;

import React, { forwardRef } from "react";
import Heart from "./components/Heart";
import { SinSvg } from "@/svg/SinSvg";

const shadowColors = [
    "rgba(0, 0, 255, 0.3)", // Blue
    "rgba(0, 255, 0, 0.3)", // Green
    "rgba(255, 192, 203, 0.3)", // Pink
];
type Ladderitem = { name: string; id: string; link: string };

const LadderText = ({ items }: { items: Ladderitem[] }) => {
    return (
        <div className="font-bold flex flex-col w-full mt-12">
            <h2 className="self-start z-10 text-xl md:text-3xl font-bold ml-[10vw]">HOTTEST BLOG POSTS</h2>
            <SinSvg />
            {items.map((item, index) => {
                const ml = 15 * (index + 1);
                const [isHovered, setIsHovered] = React.useState(false);

                return (
                    <div className="w-full relative" key={index}>
                        <div
                            className="absolute inset-0 -z-10 rounded-lg"
                            style={{
                                backgroundColor: shadowColors[index],
                                filter: "blur(60px)",
                                width: "30vw",
                                transform: "scale(1.05)",
                                zIndex: 1,
                                marginLeft: `${ml}vw`,
                            }}
                        />

                        <p
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            className="text-xl md:text-3xl p-4 rounded-lg relative transition-all duration-200 ease-in-out cursor-pointer"
                            style={{ marginLeft: `${ml}vw`, zIndex: 2, color: isHovered ? "rgba(25, 25, 112, 1)" : "", transform: isHovered ? "translateY(-5px)" : "" }}
                        >
                            {index + 1}. {item.name}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

// TODO: ADD FETCH + API
function BlogRecommendations() {
    const posts = [
        { name: "WHY YOU SHOULD TRAVEL", link: "/blog/why-you-should-travel", id: "/test-test" },
        { name: "ROME - THE BEAUTY", link: "/blog/rome-the-beauty", id: "rome" },
        { name: "MOSCOW NEVER SLEEPS", link: "/blog/moscow-never-sleeps", id: "moscow-never" },
    ];

    return (
        <div className="bg w-full h-80 relative">
            <div className="flex flex-col items-center w-full h-full ">
                <LadderText items={posts} />
            </div>
            <Heart className="absolute left-0 top-0" />
        </div>
    );
}

export default BlogRecommendations;

import { z } from "zod";
import { useAppSelector } from "shared/helpers/utils";
import { UserSelector } from "entities/store/user";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { Separator } from "@/components/ui/separator";
import { LoadingButton } from "@/components/ui/loading-button";
import { instance } from "shared/api/signature";
import { zodResolver } from "@hookform/resolvers/zod";
import { useHandleForm } from "features/user/forms/form";
import EmailInfo from "./components/EmailInfo";
import ProfileImage from "./components/ProfileImage";
import { AUTH_API_ENDPOINTS } from "shared/types/api";
import { useTranslation } from "react-i18next";

const updateUserSchema = AUTH_API_ENDPOINTS.updateUserSettings.body;

type UpdateUserData = z.infer<typeof updateUserSchema>;

function ProfileInfo() {
    const { t } = useTranslation();
    const data = useAppSelector(UserSelector.preview);
    const form = useForm<UpdateUserData>({
        resolver: zodResolver(updateUserSchema),
        defaultValues: data || undefined,
    });

    const updateUser = (data: UpdateUserData) => instance.put(AUTH_API_ENDPOINTS.updateUserSettings.path, data);

    const { state, handler } = useHandleForm<UpdateUserData, UpdateUserData>(updateUser, { initData: data || {} });
    const handle = (data: UpdateUserData) => handler(data);
    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader>
                <CardTitle>Profile</CardTitle>
                <CardDescription>Manage your profile info</CardDescription>
            </CardHeader>

            <div className="flex flex-col items-center md:items-start md:flex-row mt-4">
                <ProfileImage />
                <div className="flex-1 flex flex-col space-y-3 w-full">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handle)} className="flex flex-col space-y-4 mb-0">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t("ProfilePage.info.name")}</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="surname"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t("ProfilePage.info.surname")}</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="patronymic"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t("ProfilePage.info.patronymic")}</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <LoadingButton loading={state.loading} variant="designed" type="submit" className="h-22 md:h-22 self-center">
                                {t("provider.CreateTourStandalone.submit")}
                            </LoadingButton>
                        </form>
                    </Form>
                </div>
            </div>
        </Card>
    );
}

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState } from "react";
import { PlusCircle, Trash2, Mail, Phone } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";

export const ManageTeam = () => {
    const [guides, setGuides] = useState([
        { id: 1, name: "John Doe", expertise: "Mountain Hiking", email: "john@example.com", phone: "+1234567890" },
        { id: 2, name: "Jane Smith", expertise: "City Tours", email: "jane@example.com", phone: "+0987654321" },
    ]);
    const [newGuide, setNewGuide] = useState({ name: "", expertise: "", email: "", phone: "" });
    const [showAlert, setShowAlert] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const addGuide = () => {
        if (newGuide.name && newGuide.expertise && newGuide.email && newGuide.phone) {
            setGuides([...guides, { ...newGuide, id: guides.length + 1 }]);
            setNewGuide({ name: "", expertise: "", email: "", phone: "" });
            setShowAlert(true);
            setIsDialogOpen(false);
            setTimeout(() => setShowAlert(false), 3000);
        }
    };

    const removeGuide = (id) => {
        setGuides(guides.filter((guide) => guide.id !== id));
    };

    return (
        <Card className="p-6 w-full mx-auto border-0 shadow-none">
            <CardTitle>
                <h1 className="text-2xl font-bold mb-6">Guide Team</h1>
            </CardTitle>

            {showAlert && (
                <Alert className="mb-4">
                    <AlertDescription>New guide added successfully!</AlertDescription>
                </Alert>
            )}

            <div className="mb-6">
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogTrigger asChild>
                        <Button className="flex items-center">
                            <PlusCircle className="mr-2" size={20} />
                            Add New Guide
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Add New Guide</DialogTitle>
                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="name" className="text-right">
                                    Name
                                </Label>
                                <Input id="name" value={newGuide.name} onChange={(e) => setNewGuide({ ...newGuide, name: e.target.value })} className="col-span-3" />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="expertise" className="text-right">
                                    Expertise
                                </Label>
                                <Input
                                    id="expertise"
                                    value={newGuide.expertise}
                                    onChange={(e) => setNewGuide({ ...newGuide, expertise: e.target.value })}
                                    className="col-span-3"
                                />
                            </div>

                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="email" className="text-right">
                                    Email
                                </Label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={newGuide.email}
                                    onChange={(e) => setNewGuide({ ...newGuide, email: e.target.value })}
                                    className="col-span-3"
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                <Label htmlFor="phone" className="text-right">
                                    Phone
                                </Label>
                                <Input
                                    id="phone"
                                    type="tel"
                                    value={newGuide.phone}
                                    onChange={(e) => setNewGuide({ ...newGuide, phone: e.target.value })}
                                    className="col-span-3"
                                />
                            </div>
                        </div>
                        <Button onClick={addGuide}>Add Guide</Button>
                    </DialogContent>
                </Dialog>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {guides.map((guide) => (
                    <Card key={guide.id}>
                        <CardHeader>
                            <CardTitle>{guide.name}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p>
                                <strong>Expertise:</strong> {guide.expertise}
                            </p>
                            <p className="flex items-center mt-2">
                                <Mail className="mr-2" size={16} />
                                {guide.email}
                            </p>
                            <p className="flex items-center mt-1">
                                <Phone className="mr-2" size={16} />
                                {guide.phone}
                            </p>
                            <Button variant="destructive" className="mt-4" onClick={() => removeGuide(guide.id)}>
                                <Trash2 className="mr-2" size={16} />
                                Remove
                            </Button>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </Card>
    );
};
// Can be brought back
// export function ProfileAccountSettings() {
//     return (
//         <Card className="w-full border-0 shadow-none">
//             <CardHeader>
//                 <CardTitle>Account Settings</CardTitle>
//                 <CardDescription>Manage your account details and preferences</CardDescription>
//             </CardHeader>
//             <CardContent className="space-y-4">
//                 <EmailInfo />
//                 <Button>Save Changes</Button>
//             </CardContent>
//         </Card>
//     );
// }

export function ProfileEmail() {
    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader>
                <CardTitle>Email</CardTitle>
                <CardDescription>Control your email and what you recieve</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <EmailInfo />
                <div className="flex items-center justify-between">
                    <Label htmlFor="marketing">Marketing emails</Label>
                    <Switch id="marketing" />
                </div>
                <div className="flex items-center justify-between">
                    <Label htmlFor="updates">Product updates</Label>
                    <Switch id="updates" />
                </div>
                <div className="flex items-center justify-between">
                    <Label htmlFor="newsletter">Newsletter</Label>
                    <Switch id="newsletter" />
                </div>
                <Button>Save Preferences</Button>
            </CardContent>
        </Card>
    );
}

export function ProfileSecurity() {
    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader>
                <CardTitle>Security</CardTitle>
                <CardDescription>Manage your account security settings</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="current-password">Current Password</Label>
                    <Input id="current-password" type="password" />
                </div>
                <div className="space-y-2">
                    <Label htmlFor="new-password">New Password</Label>
                    <Input id="new-password" type="password" />
                </div>
                <div className="space-y-2">
                    <Label htmlFor="confirm-password">Confirm New Password</Label>
                    <Input id="confirm-password" type="password" />
                </div>
                {/* <div className="flex items-center justify-between">
                    <Label htmlFor="two-factor">Two-factor authentication</Label>
                    <Switch id="two-factor" />
                </div> */}
                <Button>Update Security Settings</Button>
            </CardContent>
        </Card>
    );
}

export function ProfilePaymentInformation() {
    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader>
                <CardTitle>Payment Information</CardTitle>
                <CardDescription>Manage your payment methods and billing details</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="card-number">Card Number</Label>
                    <Input id="card-number" placeholder="**** **** **** ****" />
                </div>
                <div className="flex space-x-4">
                    <div className="flex-1 space-y-2">
                        <Label htmlFor="expiry">Expiry Date</Label>
                        <Input id="expiry" placeholder="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-2">
                        <Label htmlFor="cvv">CVV</Label>
                        <Input id="cvv" placeholder="***" />
                    </div>
                </div>
                <div className="space-y-2">
                    <Label htmlFor="billing-address">Billing Address</Label>
                    <Input id="billing-address" placeholder="Enter your billing address" />
                </div>
                <Button>Save Payment Information</Button>
            </CardContent>
        </Card>
    );
}

export function ProfileNotificationSettings() {
    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader>
                <CardTitle>Notification Settings</CardTitle>
                <CardDescription>Customize how you receive notifications</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="notification-method">Preferred Notification Method</Label>
                    <Select>
                        <SelectTrigger id="notification-method">
                            <SelectValue placeholder="Select a method" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="email">Email</SelectItem>
                            <SelectItem value="push">Push Notification</SelectItem>
                            <SelectItem value="sms">SMS</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex items-center justify-between">
                    <Label htmlFor="booking-notifications">Booking Notifications</Label>
                    <Switch id="booking-notifications" />
                </div>
                <div className="flex items-center justify-between">
                    <Label htmlFor="promo-notifications">Promotional Notifications</Label>
                    <Switch id="promo-notifications" />
                </div>
                <div className="flex items-center justify-between">
                    <Label htmlFor="system-notifications">System Notifications</Label>
                    <Switch id="system-notifications" />
                </div>
                <Button>Update Notification Preferences</Button>
            </CardContent>
        </Card>
    );
}

export default ProfileInfo;

import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AUTH_API_ENDPOINTS } from "shared/types/api";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { useAppDispatch } from "shared/helpers/utils";
import { setUserData } from "entities/store/user";
import { instance } from "shared/api/signature";
import { ModalWindowProps } from "shared/providers/ModalWindow";
import PickUserProviderRole from "@/default/PickUserProvider";
import { PhoneInput } from "@/Input/phone-input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getErrorMessage } from "shared/helpers/utils";

const signupSchema = AUTH_API_ENDPOINTS.signup.body;

import { ErrorDisplay } from "@/components/ui/error-display";
import { AxiosError } from "axios";

type SignUpFormValues = z.infer<typeof signupSchema>;

function SignUpForm({ close }: Partial<ModalWindowProps>) {
    const { t } = useTranslation();
    const form = useForm<SignUpFormValues>({
        resolver: zodResolver(signupSchema),
        defaultValues: { name: "", surname: "", patronymic: "", phone: "", email: "", password: "", role: "TRAVELLER" },
    });

    const [errorResponse, setErrorResponse] = useState({});

    const dispatch = useAppDispatch();

    const onSubmit = async (data: SignUpFormValues) => {
        try {
            const response = await instance.post<z.infer<typeof AUTH_API_ENDPOINTS.signup.response>>(AUTH_API_ENDPOINTS.signup.url, data);
            if (response.data.ok) {
                dispatch(setUserData(response.data.data));
                close?.();
            }
        } catch (error) {
            // Handle error (e.g., set error message in form)
            console.error("Signup failed:", error);
            if (error instanceof AxiosError) {
                if (error.response) {
                    setErrorResponse(error.response.data);
                }
            }
        }
    };
    const setRole = (isProvider: boolean) => form.setValue("role", isProvider ? "PROVIDER" : "TRAVELLER");

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{t("form.name")}</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="surname"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{t("form.surname")}</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="patronymic"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{t("form.patronymic")}</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />
                <Controller
                    name="phone"
                    control={form.control}
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{t("form.phone")}</FormLabel>
                            <FormControl>
                                <PhoneInput {...field} defaultCountry="US" international className="flex" />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{t("form.email")}</FormLabel>
                            <FormControl>
                                <Input {...field} type="email" />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{t("form.password")}</FormLabel>
                            <FormControl>
                                <Input {...field} type="password" />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />
                {Object.keys(errorResponse).length > 0 && <ErrorDisplay errorData={errorResponse} />}

                <PickUserProviderRole setRole={setRole} />
                <Button type="submit" className="w-full">
                    {t("form.signUp")}
                </Button>
            </form>
        </Form>
    );
}

export default SignUpForm;

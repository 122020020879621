import { createContext, useState, useContext, useEffect } from "react";

type SliderContextType = { percent: number; setPercent: React.Dispatch<React.SetStateAction<number>> };
const SliderContext = createContext<SliderContextType | undefined>(undefined);
type Props = { children: JSX.Element };

const useSlider = () => {
    const context = useContext(SliderContext);
    if (context === undefined) throw new Error("useSlider must be used woth SliderProvider");
    return context;
};

type setSliderType = { percent: number; delay?: number };
const useSetSlider = ({ percent, delay = 0 }: setSliderType) => {
    const slider = useSlider();
    useEffect(() => {
        const timeout = setTimeout(() => slider.setPercent(percent), delay);
        return () => clearTimeout(timeout);
    }, []);

    return null;
};

function useSliderView() {
    const slider = useSlider();

    function moveTo(sliderNumber: number) {
        const elem = document.getElementById("progressbar");
        let stepValue = 0;
        function frame() {
            if (elem) {
                elem.style.width = sliderNumber.toString() + "%";
                stepValue = stepValue + 10;
            }
        }
        frame();
    }

    useEffect(() => {
        moveTo(slider.percent);
    }, [slider.percent]);

    return null;
}
function FooterProgressBar() {
    useSliderView();

    /**
     * Please do not touch
     * TODO: change colors of span
     */
    return (
        <div className="w-full h-[5px] mx-auto relative bg-gray-700 text-black shadow-inner">
            <span
                className={
                    "progressbar block h-full w-0 bg-blue-500 bg-gradient-to-t from-blue-800 via-gray-800 to-blue-500 relative overflow-hidden text-white text-center transition-all duration-700 ease"
                }
                id="progressbar"
            ></span>
        </div>
    );
}
const SliderProvider: React.FC<Props> = ({ children }) => {
    const [percent, setPercent] = useState(0);

    return (
        <SliderContext.Provider value={{ percent, setPercent }}>
            <>{children}</>
        </SliderContext.Provider>
    );
};

export { useSlider, useSliderView, useSetSlider, FooterProgressBar };

export default SliderProvider;

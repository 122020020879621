import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import React, { FC, ReactNode, useEffect } from "react";
import { Modal, useModal } from "shared/providers/ModalWindow";
import { CustomComponentProps } from "shared/types/types";
import { useTranslation } from "react-i18next";
import { BaseRoutes } from "shared/helpers/const";
import { useNavigate } from "react-router-dom";
import { UserCircle } from "lucide-react";
import { useAppDispatch, useAppSelector } from "shared/helpers/utils";
import user, { UserSelector, logoutThunk } from "entities/store/user";
import { User } from "shared/types/api";

const UserInfoItemMenu: FC<CustomComponentProps & React.PropsWithChildren> = ({ children, ...props }) => {
    return (
        <DropdownMenuItem className="flex justify-center" {...props}>
            {children || "Dropdown Menu"}
        </DropdownMenuItem>
    );
};

const UserIcon = () => <UserCircle className="hover:fill-slate-600 active:fill-slate-600" />;

function UserLogged() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const data = useAppSelector(UserSelector.data);

    const navigateToProfile = () => navigate(BaseRoutes.PROFILEPAGE);
    const navigateToTours = () => navigate(data?.role === "PROVIDER" ? BaseRoutes.PROVIDERS_TOURS : BaseRoutes.TRAVELLERS_TOURS);

    const logout_ = () => {
        dispatch(logoutThunk());
        navigate("/");
    };

    return (
        <>
            <DropdownMenuTrigger>
                <UserIcon />
            </DropdownMenuTrigger>

            <DropdownMenuContent>
                <UserInfoItemMenu onClick={navigateToProfile}>{t("header.profile")}</UserInfoItemMenu>
                <DropdownMenuSeparator />
                <UserInfoItemMenu onClick={navigateToTours}>{t("header.tours")}</UserInfoItemMenu>
                <DropdownMenuSeparator />
                <UserInfoItemMenu onClick={logout_}>{t("header.logout")}</UserInfoItemMenu>
            </DropdownMenuContent>
        </>
    );
}

function UserIsNotLoggedContent() {
    const { show } = useModal();
    const { t } = useTranslation();

    const showLogin = () => show(Modal.LOGIN);
    const showSignUp = () => show(Modal.SIGNUP);

    return (
        <>
            <DropdownMenuTrigger>
                <UserIcon />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <UserInfoItemMenu onClick={showLogin}>{t("header.login")}</UserInfoItemMenu>
                <DropdownMenuSeparator />
                <UserInfoItemMenu onClick={showSignUp}>{t("header.signup")}</UserInfoItemMenu>
            </DropdownMenuContent>
        </>
    );
}
``;
interface UserInfoDropdownProps {
    isLogged: boolean;
}

const UserInfoDropdown: FC<UserInfoDropdownProps> = ({ isLogged }) => <DropdownMenu>{isLogged ? <UserLogged /> : <UserIsNotLoggedContent />}</DropdownMenu>;

export default UserInfoDropdown;

const BackgroundLinear = () => {
    return (
        <div className="absolute top-0 left-0 right-0 h-full -z-10 w-screen p-0 m-0">
            <svg className="w-full" height="419" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_5_11477" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="419">
                    <rect width="100%" height="419" fill="url(#paint0_linear_5_11477)" />
                </mask>
                <g mask="url(#mask0_5_11477)">
                    <rect y="-282" width="100%" height="960" fill="url(#paint1_linear_5_11477)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_5_11477" x1="536" y1="-141.5" x2="1295" y2="538.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6287A2" />
                        <stop offset="1" stopColor="#5EC0CB" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_5_11477" x1="536" y1="-606.201" x2="1722.76" y2="-142.141" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6287A2" />
                        <stop offset="1" stopColor="#5EC0CB" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default BackgroundLinear;

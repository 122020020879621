import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en/translation.json";
import ruTranslation from "./locales/ru/translation.json";
import { TranslationKeys } from "shared/types/translationTypes";
const resources = { en: { translation: enTranslation as TranslationKeys }, ru: { translation: ruTranslation } };

i18n.use(initReactI18next)
    .init({ resources, lng: "en", fallbackLng: "en", interpolation: { escapeValue: false } })
    .catch((e) => console.log(e));

export default i18n;

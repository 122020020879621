import React from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { paymentOptions } from "shared/types/api";

interface AcceptedPaymentsPanelProps {
    acceptedPayments: string[];
    updateAcceptedPayments: (payments: string[]) => void;
}

const AcceptedPaymentsPanel: React.FC<AcceptedPaymentsPanelProps> = ({ acceptedPayments, updateAcceptedPayments }) => {
    const handlePaymentChange = (paymentId: string, checked: boolean) => {
        if (checked) {
            updateAcceptedPayments([...acceptedPayments, paymentId]);
        } else {
            updateAcceptedPayments(acceptedPayments.filter((id) => id !== paymentId));
        }
    };

    return (
        <Card>
            <CardHeader>Accepted Payment Methods</CardHeader>
            <CardContent>
                <div className="space-y-4">
                    {paymentOptions.map((option) => (
                        <div key={option.id} className="flex items-center space-x-2">
                            <Checkbox
                                id={option.id}
                                checked={acceptedPayments.includes(option.id)}
                                onCheckedChange={(checked) => handlePaymentChange(option.id, !!checked)}
                            />
                            <label htmlFor={option.id} className="text-sm font-medium">
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default AcceptedPaymentsPanel;

import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";
import { useMeasure } from "react-use";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils"; // Assuming you have a utility for class names
import { ItineraryDayWrapper } from "./itinerary-day-wrapper";

type Tab = { id: number; label: string; content: React.ReactNode };

interface Tour {
    itinerary: Array<{
        dayNumber: number;
        title: string;
        description: string;
        locationIds: string[];
    }>;
    tourLocations: any[]; // You might want to define a more specific type here
}

interface InteractiveDayListProps {
    tour: Tour;
    handleAddLocation: (dayIndex: number, locationId: string) => void;
    handleRemoveLocation: (dayIndex: number, locationId: string) => void;
    handleItineraryChange: (dayIndex: number, field: keyof Tour["itinerary"][0], value: any) => void;
    className?: string;
    rounded?: string;
}

export default function InteractiveDayList({ tour, handleAddLocation, handleRemoveLocation, handleItineraryChange, className, rounded }: InteractiveDayListProps) {
    const [activeTab, setActiveTab] = useState(0);
    const [direction, setDirection] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [ref, bounds] = useMeasure<HTMLDivElement>();
    const scrollRef = useRef<HTMLDivElement>(null);

    const tabs: Tab[] = useMemo(
        () =>
            tour.itinerary.map((day, index) => ({
                id: index,
                label: `${day.dayNumber}`,
                content: (
                    <motion.div
                        key={index}
                        className="bg-white rounded-lg shadow-lg p-6 w-full"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    >
                        <div className="flex items-center mb-4">
                            <Input
                                placeholder="Station Name"
                                value={day.title}
                                onChange={(e) => handleItineraryChange(index, "title", e.target.value)}
                                className="text-lg font-semibold flex-grow"
                            />
                        </div>
                        <Textarea
                            placeholder="Day's Journey Description"
                            value={day.description}
                            onChange={(e) => handleItineraryChange(index, "description", e.target.value)}
                            className="min-h-[100px] mb-4"
                        />
                        <ItineraryDayWrapper
                            dayIndex={index}
                            dayLocationIds={day.locationIds}
                            tourLocations={tour.tourLocations}
                            onAddLocation={handleAddLocation}
                            onRemoveLocation={handleRemoveLocation}
                        />
                    </motion.div>
                ),
            })),
        [tour, handleAddLocation, handleRemoveLocation, handleItineraryChange]
    );

    const handleTabClick = (newTabId: number) => {
        if (newTabId !== activeTab && !isAnimating) {
            const newDirection = newTabId > activeTab ? 1 : -1;
            setDirection(newDirection);
            setActiveTab(newTabId);
        }
    };

    const variants = {
        initial: (direction: number) => ({ x: 300 * direction, opacity: 0, filter: "blur(4px)" }),
        active: { x: 0, opacity: 1, filter: "blur(0px)" },
        exit: (direction: number) => ({ x: -300 * direction, opacity: 0, filter: "blur(4px)" }),
    };

    useEffect(() => {
        if (scrollRef.current) {
            const scrollWidth = scrollRef.current.scrollWidth;
            const clientWidth = scrollRef.current.clientWidth;
            const maxScroll = scrollWidth - clientWidth;
            const scrollPosition = (activeTab / (tabs.length - 1)) * maxScroll;
            scrollRef.current.scrollLeft = scrollPosition;
        }
    }, [activeTab, tabs.length]);

    useEffect(() => {
        if (activeTab >= tour.itinerary.length) {
            setActiveTab(Math.max(0, tour.itinerary.length - 1));
        }
    }, [tour.itinerary.length, activeTab]);

    return (
        <div className="flex flex-col items-center w-full bg-gradient-to-b from-sky-200 to-sky-100 p-4 sm:p-6 rounded-lg">
            <div className="w-full mb-6 overflow-x-auto">
                <div
                    ref={scrollRef}
                    className={cn(
                        "flex space-x-1 border border-none rounded-full cursor-pointer bg-neutral-600 px-[3px] py-[3.2px] shadow-inner-shadow whitespace-nowrap",
                        className,
                        rounded
                    )}
                >
                    {tabs.map((tab) => (
                        <button
                            type="button"
                            key={tab.id}
                            onClick={() => handleTabClick(tab.id)}
                            className={cn(
                                "relative rounded-full w-8 h-8 flex items-center justify-center text-xs font-medium text-neutral-200 transition focus-visible:outline-1 focus-visible:ring-1 focus-visible:outline-none",
                                activeTab === tab.id ? "text-white" : "hover:text-neutral-300/60 text-neutral-200/80",
                                rounded
                            )}
                            style={{ WebkitTapHighlightColor: "transparent" }}
                        >
                            {activeTab === tab.id && (
                                <motion.span
                                    layoutId="bubble"
                                    className="absolute inset-0 z-10 bg-neutral-700 mix-blend-difference shadow-inner-shadow border border-white/10"
                                    style={{ borderRadius: "50%" }}
                                    transition={{ type: "spring", bounce: 0.19, duration: 0.4 }}
                                />
                            )}
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className="relative w-full mb-4">
                <div className="absolute bottom-0 left-0 right-0 h-2 bg-gray-800 rounded-full" />
                <motion.div
                    className="absolute bottom-2 left-0 h-4 w-4 bg-blue-600 rounded-full"
                    initial={{ left: "0%" }}
                    animate={{ left: `${(activeTab / (tabs.length - 1)) * 100}%` }}
                    transition={{ type: "spring", bounce: 0.2, duration: 0.4 }}
                />
            </div>
            <MotionConfig transition={{ duration: 0.4, type: "spring", bounce: 0.2 }}>
                <motion.div className="relative mx-auto w-full overflow-hidden" initial={false} animate={{ height: bounds.height }}>
                    <div ref={ref}>
                        <AnimatePresence initial={false} mode="popLayout">
                            {tabs[activeTab] && (
                                <motion.div
                                    key={activeTab}
                                    variants={variants}
                                    initial="initial"
                                    animate="active"
                                    exit="exit"
                                    custom={direction}
                                    onAnimationStart={() => setIsAnimating(true)}
                                    onAnimationComplete={() => setIsAnimating(false)}
                                >
                                    {tabs[activeTab].content}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </motion.div>
            </MotionConfig>
            <div className="flex justify-between w-full mt-4">
                <button
                    type="button"
                    onClick={() => handleTabClick(Math.max(0, activeTab - 1))}
                    disabled={activeTab === 0}
                    className="p-2 bg-blue-500 text-white rounded-full disabled:opacity-50"
                >
                    <ChevronLeft />
                </button>
                <button
                    type="button"
                    onClick={() => handleTabClick(Math.min(tabs.length - 1, activeTab + 1))}
                    disabled={activeTab === tabs.length - 1}
                    className="p-2 bg-blue-500 text-white rounded-full disabled:opacity-50"
                >
                    <ChevronRight />
                </button>
            </div>
        </div>
    );
}

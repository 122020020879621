import { getFileIconAndColor } from "../utils";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { X } from "lucide-react";

type FileUploadProgress = { progress: number; File: File; source?: AbortSignal };

type RemoveFileFn = (file: File) => void;
type LoadingFileComponentProps = { fileUploadProgress: FileUploadProgress; removeFile: RemoveFileFn };
type LoadingFileListProps = { filesToUpload: FileUploadProgress[]; removeFile: RemoveFileFn };

/**
 * Preview component of loading file {loading file on upload}
 */
function LoadingFile({ fileUploadProgress, removeFile }: LoadingFileComponentProps) {
    return (
        <div className="flex justify-between gap-2 rounded-lg overflow-hidden border border-slate-100 group hover:pr-0 pr-2">
            <div className="flex items-center flex-1 p-2">
                <div className="text-white">{getFileIconAndColor(fileUploadProgress.File).icon}</div>

                <div className="w-full ml-2 space-y-1">
                    <div className="text-sm flex justify-between">
                        <p className="text-muted-foreground ">{fileUploadProgress.File.name.slice(0, 25)}</p>
                        <span className="text-xs">{fileUploadProgress.progress}%</span>
                    </div>
                    <Progress value={fileUploadProgress.progress} className={getFileIconAndColor(fileUploadProgress.File).color} />
                </div>
            </div>
            {removeFile ? (
                <button
                    onClick={() => removeFile(fileUploadProgress.File)}
                    onMouseEnter={() => console.log("e")}
                    className="bg-red-500 text-white transition-all items-center justify-center cursor-pointer px-2 hidden group-hover:flex"
                >
                    <X size={20} />
                </button>
            ) : null}
        </div>
    );
}

const LoadingFileList = ({ filesToUpload, removeFile }: LoadingFileListProps) => {
    return filesToUpload.map((fileUploadProgress, idx) => (
        <LoadingFile key={fileUploadProgress.File.name + idx} fileUploadProgress={fileUploadProgress} removeFile={removeFile} />
    ));
};
/**
 * What this do?
 */
function LoadingFiles({ filesToUpload, removeFile }: LoadingFileListProps) {
    if (filesToUpload.length <= 0) return null;
    return (
        <div>
            <ScrollArea className="h-40">
                <p className="font-medium my-2 mt-6 text-muted-foreground text-sm">Files to upload</p>
                <div className="space-y-2 pr-3">
                    <LoadingFileList removeFile={removeFile} filesToUpload={filesToUpload} />
                </div>
            </ScrollArea>
        </div>
    );
}

export { LoadingFiles };

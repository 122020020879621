import React, { useState } from "react";
import { useSubmit } from "features/user/forms/form";
import { UserSelector } from "entities/store/user";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { instance } from "shared/api/signature";
import { useAppSelector } from "shared/helpers/utils";
import { LoadingButton } from "@/components/ui/loading-button";
import { AUTH_API_ENDPOINTS } from "shared/types/api";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";

function EmailManagement() {
    const email = useAppSelector(UserSelector.email);
    const verification = useAppSelector(UserSelector.verification);
    const [newEmail, setNewEmail] = useState("");
    const [isChangingEmail, setIsChangingEmail] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const { state, handler } = useSubmit();

    const handleVerifyEmail = async () => {
        try {
            await instance.post(AUTH_API_ENDPOINTS.verifyEmail.url, { email: newEmail || email });
            handler();
            setIsChangingEmail(false);
        } catch (error) {
            console.error("Error verifying email:", error);
        }
    };

    const handleChangeEmail = async () => {
        try {
            await instance.put(AUTH_API_ENDPOINTS.updateUser.url, { email: newEmail });
            handler();
            setIsChangingEmail(false);
            setIsConfirmDialogOpen(false);
            // You might want to update the global state here to reflect the new email
            // and set the email as unverified
        } catch (error) {
            console.error("Error changing email:", error);
        }
    };

    const handleSubmit = () => {
        if (verification?.isEmailVerified) {
            setIsConfirmDialogOpen(true);
        } else {
            handleChangeEmail();
        }
    };

    return (
        <div className="flex-1">
            <div className="mb-1">
                <Label className="w-full">
                    Email
                    {verification?.isEmailVerified ? (
                        <span className="opacity-55 text-green-800 ml-2">verified</span>
                    ) : (
                        <span className="opacity-55 text-red-800 ml-2">not verified</span>
                    )}
                </Label>
            </div>
            {!verification?.isEmailVerified && (
                <Alert variant="destructive" className="mb-4">
                    <AlertDescription>Your email is not confirmed. Please verify your email address or change it if incorrect.</AlertDescription>
                </Alert>
            )}
            <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4">
                <Input
                    value={isChangingEmail ? newEmail : email || ""}
                    onChange={(e) => setNewEmail(e.target.value)}
                    disabled={!isChangingEmail}
                    className="flex-grow"
                    placeholder={isChangingEmail ? "New email address" : ""}
                />
                {isChangingEmail ? (
                    <>
                        <LoadingButton onClick={handleSubmit} loading={state.loading}>
                            Save
                        </LoadingButton>
                        <LoadingButton variant="outline" onClick={() => setIsChangingEmail(false)}>
                            Cancel
                        </LoadingButton>
                    </>
                ) : (
                    <>
                        <LoadingButton variant="outline" onClick={() => setIsChangingEmail(true)}>
                            Change Email
                        </LoadingButton>
                        {!verification?.isEmailVerified && (
                            <LoadingButton className="h-22 md:h-22" variant={"designed"} onClick={handleVerifyEmail} loading={state.loading}>
                                Verify Email
                            </LoadingButton>
                        )}
                    </>
                )}
            </div>

            <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Confirm Email Change</DialogTitle>
                        <DialogDescription>Are you sure you want to change your email? You will need to verify your new email address.</DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <LoadingButton variant="outline" onClick={() => setIsConfirmDialogOpen(false)}>
                            Cancel
                        </LoadingButton>
                        <LoadingButton variant="designed" onClick={handleChangeEmail} loading={state.loading}>
                            Confirm Change
                        </LoadingButton>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EmailManagement;

import React from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

type RadioProps = { value: string; text?: string } | string;

function Radio({ value, setValue }: { value: RadioProps; setValue?: (v: string) => void }) {
    if (typeof value == "string") value = { text: value, value: value };

    const [state, setState] = React.useState(false);

    const handler = () => {
        if (setValue) setValue(value.value);
        setState((prev) => !prev);
    };
    return (
        <div className="cursor-pointer group center-h w-full p-1" onClick={handler}>
            <Input readOnly={true} checked={state} className="w-2 mr-2 group-hover:cursor-pointer" type="radio" value={value.value} />
            <Label className="group-hover:cursor-pointer w-full font-normal">{value.text ?? value.value}</Label>
        </div>
    );
}

type Props = { variants: Array<RadioProps>; onChange: (value: string) => void };

const MultiRadio = React.forwardRef<HTMLDivElement, Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> & Props>(
    ({ variants, className, onChange, ...props }, ref) => {
        return (
            <div ref={ref} {...props} className={cn("grid grid-cols-2 w-full", className)}>
                {variants.map((variant) => (
                    <Radio key={typeof variant == "string" ? variant : variant.value} setValue={onChange} value={variant} />
                ))}
            </div>
        );
    }
);

export default MultiRadio;

import { PlaceholdersAndVanishInput } from "@/components/ui/placeholders-and-vanish-input";
import { Construction } from "lucide-react";
import React, { useState } from "react";
import { motion } from "framer-motion";

type Props = {};

function AiChatInput({ setValue, submit }: { submit: () => void; setValue: (v: string) => void }) {
    const texts = ["Хочу отдохнуть на солнце в январе", "Хочу покататься на лыжах в августе"];
    return <PlaceholdersAndVanishInput placeholders={texts} onChange={(e) => setValue(e.target.value)} onSubmit={submit} />;
}

import { cn } from "@/lib/utils";
import { instance } from "shared/api/signature";
import Ellipsis from "@/Ellipisis/Ellipsis";
import { Label } from "@/components/ui/label";

function AiChatMessageList({ messages }: { messages: AiChatMessageProps[] }) {
    return messages.map((d) => <AiChatMessage message={d} />);
}

// Define the type for AiChatMessageProps
type AiChatMessageProps =
    | {
          _type: "user";
          message: string;
      }
    | {
          _type: "system";
          text: string;
          suggestions: Array<{ activity: string; code: string; location: { name: string } }>;
      };

export function AiChatMessage({ message }: { message: AiChatMessageProps }) {
    const userStyles = "bg-gradient-to-r from-sky-400 to-sky-600 text-white self-end";
    const systemStyles = "bg-gradient-to-r from-gray-400 to-gray-600 text-white self-start";
    console.log(message);
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
            className={cn("p-4 rounded-lg z-50 mb-2 max-w-xs break-words", message._type === "user" ? userStyles : systemStyles)}
        >
            {message._type === "user" ? (
                <p>{message.message}</p>
            ) : (
                <div>
                    <p>{message.text}</p>
                    <div className="flex flex-col">
                        {message.suggestions.map((suggestion, index) => (
                            <div className="m-2 " key={index}>
                                {suggestion.activity} - {suggestion.code} - {suggestion.location.name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </motion.div>
    );
}

export function AiLoadingMessage() {
    const systemStyles = "bg-gradient-to-r from-gray-400 to-gray-600 text-white self-start";
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
            className={cn("p-4 rounded-lg z-50 mb-2 max-w-xs break-words flex h-[100px]  center-h flex-col", systemStyles)}
        >
            <Label className="mb-6">Thinking...</Label>
            <Ellipsis color="white" />
        </motion.div>
    );
}

type Message = {
    sender: "USER" | "SYSTEM";
};

function AIChatPage({}: Props) {
    const [state, setState] = React.useState("");
    const setValue = (v: string) => setState(v);
    const [messages, setMessages] = useState<Array<AiChatMessageProps>>([]);
    const [loading, setLoading] = useState(false);
    const submit = async () => {
        setTimeout(() => {
            setMessages((prev) => [{ message: state, _type: "user" }, ...prev]);
            setValue("");
        }, 600);

        setLoading(true);
        const search = await instance.post<{
            ok: boolean;
            data: { data: { text: string; suggestions: Array<{ activity: string; code: string; location: { name: string } }> } };
        }>("/v1/search/ai", { msg: state }, { timeout: 100000 });

        setLoading(false);
        setMessages((prev) => [{ suggestions: search.data.data.data.suggestions, text: search.data.data.data.text, _type: "system" }, ...prev]);
        console.log(search.data.data.data, "search");
    };

    return (
        <div className="content-padding component flex-col flex-1 ">
            <div className="flex-1 flex flex-col-reverse overflow-y-scroll p-1">
                {loading && <AiLoadingMessage />}
                <AiChatMessageList messages={messages} />
            </div>

            <AiChatInput submit={submit} setValue={setValue} />
        </div>
    );
}

export default AIChatPage;

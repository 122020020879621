import { DragLabel } from "@/upload";
import { BenchItemWrap, BenchItemValue } from "../utils/Bench";
import { useDropzone } from "react-dropzone";
import { Separator } from "@/components/ui/separator";
import { FileUpload } from "@/upload/upload";
import { InputFile } from "@/upload/upload";

function DropZoneWithLabel() {
    const onDrop = (v: File[]) => {
        console.log(v);
    };
    const dropzone = useDropzone({ onDrop });

    return <DragLabel dropzone={dropzone} />;
}

const UploadBench = ({ value }: BenchItemValue) => {
    return (
        <BenchItemWrap value={value}>
            <FileUpload link={"/"} />
            <Separator className="h-2 mb-4" />

            <DropZoneWithLabel />
            <Separator className="h-2 mb-4" />
            <InputFile />
        </BenchItemWrap>
    );
};

export default UploadBench;

import { useState, useEffect } from "react";

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowWidth;
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyCc3VWpzQ-bQmSYmmAI4CkqgRYEnRoLW_M";

interface GoogleMapsScriptStatus {
    loaded: boolean;
    error: Error | null;
}

export const useGoogleMapsScript = (): GoogleMapsScriptStatus => {
    const [scriptStatus, setScriptStatus] = useState<GoogleMapsScriptStatus>({
        loaded: false,
        error: null,
    });

    useEffect(() => {
        if (window.google && window.google.maps) {
            setScriptStatus({ loaded: true, error: null });
            return;
        }

        const scriptId = "google-maps-script";
        let script = document.getElementById(scriptId) as HTMLScriptElement;

        const handleScriptLoad = () => setScriptStatus({ loaded: true, error: null });
        const handleScriptError = () => setScriptStatus({ loaded: false, error: new Error("Google Maps script failed to load") });

        if (!script) {
            script = document.createElement("script");
            script.id = scriptId;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }

        script.addEventListener("load", handleScriptLoad);
        script.addEventListener("error", handleScriptError);

        return () => {
            script.removeEventListener("load", handleScriptLoad);
            script.removeEventListener("error", handleScriptError);
        };
    }, []);

    return scriptStatus;
};

import { useCallback } from "react";
const useClickOutside = (ref, callback) => {
    const handleClick = useCallback(
        (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        },
        [ref, callback]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [handleClick]);
};

export class GoogleMapsLoader {
    private static instance: GoogleMapsLoader;
    private apiKey: string;
    private loadPromise: Promise<void> | null = null;

    private constructor(apiKey: string) {
        this.apiKey = apiKey;
    }

    public static getInstance(apiKey: string): GoogleMapsLoader {
        if (!GoogleMapsLoader.instance) {
            GoogleMapsLoader.instance = new GoogleMapsLoader(apiKey);
        }
        return GoogleMapsLoader.instance;
    }

    public load(): Promise<void> {
        if (!this.loadPromise) {
            this.loadPromise = new Promise<void>((resolve, reject) => {
                if (typeof window === "undefined") {
                    reject(new Error("Google Maps cannot be loaded outside of browser environment"));
                    return;
                }

                const script = document.createElement("script");
                script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
                script.async = true;
                script.defer = true;

                script.addEventListener("load", () => resolve());
                script.addEventListener("error", (e) => reject(e));

                document.head.appendChild(script);
            });
        }
        return this.loadPromise;
    }
}

export const googleMapsLoader = GoogleMapsLoader.getInstance("AIzaSyCc3VWpzQ-bQmSYmmAI4CkqgRYEnRoLW_M");

export default useGoogleMapsScript;

export { useWindowWidth, useClickOutside };

import "shared/tests/wdyr";
import "shared/styles/globals.css";
import { createRoot } from "react-dom/client";

import App from "app/App";
import { store } from "app/store";
import { sessionCheckThunk } from "entities/store/user";
import { ExternalLib } from "shared/helpers/utils";

async function main() {
    await store.dispatch(sessionCheckThunk());
    const container = document.getElementById("root")!;
    const root = createRoot(container);

    await new ExternalLib("https://accounts.google.com/gsi/client", "google");

    root.render(<App />);
}

main();

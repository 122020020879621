// TODO: THROW IN BIN
import { useTranslation } from "react-i18next";
const SearchBarLabel = () => useTranslation().t("MainPage.SearchBar.btn");
const DatePickerLabel = () => useTranslation().t("common.DatePicker");
const SearchBarInput = () => useTranslation().t("MainPage.SearchBar.input");

const LogInModalBase = "header.modal.LogInModal.";
const LogInModalLogin = () => useTranslation();
const LogInModalPassword = () => useTranslation();

export { SearchBarLabel, DatePickerLabel, SearchBarInput };

import { RouterProvider } from "react-router-dom";
import router from "app/router";
import "entities/i18n/i18n";
import { Provider } from "react-redux";
import { store } from "./store";
import { StrictMode } from "react";

const App = () => (
    <StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </StrictMode>
);

export default App;

import DesktopHeader from "widgets/user/Header/DesktopHeader/DesktopHeader";
import MobileMenu from "widgets/user/Header/MobileMenu/MobileMenu";
import Logo from "@/Logo/Logo";

function Header() {
    return (
        <header className="h-[100px] z-20 flex flex-row items-center content-padding justify-between bg-DEFAULT ">
            <Logo />

            <DesktopHeader />
            <MobileMenu />
        </header>
    );
}

export default Header;

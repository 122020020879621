import { DatePicker } from "@/DatePicker/DatePicker";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import SearchIcon from "../icons/SearchIcon/Search";
import { ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import { fetchSearchResults, instance } from "shared/api/signature";
import { SearchBarLabel, SearchBarInput } from "@/labels/labels";
import { TypewriterEffectSmooth } from "@/components/ui/typewriter-effect";
import { useCallback } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Select } from "@/components/ui/select";
import InfiniteScroll from "@/components/ui/infinite-scroll";
import { Difficulty, Language, LocationApiSchema, TravellerSearchQuerySchema } from "shared/types/api";
import TourListPreview from "@/TourPreview/TourListPreview";

export const SearchPage: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const parseSearchParams = {
        query: searchParams.get("query") || undefined,
        date: searchParams.get("date") || undefined,
        minPrice: searchParams.get("minPrice") ? Number(searchParams.get("minPrice")) : undefined,
        maxPrice: searchParams.get("maxPrice") ? Number(searchParams.get("maxPrice")) : undefined,
        difficulty: searchParams.getAll("difficulty") as Difficulty[],
        languages: searchParams.getAll("languages") as Language[],
    };

    const { data, isLoading, error, hasMore, loadMore, search, filters } = useSearchTours(parseSearchParams);

    const handleSearch = useCallback(
        (newParams: TravellerSearchQuery) => {
            const params = new URLSearchParams();
            Object.entries(newParams).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((v) => params.append(key, v));
                } else if (value !== undefined) {
                    params.append(key, value.toString());
                }
            });
            setSearchParams(params);
            search(newParams);
        },
        [setSearchParams, search]
    );

    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="container mx-auto px-4">
            <h1 className="text-2xl font-bold my-4">Search Tours</h1>
            <SearchBar initialValues={filters} onSearch={handleSearch} />
            <InfiniteScroll isLoading={isLoading} hasMore={hasMore} next={loadMore} threshold={0.8}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4">
                    {data.map((tour) => {
                        let image = blank;
                        if (tour.images && tour.images.length > 0) {
                            image = tour.images[0].url;
                        }
                        return <TourListPreview key={tour.id} date={tour.date} image={image} name={tour.name || ""} id={tour.id} />;
                    })}
                </div>
            </InfiniteScroll>
            {isLoading && <div>Loading more...</div>}
        </div>
    );
};

interface SearchBarProps {
    initialValues: TravellerSearchQuery;
    onSearch: (params: TravellerSearchQuery) => void;
}

export const useSearchTours = (initialFilters: TravellerSearchQuery) => {
    const [data, setData] = useState<Tour[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState(initialFilters);

    const fetchData = useCallback(async (currentPage: number, currentFilters: TravellerSearchQuery) => {
        setIsLoading(true);
        setError(null);
        try {
            const result = await fetchSearchResults({ ...currentFilters, page: currentPage, limit: 10 });
            setData((prevData) => (currentPage === 0 ? result.data : [...prevData, ...result.data]));
            setHasMore(currentPage < result.pagination.totalPages);
            setPage(currentPage);
        } catch (error) {
            setError(error instanceof Error ? error : new Error("An unexpected error occurred"));
        } finally {
            setIsLoading(false);
        }
    }, []);

    const loadMore = useCallback(() => {
        if (!isLoading && hasMore) {
            fetchData(page, filters);
        }
    }, [isLoading, hasMore, page, filters, fetchData]);

    const search = useCallback(
        (newFilters: TravellerSearchQuery) => {
            setFilters(newFilters);
            fetchData(0, newFilters);
        },
        [fetchData]
    );

    const reset = useCallback(() => {
        setFilters(initialFilters);
        fetchData(1, initialFilters);
    }, [initialFilters, fetchData]);

    return { data, isLoading, error, hasMore, loadMore, search, reset, filters };
};

type SearchInputProps = { setValue?: Dispatch<SetStateAction<string>> };

function PlaceHolder() {
    const words = [{ text: "Berlin" }, { text: "Tokyo" }, { text: "Махачкала" }];
    TypewriterEffectSmooth;

    return <TypewriterEffectSmooth words={words} />;
}
/**
 * Search input
 */

interface Suggestion {
    suggestion: string;
    confidence: number;
}

export function useThrottle<T extends (...args: any[]) => any>(callback: T, delay: number): (...args: Parameters<T>) => void {
    const lastCall = useRef(0);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    return useCallback(
        (...args: Parameters<T>) => {
            const now = Date.now();
            if (now - lastCall.current >= delay) {
                callback(...args);
                lastCall.current = now;
            } else {
                if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                }
                timeoutId.current = setTimeout(() => {
                    callback(...args);
                    lastCall.current = Date.now();
                }, delay - (now - lastCall.current));
            }
        },
        [callback, delay]
    );
}
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { blank } from "@/TourPreview/ProvidersTourPreview";

export function SearchInput({ setValue }: SearchInputProps) {
    const [innerVal, setInnerVal] = useState("");
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const throttledSearch = useThrottle(async (value: string) => {
        if (value.length < 2) {
            setSuggestions([]);
            setOpen(false);
            return;
        }

        setIsLoading(true);
        try {
            const response = await instance.get(LocationApiSchema.autocomplete.url, {
                params: { query: value, limit: 5 },
            });
            if (response.data.success) {
                const suggestionsRes = response.data.suggestions.map((v) => {
                    return { ...v, suggestion: v.suggestion.replace("Root", "").replace(",", "").trim() };
                });
                console.log(suggestionsRes, "v");
                setSuggestions([...suggestionsRes]);
                setOpen(response.data.suggestions.length > 0);
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 10);
            }
        } catch (error) {
            console.error("Error fetching suggestions:", error);
        } finally {
            setIsLoading(false);
        }
    }, 300);

    const setVal = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (setValue) setValue(value);
        setInnerVal(value);
        throttledSearch(value);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 10);
    };

    const handleSuggestionClick = (suggestion: string) => {
        if (setValue) setValue(suggestion);
        setInnerVal(suggestion);
        setOpen(false);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 10);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <div className="relative flex flex-row rounded-md justify-center w-full h-[58px] md:h-full md:max-w-[281px]">
                    <Input ref={inputRef} value={innerVal} onChange={setVal} className="h-full w-full pr-10" placeholder="Search for locations" />
                    <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2" />
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-[281px] p-0" align="start">
                {isLoading ? (
                    <div className="p-2 text-gray-500">Loading...</div>
                ) : suggestions.length > 0 ? (
                    suggestions.map((item, index) => (
                        <div key={index} className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleSuggestionClick(item.suggestion)}>
                            {item.suggestion} ({item.confidence.toFixed(2)}%)
                        </div>
                    ))
                ) : (
                    <div className="p-2 text-gray-500">No suggestions found</div>
                )}
            </PopoverContent>
        </Popover>
    );
}

const searchBarCn = `flex flex-col md:flex-row w-[270px] md:w-full md:max-w-[807px] md:h-[87px] rounded-[9px] bg-[#CACACA] p-[10px] space-y-4 md:space-y-0 md:space-x-2`;

/**
 *
 * Search bar for main page
 */

export function MainSearchBar() {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const [date, setDate] = useState<Date | undefined>(undefined);

    const handleSearch = () => {
        const params = new URLSearchParams();
        if (date) params.append("date", date.toISOString().split("T")[0]);
        const search = searchQuery.replace("Root", "").replace(",", "").trim();

        params.append("query", search);
        navigate(`/search?${params.toString()}`);
    };

    return (
        <div className={searchBarCn}>
            <SearchInput setValue={setSearchQuery} />
            <DatePicker date={date} setDate={setDate} />
            <Button variant="designed" size="designed" onClick={handleSearch}>
                <SearchBarLabel />
            </Button>
        </div>
    );
}
export const SearchBar: React.FC<SearchBarProps> = ({ initialValues, onSearch }) => {
    const [query, setQuery] = useState(initialValues.query ?? "");
    const [date, setDate] = useState<Date | undefined>(initialValues.date ? new Date(initialValues.date) : undefined);
    const [minPrice, setMinPrice] = useState(initialValues.minPrice?.toString() || "");
    const [maxPrice, setMaxPrice] = useState(initialValues.maxPrice?.toString() || "");
    const [difficulty, setDifficulty] = useState<Difficulty[]>(initialValues.difficulty || []);
    const [languages, setLanguages] = useState<Language[]>(initialValues.languages || []);

    useEffect(() => {
        setQuery(initialValues.query ?? "");
        setDate(initialValues.date ? new Date(initialValues.date) : undefined);
        setMinPrice(initialValues.minPrice?.toString() || "");
        setMaxPrice(initialValues.maxPrice?.toString() || "");
        setDifficulty(initialValues.difficulty || []);
        setLanguages(initialValues.languages || []);
    }, [initialValues]);

    const handleSearch = () => {
        onSearch({
            query,
            date: date?.toISOString().split("T")[0],
            minPrice: minPrice ? Number(minPrice) : undefined,
            maxPrice: maxPrice ? Number(maxPrice) : undefined,
            difficulty,
            languages,
        });
    };

    return (
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
            <Input placeholder="Search tours..." value={query} onChange={(e) => setQuery(e.target.value)} />
            <DatePicker date={date} setDate={setDate} />
            <Input placeholder="Min price" type="number" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} />
            <Input placeholder="Max price" type="number" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
            <Button onClick={handleSearch}>Search</Button>
        </div>
    );
};
export default SearchBar;

import MainPageSearchContainer from "widgets/default/MainPageSearchContainer/MainPageSearchContainer";
import ToursList from "./ToursList";
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { Link } from "react-router-dom";
import BlogRecommendations from "widgets/default/BlogRecommendations/BlogRecommendations";

function HomePage() {
    return (
        <>
            <MainPageSearchContainer />

            <div className="w-full flex justify-center bg ">
                <ToursList count={4} />
            </div>

            <BlogRecommendations />
            <div className="w-full flex justify-center bg pt-5">
                <ToursList count={100} />
            </div>
            <Link to={"/ai"} className="fixed right-2 bottom-6">
                <HoverBorderGradient className=" right-2 bottom-4">Find your best with AI</HoverBorderGradient>
            </Link>
        </>
    );
}
export default HomePage;

import ToursList from "../Home/ToursList";

const Favorite = () => {
    const favorites = [1];

    if (favorites.length === 0) {
        return <div className="page">Нет избранных туров</div>;
    }

    return (
        <div className="page flex-wrap justify-center">
            <ToursList />
        </div>
    );
};

export default Favorite;

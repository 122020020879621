import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { ModalWindowProps } from "shared/providers/ModalWindow";

function ModalWindowNotImplemented({ close }: ModalWindowProps) {
    const [count, setCount] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prev) => {
                if (prev - 1 <= 0) close();
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Dialog onOpenChange={close} open>
            <DialogContent>Такого диалогового окна не существует, окно автоматически закроется через {count}</DialogContent>
        </Dialog>
    );
}

export default ModalWindowNotImplemented;

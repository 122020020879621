import { useEffect } from "react";
import { Calendar, Clock, User, Languages, Users, ChevronDown } from "lucide-react";
import { useLoaderData } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import useGoogleMapsScript, { GOOGLE_MAPS_API_KEY } from "shared/hooks/hooks";

import { useCallback, useRef } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ScrollBar } from "@/components/ui/scroll-area";
import { X, Maximize, Minimize } from "lucide-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const TourHeader = ({ name, difficulty, duration, rating, provider }) => {
    const displayName = typeof name === "string" ? name : "Tour Name Unavailable";
    const displayDifficulty = DifficultyEnum.options.includes(difficulty) ? difficulty : "UNKNOWN";
    const displayDuration = {
        days: typeof duration?.days === "number" ? Math.max(0, Math.floor(duration.days)) : 0,
        hours: typeof duration?.hours === "number" ? Math.max(0, Math.floor(duration.hours)) : 0,
    };

    const displayRating = typeof rating === "number" && !isNaN(rating) ? rating : null;
    const displayProvider = { companyName: typeof provider?.companyName === "string" ? provider.companyName : "Unknown Provider" };

    return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
            <h1 className="text-4xl font-bold mb-4">{displayName}</h1>
            <div className="flex flex-wrap items-center gap-4 mb-4">
                <Badge variant={displayDifficulty === "EASY" ? "secondary" : displayDifficulty === "MODERATE" ? "default" : "destructive"} className="text-sm py-1 px-3">
                    {displayDifficulty}
                </Badge>
                {displayDuration.days > 0 && (
                    <div className="flex items-center text-gray-600">
                        <Calendar className="w-5 h-5 mr-2" />
                        <span>
                            {displayDuration.days} day{displayDuration.days !== 1 ? "s" : ""}
                        </span>
                    </div>
                )}
                {displayDuration.hours > 0 && (
                    <div className="flex items-center text-gray-600">
                        <Clock className="w-5 h-5 mr-2" />
                        <span>
                            {displayDuration.hours} hour{displayDuration.hours !== 1 ? "s" : ""}
                        </span>
                    </div>
                )}
                {displayRating !== null && (
                    <div className="flex items-center">
                        <span className="text-yellow-500 mr-1">★</span>
                        <span className="font-semibold">{displayRating.toFixed(1)}</span>
                    </div>
                )}
            </div>
            <div className="flex items-center">
                <Avatar className="h-10 w-10 mr-3">
                    <AvatarFallback>{displayProvider.companyName[0] || "?"}</AvatarFallback>
                </Avatar>
                <div>
                    <p className="font-semibold">{displayProvider.companyName}</p>
                    <p className="text-sm text-gray-500">Tour Provider</p>
                </div>
            </div>
        </div>
    );
};

const FullScreenGallery = ({ images, currentIndex, onClose }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = useCallback(() => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [onClose]);

    return (
        <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
            <button onClick={onClose} className="absolute top-4 right-4 text-white z-10" aria-label="Close full-screen gallery">
                <X size={24} />
            </button>
            <button onClick={toggleFullScreen} className="absolute top-4 right-12 text-white z-10" aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}>
                {isFullScreen ? <Minimize size={24} /> : <Maximize size={24} />}
            </button>
            <Carousel className="w-full h-full">
                <CarouselContent>
                    {images.map((image, index) => (
                        <CarouselItem key={image.id} className="flex items-center justify-center">
                            <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
                                {({ zoomIn, zoomOut, resetTransform }) => (
                                    <React.Fragment>
                                        <div className="absolute bottom-4 left-4 z-10 flex space-x-2">
                                            <button onClick={() => zoomIn()} className="text-white bg-black bg-opacity-50 p-2 rounded">
                                                +
                                            </button>
                                            <button onClick={() => zoomOut()} className="text-white bg-black bg-opacity-50 p-2 rounded">
                                                -
                                            </button>
                                            <button onClick={() => resetTransform()} className="text-white bg-black bg-opacity-50 p-2 rounded">
                                                Reset
                                            </button>
                                        </div>
                                        <TransformComponent>
                                            <img src={image.url} alt={image.altText} className="max-w-full max-h-full object-contain" />
                                        </TransformComponent>
                                    </React.Fragment>
                                )}
                            </TransformWrapper>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious className="left-2" />
                <CarouselNext className="right-2" />
            </Carousel>
        </div>
    );
};

const TourGallery = ({ images }: { images: Array<z.infer<typeof TourImageSchema>> }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openFullScreen = (index) => {
        setCurrentIndex(index);
        setIsFullScreen(true);
    };

    return (
        <>
            <Carousel className="w-full max-w-4xl mx-auto mb-8">
                <CarouselContent>
                    {images.map((image, index) => (
                        <CarouselItem key={image.id}>
                            <img src={image.url} alt={image.altText} className="w-full h-[400px] object-cover cursor-pointer" onClick={() => openFullScreen(index)} />
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious className="left-2" />
                <CarouselNext className="right-2" />
            </Carousel>
            {isFullScreen && <FullScreenGallery images={images} currentIndex={currentIndex} onClose={() => setIsFullScreen(false)} />}
        </>
    );
};

interface Language {
    [key: string]: string;
}

interface AdditionalInfo {
    whatToBring: string[];
    includedItems: string[];
    notIncludedItems: string[];
}

const TourDescription = ({ description }: { description: string | undefined }) => (
    <Card>
        <CardHeader>
            <CardTitle>Tour Description</CardTitle>
        </CardHeader>
        <CardContent>
            <p className="text-gray-700">{typeof description === "string" ? description : "No description available."}</p>
        </CardContent>
    </Card>
);

const TourDetails = ({
    maxParticipants,
    minAge,
    languages,
    additionalInfo,
}: {
    maxParticipants: number | undefined;
    minAge: number | undefined;
    languages: Language[] | undefined;
    additionalInfo: AdditionalInfo | undefined;
}) => {
    const renderList = (items: string[] | undefined, title: string) => {
        if (!Array.isArray(items) || items.length === 0) return null;
        return (
            <>
                <h4 className="font-semibold mb-2">{title}</h4>
                <ul className="list-disc list-inside mb-4">
                    {items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </>
        );
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Tour Details</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div className="flex items-center">
                        <User className="w-5 h-5 mr-2 text-gray-500" />
                        <span>Max Participants: {typeof maxParticipants === "number" ? maxParticipants : "N/A"}</span>
                    </div>
                    <div className="flex items-center">
                        <User className="w-5 h-5 mr-2 text-gray-500" />
                        <span>Minimum Age: {typeof minAge === "number" ? minAge : "N/A"}</span>
                    </div>
                    <div className="flex items-center">
                        <Languages className="w-5 h-5 mr-2 text-gray-500" />
                        <div className="space-x-2">
                            Languages:{" "}
                            {Array.isArray(languages) && languages.length > 0
                                ? languages.map((v, index) => <Badge key={index}>{typeof v === "string" ? v : "Unknown"}</Badge>)
                                : "N/A"}
                        </div>
                    </div>
                </div>
                {additionalInfo && typeof additionalInfo === "object" && (
                    <>
                        {renderList(additionalInfo.whatToBring, "What to Bring:")}
                        {renderList(additionalInfo.includedItems, "Included Items:")}
                        {renderList(additionalInfo.notIncludedItems, "Not Included:")}
                    </>
                )}
            </CardContent>
        </Card>
    );
};

const TourItinerary = ({ itinerary }) => (
    <Card className="my-6">
        <CardHeader>
            <CardTitle>Itinerary</CardTitle>
        </CardHeader>
        <CardContent>
            {itinerary.map((day, index) => (
                <div key={day.id} className="mb-4">
                    <h4 className="font-semibold">
                        Day {day.dayNumber}: {day.title}
                    </h4>
                    <p>{day.description}</p>
                </div>
            ))}
        </CardContent>
    </Card>
);

const TourMap = ({ tourLocations, meetingPoint }) => {
    console.log("i was here!!!", tourLocations, meetingPoint);
    meetingPoint = meetingPoint ?? {
        location: {
            latitude: 37.7459192,
            longitute: -119.5331992,
        },
    };

    console.log(meetingPoint, "ues sirr??");
    const mapRef = useRef(null);
    const { loaded, error } = useGoogleMapsScript();

    const onMapLoad = useCallback(() => {
        const mapInstance = new window.google.maps.Map(mapRef.current, {
            zoom: 12,
            styles: [
                { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] },
                { featureType: "water", elementType: "geometry", stylers: [{ color: "#e9e9e9" }] },
                { featureType: "landscape", elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
            ],
        });
        const bounds = new window.google.maps.LatLngBounds();
        const meetingPointMarker = new window.google.maps.Marker({
            position: { lat: meetingPoint.location.latitude ?? 37.7459192, lng: meetingPoint.location.longitude ?? -119.5331992 },
            map: mapInstance,
            title: "Meeting Point",
            icon: {
                url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
            },
        });
        bounds.extend(meetingPointMarker.getPosition());

        tourLocations.forEach((location, index) => {
            console.log("herere", { lat: location.location.latitude, lng: location.location.longitude });
            const marker = new window.google.maps.Marker({
                position: { lat: location.location.latitude, lng: location.location.longitude },
                map: mapInstance,
                title: location.location.name,
                label: (index + 1).toString(),
            });
            bounds.extend(marker.getPosition());
        });

        mapInstance.fitBounds(bounds);
    }, [meetingPoint, tourLocations]);

    useEffect(() => {
        if (loaded) onMapLoad();
    }, [loaded]);
    if (error) return <div>Error loading map</div>;
    if (!loaded) return <div>Loading map...</div>;

    return (
        <Card className="my-6 w-full">
            <CardHeader>
                <CardTitle>Tour Map</CardTitle>
            </CardHeader>
            <CardContent>
                <div style={{ height: "400px", width: "100%" }}>
                    <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
                </div>
                {meetingPoint && (
                    <div className="mt-4">
                        <h4 className="font-semibold">Meeting Point:</h4>
                        <p>
                            {meetingPoint.location.name}, {meetingPoint.location.address}
                        </p>
                        <h4 className="font-semibold mt-2">Tour Locations:</h4>
                        {tourLocations.map((loc, index) => (
                            <div key={loc.id} className="flex items-center mt-1">
                                <Badge variant="outline" className="mr-2">
                                    {index + 1}
                                </Badge>
                                <span>{loc.location.name}</span>
                            </div>
                        ))}
                    </div>
                )}
            </CardContent>
        </Card>
    );
};
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { InfoIcon } from "lucide-react";
const TourPricing: React.FC<{ pricing: Pricing }> = ({ pricing }) => {
    const getRefundPolicyInfo = (policyIds: string[]): Policy[] => {
        if (!Array.isArray(policyIds)) return [];
        return policyIds.map((id) => refundPolicies.find((policy) => policy.id === id)).filter((policy): policy is Policy => !!policy);
    };

    const getDepositPolicyInfo = (policyId: string): Policy => {
        return depositPolicyOptions.find((policy) => policy.id === policyId) || { id: policyId, name: policyId, description: "" };
    };

    const getCancellationPolicyInfo = (policyId: string): Policy => {
        return cancellationPolicies.find((policy) => policy.id === policyId) || { id: policyId, name: policyId, description: "" };
    };

    const InfoTooltip: React.FC<{ content: string }> = ({ content }) => (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <InfoIcon className="inline-block ml-1 h-4 w-4 text-gray-500" />
                </TooltipTrigger>
                <TooltipContent>{content}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );

    if (!pricing || typeof pricing !== "object") {
        return (
            <Card className="my-6 w-[350px] min-w-[200px]">
                <CardContent>Pricing information unavailable</CardContent>
            </Card>
        );
    }

    return (
        <Card className="my-6 w-[350px] min-w-[200px]">
            <CardHeader>
                <CardTitle className="flex items-center">
                    <CreditCard className="mr-2" />
                    Pricing Details
                </CardTitle>
            </CardHeader>
            <CardContent>
                <div className="space-y-4">
                    <div className="bg-gray-100 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold mb-2">Base Price</h3>
                        <p className="text-2xl font-bold">
                            {typeof pricing.basePrice === "number" ? pricing.basePrice : "N/A"} {pricing.mainCurrency || ""}
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-2 flex items-center">
                            <CalendarComponent className="mr-2" />
                            Cancellation Policy
                        </h3>
                        <p className="font-medium">
                            {getCancellationPolicyInfo(pricing.cancellationPolicy).name}
                            <InfoTooltip content={getCancellationPolicyInfo(pricing.cancellationPolicy).description} />
                        </p>
                    </div>

                    {pricing.depositPolicy && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Deposit Policy</h3>
                            <p className="font-medium">
                                {getDepositPolicyInfo(pricing.depositPolicy.type).name} - {pricing.depositPolicy.amount}
                                {pricing.depositPolicy.type === "PERCENTAGE" ? "%" : ` ${pricing.depositPolicy.currency || ""}`}
                                <InfoTooltip content={getDepositPolicyInfo(pricing.depositPolicy.type).description} />
                            </p>
                        </div>
                    )}

                    {Array.isArray(pricing.refundPolicy) && pricing.refundPolicy.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Refund Policy</h3>
                            <ul className="list-disc pl-5">
                                {getRefundPolicyInfo(pricing.refundPolicy).map((policy, index) => (
                                    <li key={index}>
                                        {policy.name}
                                        <InfoTooltip content={policy.description} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {Array.isArray(pricing.acceptedPayments) && pricing.acceptedPayments.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Accepted Payments</h3>
                            <p>{pricing.acceptedPayments.join(", ")}</p>
                        </div>
                    )}

                    {Array.isArray(pricing.discountLevels) && pricing.discountLevels.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2 flex items-center">
                                <Users className="mr-2" />
                                Group Discounts
                            </h3>
                            <ul className="list-disc pl-5">
                                {pricing.discountLevels.map((discount, index) => (
                                    <li key={index}>
                                        {discount.people}+ people: <span className="font-medium">{discount.discount}% off</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

// const TourPricing = ({ pricing }) => {
//     const getRefundPolicyInfo = (policyIds) => {
//         return policyIds.map((id) => refundPolicies.find((policy) => policy.id === id)).filter(Boolean);
//     };

//     const getDepositPolicyInfo = (policyId) => {
//         return depositPolicyOptions.find((policy) => policy.id === policyId) || { name: policyId, description: "" };
//     };

//     const getCancellationPolicyInfo = (policyId) => {
//         return cancellationPolicies.find((policy) => policy.id === policyId) || { name: policyId, description: "" };
//     };

//     const InfoTooltip = ({ content }) => (
//         <TooltipProvider>
//             <Tooltip>
//                 <TooltipTrigger>
//                     <InfoIcon className="inline-block ml-1 h-4 w-4 text-gray-500" />
//                 </TooltipTrigger>
//                 <TooltipContent>{content}</TooltipContent>
//             </Tooltip>
//         </TooltipProvider>
//     );

//     return (
//         <Card className="my-6 w-[350px] min-w-[200px]">
//             <CardHeader>
//                 <CardTitle className="flex items-center">
//                     <CreditCard className="mr-2" />
//                     Pricing Details
//                 </CardTitle>
//             </CardHeader>
//             <CardContent>
//                 <div className="space-y-4">
//                     <div className="bg-gray-100 p-4 rounded-lg">
//                         <h3 className="text-lg font-semibold mb-2">Base Price</h3>
//                         <p className="text-2xl font-bold">
//                             {pricing.basePrice} {pricing.mainCurrency}
//                         </p>
//                     </div>

//                     <div>
//                         <h3 className="text-lg font-semibold mb-2 flex items-center">
//                             <CalendarComponent className="mr-2" />
//                             Cancellation Policy
//                         </h3>
//                         <p className="font-medium">
//                             {getCancellationPolicyInfo(pricing.cancellationPolicy).name}
//                             <InfoTooltip content={getCancellationPolicyInfo(pricing.cancellationPolicy).description} />
//                         </p>
//                     </div>

//                     <div>
//                         <h3 className="text-lg font-semibold mb-2">Deposit Policy</h3>
//                         <p className="font-medium">
//                             {getDepositPolicyInfo(pricing.depositPolicy.type).name} - {pricing.depositPolicy.amount}
//                             {pricing.depositPolicy.type === "PERCENTAGE" ? "%" : ` ${pricing.depositPolicy.currency}`}
//                             <InfoTooltip content={getDepositPolicyInfo(pricing.depositPolicy.type).description} />
//                         </p>
//                     </div>

//                     <div>
//                         <h3 className="text-lg font-semibold mb-2">Refund Policy</h3>
//                         <ul className="list-disc pl-5">
//                             {getRefundPolicyInfo(pricing.refundPolicy).map((policy, index) => (
//                                 <li key={index}>
//                                     {policy.name}
//                                     <InfoTooltip content={policy.description} />
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>

//                     <div>
//                         <h3 className="text-lg font-semibold mb-2">Accepted Payments</h3>
//                         <p>{pricing.acceptedPayments.join(", ")}</p>
//                     </div>

//                     {pricing.discountLevels.length > 0 && (
//                         <div>
//                             <h3 className="text-lg font-semibold mb-2 flex items-center">
//                                 <Users className="mr-2" />
//                                 Group Discounts
//                             </h3>
//                             <ul className="list-disc pl-5">
//                                 {pricing.discountLevels.map((discount, index) => (
//                                     <li key={index}>
//                                         {discount.people}+ people: <span className="font-medium">{discount.discount}% off</span>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </div>
//                     )}
//                 </div>
//             </CardContent>
//         </Card>
//     );
// };

import { isWithinInterval } from "date-fns";
import { useToast } from "@/components/ui/use-toast";
// import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { DifficultyEnum, TOUR_LISTINGS_API_ENDPOINTS, difficultyLevels } from "shared/types/api";

interface Availability {
    date: string;
    availableSpots: number;
    price: number;
}

interface TourCalendarProps {
    tourId: string;
    availability: Availability[];
}

const TourBooking: React.FC<{ selectedDate: Availability | null; onBook: () => void }> = ({ selectedDate, onBook }) => (
    <Card className="my-6">
        <CardHeader>
            <CardTitle>Book This Tour</CardTitle>
        </CardHeader>
        <CardContent>
            {selectedDate ? (
                <div>
                    <h4 className="font-semibold mb-2">Selected Date:</h4>
                    <div className="flex justify-between items-center">
                        <span>{new Date(selectedDate.date).toLocaleDateString()}</span>
                        <span>{selectedDate.availableSpots} spots left</span>
                        <Button onClick={onBook}>Book for ${selectedDate.price}</Button>
                    </div>
                </div>
            ) : (
                <div>
                    <p>Please select a date on the calendar.</p>
                </div>
            )}
        </CardContent>
    </Card>
);

export const TourCalendar: React.FC<TourCalendarProps> = ({ tourId, availability }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState<Availability | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handlePrevMonth = () => setCurrentDate((prev) => addMonths(prev, -1));
    const handleNextMonth = () => setCurrentDate((prev) => addMonths(prev, 1));

    const handleDateClick = (date: Date) => {
        const availableDate = availability.find((a) => a.date === format(date, "yyyy-MM-dd"));
        if (availableDate) {
            setSelectedDate(availableDate);
            setIsDialogOpen(true);
        }
    };

    const { toast } = useToast();

    const handleBook = () => {
        // Implement booking logic here
        toast({
            title: "Booking Successful",
            description: `Your tour on ${selectedDate?.date} has been booked!`,
        });
        setIsDialogOpen(false);
    };

    const DateCell: React.FC<{ date: Date; month: Date }> = ({ date, month }) => {
        const availableDate = availability.find((a) => a.date === format(date, "yyyy-MM-dd"));
        const isPast = isBefore(date, new Date()) && !isToday(date);
        const isCurrentMonth = isSameMonth(date, month);

        return (
            <div
                className={cn(
                    "h-24 border-r border-b p-1 transition duration-100 ease-in-out",
                    isCurrentMonth ? "bg-white" : "bg-gray-50 text-gray-400",
                    isToday(date) && "bg-blue-50",
                    isPast && "bg-gray-100 text-gray-400",
                    availableDate && "hover:bg-green-50 cursor-pointer",
                    !availableDate && !isPast && "hover:bg-red-50"
                )}
                onClick={() => !isPast && handleDateClick(date)}
            >
                <div className="flex justify-between items-start">
                    <span className={cn("text-sm font-medium", isToday(date) && "text-blue-600")}>{format(date, "d")}</span>
                    {availableDate && (
                        <Badge variant="success" className="text-xs px-1 py-0">
                            Available
                        </Badge>
                    )}
                </div>
                {availableDate && (
                    <div className="mt-1 text-xs">
                        <div className="font-semibold text-gray-700">${availableDate.price}</div>
                        <div className="text-gray-500">{availableDate.availableSpots} spots left</div>
                    </div>
                )}
            </div>
        );
    };

    const MonthGrid: React.FC<{ month: Date }> = ({ month }) => {
        const days = eachDayOfInterval({ start: startOfMonth(month), end: endOfMonth(month) });
        const startDayOfWeek = days[0].getDay();

        return (
            <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">{format(month, "MMMM yyyy")}</h3>
                <div className="grid grid-cols-7 border-l border-t">
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                        <div key={day} className="text-center text-sm py-1 border-r border-b text-gray-500 bg-gray-50">
                            {day}
                        </div>
                    ))}
                    {Array.from({ length: startDayOfWeek }).map((_, index) => (
                        <div key={`empty-${index}`} className="border-r border-b" />
                    ))}
                    {days.map((day) => (
                        <DateCell key={day.toString()} date={day} month={month} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Tour Calendar</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex justify-between items-center mb-4">
                    <Button onClick={handlePrevMonth} variant="outline" size="sm">
                        <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <h2 className="text-xl font-bold">{format(currentDate, "MMMM yyyy")}</h2>
                    <Button onClick={handleNextMonth} variant="outline" size="sm">
                        <ChevronRight className="h-4 w-4" />
                    </Button>
                </div>
                <MonthGrid month={currentDate} />

                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Book Your Tour</DialogTitle>
                        </DialogHeader>
                        <TourBooking selectedDate={selectedDate} onBook={handleBook} />
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

const TourReviews = ({ reviews }) => (
    <Card className="my-6">
        <CardHeader>
            <CardTitle>Reviews</CardTitle>
        </CardHeader>
        <CardContent>
            {reviews.length > 0 ? (
                <div className="space-y-4">
                    {reviews.map((review) => (
                        <div key={review.id} className="border-b pb-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <Avatar className="mr-2">
                                        <AvatarFallback>{review.traveller.name[0]}</AvatarFallback>
                                    </Avatar>
                                    <span>{review.traveller.name}</span>
                                </div>
                                <div className="flex items-center">
                                    <span className="text-yellow-500 mr-1">★</span>
                                    <span>{review.rating.toFixed(1)}</span>
                                </div>
                            </div>
                            <p className="mt-2">{review.comment}</p>
                            <p className="text-sm text-gray-500 mt-1">{new Date(review.createdAt).toLocaleDateString()}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No reviews yet.</p>
            )}
        </CardContent>
    </Card>
);

import * as RovingFocusGroup from "@radix-ui/react-roving-focus";

const ResponsiveTabs = ({ tabs }) => {
    return (
        <Tabs defaultValue={tabs[0].value} className="max-w-full">
            <ScrollArea className="w-full whitespace-nowrap rounded-md border">
                <TabsList asChild>
                    <RovingFocusGroup.Root orientation="horizontal" loop>
                        {tabs.map((tab) => (
                            <RovingFocusGroup.Item key={tab.value} asChild>
                                <TabsTrigger
                                    value={tab.value}
                                    className="px-3 py-1.5 text-sm font-medium transition-all hover:bg-gray-100 data-[state=active]:bg-gray-100"
                                >
                                    {tab.label}
                                </TabsTrigger>
                            </RovingFocusGroup.Item>
                        ))}
                    </RovingFocusGroup.Root>
                </TabsList>
                <ScrollBar orientation="horizontal" className="invisible" />
            </ScrollArea>
        </Tabs>
    );
};

import { Info, CreditCard, Bookmark, Star } from "lucide-react";
import { Language, LanguageMap, TourImageSchema, TourSchema, cancellationPolicies, depositPolicyOptions, languageOptions, refundPolicies } from "shared/types/api";
import { z } from "zod";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

import React, { useState, useMemo } from "react";
import { format, parseISO, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isBefore, addMonths } from "date-fns";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface AvailableDatesProps {
    availability: Availability[];
    onDateClick: (date: Availability) => void;
}

const AvailableDates: React.FC<AvailableDatesProps> = ({ availability, onDateClick }) => {
    const sortedAvailableDates = useMemo(() => {
        return availability.filter((date) => !isBefore(parseISO(date.date), new Date())).sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());
    }, [availability]);

    return (
        <div className="mt-4">
            <h4 className="text-sm font-semibold mb-2">Available Dates:</h4>
            <ScrollArea className="h-[200px] max-h-[200px] w-full rounded-md border">
                <div className="w-full rounded-md border">
                    {sortedAvailableDates.map((date) => (
                        <Button
                            key={date.date}
                            variant="ghost"
                            className="w-full justify-between mb-2 bg-green-50 hover:bg-green-100 text-sm"
                            onClick={() => onDateClick(date)}
                        >
                            <span>{format(parseISO(date.date), "MMM d, yyyy")}</span>
                            <span>${date.price}</span>
                        </Button>
                    ))}
                </div>
            </ScrollArea>
        </div>
    );
};

interface CalendarProps {
    currentMonth: Date;
    availability: Availability[];
    onDateClick: (date: Availability) => void;
}

const CalendarComponent: React.FC<CalendarProps> = ({ currentMonth, availability, onDateClick }) => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

    return (
        <div className="grid grid-cols-7 gap-1">
            {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                <div key={day} className="text-center text-xs font-medium text-gray-500">
                    {day}
                </div>
            ))}
            {monthDays.map((day) => {
                const dateStr = format(day, "yyyy-MM-dd");
                const availabilityInfo = availability.find((a) => format(new Date(a.date), "yyyy-MM-dd") === dateStr);
                const isAvailable = !!availabilityInfo && availabilityInfo.availableSpots > 0;

                return (
                    <Button
                        key={day.toString()}
                        variant={isAvailable ? "default" : "ghost"}
                        className={`h-10 w-10 p-0 ${!isSameMonth(day, currentMonth) && "invisible"} ${isAvailable ? "bg-green-500 hover:bg-green-700" : ""}`}
                        disabled={!isAvailable || (isBefore(day, new Date()) && !isToday(day))}
                        onClick={() => isAvailable && availabilityInfo && onDateClick(availabilityInfo)}
                    >
                        <span className={`${isToday(day) ? "font-bold" : ""}`}>{format(day, "d")}</span>
                    </Button>
                );
            })}
        </div>
    );
};

interface CompactTourCalendarProps {
    availability: Availability[];
    onBookTour: (date: Availability, participants: number) => void;
}

const CompactTourCalendar: React.FC<CompactTourCalendarProps> = ({ availability, onBookTour }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState<Availability | null>(null);
    const [participants, setParticipants] = useState(1);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handlePrevMonth = () => setCurrentMonth(addMonths(currentMonth, -1));
    const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

    const handleDateClick = (date: Availability) => {
        setSelectedDate(date);
        setIsDialogOpen(true);
    };

    const handleBooking = () => {
        if (selectedDate) {
            onBookTour(selectedDate, participants);
            setIsDialogOpen(false);
            setParticipants(1);
        }
    };

    return (
        <Card className="w-full max-w-sm">
            <CardHeader className="pb-4">
                <div className="flex items-center justify-between">
                    <Button variant="ghost" size="sm" onClick={handlePrevMonth}>
                        <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <CardTitle className="text-lg font-bold">{format(currentMonth, "MMMM yyyy")}</CardTitle>
                    <Button variant="ghost" size="sm" onClick={handleNextMonth}>
                        <ChevronRight className="h-4 w-4" />
                    </Button>
                </div>
            </CardHeader>
            <CardContent>
                <CalendarComponent currentMonth={currentMonth} availability={availability} onDateClick={handleDateClick} />
                <AvailableDates availability={availability} onDateClick={handleDateClick} />
            </CardContent>

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Book Tour</DialogTitle>
                        <DialogDescription>{selectedDate && `Book your tour for ${format(parseISO(selectedDate.date), "MMMM d, yyyy")}`}</DialogDescription>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="participants" className="text-right">
                                Participants
                            </Label>
                            <Input
                                id="participants"
                                type="number"
                                value={participants}
                                onChange={(e) => setParticipants(Math.max(1, parseInt(e.target.value)))}
                                className="col-span-3"
                                min="1"
                                max={selectedDate?.availableSpots}
                            />
                        </div>
                        {selectedDate && (
                            <div className="text-sm text-gray-500">
                                Price per person: ${selectedDate.price}
                                <br />
                                Total price: ${selectedDate.price * participants}
                            </div>
                        )}
                    </div>
                    <DialogFooter>
                        <Button onClick={handleBooking}>Book Now</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

const TourPage = () => {
    const tourData: z.infer<typeof TourSchema> & { provider: any; availability: any; reviews: any } = useLoaderData();

    return (
        <div className="bg-gray-100 w-full content-padding min-h-screen pb-12">
            <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <TourHeader
                    name={tourData.name}
                    difficulty={tourData.difficulty}
                    duration={tourData.duration}
                    rating={tourData.provider.rating}
                    provider={tourData.provider}
                />
                <TourGallery images={tourData.images} />
                <Tabs defaultValue="description" className="mt-8">
                    <div className="mt-6">
                        <TourDescription description={tourData.description} />
                        <TourDetails
                            maxParticipants={tourData.maxParticipants}
                            minAge={tourData.minAge}
                            languages={tourData.languages}
                            additionalInfo={tourData.additionalInfo}
                        />
                        <TourItinerary itinerary={tourData.itinerary} />
                        <CompactTourCalendar availability={tourData.availability} onDateSelect={console.log} />
                        {/* <TourCalendar tourId={tourData.id} availability={tourData.availability} /> */}

                        <TourBooking availability={tourData.availability} />
                        <TourReviews reviews={tourData.reviews} />
                    </div>
                </Tabs>
            </div>
            <div className="flex w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <TourMap tourLocations={tourData.tourLocations} meetingPoint={tourData.meetingPoint} />
                <TourPricing pricing={tourData.pricing} />
            </div>
        </div>
    );
};
export default TourPage;

import React, { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { X, ChevronDown, ChevronUp, MapPin } from "lucide-react";
import useGoogleMapsScript from "shared/hooks/hooks";
import Autocomplete from "pages/provider/tour_new_api/Autocomplete/Autocomplete";

import { LocationCategory } from "shared/types/api";
import { LocationSchema } from "shared/types/api";

const LocationSchemaWithStringCoordinates = LocationSchema.extend({
    name: z.string().optional(),
    latitude: z.string(),
    longitude: z.string(),
    category: z.string().optional().nullable(),
    googleTypes: z.array(z.string()).optional(),
    formattedAddress: z.string().optional(),
});
type Location = z.infer<typeof LocationSchemaWithStringCoordinates>;

interface FullScreenLocationInputProps {
    onLocationAdd: (location: Location) => void;
    initialLocation?: Location | null;
    onClose: () => void;
}

import { useMemo, useCallback, useReducer } from "react";
type State = {
    mode: "search" | "pin" | "edit";
    location: Location;
    hoveredAddress: string;
    isCardExpanded: boolean;
    map: google.maps.Map | null;
};

type Action =
    | { type: "SET_MODE"; payload: "search" | "pin" | "edit" }
    | { type: "SET_LOCATION"; payload: Partial<Location> }
    | { type: "SET_HOVERED_ADDRESS"; payload: string }
    | { type: "TOGGLE_CARD" }
    | { type: "SET_MAP"; payload: google.maps.Map }
    | { type: "RESET" };

const initialState: State = {
    mode: "search",
    location: {
        name: "",
        address: "",
        latitude: "",
        longitude: "",
        category: LocationCategory.OTHER,
        googleTypes: [],
    },
    hoveredAddress: "",
    isCardExpanded: false,
    map: null,
};

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "SET_MODE":
            return { ...state, mode: action.payload };
        case "SET_LOCATION":
            return { ...state, location: { ...state.location, ...action.payload } };
        case "SET_HOVERED_ADDRESS":
            return { ...state, hoveredAddress: action.payload };
        case "TOGGLE_CARD":
            return { ...state, isCardExpanded: !state.isCardExpanded };
        case "SET_MAP":
            return { ...state, map: action.payload };
        case "RESET":
            return initialState;
        default:
            return state;
    }
}

const FullScreenLocationInput: React.FC<FullScreenLocationInputProps> = ({ onLocationAdd, initialLocation = null, onClose }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, location: initialLocation || initialState.location });

    const mapRef = useRef<HTMLDivElement>(null);
    const geocoderRef = useRef<google.maps.Geocoder | null>(null);

    const { loaded, error } = useGoogleMapsScript();

    const handleStateLocation = (place: google.maps.places.PlaceResult | google.maps.GeocoderResult) => {
        dispatch({
            type: "SET_LOCATION",
            payload: {
                ...place,
                name: place.formatted_address || "",
                address: place.formatted_address || "",
                // TODO: FIX
                // This can make us feel very bad
                latitude: place.geometry!.location!.lat().toFixed(6),
                longitude: place.geometry!.location!.lng().toFixed(6),
                category: LocationCategory.OTHER,
                googleTypes: place.types || [],
            },
        });
    };

    const initMap = useCallback(() => {
        if (!mapRef.current || state.map) return;

        const mapInstance = new google.maps.Map(mapRef.current, { center: { lat: 50.0, lng: 15.0 }, zoom: 5, disableDefaultUI: true, gestureHandling: "greedy" });

        dispatch({ type: "SET_MAP", payload: mapInstance });
        geocoderRef.current = new google.maps.Geocoder();

        if (initialLocation?.latitude && initialLocation?.longitude) {
            const position = new google.maps.LatLng(parseFloat(initialLocation.latitude), parseFloat(initialLocation.longitude));
            mapInstance.setCenter(position);
            mapInstance.setZoom(15);
        }

        const updateLocationFromMapCenter = () => {
            const center = mapInstance.getCenter();
            if (center) {
                const lat = center.lat().toFixed(6);
                const lng = center.lng().toFixed(6);
                dispatch({
                    type: "SET_LOCATION",
                    payload: { latitude: lat, longitude: lng, isCustom: true },
                });
            }
        };

        const updateHoveredAddress = () => {
            const center = mapInstance.getCenter();
            if (geocoderRef.current && center) {
                geocoderRef.current.geocode({ location: center }, (results, status) => {
                    console.log(results, "resulllllts");
                    if (status === "OK" && results?.[0]) {
                        const place = results[0];
                        handleStateLocation(place);
                        dispatch({ type: "SET_HOVERED_ADDRESS", payload: place.formatted_address });
                    } else {
                        dispatch({ type: "SET_HOVERED_ADDRESS", payload: "Address not found" });
                    }
                });
            }
        };

        mapInstance.addListener("center_changed", updateLocationFromMapCenter);
        mapInstance.addListener("idle", updateHoveredAddress);
    }, [state.map, initialLocation]);

    useEffect(() => {
        if (loaded && !state.map) {
            initMap();
        }
    }, [loaded, state.map, initMap]);

    const handlePlaceSelected = useCallback(
        (place: google.maps.places.PlaceResult) => {
            if (!place.geometry?.location || !state.map) return;
            state.map.setCenter(place.geometry.location);
            state.map.setZoom(15);
            handleStateLocation(place);
            dispatch({ type: "SET_MODE", payload: "edit" });
            dispatch({ type: "TOGGLE_CARD" });
        },
        [state.map]
    );

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: "SET_LOCATION", payload: { [name]: value } });
    }, []);

    const handleCategoryChange = useCallback((value: LocationCategory) => {
        dispatch({ type: "SET_LOCATION", payload: { category: value } });
    }, []);

    const handleSave = useCallback(() => {
        onLocationAdd(state.location);
        onClose();
    }, [state.location, onLocationAdd, onClose]);

    const enterPinMode = useCallback(() => {
        dispatch({ type: "SET_MODE", payload: "pin" });
        if (state.map) {
            const center = state.map.getCenter();
            if (center) {
                console.log(center?.toJSON(), "jsonnnn");
                dispatch({ type: "SET_LOCATION", payload: { latitude: center.lat().toFixed(6), longitude: center.lng().toFixed(6) } });
            }
        }
    }, [state.map]);

    const exitPinMode = useCallback(() => {
        dispatch({ type: "SET_MODE", payload: "search" });
        dispatch({ type: "TOGGLE_CARD" });
    }, []);

    const addThisLocation = useCallback(() => {
        dispatch({ type: "SET_MODE", payload: "edit" });
        dispatch({ type: "SET_LOCATION", payload: { address: state.hoveredAddress } });
        dispatch({ type: "TOGGLE_CARD" });
    }, [state.hoveredAddress]);

    const toggleCardExpanded = useCallback(() => {
        dispatch({ type: "TOGGLE_CARD" });
        if (!state.isCardExpanded) {
            dispatch({ type: "SET_MODE", payload: "pin" });
        }
    }, [state.isCardExpanded]);

    useEffect(() => {
        if (state.isCardExpanded && state.map) {
            const center = state.map.getCenter();
            if (center) {
                dispatch({
                    type: "SET_LOCATION",
                    payload: { latitude: center.lat().toFixed(6), longitude: center.lng().toFixed(6), address: state.hoveredAddress },
                });
            }
        }
    }, [state.isCardExpanded, state.map, state.hoveredAddress]);

    const mapContent = useMemo(
        () => (loaded ? <div ref={mapRef} className="h-full w-full"></div> : <div className="flex items-center -z-0 justify-center h-full">Loading Google Maps...</div>),
        [loaded]
    );

    if (error) {
        return <div>Error loading Google Maps: {error.message}</div>;
    }

    return (
        <div className="flex flex-col h-full">
            <div className="relative flex-grow">
                {mapContent}

                <div className="absolute top-12 left-4 right-4 z-10 flex gap-2">
                    <Button onClick={onClose} variant="outline" size="icon">
                        <X className="h-4 w-4" />
                    </Button>
                    <div className="flex-grow">
                        <Autocomplete onPlaceSelected={handlePlaceSelected} placeholder="Search for a location" />
                    </div>
                </div>

                {state.mode === "search" && (
                    <Button onClick={enterPinMode} className="absolute bottom-12 right-3 z-10" variant="secondary">
                        <MapPin className="mr-2 h-4 w-4" /> Add Custom Location
                    </Button>
                )}

                {state.mode === "pin" && (
                    <>
                        <div className="absolute bottom-4 left-4 right-4 z-10 flex justify-between">
                            <Button onClick={exitPinMode} variant="secondary">
                                Cancel
                            </Button>
                            <Button onClick={addThisLocation} variant="default">
                                Add This Location
                            </Button>
                        </div>
                        <div className="mb-8 absolute top-20 text-center w-full">
                            <span className="text-gray-700 text-lg sh drop-shadow-lg font-semibold rounded px-2 py-1 bg-white/80">{state.hoveredAddress}</span>
                        </div>
                    </>
                )}
                {(state.mode === "pin" || state.isCardExpanded) && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
                        <MapPin className="h-8 w-8 text-red-500" />
                    </div>
                )}
            </div>

            <Card className={`absolute left-0 right-0 bottom-0 z-20 transition-transform duration-300 ${state.isCardExpanded ? "translate-y-0" : "translate-y-full"}`}>
                <div className="p-2 flex justify-center cursor-pointer" onClick={toggleCardExpanded}>
                    {state.isCardExpanded ? <ChevronDown className="h-6 w-6" /> : <ChevronUp className="h-6 w-6" />}
                </div>
                <CardContent className="p-4 mb-7">
                    <div className="space-y-2">
                        <Input name="name" value={state.location.name} onChange={handleInputChange} placeholder="Location Name" required />
                        <Input name="address" value={state.location.address} placeholder="Address" disabled />
                        <Select onValueChange={handleCategoryChange} value={state.location.category ?? LocationCategory.HISTORICAL_SITE}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select a category" />
                            </SelectTrigger>
                            <SelectContent>
                                {Object.values(LocationCategory).map((category) => (
                                    <SelectItem key={category} value={category}>
                                        {category}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <Button onClick={handleSave} className="w-full mt-4">
                            Save Location
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { z } from "zod";
import { cn } from "@/lib/utils";
export { FullScreenLocationInput, AddLocationMobileDialog };

interface AddLocationMobileDialogProps {
    onLocationAdd: (location: Location) => void;
    initialLocation?: Location | null;
    triggerElement?: React.ReactNode;
    triggerText?: string;
}

const AddLocationMobileDialog: React.FC<AddLocationMobileDialogProps> = ({ onLocationAdd, initialLocation = null, triggerElement, triggerText = "Add Location" }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleLocationAdd = (location: Location) => {
        onLocationAdd(location);
        setIsOpen(false);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>{triggerElement || <Button>{triggerText}</Button>}</DialogTrigger>
            <DialogContent className="p-0 w-full max-w-full h-[100vh] max-h-[100vh] sm:max-w-full">
                <div className="h-full">
                    <FullScreenLocationInput onLocationAdd={handleLocationAdd} initialLocation={initialLocation} onClose={handleClose} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

import { Outlet } from "react-router-dom";
import ProfilePageNavBar from "./components/ProfilePageNavBar";

function Profile() {
    return (
        <div className="flex w-full content-padding min-h-[65dvh]">
            <ProfilePageNavBar />
            <Outlet />
        </div>
    );
}

export default Profile;

import { createContext, useState, useContext, useCallback } from "react";
import { PropsWithChildren } from "react";
type OverlayContextType = {
    isOpen: boolean;

    close: () => void;
    open: () => void;
};

const OverlayContextValue: OverlayContextType = {
    isOpen: true,
    open: () => void 1,
    close: () => void 1,
};
const OverlayContext = createContext<OverlayContextType>(OverlayContextValue);

export const OverlayProvider = ({ children }: PropsWithChildren) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    return (
        <OverlayContext.Provider value={{ isOpen, open, close }}>
            {children}
            <LoaderOverlay />
        </OverlayContext.Provider>
    );
};

export const useOverlay = () => {
    return useContext(OverlayContext);
};

import * as Dialog from "@radix-ui/react-dialog";
import Loading from "./loading/Loading";
import Ellipsis from "./Ellipisis/Ellipsis";

const LoaderOverlay = () => {
    const { isOpen } = useOverlay();

    return (
        <Dialog.Root open={isOpen}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-25 z-[5000]" />
            <Dialog.Content className="fixed inset-0 flex items-center justify-center z-[5000]">
                <Ellipsis color="white" />
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default LoaderOverlay;

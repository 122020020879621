import { AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import React from "react";

export type BenchItemValue = { value: string };
const BenchItemWrap = ({ children, value }: React.PropsWithChildren & BenchItemValue) => {
    return (
        <AccordionItem key={value} value={value}>
            <AccordionTrigger>{value}</AccordionTrigger>
            <AccordionContent>{children}</AccordionContent>
        </AccordionItem>
    );
};

export { BenchItemWrap };

import * as React from "react";

import { cn } from "@/lib/utils";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, children, ...props }, ref) => {
    return (
        <textarea
            className={cn(
                "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            ref={ref}
            {...props}
        >
            {children}
        </textarea>
    );
});
Textarea.displayName = "Textarea";
import { useRef, useEffect, useState } from "react";

export type ExpandingTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    minRows?: number;
    maxRows?: number;
};

const ExpandingTextArea = React.forwardRef<HTMLTextAreaElement, ExpandingTextAreaProps>(({ className, minRows = 4, maxRows = 10, onChange, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [content, setContent] = useState(props.value || props.defaultValue || "");
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = "auto";
            const scrollHeight = textarea.scrollHeight;
            const newHeight = Math.min(Math.max(scrollHeight, minRows * 24), maxRows * 24);
            textarea.style.height = `${newHeight}px`;
            setIsOverflowing(scrollHeight > maxRows * 24);
        }
    }, [content, minRows, maxRows]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <textarea
            {...props}
            ref={(element) => {
                textareaRef.current = element;
                if (typeof ref === "function") {
                    ref(element);
                } else if (ref) {
                    ref.current = element;
                }
            }}
            onChange={handleChange}
            className={cn(
                "flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                isOverflowing ? "overflow-y-auto" : "overflow-hidden",
                "resize-none",
                className
            )}
            style={{
                minHeight: `${minRows * 24}px`,
                maxHeight: `${maxRows * 24}px`,
            }}
        />
    );
});

ExpandingTextArea.displayName = "ExpandingTextArea";

export { ExpandingTextArea };
export { Textarea };

import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Drawer, DrawerTrigger, DrawerContent, DrawerHeader, DrawerTitle, DrawerDescription, DrawerFooter, DrawerClose } from "@/components/ui/drawer";
import axios from "axios";
import { MessageSquare, Info } from "lucide-react";
import { TourConstants } from "shared/data/tour_schema";
import { Booking } from "shared/data/tour_schema";
import { BookingStatus } from "shared/types/api";
function getLabel(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

const BOOKING_STATUSES = [BookingStatus.CONFIRMED, BookingStatus.PENDING, BookingStatus.CANCELLED].map((v) => v.toLowerCase());
const STATUS_FILTERS = ["all", ...BOOKING_STATUSES];
const DATE_FILTERS = ["all", "upcoming", "past"];

interface BookingsResponse {
    bookings: Booking[];
    total_count: number;
    current_page: number;
    total_pages: number;
}

interface GetBookingsParams {
    page?: number;
    pageSize?: number;
    searchTerm?: string;
    statusFilter?: BookingStatus | "all";
    dateFilter?: DateFilter;
    sortBy?: "tourDate" | "bookingDate" | "customer" | "status" | "totalAmount";
    sortOrder?: "asc" | "desc";
}

const get_bookings = async (params: GetBookingsParams): Promise<BookingsResponse> => {
    try {
        const response = await axios.get<BookingsResponse>("/v1/api/provider/bookings", {
            params: {
                page: params.page || 1,
                pageSize: params.pageSize || 10,
                search: params.searchTerm,
                status: params.statusFilter !== "all" ? params.statusFilter : undefined,
                dateFilter: params.dateFilter !== "all" ? params.dateFilter : undefined,
                sortBy: params.sortBy,
                sortOrder: params.sortOrder,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching bookings:", error);
        return Promise.resolve({
            total_count: 3,
            current_page: 0,
            total_pages: 1,
            bookings: [],
        });
    }
};

const TourProviderBookingsTab = () => {
    const [bookings, setBookings] = useState<Array<Booking>>([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [statusFilter, setStatusFilter] = useState("All");
    const [dateFilter, setDateFilter] = useState("All");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const filteredBookings = bookings.filter((booking) => {
        const matchesSearch =
            booking.customer.toLowerCase().includes(searchTerm.toLowerCase()) || booking.tourDate.includes(searchTerm) || booking.bookingDate.includes(searchTerm);

        const matchesStatus = statusFilter === "All" || booking.status === statusFilter;

        const matchesDate =
            dateFilter === "All" ||
            (dateFilter === "Upcoming" && new Date(booking.tourDate) >= new Date()) ||
            (dateFilter === "Past" && new Date(booking.tourDate) < new Date());

        return matchesSearch && matchesStatus && matchesDate;
    });

    const getStatusColor = (status: StatusFilter) => {
        switch (status.toLowerCase()) {
            case "confirmed":
                return "bg-green-100 text-green-800";
            case "pending":
                return "bg-yellow-100 text-yellow-800";
            case "cancelled":
                return "bg-red-100 text-red-800";
            default:
                return "bg-gray-100 text-gray-800";
        }
    };

    const BookingDetails = ({ booking }: { booking: Booking }) => (
        <div className="space-y-4">
            <p>
                <strong>Tour Date:</strong> {booking.tourDate}
            </p>
            <p>
                <strong>Customer:</strong> {booking.customer}
            </p>
            <p>
                <strong>Booking Date:</strong> {booking.bookingDate}
            </p>
            <p>
                <strong>Participants:</strong> {booking.participants}
            </p>
            <p>
                <strong>Status:</strong> {booking.status}
            </p>
            <p>
                <strong>Total Amount:</strong> ${booking.totalAmount}
            </p>
            <p>
                <strong>Special Requests:</strong> {booking.specialRequests}
            </p>
        </div>
    );

    const MessageDrawer = ({ booking }: { booking: Booking }) => (
        <Drawer>
            <DrawerTrigger asChild>
                <Button variant="outline" size="sm">
                    <MessageSquare className="h-4 w-4 mr-2" />
                    Message
                </Button>
            </DrawerTrigger>
            <DrawerContent>
                <DrawerHeader>
                    <DrawerTitle>Message {booking.customer}</DrawerTitle>
                    <DrawerDescription>Send a message regarding the tour on {booking.tourDate}</DrawerDescription>
                </DrawerHeader>
                <div className="p-4 space-y-4">
                    <textarea className="w-full h-32 p-2 border rounded" placeholder="Type your message here..."></textarea>
                    <Button className="w-full">Send Message</Button>
                </div>
                <DrawerFooter>
                    <DrawerClose asChild>
                        <Button variant="outline">Cancel</Button>
                    </DrawerClose>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );

    return (
        <Card>
            <CardHeader></CardHeader>
            <CardContent>
                <div className="flex justify-between flex-col md:flex-row items-center mb-4 space-x-4">
                    <Input type="text" placeholder="Search bookings..." value={searchTerm} onChange={handleSearchChange} className="w-64" />
                    <Select value={statusFilter} onValueChange={setStatusFilter}>
                        <SelectTrigger className="w-[180px] hidden md:block">
                            <SelectValue placeholder="Filter by status" />
                        </SelectTrigger>
                        <SelectContent className="">
                            <SelectItem value="All">All Statuses</SelectItem>
                            <SelectItem value="Confirmed">Confirmed</SelectItem>
                            <SelectItem value="Pending">Pending</SelectItem>
                            <SelectItem value="Cancelled">Cancelled</SelectItem>
                        </SelectContent>
                    </Select>
                    <Select value={dateFilter} onValueChange={setDateFilter}>
                        <SelectTrigger className="hidden md:block w-[180px]">
                            <SelectValue placeholder="Filter by date" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="All">All Dates</SelectItem>
                            <SelectItem value="Upcoming">Upcoming</SelectItem>
                            <SelectItem value="Past">Past</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Tour Date</TableHead>
                            <TableHead>Customer</TableHead>
                            <TableHead>Booking Date</TableHead>
                            <TableHead>Participants</TableHead>
                            <TableHead>Total Amount</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {filteredBookings.map((booking) => (
                            <TableRow key={booking.id}>
                                <TableCell>{booking.tourDate}</TableCell>
                                <TableCell>{booking.customer}</TableCell>
                                <TableCell>{booking.bookingDate}</TableCell>
                                <TableCell>{booking.participants}</TableCell>
                                <TableCell>${booking.totalAmount}</TableCell>
                                <TableCell>
                                    <Badge className={getStatusColor(booking.status)}>{booking.status}</Badge>
                                </TableCell>
                                <TableCell>
                                    <div className="flex space-x-2">
                                        <Dialog>
                                            <DialogTrigger asChild>
                                                <Button variant="outline" size="sm">
                                                    <Info className="h-4 w-4 mr-2" />
                                                    Details
                                                </Button>
                                            </DialogTrigger>
                                            <DialogContent>
                                                <DialogHeader>
                                                    <DialogTitle>Booking Details</DialogTitle>
                                                    <DialogDescription>
                                                        <BookingDetails booking={booking} />
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                        <MessageDrawer booking={booking} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default TourProviderBookingsTab;

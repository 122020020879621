import React, { useEffect, useState } from "react";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { Calendar, BookOpen, Building, InfoIcon, DollarSignIcon, Satellite } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tab, TourDashboardNavBar } from "../custom-nav-bar";
import TourProviderBookingsTab from "./components/bookings/bookings-tab";
import TourDatesManagementTab from "./components/tour-dates-management/tour-dates-management-tab";
import TourEntityManagementPanel from "./components/entity-management/tour-entity-managent";
import AdditionalInfoTab from "./components/additional-info/additional-info";
import PricingPoliciesTab from "./components/pricing/pricing-policy-tab";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Tour } from "shared/data/tour_schema";

import tour_schema from "shared/data/tour_schema";
import { TourConstants } from "shared/data/tour_schema";
import { Badge } from "@/components/ui/badge";
import { useParams } from "react-router-dom";
import { API_ENDPOINTS, PROVIDER_TOUR_API_ENDPOINTS } from "shared/types/api";
import { instance } from "shared/api/signature";

const { tour_form_default_values } = TourConstants;

const tabs: Array<Tab> = [
    { value: "bookings", label: "Bookings", icon: <BookOpen className="w-4 h-4" /> },
    { value: "tourDates", label: "Tour Dates", icon: <Calendar className="w-4 h-4" /> },
    { value: "entityManagement", label: "Entity Management", icon: <Building className="w-4 h-4" /> },
    { value: "pricing", label: "Pricing", icon: <DollarSignIcon className="w-4 h-4" /> },
    { value: "additionalInfo", label: "Additional Info", icon: <InfoIcon className="w-4 h-4" /> },
    { value: "statistics", label: "Statistics", icon: <Satellite className="w-4 h-4" /> },
];
const TourDashboard: React.FC = () => {
    const { handleSubmit, watch, setValue, formState } = useForm<Tour>({ defaultValues: tour_form_default_values });

    const params = useParams();

    useEffect(() => {
        const f = async (id: string) => {
            const { tourId } = PROVIDER_TOUR_API_ENDPOINTS["getTour"].params.parse({ tourId: id });
            const g = PROVIDER_TOUR_API_ENDPOINTS["getTour"].url.replace(":tourId", tourId);
            const t = await instance.get(g);

            Object.entries(t.data.data).forEach(([key, value]) => {
                setValue(key as keyof Tour, value);
            });
        };
        if (params.id) {
            f(params.id);
        }
    }, []);
    const [activeTab, setActiveTab] = React.useState("bookings");
    const tour = watch();

    const updateTour = (updater: Partial<Tour> | ((prevState: Tour) => Tour)) => {
        if (typeof updater === "function") {
            const newTour = updater(tour);
            Object.entries(newTour).forEach(([key, value]) => {
                if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                    Object.entries(value).forEach(([nestedKey, nestedValue]) => setValue(`${key}.${nestedKey}` as any, nestedValue, { shouldDirty: true }));
                } else {
                    setValue(key as keyof Tour, value, { shouldDirty: true });
                }
            });
        } else {
            Object.entries(updater).forEach(([key, value]) => {
                // For nested objects, we need to update each field individually
                if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                    Object.entries(value).forEach(([nestedKey, nestedValue]) => setValue(`${key}.${nestedKey}` as any, nestedValue, { shouldDirty: true }));
                } else setValue(key as keyof Tour, value, { shouldDirty: true });
            });
        }
    };

    const onSubmit = (data: Tour) => {
        const submissionData = { ...data };
        console.log(submissionData);
    };

    console.log(tour);
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="container mx-auto p-4">
                <div className="flex items-center">
                    <TourDashboardNavBar status={tour.status} tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
                </div>
                <Tabs value={activeTab} onValueChange={setActiveTab} className="mt-6">
                    <TabsContent value="bookings">
                        <Card className="w-full shadow-none border-0">
                            <CardHeader>
                                <CardTitle>Bookings</CardTitle>
                                <CardDescription>Manage your tour bookings</CardDescription>
                            </CardHeader>
                            <CardContent>
                                <TourProviderBookingsTab />
                            </CardContent>
                        </Card>
                    </TabsContent>

                    <TabsContent value="tourDates">
                        <Card className="w-full shadow-none border-0">
                            <CardContent>
                                <TourDatesManagementTab tourId={tour.id} />
                            </CardContent>
                        </Card>
                    </TabsContent>
                    <TabsContent value="entityManagement">
                        <TourEntityManagementPanel tour={tour} setTour={updateTour} />
                    </TabsContent>
                    <TabsContent value="pricing">
                        <PricingPoliciesTab tour={tour} updateTour={updateTour} />
                    </TabsContent>
                    <TabsContent value="additionalInfo">
                        <AdditionalInfoTab tour={tour} updateTour={updateTour} />
                    </TabsContent>
                </Tabs>
            </div>
        </form>
    );
};

export default TourDashboard;

import { Pen, UserIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "shared/helpers/utils";
import { UserSelector, updateUserImage } from "entities/store/user";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import React from "react";
import { Upload } from "@/upload/upload";

import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop";

interface ImageCropperProps {
    image: string;
    onCropComplete: (croppedImage: string) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({ image, onCropComplete }) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

    const onCropChange = (crop: Point) => {
        setCrop(crop);
    };
    const onCropComplete_ = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onZoomChange = (zoom: number) => {
        setZoom(zoom);
    };

    const createImage = (url: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.src = url;
        });

    const getCroppedImg = async (imageSrc: string, pixelCrop: Area) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return null;
        }

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

        return canvas.toDataURL("image/jpeg");
    };

    const handleSaveCroppedImage = useCallback(async () => {
        if (croppedAreaPixels) {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
            if (croppedImage) {
                onCropComplete(croppedImage);
            }
        }
    }, [croppedAreaPixels, image, onCropComplete]);
    return (
        <>
            <div className="relative h-[400px]">
                <Cropper image={image} crop={crop} zoom={zoom} aspect={1} onCropChange={onCropChange} onZoomChange={onZoomChange} onCropComplete={onCropComplete_} />
            </div>
            <div className="mt-12 z-100">
                <Button onClick={handleSaveCroppedImage}>Crop Image</Button>
            </div>
        </>
    );
};

function DefaultUserImage() {
    return (
        <div className="relative w-[120px] h-[120px] cursor-pointer">
            <UserIcon className="mb-5 rounded-full w-full h-full  bg-slate-500" />
            <Pen className="absolute bg-white right-[2px] bottom-[2px] md:right-[5px] rounded-full w-[16px] h-[16px] md:w-[20px] md:h-[20px] p-[3px]" />
        </div>
    );
}

import { useToast } from "@/components/ui/use-toast";

const useUserImage = () => useAppSelector(UserSelector.image);

function ProfileImage() {
    const image = useUserImage();
    const dispatch = useAppDispatch();
    const { toast } = useToast();
    const [modal, setModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<string | null>(null);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const closeModal = () => {
        setModal(false);
        setUploadedFile(null);
        setCroppedImage(null);
    };
    const setOpen = () => setModal(true);

    const handleFileSelect = useCallback((file: File | null) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUploadedFile(e.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleCropComplete = useCallback((croppedImageData: string) => {
        setCroppedImage(croppedImageData);
    }, []);

    const handleSaveImage = async () => {
        if (croppedImage) {
            try {
                const response = await fetch(croppedImage);
                const blob = await response.blob();

                const formData = new FormData();
                formData.append("file", blob, "profile_image.jpg");

                const resultAction = await dispatch(updateUserImage({ imageData: formData, img: croppedImage }));
                if (updateUserImage.fulfilled.match(resultAction)) {
                    toast({ title: "Success", description: "Profile picture updated successfully" });
                    closeModal();
                } else {
                    throw new Error("Failed to update profile picture");
                }
            } catch (error) {
                console.error("Error uploading profile picture:", error);
                toast({ title: "Error", description: "Failed to update profile picture. Please try again.", variant: "destructive" });
            }
        }
    };

    return (
        <div className="flex justify-center items-center w-[180px] h-[180px]">
            <Dialog open={modal} onOpenChange={setModal}>
                <DialogTrigger asChild>
                    <Button variant="ghost" onClick={setOpen}>
                        {!image ? <DefaultUserImage /> : <img src={image} alt="Profile" className="w-[120px] h-[120px] rounded-full object-cover" />}
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    {!uploadedFile && <Upload onFileSelect={handleFileSelect} maxFiles={1} acceptedFileTypes={["image/jpeg", "image/png", "image/gif"]} />}
                    {uploadedFile && !croppedImage && <ImageCropper image={uploadedFile} onCropComplete={handleCropComplete} />}
                    {croppedImage && (
                        <div className="flex flex-col items-center">
                            <img src={croppedImage} alt="Cropped" className="w-[120px] h-[120px] rounded-full object-cover mb-4" />
                            <Button onClick={handleSaveImage}>Save Profile Picture</Button>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ProfileImage;

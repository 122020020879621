import React from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import { API_ROUTES, proxy_route } from "shared/api/signature";

type ResponseDataType = { code: number; text: string; responseRoute: keyof typeof API_ROUTES };

/**
 * Used for generating error messages, where to reroute and etc
 */
export class ResponseDataText {
    private data: ResponseDataType;

    constructor(code: number, text: string, responseRoute: keyof typeof API_ROUTES) {
        this.data = { code, text, responseRoute };
    }

    get() {
        return JSON.stringify(this.data) as BodyInit;
    }

    static get(data: string) {
        try {
            return JSON.parse(data) as ResponseDataType;
        } catch (error) {
            return "";
        }
    }
}

const getErrorImage = (code: number) => {
    const s3Route = proxy_route + `/static/service/`;
    switch (code) {
        case 403:
        case 404:
        case 418:
        case 500:
        case 503:
            return s3Route + code + ".png";
        default:
            return s3Route + "wrong.png";
    }
};

function ErrorPage() {
    const navigate = useNavigate();
    const reroute = () => navigate("/");
    const toast = useToast();
    const error = useRouteError();

    // LINK TO S3 FOR STATUS CODES
    const [imageS3, setImageS3] = React.useState("");
    const [text, setText] = React.useState("Произошла ошибка, перейдите на главную страницу");

    const output = (code: number, text: string) => {
        setImageS3(getErrorImage(code));
        setText(text);
    };

    React.useEffect(() => {
        if (isRouteErrorResponse(error)) {
            const res = ResponseDataText.get(error.data);
            if (typeof res != "string") {
                output(res.code, res.text);
            } else output(error.status, error.statusText);
        }
    }, []);

    return (
        <div className="border flex-1  w-full flex justify-center items-center flex-col space-y-4 ">
            <h1 className="text-2xl text-center font-bold">{text}</h1>
            {imageS3 !== "" && <img src={imageS3} className="max-w-[300px] max-h-[200px] " />}
            <Button variant={"outline"} onClick={reroute}>
                Back to main page
            </Button>
        </div>
    );
}

export default ErrorPage;

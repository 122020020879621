import React, { useRef, useMemo, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { MapPin, Loader2, Star } from "lucide-react";
import { useGoogleMapsScript } from "shared/hooks/hooks";

interface AutocompleteProps {
    onPlaceSelected?: (place: google.maps.places.PlaceResult) => void;
    placeholder?: string;
    className?: string;
}

interface Suggestion {
    placeId: string;
    description: string;
}

type State =
    | { status: "idle" }
    | { status: "inputting"; input: string }
    | { status: "fetching"; input: string }
    | { status: "suggesting"; input: string; suggestions: Suggestion[] }
    | { status: "selected"; input: string; placeId: string };

type Action =
    | { type: "INPUT_CHANGE"; payload: string }
    | { type: "FETCH_START" }
    | { type: "FETCH_SUCCESS"; payload: Suggestion[] }
    | { type: "FETCH_ERROR" }
    | { type: "SELECT_SUGGESTION"; payload: { input: string; placeId: string } }
    | { type: "CLEAR" }
    | { type: "BLUR" };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "INPUT_CHANGE":
            return { status: "inputting", input: action.payload };
        case "FETCH_START":
            return { ...state, status: "fetching" };
        case "FETCH_SUCCESS":
            return { status: "suggesting", input: (state as any).input, suggestions: action.payload };
        case "FETCH_ERROR":
            return { status: "inputting", input: (state as any).input };
        case "SELECT_SUGGESTION":
            return { status: "selected", input: action.payload.input, placeId: action.payload.placeId };
        case "CLEAR":
            return { status: "idle" };
        case "BLUR":
            return state.status === "suggesting" ? { status: "inputting", input: (state as any).input } : state;
        default:
            return state;
    }
}
const search_fields = ["address_components", "adr_address", "formatted_address", "geometry", "name", "place_id", "type", "utc_offset", "vicinity"];

const Autocomplete = forwardRef<{ clearInput: () => void }, AutocompleteProps>(
    ({ onPlaceSelected = (v) => console.log(v), placeholder = "Search for a location", className = "" }, ref) => {
        const [state, dispatch] = React.useReducer(reducer, { status: "idle" });

        const inputRef = useRef<HTMLInputElement>(null);
        const suggestionsRef = useRef<HTMLDivElement>(null);
        const mapRef = useRef<HTMLDivElement>(null);

        const { loaded, error } = useGoogleMapsScript();

        const placesService = useMemo(() => {
            if (loaded && mapRef.current) {
                return new window.google.maps.places.AutocompleteService();
            }
            return null;
        }, [loaded]);

        useImperativeHandle(ref, () => ({
            clearInput: () => dispatch({ type: "CLEAR" }),
        }));

        useEffect(() => {
            if (state.status === "inputting") {
                const timer = setTimeout(() => {
                    if (state.input) {
                        dispatch({ type: "FETCH_START" });
                    }
                }, 300);

                return () => clearTimeout(timer);
            }
        }, [state]);

        useEffect(() => {
            if (state.status === "fetching" && placesService) {
                placesService.getPlacePredictions({ input: (state as any).input }, (predictions, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                        const suggestions = predictions.map((prediction) => ({
                            placeId: prediction.place_id,
                            description: prediction.description,
                        }));
                        dispatch({ type: "FETCH_SUCCESS", payload: suggestions });
                    } else {
                        dispatch({ type: "FETCH_ERROR" });
                    }
                });
            }
        }, [state, placesService]);

        useEffect(() => {
            if (state.status === "selected" && loaded && mapRef.current) {
                const placeService = new google.maps.places.PlacesService(mapRef.current);
                placeService.getDetails({ placeId: state.placeId, fields: search_fields }, (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                        onPlaceSelected(place);
                    }
                });
            }
        }, [state, loaded, onPlaceSelected]);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
                    dispatch({ type: "BLUR" });
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, []);

        if (error) {
            return <div>Error loading Google Maps: {error.message}</div>;
        }

        return (
            <div className="relative" ref={suggestionsRef}>
                <div ref={mapRef} style={{ display: "none" }}></div>
                <div className="relative">
                    <Input
                        ref={inputRef}
                        type="text"
                        value={state.status !== "idle" ? (state as any).input : ""}
                        onChange={(e) => dispatch({ type: "INPUT_CHANGE", payload: e.target.value })}
                        placeholder={loaded ? placeholder : "Loading..."}
                        className={`${className} pr-10`}
                        disabled={!loaded}
                    />
                    {state.status === "fetching" && <Loader2 className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 animate-spin" />}
                </div>
                {state.status === "suggesting" && (state as any).suggestions.length > 0 && (
                    <div className="absolute z-50 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                        {(state as any).suggestions.map((suggestion: Suggestion) => (
                            <Button
                                key={suggestion.placeId}
                                className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center"
                                variant="ghost"
                                onClick={() => dispatch({ type: "SELECT_SUGGESTION", payload: { input: suggestion.description, placeId: suggestion.placeId } })}
                            >
                                <MapPin className="h-5 w-5 mr-2 flex-shrink-0" />
                                <span className="truncate">{suggestion.description}</span>
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

Autocomplete.displayName = "Autocomplete";

export default React.memo(Autocomplete);

import { X, Globe, Phone } from "lucide-react";
interface PlacePreviewProps {
    place: google.maps.places.PlaceResult;
    onDelete?: () => void;
}
const PlacePreview: React.FC<PlacePreviewProps> = ({ place, onDelete }) => {
    return (
        <div className="bg-white rounded-lg shadow-md overflow-hidden max-w-md w-full">
            <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                    <h2 className="text-xl font-semibold text-gray-800 leading-tight">{place.name}</h2>
                    {onDelete && (
                        <Button variant="ghost" size="icon" onClick={onDelete} className="text-gray-400 hover:text-red-500 transition-colors">
                            <X className="h-5 w-5" />
                        </Button>
                    )}
                </div>

                <div className="flex items-center text-gray-600 mb-4">
                    <MapPin className="h-4 w-4 mr-2 flex-shrink-0" />
                    <p className="text-sm">{place.formatted_address}</p>
                </div>

                {place.rating && (
                    <div className="flex items-center mb-4">
                        <Star className="h-5 w-5 text-yellow-400 mr-1" />
                        <span className="text-gray-700 font-medium">{place.rating.toFixed(1)}</span>
                        <span className="text-gray-500 text-sm ml-2">({place.user_ratings_total} reviews)</span>
                    </div>
                )}

                {place.types && place.types.length > 0 && (
                    <div className="flex flex-wrap gap-2 mb-4">
                        {place.types.slice(0, 3).map((type, index) => (
                            <span key={index} className="px-2 py-1 bg-blue-50 text-blue-600 text-xs font-medium rounded-full">
                                {type.replace(/_/g, " ")}
                            </span>
                        ))}
                    </div>
                )}

                <div className="flex flex-col space-y-2">
                    {place.website && (
                        <a href={place.website} target="_blank" rel="noopener noreferrer" className="flex items-center text-sm text-blue-600 hover:underline">
                            <Globe className="h-4 w-4 mr-2" />
                            Website
                        </a>
                    )}
                    {place.formatted_phone_number && (
                        <a href={`tel:${place.formatted_phone_number}`} className="flex items-center text-sm text-blue-600 hover:underline">
                            <Phone className="h-4 w-4 mr-2" />
                            {place.formatted_phone_number}
                        </a>
                    )}
                    {place.url && (
                        <a href={place.url} target="_blank" rel="noopener noreferrer" className="flex items-center text-sm text-blue-600 hover:underline">
                            <MapPin className="h-4 w-4 mr-2" />
                            View on Google Maps
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export { PlacePreview };

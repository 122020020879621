import TourListPreview from "@/TourPreview/TourListPreview";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { blank } from "@/TourPreview/ProvidersTourPreview";
import * as API from "shared/api/signature";
import InfiniteScroll from "@/components/ui/infinite-scroll";
import { usePaginatedData } from "shared/api/usePaginate";

const fetchTours = async (page: number) => {
    const response = await API.tours_get(page);
    return response.data.data;
};

function ToursList({ count = 20 }) {
    const { t } = useTranslation();
    const initialData = useLoaderData();

    const { data, loading, error, hasMore, loadMore } = usePaginatedData({
        initialData: initialData.data ?? [],
        initialPagination: initialData.pagination,
        fetchFunction: fetchTours,
    });

    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="grid home-grid-template justify-items-center h-full w-full max-w-[1600px] m-0 p-0">
            <InfiniteScroll isLoading={loading} hasMore={hasMore} next={loadMore} threshold={0.8}>
                {data.map((tour, index) => {
                    if (index >= count) return null;

                    let image = blank;
                    if (tour.images && tour.images.length > 0) {
                        image = tour.images[0].url;
                    }
                    return <TourListPreview key={tour.id} date={tour.date} image={image} name={tour.name || ""} id={tour.id} />;
                })}
            </InfiniteScroll>
            {loading && <div>Loading more tours...</div>}
        </div>
    );
}

export default ToursList;

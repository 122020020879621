import Logo from "@/Logo/Logo";

// const Footer = () => {
//     const socialLinks = ["Fb", "In", "Tw", "Ln"];
//     const companyLinks = ["About", "Pricing", "Jobs", "Blog", "Careers"];
//     const productLinks = ["Sales software", "Features", "Privacy and security", "Marketplace", "API"];

//     return (
//         <footer className="py-8 px-4 bg-white text-gray-600 bg-gradient-to-br from-primary-light to-secondary-light">
//             <div className="max-w-7xl mx-auto">
//                 <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
//                     <div className="md:col-span-1">
//                         <h2 className="text-3xl font-bold mb-2 text-[#ff7f50]">Let's travel</h2>
//                         <p className="mb-4 text-sm">Discover the world with us. Your journey begins here.</p>
//                         <div className="flex space-x-4">
//                             {socialLinks.map((social, index) => (
//                                 <a key={index} href="#" className="text-sm hover:text-[#ff7f50] transition-colors duration-300">
//                                     {social}
//                                 </a>
//                             ))}
//                         </div>
//                     </div>
//                     <div>
//                         <h3 className="text-lg font-semibold mb-2 text-[#ff7f50]">Company</h3>
//                         <ul className="space-y-1 text-sm">
//                             {companyLinks.map((link, index) => (
//                                 <li key={index}>
//                                     <a href="#" className="hover:text-[#ff7f50] transition-colors duration-300">
//                                         {link}
//                                     </a>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                     <div>
//                         <h3 className="text-lg font-semibold mb-2 text-[#ff7f50]">Product</h3>
//                         <ul className="space-y-1 text-sm">
//                             {productLinks.map((link, index) => (
//                                 <li key={index}>
//                                     <a href="#" className="hover:text-[#ff7f50] transition-colors duration-300">
//                                         {link}
//                                     </a>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//             <div className="mt-8 pt-4 border-t border-gray-200 flex justify-between items-center text-sm">
//                 <div className="font-bold text-[#ff7f50]">GET TRAVEL</div>
//                 <div>© {new Date().getFullYear()} All rights reserved.</div>
//             </div>
//         </footer>
//     );
// };
const Footer = () => {
    const socialLinks = ["Fb", "In", "Tw", "Ln"];
    const companyLinks = ["About", "Pricing", "Jobs", "Blog", "Careers"];
    const productLinks = ["Sales software", "Features", "Privacy and security", "Marketplace", "API"];

    return (
        <footer className="py-16 px-4 bg-gradient-to-br from-primary-light to-secondary-light text-white">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                    <div className="md:col-span-2">
                        <h2 className="text-4xl font-bold mb-4 text-white">Let's travel</h2>
                        <p className="mb-6 text-white/80">Discover the world with us. Your journey begins here.</p>
                        <div className="flex space-x-6">
                            {socialLinks.map((social, index) => (
                                <a key={index} href="#" className="text-white/80 hover:text-white transition-colors duration-300">
                                    {social}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4 text-white">Company</h3>
                        <ul className="space-y-3">
                            {companyLinks.map((link, index) => (
                                <li key={index}>
                                    <a href="#" className="text-white/80 hover:text-white transition-colors duration-300">
                                        {link}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4 text-white">Product</h3>
                        <ul className="space-y-3">
                            {productLinks.map((link, index) => (
                                <li key={index}>
                                    <a href="#" className="text-white/80 hover:text-white transition-colors duration-300">
                                        {link}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="mt-16 pt-8 border-t border-white/20 flex flex-col md:flex-row justify-between items-center">
                    <Logo className="mb-4 md:mb-0 text-white" />
                    <div className="text-sm text-white/80">&copy; {new Date().getFullYear()} All rights reserved.</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

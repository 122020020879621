import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { MultiSelect } from "@/multi-select";
import { X, Upload, Clock, Loader2, Construction, ChevronDown, ChevronUp, Move } from "lucide-react";
import { TourLocationManagementWrapper } from "./components/day-wrapper/itinerary-day-wrapper";
import InteractiveItinerary from "./components/day-wrapper/InteractiveItinerary";
import { SliderInput } from "@/components/ui/slider";
import { Dialog, DialogClose, DialogContent } from "@radix-ui/react-dialog";
import Autocomplete from "pages/provider/tour_new_api/Autocomplete/Autocomplete";
import {
    PROVIDER_TOUR_API_ENDPOINTS,
    TourSchema,
    DurationTypeEnum,
    DifficultyEnum,
    LanguageEnum,
    CurrencyEnum,
    LocationCategory,
    languageOptions,
    difficultyLevels,
    DurationType,
    TourImageSchema,
} from "shared/types/api";
import { FullScreenLocationInput } from "../locations/full-screen-location-input";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { AnimatePresence, motion } from "framer-motion";

type EntityManageImagesProps = {
    entityId: string;
    initialImages: ImageType[];
    onImagesChange: (images: ImageType[]) => void;
};
type TourImage = z.infer<typeof TourImageSchema>;

const DraggableImage: React.FC<{
    image: ImageType;
    index: number;
    totalImages: number;
    moveImage: (fromIndex: number, toIndex: number) => void;
    removeImage: (id: string) => void;
}> = ({ image, index, totalImages, moveImage, removeImage }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: image.id });
    const moveHandleRef = useRef<HTMLDivElement>(null);

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        zIndex: isDragging ? 1000 : 1,
        opacity: isDragging ? 0.6 : 1,
    };

    const handleMoveClick = (direction: number) => (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newIndex = index + direction;
        if (newIndex >= 0 && newIndex < totalImages) {
            moveImage(index, newIndex);
        }
    };

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        removeImage(image.id);
    };

    useEffect(() => {
        const moveHandle = moveHandleRef.current;
        if (!moveHandle) return;

        const preventScroll = (e: TouchEvent) => {
            e.preventDefault();
        };

        const enableScrollLock = () => {
            document.addEventListener("touchmove", preventScroll, { passive: false });
            document.body.style.overflow = "hidden";
        };

        const disableScrollLock = () => {
            document.removeEventListener("touchmove", preventScroll);
            document.body.style.overflow = "";
        };

        moveHandle.addEventListener("mousedown", enableScrollLock);
        moveHandle.addEventListener("touchstart", enableScrollLock);

        document.addEventListener("mouseup", disableScrollLock);
        document.addEventListener("touchend", disableScrollLock);

        return () => {
            moveHandle.removeEventListener("mousedown", enableScrollLock);
            moveHandle.removeEventListener("touchstart", enableScrollLock);
            document.removeEventListener("mouseup", disableScrollLock);
            document.removeEventListener("touchend", disableScrollLock);
            disableScrollLock();
        };
    }, []);

    return (
        <div className="relative">
            <div
                ref={moveHandleRef}
                {...attributes}
                {...listeners}
                className="absolute top-2 z-[100] left-2 text-white bg-black bg-opacity-50 rounded-full p-1 cursor-move"
            >
                <Move className="h-5 w-5" />
            </div>
            <motion.div
                ref={setNodeRef}
                style={style}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.2 }}
                className="relative bg-white rounded-lg shadow-md overflow-hidden group"
            >
                <img
                    src={image.url}
                    alt={image.altText || `Tour image ${index + 1}`}
                    className="w-full h-40 object-cover transition-transform duration-200 group-hover:scale-105"
                    draggable={false}
                />

                <div className="absolute inset-0 z-10 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-200 flex items-center justify-center">
                    <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                        <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            className="text-white hover:bg-white hover:bg-opacity-20"
                            onClick={handleMoveClick(-1)}
                            disabled={index === 0}
                        >
                            <ChevronUp className="h-6 w-6" />
                        </Button>
                        <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            className="text-white hover:bg-white hover:bg-opacity-20"
                            onClick={handleMoveClick(1)}
                            disabled={index === totalImages - 1}
                        >
                            <ChevronDown className="h-6 w-6" />
                        </Button>
                        <Button type="button" variant="ghost" size="icon" className="text-white hover:bg-white hover:bg-opacity-20" onClick={handleDeleteClick}>
                            <X className="h-6 w-6" />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

const EntityManageImages: React.FC<EntityManageImagesProps> = ({ entityId, initialImages, onImagesChange }) => {
    const [images, setImages] = useState<ImageType[]>(initialImages);
    const [uploadingImages, setUploadingImages] = useState<Record<string, boolean>>({});
    const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
    const { toast } = useToast();

    const { setValue, watch } = useForm<{ images: ImageType[] }>({
        defaultValues: { images: initialImages },
    });

    const watchImages = watch("images");

    useEffect(() => {
        setImages(watchImages.sort((a, b) => (a.order || 0) - (b.order || 0)));
    }, [watchImages]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const uploadImage = useCallback(
        async (file: File) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("order", (images.length + 1).toString());

            try {
                const response = await instance.post(`/v1/provider/tours/${entityId}/images`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prev) => ({ ...prev, [file.name]: percentCompleted }));
                    },
                });

                if (!response.data) {
                    throw new Error("Failed to upload image");
                }

                return response.data;
            } catch (error) {
                console.error("Error uploading image:", error);
                throw error;
            }
        },
        [entityId, images.length]
    );

    const handleImageUpload = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files) return;

            const files = Array.from(event.target.files);
            const newUploadingImages: Record<string, boolean> = {};
            const newUploadProgress: Record<string, number> = {};

            files.forEach((file) => {
                newUploadingImages[file.name] = true;
                newUploadProgress[file.name] = 0;
            });

            setUploadingImages(newUploadingImages);
            setUploadProgress(newUploadProgress);

            try {
                const uploadedImages = await Promise.all(
                    files.map(async (file) => {
                        const result = await uploadImage(file);
                        setUploadingImages((prev) => ({ ...prev, [file.name]: false }));
                        return result;
                    })
                );

                const newImages = [...images, ...uploadedImages].sort((a, b) => (a.order || 0) - (b.order || 0));
                setImages(newImages);
                setValue("images", newImages);
                onImagesChange(newImages);

                toast({
                    title: "Success",
                    description: "Images uploaded successfully",
                });
            } catch (error) {
                console.error("Error in handleImageUpload:", error);
                toast({ title: "Error", description: "Failed to upload images", variant: "destructive" });
            } finally {
                setUploadingImages({});
                setUploadProgress({});
            }
        },
        [uploadImage, images, setValue, onImagesChange, toast]
    );

    const removeImage = useCallback(
        async (imageId: string) => {
            try {
                await instance.delete(`/v1/provider/tours/${entityId}/images/${imageId}`);

                const newImages = images.filter((img) => img.id !== imageId).map((img, index) => ({ ...img, order: index + 1 }));

                setImages(newImages);
                setValue("images", newImages);
                onImagesChange(newImages);

                toast({ title: "Success", description: "Image deleted successfully" });
            } catch (error) {
                console.error("Error deleting image:", error);
                toast({ title: "Error", description: "Failed to delete image", variant: "destructive" });
            }
        },
        [entityId, images, setValue, onImagesChange, toast]
    );

    const moveImage = useCallback(
        (fromIndex: number, toIndex: number) => {
            const newImages = arrayMove(images, fromIndex, toIndex).map((img, index) => ({ ...img, order: index + 1 }));

            setImages(newImages);
            setValue("images", newImages);
            onImagesChange(newImages);
        },
        [images, setValue, onImagesChange]
    );

    const handleDragEnd = useCallback(
        (event: any) => {
            const { active, over } = event;

            if (active.id !== over.id) {
                const oldIndex = images.findIndex((item) => item.id === active.id);
                const newIndex = images.findIndex((item) => item.id === over.id);

                moveImage(oldIndex, newIndex);
            }
        },
        [images, moveImage]
    );

    const memoizedImages = useMemo(() => images, [images]);

    return (
        <div className="space-y-6">
            <Label htmlFor="images">Tour Images</Label>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={memoizedImages.map((img) => img.id)}>
                    <div className="grid grid-cols-3 gap-4">
                        <AnimatePresence>
                            {memoizedImages.map((image, index) => (
                                <DraggableImage
                                    key={image.id}
                                    image={image}
                                    index={index}
                                    totalImages={memoizedImages.length}
                                    moveImage={moveImage}
                                    removeImage={removeImage}
                                />
                            ))}
                        </AnimatePresence>
                        <label className="flex items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200">
                            <input type="file" multiple accept="image/*" onChange={handleImageUpload} className="sr-only" />
                            <div className="flex flex-col items-center">
                                <Upload className="w-8 h-8 text-gray-400" />
                                <span className="mt-2 text-sm text-gray-500">Upload images</span>
                            </div>
                        </label>
                    </div>
                </SortableContext>
            </DndContext>
        </div>
    );
};

const TourFormSchema = TourSchema.extend({
    images: z.array(z.object({ url: z.string(), altText: z.string() })),
});

type TourFormData = z.infer<typeof TourFormSchema>;

const TourEntityManagementPanel: React.FC<{ tour?: TourFormData }> = ({ tour }) => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isDirty, dirtyFields },
        getValues,
    } = useForm<TourFormData>({
        resolver: zodResolver(TourFormSchema),
        defaultValues: tour || {
            id: "",
            name: "",
            description: "",
            durationType: DurationTypeEnum.enum.SINGLE_DAY,
            duration: { days: 1, hours: 0 },
            pricing: { basePrice: 0, mainCurrency: CurrencyEnum.enum.USD },
            maxParticipants: 1,
            minAge: 1,
            difficulty: DifficultyEnum.enum.EASY,
            languages: [],
            images: [],
            tourLocations: [],
            itinerary: [],
            meetingPoint: null,
        },
    });
    const [isSaving, setIsSaving] = useState(false);

    const { meetingPoint, addMeetingPoint, removeMeetingPoint } = useTourLocations(tour.id, tour.tourLocations, tour.meetingPoint);

    const handleDurationTypeChange = (value: DurationTypeEnum) => {
        setValue("durationType", value);
        let newDuration = { ...watch("duration") };
        let newItinerary = [...watch("itinerary")];

        if (value === DurationTypeEnum.enum.SINGLE_DAY) {
            newDuration = { days: 1, hours: 0 };
            newItinerary = newItinerary.slice(0, 1);
        } else if (durationType === DurationTypeEnum.enum.SINGLE_DAY) {
            newDuration = { days: 2, hours: 0 };
            newItinerary.push({ dayNumber: 2, title: "", description: "", locationIds: [] });
        }

        setValue("duration", newDuration);
        setValue("itinerary", newItinerary);
    };

    const handleDurationChange = (field: "days" | "hours", value: number) => {
        const newDuration = { ...watch("duration"), [field]: value };
        let newItinerary = [...watch("itinerary")];

        if (field === "days") {
            if (value > newItinerary.length) {
                for (let i = newItinerary.length + 1; i <= value; i++) {
                    newItinerary.push({ dayNumber: i, title: "", description: "", locationIds: [] });
                }
            } else if (value < newItinerary.length) {
                newItinerary = newItinerary.slice(0, value);
            }
        }

        setValue("duration", newDuration);
        setValue("itinerary", newItinerary);
    };

    const handleMeetingPointChange = async (locationData: any | null) => {
        if (locationData === null) {
            await removeMeetingPoint();
            setValue("meetingPoint", { meetingPoint: null });
        } else {
            const newMeetingPoint = await addMeetingPoint(locationData);
            setValue("meetingPoint", { meetingPoint: newMeetingPoint });
        }
    };
    const durationType = watch("durationType");

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            const newImages = files.map((file) => ({ url: URL.createObjectURL(file), altText: file.name }));
            setValue("images", [...watch("images"), ...newImages]);
        }
    };

    const removeImage = async (index: number) => {
        setValue(
            "images",
            watch("images").filter((_, i) => i !== index)
        );
    };

    const onSubmit = async (data: TourFormData) => {
        setIsSaving(true);

        const dirtyData = Object.keys(dirtyFields).reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {} as UpdateBodyType);

        try {
            await updateTour(tour.id, dirtyData);
            // Handle success (e.g., show a success message)
            console.log("Tour updated successfully");
        } catch (error) {
            // Handle error (e.g., show an error message)
            console.error("Failed to update tour:", error);
        } finally {
            setIsSaving(false);
        }
    };

    const onSave = async () => {
        if (!isDirty) return;

        setIsSaving(true);

        const formData = getValues();
        const dirtyData = Object.keys(dirtyFields).reduce((acc, key) => {
            acc[key] = formData[key];
            return acc;
        }, {} as UpdateBodyType);

        try {
            await updateTour(tour.id, dirtyData);
            console.log("Tour updated successfully");
        } catch (error) {
            console.error("Failed to update tour:", error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Card className="w-full border-0 shadow-none mx-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle>Tour Entity Management</CardTitle>{" "}
                    <Button type="button" onClick={onSave} className="ml-auto" disabled={!isDirty || isSaving}>
                        {isSaving ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Saving...
                            </>
                        ) : (
                            "Save Tour"
                        )}
                    </Button>
                </CardHeader>
                <CardContent className="space-y-6">
                    <div>
                        <Label htmlFor="name">Tour Name</Label>
                        <Input id="name" {...register("name")} />
                        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                    </div>

                    <div>
                        <Label htmlFor="description">Description</Label>
                        <Textarea id="description" {...register("description")} maxLength={800} rows={4} />
                        {errors.description && <p className="text-red-500">{errors.description.message}</p>}
                    </div>

                    <div>
                        <Label className="text-lg font-semibold text-primary-dark flex items-center mb-2">
                            <Clock className="w-5 h-5 mr-2 text-primary" />
                            Tour Type
                        </Label>
                        <Controller
                            name="durationType"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup
                                    onValueChange={(value) => {
                                        field.onChange(value);
                                        handleDurationTypeChange(value as DurationTypeEnum);
                                    }}
                                    value={field.value}
                                    className="flex flex-col w-full items-start space-y-2 justify-end"
                                >
                                    <div className="flex items-end space-x-2">
                                        <RadioGroupItem value={DurationTypeEnum.enum.SINGLE_DAY} id="singleDay" />
                                        <Label htmlFor="singleDay">Single Day</Label>
                                    </div>
                                    <div className="flex items-end space-x-2">
                                        <RadioGroupItem value={DurationTypeEnum.enum.MULTI_DAY} id="multiDay" />
                                        <Label htmlFor="multiDay">Multi-Day</Label>
                                    </div>
                                </RadioGroup>
                            )}
                        />

                        {durationType === DurationTypeEnum.enum.SINGLE_DAY ? (
                            <div className="flex space-x-4 mt-4">
                                <div className="flex-1">
                                    <Label htmlFor="durationHours">Hours</Label>
                                    <Controller
                                        name="duration.hours"
                                        control={control}
                                        render={({ field }) => <SliderInput value={field.value} setState={(v) => handleDurationChange("hours", v)} min={1} max={23} />}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="w-full mt-4">
                                <Label htmlFor="durationDays">Days</Label>
                                <Controller
                                    name="duration.days"
                                    control={control}
                                    render={({ field }) => <SliderInput value={field.value} setState={(v) => handleDurationChange("days", v)} min={2} max={30} />}
                                />
                            </div>
                        )}
                    </div>

                    {/* {durationType === DurationTypeEnum.enum.MULTI_DAY && ( */}
                    <Controller
                        name="itinerary"
                        control={control}
                        render={({ field }) => (
                            <InteractiveItinerary
                                tour={{ ...watch(), itinerary: field.value }}
                                handleAddLocation={(dayIndex, locationId) => {
                                    const newItinerary = [...field.value];
                                    newItinerary[dayIndex].locationIds.push(locationId);
                                    field.onChange(newItinerary);
                                }}
                                handleItineraryChange={(dayIndex, key, value) => {
                                    const newItinerary = [...field.value];
                                    newItinerary[dayIndex] = { ...newItinerary[dayIndex], [key]: value };
                                    field.onChange(newItinerary);
                                }}
                                handleRemoveLocation={(dayIndex, locationId) => {
                                    const newItinerary = [...field.value];
                                    newItinerary[dayIndex].locationIds = newItinerary[dayIndex].locationIds.filter((id) => id !== locationId);
                                    field.onChange(newItinerary);
                                }}
                            />
                        )}
                    />
                    {/* )} */}

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Label htmlFor="basePrice">Base Price</Label>
                            <Input id="basePrice" type="number" {...register("pricing.basePrice", { valueAsNumber: true })} />
                            {errors.pricing?.basePrice && <p className="text-red-500">{errors.pricing.basePrice.message}</p>}
                        </div>
                        <div>
                            <Label htmlFor="mainCurrency">Main Currency</Label>
                            <Controller
                                name="pricing.mainCurrency"
                                control={control}
                                render={({ field }) => (
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select currency" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Object.values(CurrencyEnum.enum).map((currency) => (
                                                <SelectItem key={currency} value={currency}>
                                                    {currency}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <Label htmlFor="maxParticipants">Max Participants</Label>
                            <Input id="maxParticipants" type="number" {...register("maxParticipants", { valueAsNumber: true })} />
                            {errors.maxParticipants && <p className="text-red-500">{errors.maxParticipants.message}</p>}
                        </div>
                        <div>
                            <Label htmlFor="minAge">Minimum Age</Label>
                            <Input id="minAge" type="number" {...register("minAge", { valueAsNumber: true })} />
                            {errors.minAge && <p className="text-red-500">{errors.minAge.message}</p>}
                        </div>
                        <div>
                            <Label htmlFor="difficulty">Difficulty</Label>
                            <Controller
                                name="difficulty"
                                control={control}
                                render={({ field }) => (
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select difficulty" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {difficultyLevels.map((difficulty) => (
                                                <SelectItem key={difficulty.value} value={difficulty.value}>
                                                    {difficulty.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="languages">Languages</Label>
                        <Controller
                            name="languages"
                            control={control}
                            render={({ field }) => <MultiSelect selectedValues={field.value ?? []} options={languageOptions} onChange={field.onChange} />}
                        />
                    </div>

                    <div>
                        <Accordion type="single" collapsible className="w-full">
                            <AccordionItem value="location-search">
                                <AccordionTrigger>Tour locations</AccordionTrigger>
                                <AccordionContent>
                                    <Controller
                                        name="tourLocations"
                                        control={control}
                                        render={({ field }) => (
                                            <TourLocationManagementWrapper tourId={tour.id} initialTourLocations={field.value ?? []} setValue={setValue} />
                                        )}
                                    />
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>

                    <div>
                        <MeetingPointDashboard key={meetingPoint?.location?.id} value={meetingPoint?.location} onChange={handleMeetingPointChange} />
                    </div>
                    <EntityManageImages entityId={tour.id} initialImages={tour.images} onImagesChange={(newImages) => setValue("images", newImages)} />
                </CardContent>
            </form>
        </Card>
    );
};

export default TourEntityManagementPanel;

import { MapPin, Edit2, Map } from "lucide-react";
import { useTourLocations } from "features/provider/useTourLocations";
import { updateTour } from "pages/provider/tour_new_api/Constructor/State";
import { MobileImagesUploadStep } from "pages/provider/tour_new_api/Constructor/Concept/Provider";
import { useToast } from "@/components/ui/use-toast";
import { instance } from "shared/api/signature";

type State =
    | { status: "viewing"; location: Location }
    | { status: "editing"; location: Location | null }
    | { status: "fullscreen"; location: Location | null }
    | { status: "empty" };

type Action = { type: "EDIT" } | { type: "FULLSCREEN" } | { type: "CLEAR" } | { type: "SET_LOCATION"; location: Location } | { type: "CANCEL_EDIT" };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "EDIT":
            return { status: "editing", location: state.status === "viewing" ? state.location : null };
        case "FULLSCREEN":
            return { status: "fullscreen", location: state.status === "viewing" ? state.location : null };
        case "CLEAR":
            return { status: "empty" };
        case "SET_LOCATION":
            return { status: "viewing", location: action.location };
        case "CANCEL_EDIT":
            return state.status === "viewing"
                ? state
                : state.status === "editing" && state.location
                ? { status: "viewing", location: state.location }
                : { status: "empty" };
        default:
            return state;
    }
}

interface MeetingPointDashboardProps {
    value: Location | null;
    onChange: (location: Location | null) => void;
    label?: string;
}

export const MeetingPointDashboard: React.FC<MeetingPointDashboardProps> = ({ value, onChange, label = "Meeting Point" }) => {
    const initialState: State = value ? { status: "viewing", location: value } : { status: "empty" };
    const [state, dispatch] = useReducer(reducer, initialState);

    const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
        const newLocation: Location = {
            ...place,
            name: place.name || "",
            address: place.formatted_address || "",
            latitude: place.geometry ? (place.geometry.location ? place.geometry.location.lat().toFixed(6) : "50.00") : "50.00",
            longitude: place.geometry ? (place.geometry.location ? place.geometry.location.lng().toFixed(6) : "50.00") : "50.00",
            category: LocationCategory.OTHER,
            isCustom: false,
            googleTypes: place.types || [],
        };
        onChange(newLocation);
        dispatch({ type: "SET_LOCATION", location: newLocation });
    };

    const handleFullScreenLocationAdd = (newLocation: Location) => {
        onChange(newLocation);
        dispatch({ type: "SET_LOCATION", location: newLocation });
    };

    const handleClear = () => {
        onChange(null);
        dispatch({ type: "CLEAR" });
    };

    return (
        <Card className="w-full">
            <CardHeader>
                <CardTitle className="flex justify-between items-center">
                    <span>{label}</span>
                    {state.status === "viewing" && (
                        <div>
                            <Button variant="outline" size="sm" className="mr-2" onClick={() => dispatch({ type: "EDIT" })}>
                                <Edit2 className="h-4 w-4 mr-2" />
                                Edit
                            </Button>
                            <Button variant="outline" size="sm" onClick={handleClear}>
                                <X className="h-4 w-4 mr-2" />
                                Clear
                            </Button>
                        </div>
                    )}
                </CardTitle>
            </CardHeader>
            <CardContent>
                {state.status === "editing" && (
                    <div className="space-y-2">
                        <Autocomplete onPlaceSelected={handlePlaceSelected} placeholder="Search for a meeting point" className="w-full mb-2" />
                        <div className="flex justify-between">
                            <Button variant="outline" size="sm" onClick={() => dispatch({ type: "FULLSCREEN" })}>
                                <Map className="h-4 w-4 mr-2" />
                                Open Map
                            </Button>
                            <Button variant="outline" size="sm" onClick={() => dispatch({ type: "CANCEL_EDIT" })}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
                {state.status === "viewing" && (
                    <div className="space-y-2 shadow-sm border p-3 rounded-sm">
                        <div className="flex items-center space-x-2">
                            <MapPin className="h-5 w-5 text-blue-500" />
                            <span className="font-semibold">{state.location.name}</span>
                        </div>
                        <p className="text-sm text-gray-600">{state.location.address}</p>
                    </div>
                )}
                {state.status === "empty" && (
                    <div className="space-y-2">
                        <p className="text-sm text-gray-500 italic mb-2">No meeting point set</p>
                        <div className="flex space-x-2">
                            <Button variant="outline" size="sm" onClick={() => dispatch({ type: "EDIT" })}>
                                <Edit2 className="h-4 w-4 mr-2" />
                                Add Meeting Point
                            </Button>
                            <Button variant="outline" size="sm" onClick={() => dispatch({ type: "FULLSCREEN" })}>
                                <Map className="h-4 w-4 mr-2" />
                                Open Map
                            </Button>
                        </div>
                    </div>
                )}
            </CardContent>

            <Dialog open={state.status === "fullscreen"} onOpenChange={(isOpen) => !isOpen && dispatch({ type: "CANCEL_EDIT" })}>
                <DialogContent className="p-0 w-full max-w-full h-[100vh] max-h-[100vh] sm:max-w-full">
                    <FullScreenLocationInput onLocationAdd={handleFullScreenLocationAdd} onClose={() => dispatch({ type: "CANCEL_EDIT" })} />
                </DialogContent>
            </Dialog>
        </Card>
    );
};

import React from "react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cancellationPolicies } from "shared/types/api";

interface CancellationPolicyPanelProps {
    cancellationPolicy: string;
    updateCancellationPolicy: (policy: string | { type: string; description: string }) => void;
}

const CancellationPolicyPanel: React.FC<CancellationPolicyPanelProps> = ({ cancellationPolicy, updateCancellationPolicy }) => {
    const [customDescription, setCustomDescription] = React.useState("");

    const handlePolicyChange = (value: string) => {
        updateCancellationPolicy(value);
    };

    const handleCustomPolicyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCustomDescription(e.target.value);
        updateCancellationPolicy({ type: "CUSTOM", description: e.target.value });
    };

    return (
        <Card className="w-full">
            <CardContent>
                <CardHeader>Cancellation Policy</CardHeader>
                <div className="space-y-6">
                    <RadioGroup value={typeof cancellationPolicy === "string" ? cancellationPolicy : "CUSTOM"} onValueChange={handlePolicyChange} className="space-y-4">
                        {cancellationPolicies.map((policy) => (
                            <div key={policy.id} className="flex items-start space-x-2">
                                <RadioGroupItem value={policy.id} id={policy.id} className="mt-1" />
                                <div className="grid gap-1.5 leading-none">
                                    <Label htmlFor={policy.id}>{policy.name}</Label>
                                    <p className="text-sm text-muted-foreground">{policy.description}</p>
                                </div>
                            </div>
                        ))}
                    </RadioGroup>

                    {(cancellationPolicy === "CUSTOM" || typeof cancellationPolicy === "object") && (
                        <div className="mt-4">
                            <Label htmlFor="customPolicy">Custom Cancellation Policy</Label>
                            <Textarea
                                id="customPolicy"
                                value={typeof cancellationPolicy === "object" ? cancellationPolicy.description : customDescription}
                                onChange={handleCustomPolicyChange}
                                placeholder="Describe your custom cancellation policy..."
                                className="mt-1"
                            />
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default CancellationPolicyPanel;

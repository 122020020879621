import React from "react";
import { cn } from "@/lib/utils";
const ErrorMessage = React.forwardRef<HTMLHeadingElement, React.InputHTMLAttributes<HTMLHeadingElement>>(({ className, children, ...props }, ref) => {
    return (
        <h1 className={cn("text-red-600 mt-4", className)} ref={ref} {...props}>
            {children}
        </h1>
    );
});

export { ErrorMessage };

import { Outlet } from "react-router-dom";

import { ReactNode } from "react";
import { AuroraBackground } from "@/components/ui/aurora-background";

type LayoutProps = { children?: ReactNode };
import DesktopHeader from "widgets/user/Header/DesktopHeader/DesktopHeader";
import MobileMenu from "widgets/user/Header/MobileMenu/MobileMenu";
import Logo from "@/Logo/Logo";

function AIPageLayout({ children }: LayoutProps) {
    return (
        <div className="flex flex-col">
            <AuroraBackground showRadialGradient={false} className="pb-3 m-0">
                <header className="h-[100px] w-full z-10 flex flex-row items-center content-padding justify-between bg-transparrent ">
                    <Logo />
                    <DesktopHeader />
                    <MobileMenu />
                </header>
                <div className="m-0 component items-center flex-1 flex-col">{children ? children : <Outlet />}</div>
            </AuroraBackground>
        </div>
    );
}
export default AIPageLayout;

import { ModalProvider } from "shared/providers/ModalWindow";
import { Outlet } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { useNetwork } from "shared/api/signature";
import { TooltipProvider } from "@/components/ui/tooltip";
import GoogleProvider from "shared/providers/GoogleProvider";
import SockProvider from "shared/providers/SocketProvider";

/**
 *  This is root loader and main purpose of it
 *  is to trigger all data loading and etc, every component
 *  should be a child of this
 *
 *  - init user - request to server and etc
 *  - init all sort of providers - theme providers, date providers, etc and etc
 *
 *  TODO:
 *      - ruleset for routes that user can or can not access
 *        based on his rights and authorization status
 */

function Root() {
    useNetwork();

    return (
        <TooltipProvider delayDuration={0} skipDelayDuration={100}>
            <GoogleProvider>
                <ModalProvider>
                    <SockProvider>
                        <Outlet />
                    </SockProvider>
                    <Toaster />
                </ModalProvider>
            </GoogleProvider>
        </TooltipProvider>
    );
}

export default Root;

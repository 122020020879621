import Joi from "joi";
import React from "react";

import { ModalWindowProps } from "shared/providers/ModalWindow";
import { RegistrationForm, setUserData } from "entities/store/user";
import { zodResolver } from "@hookform/resolvers/zod";
import { AUTH_API_ENDPOINTS } from "shared/types/api";
import { useForm } from "react-hook-form";
import z from "zod";
import { instance } from "shared/api/signature";
import { useAppDispatch } from "shared/helpers/utils";
import { LoadingButton } from "@/components/ui/loading-button";
import { ErrorMessage } from "@/headings";
import { ActionTypes, actionTypes, handleAxiosError, initialState, reducer } from "./form";
import i18n from "entities/i18n/i18n";

import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { TranslationKey, TranslationKeys } from "shared/types/translationTypes";
import { getErrorMessage } from "shared/helpers/utils";

type LoginFormInput = z.infer<typeof AUTH_API_ENDPOINTS.login.body>;

function LogInForm({ close }: Partial<ModalWindowProps>) {
    const form = useForm<LoginFormInput>({
        resolver: zodResolver(AUTH_API_ENDPOINTS.login.body),
        defaultValues: { email: "", password: "" },
    });

    const dispatchStore = useAppDispatch();
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const handle = async (data: LoginFormInput) => {
        dispatch({ type: ActionTypes.SET_ERROR_MESSAGE, payload: "" });
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });

        try {
            const response = await instance.request({
                method: AUTH_API_ENDPOINTS.login.method,
                url: AUTH_API_ENDPOINTS.login.url,
                data: data,
            });

            if (response.data.ok) {
                dispatchStore(setUserData(response.data.data));
                if (close) setTimeout(() => close(), 100);
            } else {
                throw new Error("Login failed");
            }
        } catch (error) {
            const { delay, message } = handleAxiosError(error);

            setTimeout(() => {
                dispatch({ type: ActionTypes.SET_ERROR_MESSAGE, payload: message });
                dispatch({ type: ActionTypes.SET_LOADING, payload: false });
            }, delay);
        } finally {
            dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(handle)} className="space-y-4">
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{i18n.t("form.name")}</FormLabel>
                            <FormControl>
                                <Input type="email" placeholder={i18n.t("form.email")} {...field} />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="password"
                    render={({ field, fieldState }) => (
                        <FormItem>
                            <FormLabel>{i18n.t("form.password")}</FormLabel>
                            <FormControl>
                                <Input type="password" placeholder={i18n.t("form.password")} {...field} />
                            </FormControl>
                            <FormMessage>{fieldState.error ? getErrorMessage(fieldState.error.message) : null}</FormMessage>
                        </FormItem>
                    )}
                />

                {state.errorMessage !== "" && <ErrorMessage>{i18n.t(state.errorMessage as TranslationKey)}</ErrorMessage>}

                <div className="w-full flex justify-center mt-5">
                    <LoadingButton loading={state.loading} variant="designed" type="submit" className="h-22 md:h-22">
                        {i18n.t("header.login")}
                    </LoadingButton>
                </div>
            </form>
        </Form>
    );
}

export default LogInForm;

import React, { forwardRef } from "react";

const SinSvg = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 100" className="absolute bg-transparent -z-0 top-40 md:-top-20" ref={ref} {...props}>
            <defs className="bg-transparent">
                <linearGradient id="blue-pink" x1="0%" y1="0%" x2="100%" y2="0%" className="bg-transparent">
                    <stop offset="0%" style={{ stopColor: "#4169E1", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "#FF69B4", stopOpacity: 1 }} />
                </linearGradient>
                <path
                    id="sinusoidal"
                    d="M0,90 C31.25,85 62.5,95 93.75,90 S156.25,85 187.5,90 S250,95 281.25,90 S343.75,85 375,90 S437.5,95 468.75,90 S500,85 500,90"
                    fill="none"
                />
            </defs>
            <use href="#sinusoidal" stroke="url(#blue-pink)" strokeWidth="3" />
        </svg>
    );
});

SinSvg.displayName = "WavyTitleSVG";
export { SinSvg };

import React, { useState, useEffect, useRef } from "react";
import { useWindowWidth } from "shared/hooks/hooks";
import { twMerge } from "tailwind-merge";
import { Leaf, Menu, X } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { getBadgeColor } from "@/TourPreview/ProvidersTourPreview";
import { Button } from "@/components/ui/button";
import { TourStatus } from "shared/types/api";
import i18n from "entities/i18n/i18n";

export type Tab = { value: string; label: string; icon: React.ReactNode };
type AnimatedNavBarProps = { tabs: Array<Tab>; activeTab: string; onTabChange: (tab_value: string) => void };
type NavBarLink = { index: number; left: number | undefined; width: number | undefined };

const AnimatedNavBar = ({ tabs, activeTab, onTabChange }: AnimatedNavBarProps) => {
    const [currentLink, setCurrentLink] = useState<NavBarLink>({ index: 0, left: undefined, width: undefined });
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const fired = useRef(false);
    const w = useWindowWidth();

    useEffect(() => {
        const activeIndex = tabs.findIndex((tab) => tab.value === activeTab);
        updateCurrentLink(activeIndex);
    }, [activeTab, tabs, w]);

    const handleTabClick = (active_tab: Tab, index: number) => {
        onTabChange(active_tab.value);
        updateCurrentLink(index);
        setIsMenuOpen(false);
    };

    const updateCurrentLink = (index: number) => {
        const element = document.getElementById(`nav-btn-${index}`);
        if (element) {
            fired.current = true;
            setCurrentLink({ index, left: element.offsetLeft, width: element.getBoundingClientRect().width });
        }
    };

    return (
        <div className="relative">
            <button className="md:hidden fixed top-4 right-4 z-50 p-2 rounded-full bg-white  shadow-md" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>

            <div
                className={`md:hidden fixed inset-0 z-40 bg-white transform ${
                    isMenuOpen ? "translate-x-0" : "translate-x-full"
                } transition-transform duration-300 ease-in-out`}
            >
                <div className="p-4 mt-2">
                    <h1 className="text-3xl font-bold mb-6">Dashboard Menu</h1>
                    <div className="flex flex-col items-center justify-center mt-8  h-full">
                        {tabs.map((tab, i) => (
                            <button
                                key={i}
                                onClick={() => handleTabClick(tab, i)}
                                className={twMerge(
                                    "w-full py-4 px-6 text-lg rounded-sm flex items-center font-medium text-center transition-colors duration-200",
                                    activeTab === tab.value ? "text-white bg-neutral-800" : ""
                                )}
                            >
                                <span className="mr-2">{tab.icon}</span>
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="hidden w-full relative border dark:border-neutral-800 border-neutral-300 md:flex flex-row rounded-full items-center justify-center p-2 backdrop-blur-2xl">
                {tabs.map((tab, i) => (
                    <button
                        key={i}
                        id={`nav-btn-${i}`}
                        onClick={() => handleTabClick(tab, i)}
                        className={twMerge(
                            "transition-colors duration-200 flex items-center justify-center rounded-full h-fit px-4 py-2 text-nowrap",
                            activeTab === tab.value ? " text-white" : ""
                        )}
                    >
                        <span className="mr-2">{tab.icon}</span>
                        {tab.label}
                    </button>
                ))}

                <div className="absolute inset-0 h-full p-2 -z-[1] overflow-hidden">
                    <div className="relative h-full w-full overflow-hidden">
                        <div
                            style={{ left: `calc(${currentLink.left || 0}px - 0.5rem)`, width: `${currentLink.width || 0}px` }}
                            className={twMerge(
                                "transition-[left,width] duration-300 absolute top-1/2 -translate-y-1/2 h-full rounded-full -z-[1]",
                                fired.current ? " bg-neutral-950" : "bg-transparent"
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export { AnimatedNavBar };

const headerStyle: React.CSSProperties = { padding: "10px 16px", background: "#fff", transition: "box-shadow 0.3s ease-in-out" };

const TourDashboardNavBar = ({ tabs, activeTab, onTabChange, status }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentLink, setCurrentLink] = useState<{ index: number; left?: number; width?: number }>({ index: 0, left: undefined, width: undefined });
    const headerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const fired = useRef(false);

    useEffect(() => {
        if (!headerRef.current) return;

        const header = headerRef.current;
        const sticky = header.offsetTop;
        setHeaderHeight(header.offsetHeight);

        const handleScroll = () => {
            if (window.scrollY > sticky) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const activeIndex = tabs.findIndex((tab) => tab.value === activeTab);
        updateCurrentLink(activeIndex);
    }, [activeTab, tabs]);

    const handleTabClick = (tab, index) => {
        onTabChange(tab.value);
        updateCurrentLink(index);
        setIsMenuOpen(false);
    };

    const updateCurrentLink = (index) => {
        const element = document.getElementById(`nav-btn-${index}`);
        if (element) {
            fired.current = true;
            setCurrentLink({ index, left: element.offsetLeft, width: element.getBoundingClientRect().width });
        }
    };

    const stickyStyle: React.CSSProperties = isSticky ? { position: "fixed", top: 0, left: 0, width: "100%", boxShadow: "0 2px 4px rgba(0,0,0,0.1)", zIndex: 1000 } : {};

    return (
        <>
            <header ref={headerRef} style={{ ...headerStyle, ...stickyStyle }} className={twMerge("w-full bg-white", isSticky ? "shadow-md" : "")}>
                <div className="">
                    <div className="flex justify-between items-center">
                        <h2 className="text-lg font-semibold">Tour Management Dashboard</h2>
                        <TourStatusNavComponent status={status} />

                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="lg:hidden p-2 text-gray-500 hover:text-gray-600">
                            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>

                    <nav className="hidden lg:block relative">
                        <div className="flex justify-start space-x-4 mt-4">
                            {tabs.map((tab, i) => (
                                <button
                                    key={tab.value}
                                    id={`nav-btn-${i}`}
                                    onClick={() => handleTabClick(tab, i)}
                                    className={twMerge(
                                        "px-4 py-2 rounded-full flex transition-colors duration-200",
                                        activeTab === tab.value ? "text-secondary" : "text-gray-700 hover:bg-gray-100"
                                    )}
                                >
                                    {tab.icon}
                                    <span className="ml-2">{tab.label}</span>
                                </button>
                            ))}
                        </div>
                        {fired.current && (
                            <div
                                className="absolute bottom-0 h-1 bg-secondary transition-all duration-300"
                                style={{ left: currentLink.left, width: currentLink.width }}
                            />
                        )}
                    </nav>

                    {isMenuOpen && (
                        <div className="lg:hidden mt-4">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.value}
                                    onClick={() =>
                                        handleTabClick(
                                            tab,
                                            tabs.findIndex((t) => t.value === tab.value)
                                        )
                                    }
                                    className={twMerge(
                                        "w-full py-2 px-4 text-left text-lg rounded-sm flex items-center font-medium transition-colors duration-200",
                                        activeTab === tab.value ? "bg-secondary text-white" : "text-gray-700 hover:bg-gray-100"
                                    )}
                                >
                                    {tab.icon}
                                    <span className="ml-2">{tab.label}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </header>
            {isSticky && <div style={{ height: `${headerHeight}px` }} />}
        </>
    );
};

function TourStatusNavComponent({ status }) {
    return (
        <div className="flex flex-col text-xs  md:flex-row md:space-x-2 ">
            <Badge className={cn("text-black", getBadgeColor(status), "group-hover:bg-white")}>{i18n.t("provider.TourComponent.stage." + status)}</Badge>
            {status == TourStatus.DRAFT && <Button className="text-xs p-1">Опубликовать</Button>}
        </div>
    );
}

export { TourDashboardNavBar };

import React, { createContext, useContext, useState, FC } from "react";

import LogInModal from "widgets/user/modals/LogInModal";
import SignUpModal from "widgets/user/modals/SignUpModal";
import LanguageModal from "widgets/user/modals/LanguageModal";
import ModalWindowNotImplemented from "widgets/user/modals/ModalWindowNotImplemented";

import { OverlayProvider } from "@/Overlay";
import { MobileMenuDrawer } from "widgets/user/Header/MobileMenu/MobileMenu";
enum Modal {
    SIGNUP = "SIGNUP",
    LOGIN = "LOGIN",
    BOOKTOUR = "BOOKTOUR",
    NOTIFICATIONS = "NOTIFICATIONS",
    LANGUAGE = "LANGUAGE",
    MOBILE_MENU = "MOBILE_MENU",
}

type ModalContextType = { show: (modal_: Modal) => void };
const ModalContextDefaultValue: ModalContextType = { show: () => void 1 };

const ModalContext = createContext(ModalContextDefaultValue);
const useModal = () => {
    const context = useContext(ModalContext);
    if (context === undefined) throw new Error(`useModal must be used with with ModalProvider`);
    return context;
};

type ShowModalProps = { modal: Modal; close: () => void };
type ModalWindowProps = { close: ShowModalProps["close"] };

const ShowModal: FC<ShowModalProps> = ({ modal, close }) => {
    switch (modal) {
        case Modal.LOGIN:
            return <LogInModal close={close} />;
        case Modal.SIGNUP:
            return <SignUpModal close={close} />;
        case Modal.LANGUAGE:
            return <LanguageModal close={close} />;
        case Modal.MOBILE_MENU:
            return <MobileMenuDrawer close={close} />;
        default:
            return <ModalWindowNotImplemented close={close} />;
    }
};

/**
 * Rules:
 *  - there's only one modal window that can be showed for user
 *  - component without useEffects
 */
const ModalProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState<null | Modal>(null);

    const show = (modal_: Modal) => {
        setModal(modal_);
        setOpen(true);
    };
    const close = () => setOpen(false);

    return (
        <ModalContext.Provider value={{ show }}>
            <OverlayProvider>
                {open && modal ? <ShowModal modal={modal} close={close} /> : null}
                {children}
            </OverlayProvider>
        </ModalContext.Provider>
    );
};

export { Modal, ModalProvider, useModal };
export type { ModalWindowProps };

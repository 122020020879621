import { useToast } from "@/components/ui/use-toast";
import Loading from "@/loading/Loading";
import { UserSelector } from "entities/store/user";
import { PropsWithChildren, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "shared/helpers/utils";

function ProtectedRoute({ children }: PropsWithChildren) {
    const loggedIn = useAppSelector(UserSelector.isLogged);
    const [check, setCheck] = useState(false);
    const navigate = useNavigate();
    const { toast } = useToast();

    useEffect(() => {
        if (!loggedIn) {
            navigate("/", {});
            toast({ title: "Please authorize" });
        }
        setCheck(true);
    }, [loggedIn]);

    if (!check) {
        return (
            <div className="flex-1 flex w-full h-full justify-center items-center">
                <Loading />
            </div>
        );
    }
    return children ? children : <Outlet />;
}

export default ProtectedRoute;

import { redirect } from "react-router-dom";
import { LoaderFunction } from "react-router-dom";
import * as API from "shared/api/signature";
import { getSearchParamsAsObject as getSearchParamsObject } from "pages/provider/tours/CreateTour/CreateTour";
import { ResponseDataText } from "pages/default/Error/Error";
import { AxiosError } from "axios";
import { Tour } from "pages/provider/tours/CreateTourStandalonePage/CreateTourStandalonePage";

const tour_create_edit_loader: LoaderFunction = async (r): Promise<API.LoaderResponse<Tour> | unknown> => {
    const params = r.request.url.split("?");

    if (params[1]) {
        const t: { id?: string } = getSearchParamsObject(new URLSearchParams(params[1])) as { id?: string };

        if (t.id) {
            const tour = await API.get_tour_create_stage(t.id);

            if (!API.PROD) console.log(tour.data.data, "data from backend for tour page");

            if (tour instanceof AxiosError) {
                const status = parseInt(tour.code ?? "500");
                throw new Response(new ResponseDataText(status, "mda", "createBlankTour").get(), { status });
            } else return tour.data.data;
        } else {
            throw new Response(new ResponseDataText(400, "Something went wrong on loading tour page", "createBlankTour").get(), { status: 400 });
        }
    } else {
        const new_tour = (await API.createBlankTour()).data.data;
        return redirect(r.request.url + `?id=${new_tour.id}`);
    }
};

export { tour_create_edit_loader };

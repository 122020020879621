import SuspenseLoading from "@/loading/SuspenseLoading";
import { BaseRoutes } from "shared/helpers/const";
import * as API from "shared/api/signature";
import { lazy } from "react";
import { LoaderFunction, createBrowserRouter } from "react-router-dom";

/**
 *  Default routes
 */
import Layout from "pages/default/Layout/Layout";
import Root from "widgets/default/Root/Root";
import ErrorPage from "pages/default/Error/Error";
import Profile from "pages/user/Profile/Profile";
import Home from "pages/default/Home/Home";

/**
 *  User specific routes
 */
import TourPage from "pages/default/Tour/TourPage";
import Favorite from "pages/default/Favorite/Favorite";
import ChatPage from "pages/user/Chats/ChatPage";
import TestBench from "pages/dev/TestBench";
import { store } from "./store";
import ProtectedRoute from "features/default/ProtectedRoute/ProtectedRoute";

/**
 *
 *
 * .____                                                                                    __
 * |    |   _____  ___________.__.   ____  ____   _____ ______   ____   ____   ____   _____/  |_  ______
 * |    |   \__  \ \___   <   |  | _/ ___\/  _ \ /     \\____ \ /  _ \ /    \_/ __ \ /    \   __\/  ___/
 * |    |___ / __ \_/    / \___  | \  \__(  <_> )  Y Y  \  |_> >  <_> )   |  \  ___/|   |  \  |  \___ \
 * |_______ (____  /_____ \/ ____|  \___  >____/|__|_|  /   __/ \____/|___|  /\___  >___|  /__| /____  >
 *         \/    \/      \/\/           \/            \/|__|               \/     \/     \/          \/
 *
 */

const ProfileInfoComponent = lazy(() => import("features/user/ProfilePage/ProfileInfo/ProfileInfo"));

const CreateTourComponent = lazy(() => import("pages/provider/tours/CreateTour/CreateTour"));
const CreateTourStandalone = lazy(() => import("pages/provider/tours/CreateTourStandalonePage/CreateTourStandalonePage"));
const ProvidersTours = lazy(() => import("pages/provider/tours/ProviderToursPage"));
const TravellersTours = lazy(() => import("pages/traveller/tours/TravellerToursPage"));

const ProvidersToursPage = () => (
    <SuspenseLoading>
        <ProvidersTours />
    </SuspenseLoading>
);
const TravellersToursPage = () => (
    <SuspenseLoading>
        <TravellersTours />
    </SuspenseLoading>
);

const ProfileInfo = () => (
    <SuspenseLoading>
        <ProfileInfoComponent />
    </SuspenseLoading>
);
const CreateTourConstructor = () => (
    <SuspenseLoading>
        <CreateTourComponent />
    </SuspenseLoading>
);
const CreateTourStandalonePage = () => (
    <SuspenseLoading>
        <CreateTourStandalone />
    </SuspenseLoading>
);

/**
 *
 *
 * .____                     .___
 * |    |    _________     __| _/___________  ______
 * |    |   /  _ \__  \   / __ |/ __ \_  __ \/  ___/
 * |    |__(  <_> ) __ \_/ /_/ \  ___/|  | \/\___ \
 * |_______ \____(____  /\____ |\___  >__|  /____  >
 *         \/         \/      \/    \/           \/
 *
 *
 */

import { tour_create_edit_loader } from "shared/api/loaders/tour_create_edit_loader";
import AIPageLayout from "pages/default/AI/AiPageLayout";
import AIChatPage from "pages/default/AI/AIChatPage";
import CreateTourGreetings from "pages/provider/tours/CreateTour/CreateTourGreetings";
import { ManageTeam, ProfileEmail, ProfileNotificationSettings, ProfilePaymentInformation, ProfileSecurity } from "features/user/ProfilePage/ProfileInfo/ProfileInfo";
import { TourCreator } from "pages/provider/tour_new_api/Constructor/Concept/MobileTourCreation";
import { DesktopTourCreator } from "pages/provider/tour_new_api/Constructor/Desktop/Desktop";
import TourDashboard from "pages/provider/tour_new_api/Dashboard/dashboard";
import { SearchPage } from "@/SearchBar/SearchBar";
import { Checkout } from "pages/default/Tour/checkout";
const get_provider_tours: LoaderFunction = async () => {
    const res = await API.provider_tours_get(0);
    return res.data;
};

const get_tours: LoaderFunction = async () => {
    const res = await API.tours_get(0);

    return res.data.data;
};

const get_tour: LoaderFunction = async (r) => {
    const id = r.params as { id: string };
    if (id) {
        const req = await API.instance.get("/v1/traveller/tours/" + id.id);
        console.log(req.data);
        return req.data.data;
    }
    return true;
};

const profile_loader = () => {
    if (store.getState().user.loggedIn) {
        new Response("You are not logged in", { status: 401 });
    }
    return true;
};

const router = createBrowserRouter(
    [
        {
            element: <Root />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "/",
                    element: <Layout />,
                    errorElement: <ErrorPage />,
                    children: [
                        { path: "/", loader: get_tours, element: <Home /> },
                        // Bookings and checkout
                        { path: "/checkout", element: <Checkout /> },

                        { path: BaseRoutes.FAVORITE, element: <Favorite /> },
                        {
                            path: BaseRoutes.PROFILEPAGE,
                            element: (
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            ),
                            children: [
                                { path: "", element: <ProfileInfo /> },
                                { path: BaseRoutes["PROFILEPAGE.EMAIL_PREFERENCES"], element: <ProfileEmail /> },
                                { path: BaseRoutes["PROFILEPAGE.SECURITY"], element: <ProfileSecurity /> },
                                { path: BaseRoutes["PROFILEPAGE.NOTIFICATIONS"], element: <ProfileNotificationSettings /> },
                                { path: BaseRoutes["PROFILEPAGE.PAYMENT_INFO"], element: <ProfilePaymentInformation /> },
                                { path: BaseRoutes["PROFILEPAGE.TEAMS"], element: <ManageTeam /> },
                            ],
                            loader: profile_loader,
                        },

                        { path: BaseRoutes.SEARCH_RESULTS, element: <SearchPage /> },
                        { path: "/tour/:id", loader: get_tour, element: <TourPage /> },

                        { path: BaseRoutes.MESSAGES, element: <ChatPage /> },

                        { path: BaseRoutes.TRAVELLERS_TOURS, element: <TravellersToursPage /> },
                        { path: BaseRoutes.PROVIDERS_TOURS, loader: get_provider_tours, element: <ProvidersToursPage /> },
                        { path: BaseRoutes.PROVIDER_TOUR_DASHBOARD, element: <TourDashboard /> },
                        // DEV ROUTE
                        { path: BaseRoutes.BENCH, element: <TestBench /> },

                        // Create tour routes
                        {
                            path: BaseRoutes.CREATETOUR_STANDALONE,
                            loader: tour_create_edit_loader,
                            element: <CreateTourStandalonePage />,
                        },
                        {
                            path: "/tours/greet/",
                            element: <CreateTourGreetings />,
                        },
                        { path: "/blog/:name" },
                    ],
                },
                {
                    path: "/provider/db/constructor",
                    element: <TourCreator />,
                },
                {
                    path: "/provider/db/constructor/desktop",
                    element: <DesktopTourCreator />,
                },
                {
                    path: "/provider/db/dashboard",
                    element: <TourDashboard />,
                },

                {
                    element: <AIPageLayout />,
                    children: [{ path: BaseRoutes.AI, element: <AIChatPage /> }],
                },
            ],
        },
    ],
    { future: { v7_relativeSplatPath: true } }
);

export default router;

import { useState, useEffect, useCallback, useReducer } from "react";
import { instance } from "shared/api/signature";

interface PaginationState<T> {
    data: T[];
    loading: boolean;
    error: Error | null;
    hasMore: boolean;
    pagination: { totalItems: number; totalPages: number; currentPage: number; itemsPerPage: number };
}

type PaginationAction<T> =
    | { type: "FETCH_INIT" }
    | { type: "FETCH_SUCCESS"; payload: { data: T[]; pagination: PaginationState<T>["pagination"] } }
    | { type: "FETCH_FAILURE"; payload: Error }
    | { type: "UPDATE_HAS_MORE"; payload: boolean };

function paginationReducer<T>(state: PaginationState<T>, action: PaginationAction<T>): PaginationState<T> {
    switch (action.type) {
        case "FETCH_INIT":
            return { ...state, loading: true, error: null };
        case "FETCH_SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.payload.pagination.currentPage === 0 ? action.payload.data : [...state.data, ...action.payload.data],
                pagination: action.payload.pagination,
                hasMore: action.payload.pagination.currentPage < action.payload.pagination.totalPages - 1,
            };
        case "FETCH_FAILURE":
            return { ...state, loading: false, error: action.payload };
        case "UPDATE_HAS_MORE":
            return { ...state, hasMore: action.payload };
        default:
            return state;
    }
}

interface UsePaginatedDataOptions<T, F> {
    initialData?: T[];
    initialPagination?: Partial<PaginationState<T>["pagination"]>;
    fetchFunction: (page: number, itemsPerPage: number, filters?: F) => Promise<{ data: T[]; pagination: PaginationState<T>["pagination"] }>;
    dependencyArray?: any[];
    initialFilters?: F;
}

export function usePaginatedData<T, F = Record<string, unknown>>({
    initialData = [],
    initialPagination = {},
    fetchFunction,
    dependencyArray = [],
    initialFilters,
}: UsePaginatedDataOptions<T, F>) {
    const [filters, setFilters] = useState<F | undefined>(initialFilters);

    const [state, dispatch] = useReducer(paginationReducer<T>, {
        data: initialData,
        loading: false,
        error: null,
        hasMore: true,
        pagination: { totalItems: 0, totalPages: 0, currentPage: 0, itemsPerPage: 10, ...initialPagination },
    });

    const fetchData = useCallback(
        async (page: number) => {
            dispatch({ type: "FETCH_INIT" });
            try {
                const result = await fetchFunction(page, state.pagination.itemsPerPage, filters);
                dispatch({ type: "FETCH_SUCCESS", payload: result });
            } catch (error) {
                dispatch({ type: "FETCH_FAILURE", payload: error as Error });
            }
        },
        [fetchFunction, state.pagination.itemsPerPage, filters]
    );

    useEffect(() => {
        fetchData(0);
    }, [...dependencyArray, filters]);

    const loadMore = useCallback(() => {
        if (!state.loading && state.hasMore) {
            fetchData(state.pagination.currentPage + 1);
        }
    }, [state.loading, state.hasMore, state.pagination.currentPage, fetchData]);

    const updateFilters = useCallback((newFilters: F) => {
        setFilters(newFilters);
        dispatch({ type: "FETCH_INIT" });
    }, []);

    return {
        ...state,
        loadMore,
        updateFilters,
    };
}

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { SetStateAction, Dispatch, useState } from "react";
import CalendarIcon from "../icons/CalendarIcon/CalendarIcon";
import { getFormattedDate } from "shared/helpers/utils";
import { DatePickerLabel } from "@/labels/labels";

type Props = { date: Date | undefined; setDate: Dispatch<SetStateAction<Date | undefined>> };
type CalendarComponentType = { selectDate: (date_: Date | undefined) => void; date: Props["date"]; trigger: () => void };

/**
 * This code feel a little bit exploiting and bad,
 * but it's working because i just need my task to be in next stack in event loop
 * and there's should be 0 bugs
 */
const CalendarComponent: React.FC<CalendarComponentType> = ({ date, selectDate, trigger }) => {
    return (
        <PopoverContent className="w-auto p-0" align="start" onEscapeKeyDown={trigger} onInteractOutside={trigger}>
            <Calendar mode="single" selected={date} onSelect={selectDate} initialFocus />
        </PopoverContent>
    );
};
export function DatePicker({ date, setDate }: Props) {
    const [open, setOpen] = useState(false);

    const trigger = () => setOpen((prev) => !prev);

    const selectDate = (date_: Date | undefined) => {
        if (date_) {
            setDate(date_);
            trigger();
        }
    };

    return (
        <Popover open={open}>
            <PopoverTrigger className="w-full h-full rounded-md  md:max-h-full" onClick={trigger} asChild>
                <Button
                    variant={"outline"}
                    className={cn("justify-start text-left font-normal h-[58px]  md:h-full", !date && "text-muted-foreground", "max-w-[281px] rounded-md ")}
                >
                    {date ? (
                        getFormattedDate(date)
                    ) : (
                        <span>
                            <DatePickerLabel />
                        </span>
                    )}
                    <CalendarIcon className="ml-auto h-8 w-8" />
                </Button>
            </PopoverTrigger>
            <CalendarComponent date={date} selectDate={selectDate} trigger={trigger} />
        </Popover>
    );
}

import { Calendar as DefaultCalendarIcon } from "lucide-react";
import dayjs from "dayjs";
type DefaultProps = { date: Date | undefined; setDate: (v: Date) => void };

const one_day_before = dayjs().subtract(1, "day").toDate();
const DefaultCalendarComponent: React.FC<CalendarComponentType> = ({ date, selectDate, trigger }) => {
    return (
        <PopoverContent className="w-auto p-0" align="start" onEscapeKeyDown={trigger} onInteractOutside={trigger}>
            <Calendar disabled={{ before: one_day_before }} defaultMonth={date ?? new Date()} mode="single" selected={date} onSelect={selectDate} initialFocus />
        </PopoverContent>
    );
};

export function DefaultDatePicker({ date, setDate }: DefaultProps) {
    const [open, setOpen] = useState(false);

    const trigger = () => setOpen((prev) => !prev);

    const selectDate = (date_: Date | undefined) => {
        if (date_) {
            setDate(date_);
            trigger();
        }
    };

    return (
        <Popover open={open}>
            <PopoverTrigger className="w-full h-full rounded-md  md:max-h-full" onClick={trigger} asChild>
                <Button variant={"outline"} className={cn("w-full justify-between text-left font-normal", !date && "text-muted-foreground")}>
                    {date ? (
                        getFormattedDate(date)
                    ) : (
                        <span>
                            <DatePickerLabel />
                        </span>
                    )}
                    <DefaultCalendarIcon className="mr-2 h-4 w-4" />
                </Button>
            </PopoverTrigger>
            <DefaultCalendarComponent date={date} selectDate={selectDate} trigger={trigger} />
        </Popover>
    );
}

export { DefaultCalendarComponent };

import Joi from "joi";

import { Currency, DurationType, Difficulty, Seasonality, TourStatus, BookingSchema } from "shared/types/api";

// Think of removing
import { Language } from "shared/types/api";

import { FoodOption, CancellationPolicy, PaymentMethod } from "shared/types/api";
import type { Duration } from "shared/types/api";

import type { ItineraryDay, TourHighlight } from "shared/types/api";

type CommonItem = {
    id: "guide" | "transport" | "entry" | "meals" | "accommodation" | "equipment";
    label: string;
};

import { DepositPolicyEnum, DepositPolicyType, DepositPolicy } from "shared/types/api";
import { DiscountLevel } from "shared/types/api";

function formatCurrency(amount: number, currency: Currency): string {
    return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(amount);
}

function getDurationString(duration: Duration): string {
    const parts = [];
    if (duration.days > 0) parts.push(`${duration.days} day${duration.days > 1 ? "s" : ""}`);
    if (duration.hours > 0) parts.push(`${duration.hours} hour${duration.hours > 1 ? "s" : ""}`);
    return parts.join(", ");
}

import { BookingStatus } from "shared/types/api";
import { ListingStatus } from "shared/types/api";

// Additional Types
type Listing = {
    id: string;
    tourId: string;
    date: Date;
    startTime: Date;
    endTime: Date;
    maxParticipants: number;
    currentBookings: number;
    basePrice: number;

    mainCurrency: Currency;

    currencyPrices: Record<Currency, number>;
    status: ListingStatus;

    createdAt?: Date;
    updatedAt?: Date;
};

type Booking = {
    id?: string;
    listingId: string;
    travellerId?: string;

    participants: number;
    totalAmount: number;

    status: BookingStatus;
    tourDate: Date;
    currency: Currency;
    specialRequests?: string;
    paymentStatus: any;
    paymentMethod: PaymentMethod;
    paymentDetails: PaymentDetails;
    promocodeId?: string;
    createdAt?: Date;
    updatedAt?: Date;
};

type PaymentDetails = {
    cardType?: string;
    last4?: string;
    expirationMonth?: number;
    expirationYear?: number;
    bankAccountLast4?: string;
    bankName?: string;
};

type Review = {
    id: string;
    tourId: string;
    travellerId: string;
    bookingId?: string;
    rating: number;
    comment?: string;
    images?: string[];
    createdAt?: Date;
    updatedAt?: Date;
};

// Joi Schemas for new entities
const listingSchema = Joi.object<Listing>({
    id: Joi.string().required(),
    tourId: Joi.string().required(),
    date: Joi.date().required(),
    startTime: Joi.date().required(),
    endTime: Joi.date().required(),
    maxParticipants: Joi.number().min(1).required(),
    currentBookings: Joi.number().min(0).required(),
    basePrice: Joi.number().min(0).required(),
    mainCurrency: Joi.string()
        .valid(...Object.values(Currency))
        .required(),
    currencyPrices: Joi.object()
        .pattern(Joi.string().valid(...Object.values(Currency)), Joi.number().min(0))
        .required(),
    status: Joi.string()
        .valid(...Object.values(ListingStatus))
        .required(),
}).required();

const reviewSchema = Joi.object<Review>({
    id: Joi.string().required(),
    tourId: Joi.string().required(),
    travellerId: Joi.string().required(),
    bookingId: Joi.string().optional(),
    rating: Joi.number().min(1).max(5).required(),
    comment: Joi.string().optional(),
    images: Joi.array().items(Joi.string()).optional(),
}).required();

const tour_form_default_values = {
    id: "T001",
    name: "Historical Venice Walking Tour",
    description: "Explore the hidden gems of Venice with our expert local guide. Discover the rich history and stunning architecture of this unique city.",
    durationType: DurationType.SINGLE_DAY,
    duration: { days: 0, hours: 3, minutes: 0 },
    maxParticipants: 15,
    minAge: 12,
    difficulty: Difficulty.EASY,
    languages: [Language.EN],
    isActive: true,
    status: TourStatus.DRAFT,
    createdAt: new Date(),
    updatedAt: new Date(),
    meetingPoint: null,
    providerId: "",
    images: [],
    itinerary: [],
    tourLocations: [],
    additionalInfo: {
        highlights: [],
        includedItems: [],
        notIncludedItems: [],
        whatToBring: [],
        accessibility: [],
        seasonality: Seasonality.YEAR_ROUND,
        privateGroupOption: false,
        foodOptions: [],
        categoryTags: [],
    },
    pricing: {
        basePrice: 108,
        mainCurrency: Currency.RUB,
        currencyPrices: {
            [Currency.USD]: { amount: 0, isUserSet: false },
            [Currency.EUR]: { amount: 0, isUserSet: false },
            [Currency.RUB]: { amount: 108, isUserSet: true },
        },
        cancellationPolicy: CancellationPolicy.STRICT,
        refundPolicy: { policies: [], customPolicy: "" },
        depositPolicy: { type: "NO_DEPOSIT", amount: undefined, currency: undefined },
        acceptedPayments: [],
        discountLevels: [{ people: 1, discount: 0 }],
    },
};

// Helper functions for new entities
function validateListing(listing: Partial<Listing>): Joi.ValidationResult {
    return listingSchema.validate(listing, { abortEarly: false });
}

function validateBooking(booking: Partial<Booking>) {
    return BookingSchema.parse(booking);
}

function validateReview(review: Partial<Review>): Joi.ValidationResult {
    return reviewSchema.validate(review, { abortEarly: false });
}

/* prettier-ignore */
export type {   ItineraryDay,  Duration,
               TourHighlight, CommonItem,   DepositPolicy, DiscountLevel, 
              Listing, Booking, PaymentDetails, Review,
};

// Export enums directly
export { Currency, DurationType, Difficulty, Seasonality, TourStatus, Language, FoodOption, CancellationPolicy, PaymentMethod };

export const TourConstants = {
    tour_form_default_values,
};

// Export helper functions
export { validateListing, validateBooking, validateReview, formatCurrency, getDurationString };

// Default export (optional, for backwards compatibility)
export default {
    constants: TourConstants,
    schema: { listing: listingSchema, booking: BookingSchema, review: reviewSchema },
    helpers: { formatCurrency, getDurationString },
};

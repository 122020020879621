import { NavigationRoute } from "shared/types/types";
import { User, Settings, Mail, Shield, CreditCard, Key, Users, Bell } from "lucide-react";

const MDWIDTH = 750;
/**
 * Here presented only paths that starts from /
 */
const BaseRoutes = {
    AI: "/AI",
    PROFILEPAGE: "/profile/",
    // "PROFILEPAGE.SETTINGS": "/profile/settings",
    "PROFILEPAGE.EMAIL_PREFERENCES": "/profile/email-preferences",
    "PROFILEPAGE.SECURITY": "/profile/security",
    "PROFILEPAGE.PAYMENT_INFO": "/profile/payment-info",
    "PROFILEPAGE.NOTIFICATIONS": "/profile/notifications",
    "PROFILEPAGE.TEAMS": "/profile/teams",
    FAVORITE: "/favotite/",
    MESSAGES: "/messages/",
    TOUR: "/tour/",
    TOURS: "/tours/",
    PROVIDERS_TOURS: "/provider/tours",
    PROVIDER_TOUR_DASHBOARD: "/provider/tour/:id",
    TRAVELLERS_TOURS: "/traveller/tours",
    CREATETOUR: "/tours/create/",
    CREATETOUR_STANDALONE: "/tours/page/",
    NEW_TOUR_MOBILE_CONSTRUCTOR: "/provider/db/constructor",
    BENCH: "/test",
    CHATS: "/chats",
    TEST: { MAP: "/test/map/" },
    SEARCH_RESULTS: "/search",
};

const profilePageRoutes: NavigationRoute[] = [
    { path: BaseRoutes.PROFILEPAGE, name: "Profile Info", icon: <User size={20} /> },
    // { path: BaseRoutes["PROFILEPAGE.SETTINGS"], name: "Account Settings", icon: <Settings size={20} /> },
    { path: BaseRoutes["PROFILEPAGE.EMAIL_PREFERENCES"], name: "Email Preferences", icon: <Mail size={20} /> },
    { path: BaseRoutes["PROFILEPAGE.SECURITY"], name: "Security", icon: <Shield size={20} /> },
    { path: BaseRoutes["PROFILEPAGE.PAYMENT_INFO"], name: "Payment Information", icon: <CreditCard size={20} /> },
    { path: BaseRoutes["PROFILEPAGE.NOTIFICATIONS"], name: "Notification Settings", icon: <Bell size={20} /> },
];

export const providerProfileRoutes: NavigationRoute[] = [{ path: BaseRoutes["PROFILEPAGE.TEAMS"], name: "Guide Team", icon: <Users size={20} /> }];

const providerNavigationRoutes: NavigationRoute[] = [
    { path: "/user/tour/list", name: "панель туров" },
    { path: "/notifications", name: "уведомления" },
    { path: "/chats", name: "чаты" },
    { path: "/profile", name: "профиль" },
];

const travellerNavigationRoutes: NavigationRoute[] = [
    { path: BaseRoutes.TRAVELLERS_TOURS, name: "мои туры" },
    { path: "/notifications", name: "уведомления" },
    { path: BaseRoutes.CHATS, name: "чаты" },
    { path: BaseRoutes.PROFILEPAGE, name: "профиль" },
    { path: BaseRoutes.FAVORITE, name: "избранное" },
];

export { providerNavigationRoutes, travellerNavigationRoutes, BaseRoutes, profilePageRoutes, MDWIDTH };

import React from "react";

type Props = {};

function CreateTourGreetings({}: Props) {
    return (
        <>
            <div className="h-screen -z-10 absolute w-full bg-[url('https://s3.timeweb.cloud/4498e8c1-adventurer-public/static/bg.png')] bg-cover top-0"></div>
            <div className="w-full bg-cover bg-transparent"></div>
        </>
    );
}

export default CreateTourGreetings;

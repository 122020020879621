import React, { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import CancellationPolicyPanel from "./cancecellation-policy";
import DepositPolicyPanel from "./deposit-policy";
import RefundPolicyPanel from "./redund-policy-panel";
import CustomDiscountPanel from "./custom-discount";
import AcceptedPaymentsPanel from "./accepted-payments-panel";
import { Controller, useForm } from "react-hook-form";
import { Currency, DepositPolicyEnum, PricingSchema, RefundPolicy, cancellationPolicies, paymentOptions, refundPolicies } from "shared/types/api";
import { zodResolver } from "@hookform/resolvers/zod";

import { DollarSign, Ban, RefreshCw, Percent, CreditCard, Loader2 } from "lucide-react";
import { updateTour } from "pages/provider/tour_new_api/Constructor/State";
import { motion } from "framer-motion";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";

const PricingPoliciesTab: React.FC<{ tour: any }> = ({ tour }) => {
    const [localTour, setLocalTour] = useState(tour);
    const [isSaving, setIsSaving] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, [name]: parseInt(value) } }));
    };

    const handleCurrencyChange = (value: string) => {
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, mainCurrency: value } }));
    };

    const updateAcceptedPayments = (payments: string[]) => {
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, acceptedPayments: payments } }));
    };

    const updateRefundPolicy = (refundPolicy: string[]) => {
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, refundPolicy } }));
    };

    const updateCancellationPolicy = (cancellationPolicy: string) => {
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, cancellationPolicy } }));
    };

    const updateDiscountLevels = (levels: any[]) => {
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, discountLevels: levels } }));
    };

    const updateDepositPolicy = (depositPolicy: any) => {
        setLocalTour((prevTour) => ({ ...prevTour, pricing: { ...prevTour.pricing, depositPolicy } }));
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const updatedTour: UpdateBodyType = { id: localTour.id, pricing: localTour.pricing };
            await updateTour(localTour.id, updatedTour);

            console.log("Tour pricing updated successfully");
        } catch (error) {
            console.error("Failed to update tour pricing:", error);
            // You might want to add an error notification here
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle>Pricing & Policies</CardTitle>
                <Button onClick={handleSave} disabled={isSaving}>
                    {isSaving ? (
                        <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Saving...
                        </>
                    ) : (
                        "Save Changes"
                    )}
                </Button>
            </CardHeader>
            <CardContent className="space-y-6">
                <div className="flex flex-row space-x-4">
                    <div className="w-full">
                        <Label htmlFor="basePrice">Base Price</Label>
                        <Input id="basePrice" name="basePrice" type="number" value={localTour.pricing.basePrice} onChange={handleInputChange} min="0" step="1" />
                    </div>
                    <div>
                        <Label htmlFor="mainCurrency">Currency</Label>
                        <Select name="mainCurrency" value={localTour.pricing.mainCurrency} onValueChange={handleCurrencyChange}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select currency" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="USD">USD</SelectItem>
                                <SelectItem value="EUR">EUR</SelectItem>
                                <SelectItem value="RUB">RUB</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <AcceptedPaymentsPanel acceptedPayments={localTour.pricing.acceptedPayments} updateAcceptedPayments={updateAcceptedPayments} />
                <RefundPolicyPanel refundPolicy={localTour.pricing.refundPolicy} updateRefundPolicy={updateRefundPolicy} />
                <CancellationPolicyPanel cancellationPolicy={localTour.pricing.cancellationPolicy} updateCancellationPolicy={updateCancellationPolicy} />
                {localTour.pricing.discountLevels && (
                    <CustomDiscountPanel discountLevels={localTour.pricing.discountLevels} updateDiscountLevels={updateDiscountLevels} />
                )}
                {localTour.pricing.depositPolicy && <DepositPolicyPanel depositPolicy={localTour.pricing.depositPolicy} updateDepositPolicy={updateDepositPolicy} />}
            </CardContent>
        </Card>
    );
};

export default PricingPoliciesTab;

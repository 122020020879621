import { UserSelector } from "entities/store/user";
import { UserLoggedProps } from "shared/types/types";
import { Globe, HeartIcon, MessageCircleIcon, Settings } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useScreenWidth } from "shared/helpers/utils";
import { BaseRoutes, MDWIDTH } from "shared/helpers/const";
import { Modal, useModal } from "shared/providers/ModalWindow";
import UserInfoDropdown from "./UserInfo/UserInfoHeaderDropdown";
import NotificationHeaderComponent from "features/user/Header/Notifications/Notifications";

const DevItem = () => {
    const dev = import.meta.env.DEV;
    const navigate = useNavigate();
    const testBench = () => navigate(BaseRoutes.BENCH);

    if (!dev) return null;

    return (
        <Button variant={"ghost"} onClick={testBench}>
            <Settings />
        </Button>
    );
};

const MenuItems = ({ isLogged }: UserLoggedProps) => {
    const navigate = useNavigate();

    const messages = () => navigate(BaseRoutes.MESSAGES);
    const favorite = () => navigate(BaseRoutes.FAVORITE);

    const modal = useModal();
    const openLanguageModal = () => modal.show(Modal.LANGUAGE);

    return (
        <div className="flex flex-row mr-4">
            <DevItem />
            <Button variant="ghost" onClick={favorite}>
                <HeartIcon />
            </Button>

            <Button variant="ghost" onClick={openLanguageModal}>
                <Globe />
            </Button>

            {isLogged ? (
                <>
                    <NotificationHeaderComponent />
                    <Button variant="ghost" onClick={messages}>
                        <MessageCircleIcon />
                    </Button>
                </>
            ) : null}
        </div>
    );
};

function DesktopHeader() {
    const isLogged = useAppSelector(UserSelector.isLogged);
    const width = useScreenWidth();

    if (width < MDWIDTH) return null;

    return (
        <div className="flex flex-1 justify-end">
            <MenuItems isLogged={isLogged} />
            <UserInfoDropdown isLogged={isLogged} />
        </div>
    );
}

export default DesktopHeader;

import { OneOptional } from "shared/types/types";
import axios, { AxiosProgressEvent, AxiosResponse } from "axios";

import { AudioWaveform, Construction, File, FileImage, FolderArchive, UploadCloud, Video, X } from "lucide-react";
enum FileTypes {
    Image = "image",
    Pdf = "pdf",
    Audio = "audio",
    Video = "video",
    Other = "other",
}
export interface FileUploadProgress {
    progress: number;
    File: File;
    source?: AbortSignal;
}

export type SendFunction<T = any, V = any> = (
    formData: FormData,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    signal: AbortSignal
) => Promise<AxiosResponse<T, V>>;

export type UploadFileOptions = { send: SendFunction; link: string };

export type UploadWithLink = OneOptional<UploadFileOptions, "send">;
export type UploadWithSend = OneOptional<UploadFileOptions, "link">;

export type UploadProps = UploadWithSend | UploadWithLink;

const ImageColor = { bgColor: "bg-purple-600", fillColor: "fill-purple-600" };
const PdfColor = { bgColor: "bg-blue-400", fillColor: "fill-blue-400" };
const AudioColor = { bgColor: "bg-yellow-400", fillColor: "fill-yellow-400" };
const VideoColor = { bgColor: "bg-green-400", fillColor: "fill-green-400" };
const OtherColor = { bgColor: "bg-gray-400", fillColor: "fill-gray-400" };

type ImageMimeTypes = ["image/avif", "image/bmp", "image/gif", "image/pjpeg", "image/heic", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
const allowedImageMimes = ["image/avif", "image/bmp", "image/gif", "image/pjpeg", "image/heic", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
const acceptMimeTypes = allowedImageMimes.join(" ");

const getFileIconAndColor = (file: File) => {
    if (file.type.includes(FileTypes.Image)) return { icon: <FileImage size={40} className={ImageColor.fillColor} />, color: ImageColor.bgColor };
    if (file.type.includes(FileTypes.Pdf)) return { icon: <File size={40} className={PdfColor.fillColor} />, color: PdfColor.bgColor };
    if (file.type.includes(FileTypes.Audio)) return { icon: <AudioWaveform size={40} className={AudioColor.fillColor} />, color: AudioColor.bgColor };
    if (file.type.includes(FileTypes.Video)) return { icon: <Video size={40} className={VideoColor.fillColor} />, color: VideoColor.bgColor };

    return { icon: <FolderArchive size={40} className={OtherColor.fillColor} />, color: OtherColor.bgColor };
};

export { getFileIconAndColor, acceptMimeTypes };

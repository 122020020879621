import { BenchItemWrap, BenchItemValue } from "../utils/Bench";

const UtilityBench = ({ value }: BenchItemValue) => {
    return (
        <BenchItemWrap value={value}>
            <p>1. Axios</p>
            <p>2. Formatters</p>
        </BenchItemWrap>
    );
};

export default UtilityBench;

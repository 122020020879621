import { BaseRoutes } from "shared/helpers/const";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import React from "react";
import { useAppSelector } from "shared/helpers/utils";
import { RootState } from "app/store";

function FullSizeComponents() {
    return (
        <div>
            <h1 className="text-xl font-semibold">Full size components</h1>
            <Separator className="h-2" />
            <div className="flex flex-row flex-nowrap mt-2">
                <Link to={BaseRoutes.TEST.MAP}>
                    <Button className="h-12 md:h-12">Test Map</Button>
                </Link>
            </div>
        </div>
    );
}

function TestBenchHeader() {
    return (
        <div className="mb-4">
            <h1 className="text-3xl font-bold">Test bench</h1>
            <Separator className="h-2 mt-2" />
            <p className="mt-1">
                This is test bench, if you came here accidently, please report to our{" "}
                <a className="text-blue-800 underline " href="mailto:support@adventu.ru">
                    support
                </a>
            </p>
        </div>
    );
}

type StateKey = keyof RootState;

function ShowState({ obj }: { obj: any }) {
    return Object.keys(obj).map((key) => {
        return (
            <div key={key}>
                {key}: {JSON.stringify(obj[key])}
            </div>
        );
    });
}
function UserState() {
    const types: StateKey[] = ["user"];
    const [state_, setState] = React.useState(0);
    const states = useAppSelector((state) => state[types[state_]]);
    const data = Object(states);

    return (
        <>
            <div>
                <h1 className="text-xl font-semibold">Current user state</h1>
                <Separator className="h-2" />
            </div>
            <ShowState obj={data} />
            <Separator className="h-2" />
        </>
    );
}

export { FullSizeComponents, TestBenchHeader, UserState };

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FC } from "react";
import { CustomComponentProps } from "shared/types/types";
import { BetaTag } from "@/buttons/beta-button/beta-button";

const Logo: FC<CustomComponentProps> = ({ className }) => {
    const { t } = useTranslation();
    const logoName: string[] = t("name").split(".");
    const isDev = import.meta.env.DEV;

    return (
        <div className={`font-bold ${className}`}>
            <Link to="/" className="no-underline cursor-pointer">
                <h3 className="text-2xl uppercase font-['PT_Serif'] relative">
                    <span className="text-white bg-secondary px-1">{logoName[0]}</span>
                    <span className="text-black ml-1">{logoName[1]}</span>
                    <BetaTag />
                    {isDev && <span className="text-xs absolute -bottom-4 left-0 text-red-500">DEV VERSION</span>}
                </h3>
            </Link>
        </div>
    );
};

const Logo_v1: FC<CustomComponentProps> = ({ className }) => {
    const { t } = useTranslation();
    const logoName: string[] = t("name").split(".");
    const isDev = import.meta.env.DEV;

    return (
        <div className={`font-bold ${className}`}>
            <Link to="/" className="no-underline cursor-pointer">
                <h3 className="text-2xl uppercase font-['PT_Serif'] relative inline-block">
                    <span className="text-white bg-secondary px-1">{logoName[0]}</span>
                    <span className="text-black ml-1">{logoName[1]}</span>

                    <span className="text-xs absolute -top-1 -right-10 px-2 py-0.5 rounded-full bg-gray-200 text-gray-700">BETA</span>
                    {isDev && <span className="text-xs absolute  -bottom-5 left-0 px-2 py-0.5 rounded-full bg-gray-200 text-gray-700">DEV</span>}
                </h3>
            </Link>
        </div>
    );
};

export default Logo;

import React from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import GoogleSvg from "shared/ui/icons/GoogleSvg";

const GoogleButton = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(({ className, onClick, ...props }, ref) => {
    const { toast } = useToast();
    if (!onClick) onClick = (_) => toast({ title: "No action for this button" });

    return (
        <Button className="h-[60px]" onClick={onClick} variant={"ghost"} ref={ref}>
            <div className="flex w-[200px] items-center justify-around">
                <GoogleSvg width={"35px"} height={"35px"} />
                Sign in with Google
            </div>
        </Button>
    );
});

export default GoogleButton;

import React, { createContext, useCallback, useContext, useState, useEffect, useReducer, useRef } from "react";
import { useDebounce } from "react-use";

import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ExpandingTextArea, Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { LoadingButton } from "@/components/ui/loading-button";
import { Switch } from "@/components/ui/switch";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { AddLocationMobileDialog } from "../../Dashboard/components/locations/full-screen-location-input";
import Autocomplete, { PlacePreview } from "../../Autocomplete/Autocomplete";
import { HandPlatter, MapPin, Plus, Trash2 } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { useTourCreation } from "../State";
import { GripVertical } from "lucide-react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Loader2 } from "lucide-react";
import CategoryTagsSelect from "../../Dashboard/components/additional-info/category-tags-select";

import { useFieldArray } from "react-hook-form";
import { MultiSelect } from "@/multi-select";
import { useToast } from "@/components/ui/use-toast";
import { Move } from "lucide-react";
import { useAnimation } from "framer-motion";

/* prettier-ignore */
import { Compass, Map, Camera, Calendar, CheckCircle2, AlertCircle, Clock, FileText, Construction,
        Users, Utensils, User, ChevronDown, ChevronUp, Check, Minus, Info, AccessibilityIcon,CheckCircle, CreditCard,
        DollarSign, Mountain, ShoppingBag, Ban, Accessibility, Image as ImageIcon, RefreshCw, Percent, CreditCardIcon, Upload,
        ChevronRight, ChevronLeft, X, Sun, Tag
 } from "lucide-react";

import { instance } from "shared/api/signature";
import { CategoryOption } from "shared/data/tour_schema";
import { Difficulty, difficultyLevels, foodOptions } from "shared/types/api";
import { Seasonality, TourStatus } from "shared/types/api";
import { paymentOptions } from "shared/types/api";
import {
    API_ENDPOINTS,
    AUTH_API_ENDPOINTS,
    AdditionalInfoSchema,
    DepositPolicyEnum,
    FoodOption,
    LocationSchema,
    PROVIDER_TOUR_API_ENDPOINTS,
    PricingSchema,
    SeasonalityEnum,
    TourLocationSchema,
    UpdateBodyType,
    Currency,
    DurationType,
    accessibilityOptions,
} from "shared/types/api";
import { cancellationPolicies, depositPolicyOptions, refundPolicies } from "shared/types/api";

import { languageOptions, ItineraryDay } from "shared/types/api";

import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

interface CarouselImage {
    src: string;
    comment?: string;
}

interface CarouselImage {
    src: string;
}
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
interface ShadcnImageCarouselProps {
    images: CarouselImage[];
    onClose: () => void;
    initialIndex?: number;
}

const InteractiveImageCarousel: React.FC<ShadcnImageCarouselProps> = ({ images, onClose, initialIndex = 0 }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center">
            <button onClick={onClose} className="absolute top-4 right-4 text-white hover:text-gray-300 z-10">
                <X size={24} />
            </button>
            <Carousel className="w-full max-w-md" startIndex={initialIndex}>
                <CarouselContent>
                    {images.map((image, index) => (
                        <CarouselItem key={index}>
                            <div className="p-1">
                                <img src={image.src} alt={image.alt || `Tour image ${index + 1}`} className="w-full h-auto object-contain" />
                            </div>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
    );
};

import { Globe } from "lucide-react";
import { z } from "zod";

import { useNavigate } from "react-router-dom";
import { SliderInput } from "@/components/ui/slider";
import { MeetingPointDashboard } from "../../Dashboard/components/entity-management/tour-entity-managent";

const create_tour_req = async () => {
    const req = await instance.post(PROVIDER_TOUR_API_ENDPOINTS.createTour.url);
    return req.data;
};

const MobileIntroStep: React.FC = () => {
    const { setStep, updateTourData, tourData, step } = useTourCreation();
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    const steps = [
        { icon: Compass, text: "Tour name and description" },
        { icon: Map, text: "Itinerary and key locations" },
        { icon: Camera, text: "Tour photos" },
        { icon: Calendar, text: "Dates and availability" },
        { icon: DollarSign, text: "Pricing and policies" },
        { icon: Info, text: "Additional details" },
    ];

    const handleStartCreating = async () => {
        setIsLoading(true);

        try {
            const newTour = await create_tour_req();
            updateTourData(newTour.data);
            setStep(1);
            navigate(`/provider/db/constructor?tourId=${newTour.data.id}&step=1`);
        } catch (error) {
            console.error("Error creating tour:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col h-full py-6 px-4">
            <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                <h2 className="text-2xl font-bold text-primary-dark mb-4">Basic Tour Information</h2>
                <p className="text-sm text-primary mb-6">
                    You're about to begin creating your tour. This process will guide you through providing essential information about your tour experience. Here's what
                    you'll need to prepare:
                </p>
            </motion.div>

            <motion.div className="space-y-3 mb-6" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3, duration: 0.5 }}>
                {steps.map((step, index) => (
                    <div key={index} className="flex items-center bg-background p-3 rounded-lg shadow-sm">
                        <step.icon className="w-5 h-5 mr-3 text-primary" />
                        <p className="text-sm text-text-primary">{step.text}</p>
                    </div>
                ))}
            </motion.div>

            <motion.div className="mt-auto" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6, duration: 0.5 }}>
                <p className="text-sm text-primary mb-4">
                    Don't worry if you don't have all the information right now. You can save your progress and come back to complete it later.
                </p>
                <LoadingButton
                    onClick={handleStartCreating}
                    loading={isLoading}
                    className="w-full bg-secondary hover:bg-secondary-dark text-background py-2 rounded-lg text-sm font-semibold transition-all duration-300 shadow"
                >
                    Start Creating Your Tour
                </LoadingButton>
            </motion.div>
        </div>
    );
};

const MobileBasicInfoStep: React.FC = () => {
    const { tourData, updateTourData, setStep } = useTourCreation();
    const [name, setName] = useState(tourData.name || "");
    const [description, setDescription] = useState(tourData.description || "");

    const debouncedUpdateTourData = useCallback(
        (data: Partial<typeof tourData>) => {
            updateTourData(data);
        },
        [updateTourData]
    );

    useDebounce(() => debouncedUpdateTourData({ name, description }), 100, [name, description]);

    const isValid = name.trim() !== "" && description.trim() !== "";

    return (
        <div className="flex flex-col h-full p-6 bg-background">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="space-y-6 flex-grow overflow-y-auto pr-1 pl-1"
            >
                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
                    <Label htmlFor="name" className="text-lg font-semibold text-primary-dark flex items-center">
                        <Compass className="w-5 h-5 mr-2 text-primary" />
                        Tour Name
                    </Label>
                    <Input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="e.g., Venice Culinary Adventure"
                        className="mt-1 border-primary focus:border-primary-dark focus:ring focus:ring-primary-light"
                    />
                </motion.div>

                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.3, duration: 0.5 }}>
                    <Label htmlFor="description" className="text-lg font-semibold text-primary-dark flex items-center">
                        <FileText className="w-5 h-5 mr-2 text-primary" />
                        Tour Description
                    </Label>
                    <ExpandingTextArea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Describe your tour in a few sentences..."
                        maxRows={24}
                        className="mt-1 border-primary focus:border-primary-dark focus:ring focus:ring-primary-light"
                    />
                </motion.div>
            </motion.div>
        </div>
    );
};

const MobileDurationDatesStep: React.FC = () => {
    const { tourData, updateTourData, setStep } = useTourCreation();

    const handleDurationTypeChange = (value: string) => {
        const newDurationType = value === DurationType.SINGLE_DAY || value === DurationType.MULTI_DAY ? value : DurationType.SINGLE_DAY;

        let newDuration = { ...tourData.duration };
        let newItinerary = [...tourData.itinerary];

        if (newDurationType === DurationType.SINGLE_DAY) {
            newDuration = { days: 1, hours: 0, minutes: 0 };
            newItinerary = newItinerary.slice(0, 1);
        } else if (tourData.durationType === DurationType.SINGLE_DAY) {
            newDuration = { days: 2, hours: 0, minutes: 0 };
            newItinerary.push({ dayNumber: 2, title: "", description: "", locationIds: [] });
        }

        updateTourData({ durationType: newDurationType, duration: newDuration, itinerary: newItinerary });
    };

    const handleDurationChange = (field: "days" | "hours", value: number) => {
        const newDuration = { ...tourData.duration, [field]: value };
        let newItinerary = [...tourData.itinerary];

        if (field === "days") {
            if (value > newItinerary.length) {
                for (let i = newItinerary.length + 1; i <= value; i++) {
                    newItinerary.push({ dayNumber: i, title: "", description: "", locationIds: [] });
                }
            } else if (value < newItinerary.length) {
                newItinerary = newItinerary.slice(0, value);
            }
        }

        updateTourData({ duration: newDuration, itinerary: newItinerary });
    };

    return (
        <div className="flex flex-col h-full py-6 px-4 bg-background">
            <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="mb-6">
                <h2 className="text-2xl font-bold text-primary-dark mb-2">Tour Duration</h2>
                <p className="text-sm text-primary">Specify the length of your tour experience.</p>
            </motion.div>

            <motion.div className="space-y-6 flex-grow overflow-y-auto" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2, duration: 0.5 }}>
                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.4, duration: 0.5 }}>
                    <Label className="text-lg font-semibold text-primary-dark flex items-center mb-2">
                        <Clock className="w-5 h-5 mr-2 text-primary" />
                        Tour Type
                    </Label>

                    <RadioGroup value={tourData.durationType} onValueChange={handleDurationTypeChange} className="flex space-x-4">
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value={DurationType.SINGLE_DAY} id={DurationType.SINGLE_DAY} />
                            <Label htmlFor={DurationType.SINGLE_DAY}>Single Day</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value={DurationType.MULTI_DAY} id={DurationType.MULTI_DAY} />
                            <Label htmlFor={DurationType.MULTI_DAY}>Multi-Day</Label>
                        </div>
                    </RadioGroup>
                </motion.div>

                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.5 }} className="space-y-4">
                    <Label className="text-lg font-semibold text-primary-dark flex items-center">
                        <Clock className="w-5 h-5 mr-2 text-primary" />
                        Duration
                    </Label>
                    {tourData.durationType === DurationType.MULTI_DAY && (
                        <div>
                            <Label htmlFor="days">Days</Label>
                            <SliderInput value={tourData.duration.days} min={2} max={30} setState={(v) => handleDurationChange("days", parseInt(v))} />
                        </div>
                    )}

                    {tourData.durationType === DurationType.SINGLE_DAY && (
                        <div className="flex space-x-4">
                            <div className="flex-1">
                                <Label htmlFor="hours">Hours</Label>
                                <SliderInput value={tourData.duration.hours} max={23} min={1} setState={(v) => handleDurationChange("hours", v)} />
                            </div>
                        </div>
                    )}
                </motion.div>
            </motion.div>
        </div>
    );
};

type PricingFormData = z.infer<typeof PricingSchema>;

const MobilePricingStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();

    const form = useForm<PricingFormData>({
        resolver: zodResolver(PricingSchema),

        defaultValues: {
            basePrice: tourData.pricing.basePrice ?? 100,
            mainCurrency: (tourData.pricing.mainCurrency as Currency) || Currency.USD,
            currencyPrices: {},
            cancellationPolicy: tourData.pricing?.cancellationPolicy || "",
            refundPolicy: tourData.pricing?.refundPolicy || [],
            depositPolicy: tourData.pricing?.depositPolicy ?? { policyType: "no_deposit", amount: 0, currency: Currency.USD },
            acceptedPayments: tourData.pricing?.acceptedPayments || [],
            discountLevels: tourData.pricing?.discountLevels || [],
        },
    });

    const { control } = form;

    const handleFieldChange = (fieldName: keyof PricingFormData, value: any) => {
        const updatedData = { pricing: { ...tourData.pricing, [fieldName]: value } };

        if (fieldName === "basePrice") {
            updatedData.basePrice = value;
        } else if (fieldName === "mainCurrency") {
            updatedData.currency = value;
        }

        updateTourData({ pricing: updatedData.pricing });
    };

    return (
        <div className="flex flex-col h-[90dvh] p-6 bg-background">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-6 flex-grow overflow-y-auto">
                <h2 className="text-2xl font-bold text-primary-dark mb-4">Tour Pricing</h2>

                <Card>
                    <CardContent className="p-4 space-y-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-grow">
                                <Label htmlFor="basePrice" className="text-sm font-medium text-primary">
                                    Base Price
                                </Label>
                                <div className="relative mt-1">
                                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary" />
                                    <Controller
                                        name="basePrice"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                id="basePrice"
                                                type="number"
                                                {...field}
                                                onChange={(e) => {
                                                    const value = parseFloat(e.target.value);
                                                    field.onChange(value);
                                                    handleFieldChange("basePrice", value);
                                                }}
                                                className="pl-8 border-primary focus:border-primary-dark focus:ring focus:ring-primary-light"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label htmlFor="mainCurrency" className="text-sm font-medium text-primary">
                                    Currency
                                </Label>
                                <Controller
                                    name="mainCurrency"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            onValueChange={(value) => {
                                                field.onChange(value);
                                                handleFieldChange("mainCurrency", value);
                                            }}
                                            defaultValue={field.value}
                                        >
                                            <SelectTrigger
                                                id="mainCurrency"
                                                className="w-[100px] border-primary focus:border-primary-dark focus:ring focus:ring-primary-light"
                                            >
                                                <SelectValue placeholder="Currency" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.values(Currency).map((curr) => (
                                                    <SelectItem key={curr} value={curr}>
                                                        {curr}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Accordion type="single" collapsible className="w-full space-y-4">
                    {/* Cancellation Policy */}
                    <AccordionItem value="cancellationPolicy">
                        <AccordionTrigger className="text-lg font-semibold text-primary">
                            <Ban className="w-5 h-5 mr-2 text-primary" />
                            Cancellation Policy
                        </AccordionTrigger>
                        <AccordionContent>
                            <Controller
                                name="cancellationPolicy"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        onValueChange={(value) => {
                                            field.onChange(value);
                                            handleFieldChange("cancellationPolicy", value);
                                        }}
                                        defaultValue={field.value}
                                    >
                                        <SelectTrigger className="border-primary focus:border-primary-dark focus:ring focus:ring-primary-light">
                                            <SelectValue placeholder="Select cancellation policy" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {cancellationPolicies.map((policy) => (
                                                <SelectItem key={policy.id} value={policy.id}>
                                                    {policy.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                            {form.watch("cancellationPolicy") && (
                                <p className="mt-2 text-sm text-primary">{cancellationPolicies.find((p) => p.id === form.watch("cancellationPolicy"))?.description}</p>
                            )}
                        </AccordionContent>
                    </AccordionItem>

                    {/* Refund Policy */}
                    <AccordionItem value="refundPolicy">
                        <AccordionTrigger className="text-lg font-semibold text-primary">
                            <RefreshCw className="w-5 h-5 mr-2" />
                            Refund Policy
                        </AccordionTrigger>
                        <AccordionContent>
                            {refundPolicies.map((policy) => (
                                <div key={policy.id} className="flex items-center space-x-2 mb-2">
                                    <Controller
                                        name="refundPolicy"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                id={`refund-${policy.id}`}
                                                checked={field.value.includes(policy.id)}
                                                onCheckedChange={(checked) => {
                                                    const updatedPolicies = checked ? [...field.value, policy.id] : field.value.filter((p) => p !== policy.id);
                                                    field.onChange(updatedPolicies);
                                                    handleFieldChange("refundPolicy", updatedPolicies);
                                                }}
                                            />
                                        )}
                                    />
                                    <Label htmlFor={`refund-${policy.id}`}>{policy.name}</Label>
                                </div>
                            ))}
                        </AccordionContent>
                    </AccordionItem>

                    {/* Deposit Policy */}
                    <AccordionItem value="depositPolicy">
                        <AccordionTrigger className="text-lg font-semibold text-primary">
                            <Percent className="w-5 h-5 mr-2" />
                            Deposit Policy
                        </AccordionTrigger>
                        <AccordionContent>
                            <div className="space-y-4">
                                <Controller
                                    name="depositPolicy.type"
                                    control={control}
                                    render={({ field }) => {
                                        console.log(field);
                                        return (
                                            <Select
                                                onValueChange={(value) => {
                                                    field.onChange(value);
                                                    handleFieldChange("depositPolicy", { ...form.getValues("depositPolicy"), policyType: value });
                                                }}
                                                defaultValue={field.value}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select deposit policy" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={DepositPolicyEnum.NO_DEPOSIT}>No Deposit</SelectItem>
                                                    <SelectItem value={DepositPolicyEnum.PERCENTAGE}>Percentage</SelectItem>
                                                    <SelectItem value={DepositPolicyEnum.FIXED_AMOUNT}>Fixed Amount</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        );
                                    }}
                                />
                                {form.watch("depositPolicy.type") !== DepositPolicyEnum.NO_DEPOSIT && (
                                    <div className="flex items-center space-x-4">
                                        <Controller
                                            name="depositPolicy.amount"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type="number"
                                                    {...field}
                                                    onChange={(e) => {
                                                        const value = parseFloat(e.target.value);
                                                        field.onChange(value);
                                                        handleFieldChange("depositPolicy", { ...form.getValues("depositPolicy"), amount: value });
                                                    }}
                                                    placeholder={form.watch("depositPolicy.type") === "PERCENTAGE" ? "Percentage" : "Amount"}
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    {/* Accepted Payments */}
                    <AccordionItem value="acceptedPayments">
                        <AccordionTrigger className="text-lg font-semibold text-primary">
                            <CreditCard className="w-5 h-5 mr-2" />
                            Accepted Payments
                        </AccordionTrigger>
                        <AccordionContent>
                            {paymentOptions.map((method) => (
                                <div key={method.id} className="flex items-center space-x-2 mb-2">
                                    <Controller
                                        name="acceptedPayments"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                id={`payment-${method.id}`}
                                                checked={field.value.includes(method.id)}
                                                onCheckedChange={(checked) => {
                                                    const updatedPayments = checked ? [...field.value, method.id] : field.value.filter((m) => m !== method.id);
                                                    field.onChange(updatedPayments);
                                                    handleFieldChange("acceptedPayments", updatedPayments);
                                                }}
                                            />
                                        )}
                                    />
                                    <Label htmlFor={`payment-${method.id}`}>{method.name}</Label>
                                </div>
                            ))}
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </motion.div>
        </div>
    );
};

const MobileTourDetailsStep: React.FC = () => {
    const { tourData, updateTourData, setStep } = useTourCreation();
    const [difficulty, setDifficulty] = useState<Difficulty>(tourData.difficulty || "easy");
    const [maxParticipants, setMaxParticipants] = useState(tourData.maxParticipants || 1);
    const [minAge, setMinAge] = useState(tourData.minAge || 0);
    const [feedback, setFeedback] = useState("");
    const [isValid, setIsValid] = useState(false);

    const debouncedUpdateTourData = useCallback(
        (data: Partial<typeof tourData>) => {
            updateTourData(data);
        },
        [updateTourData]
    );

    useDebounce(
        () => {
            debouncedUpdateTourData({ difficulty, maxParticipants, minAge });
        },
        500,
        [difficulty, maxParticipants, minAge]
    );

    const update_difficulty = (value: Difficulty) => {
        setDifficulty(value);
    };

    useEffect(() => {
        validateInputs();
    }, [difficulty, maxParticipants, minAge]);

    const validateInputs = () => {
        if (maxParticipants < 1) {
            setFeedback("Maximum participants must be at least 1.");
            setIsValid(false);
        } else if (maxParticipants > 100) {
            setFeedback("Are you sure you can manage more than 100 participants?");
            setIsValid(true);
        } else if (minAge < 0) {
            setFeedback("Minimum age cannot be negative.");
            setIsValid(false);
        } else if (minAge > 18) {
            setFeedback("A minimum age above 18 may limit your audience.");
            setIsValid(true);
        } else {
            setFeedback("");
            setIsValid(true);
        }
    };

    return (
        <div className="flex flex-col h-full p-6 bg-gradient-to-br">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-6">
                <h2 className="text-2xl font-bold text-primary mb-4">Tour Details</h2>

                <div>
                    <Label htmlFor="difficulty" className="block text-sm font-medium text-primary">
                        Difficulty Level
                    </Label>
                    <Select value={difficulty} onValueChange={update_difficulty}>
                        <SelectTrigger className="w-full mt-1">
                            <SelectValue placeholder="Select difficulty" />
                        </SelectTrigger>
                        <SelectContent>
                            {difficultyLevels.map((level) => (
                                <SelectItem key={level.value} value={level.value}>
                                    {level.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label htmlFor="maxParticipants" className="block text-sm font-medium text-primary">
                        Maximum Participants
                    </Label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Users className="h-5 w-5 text-purple-400" />
                        </div>
                        <Input
                            type="number"
                            name="maxParticipants"
                            id="maxParticipants"
                            value={maxParticipants}
                            onChange={(e) => setMaxParticipants(parseInt(e.target.value))}
                            className="block w-full pl-10 sm:text-sm border-purple-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                            placeholder="10"
                            min="1"
                        />
                    </div>
                </div>

                <div>
                    <Label htmlFor="minAge" className="block text-sm font-medium text-primary">
                        Minimum Age
                    </Label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Clock className="h-5 w-5 text-purple-400" />
                        </div>
                        <Input
                            type="number"
                            name="minAge"
                            id="minAge"
                            value={minAge}
                            onChange={(e) => setMinAge(parseInt(e.target.value))}
                            className="block w-full pl-10 sm:text-sm border-purple-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                            placeholder="0"
                            min="0"
                        />
                    </div>
                </div>

                {feedback && (
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className={`mt-2 text-sm flex items-center ${isValid ? "text-yellow-600" : "text-red-600"}`}
                    >
                        <AlertCircle className="mr-2" size={16} />
                        {feedback}
                    </motion.p>
                )}

                {isValid && !feedback && (
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="mt-2 text-sm text-green-600 flex items-center">
                        <CheckCircle className="mr-2" size={16} />
                        Tour details set successfully!
                    </motion.p>
                )}
            </motion.div>
        </div>
    );
};
interface FoodOptionsSelectProps {
    selectedOptions: string[];
    onChange: (options: string[]) => void;
}

export const FoodOptionsSelect: React.FC<FoodOptionsSelectProps> = ({ selectedOptions, onChange }) => {
    const [customOption, setCustomOption] = useState("");

    const handleOptionChange = (selectedOption: string) => {
        const updatedOptions = selectedOptions.includes(selectedOption) ? selectedOptions.filter((opt) => opt !== selectedOption) : [...selectedOptions, selectedOption];
        onChange(updatedOptions);
    };

    const removeOption = (optionToRemove: string) => {
        const updatedOptions = selectedOptions.filter((opt) => opt !== optionToRemove);
        onChange(updatedOptions);
    };

    const addCustomOption = () => {
        if (customOption.trim() && !selectedOptions.includes(customOption.trim())) {
            onChange([...selectedOptions, customOption.trim()]);
            setCustomOption("");
        }
    };

    return (
        <div className="space-y-2">
            <Label htmlFor="foodOptions">Food Options</Label>
            <Select onValueChange={handleOptionChange}>
                <SelectTrigger>
                    <SelectValue placeholder="Select food options" />
                </SelectTrigger>
                <SelectContent>
                    {foodOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                            <div className="flex items-center space-x-2">
                                <Label>{option.label}</Label>
                                {selectedOptions.includes(option.value) && <Check className="h-4 w-4 absolute right-10 top-2" />}
                            </div>
                        </SelectItem>
                    ))}
                    <div className="p-2 border-t">
                        <div className="flex items-center space-x-2">
                            <Input
                                placeholder="Add custom food option"
                                value={customOption}
                                onChange={(e) => setCustomOption(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        addCustomOption();
                                    }
                                }}
                            />
                            <Button size="sm" onClick={addCustomOption}>
                                <Plus className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </SelectContent>
            </Select>
            <div className="flex flex-wrap gap-2 mt-2">
                {selectedOptions.map((option) => (
                    <Badge key={option} variant="secondary" className="flex items-center gap-1">
                        {foodOptions.find((opt) => opt.value === option)?.label || option}
                        <button onClick={() => removeOption(option)} className="text-xs">
                            <X size={12} />
                        </button>
                    </Badge>
                ))}
            </div>
        </div>
    );
};

const MobileLanguagesStep: React.FC = () => {
    const { tourData, updateTourData, setStep } = useTourCreation();
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>(tourData.languages || []);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        updateTourData({ languages: selectedLanguages });
        setIsValid(selectedLanguages.length > 0);
    }, [selectedLanguages]);

    const toggleLanguage = (code: string) => {
        setSelectedLanguages((prev) => (prev.includes(code) ? prev.filter((lang) => lang !== code) : [...prev, code]));
    };

    return (
        <div className="flex flex-col h-full p-6 bg-gradient-to-br ">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-6">
                <h2 className="text-2xl font-bold text-primary mb-4 flex items-center">
                    <Globe className="mr-2" />
                    Tour Languages
                </h2>

                <p className="text-sm text-primary">Select all languages your tour will be offered in:</p>

                <div className="grid grid-cols-2 gap-4">
                    {languageOptions.map((lang) => (
                        <motion.button
                            key={lang.value}
                            onClick={() => toggleLanguage(lang.value)}
                            className={`p-3 rounded-lg flex items-center justify-between ${
                                selectedLanguages.includes(lang.value) ? "bg-blue-500 text-white" : "bg-white text-blue-800 border border-blue-300"
                            }`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <span>{lang.label}</span>
                            {selectedLanguages.includes(lang.value) && <CheckCircle size={20} />}
                        </motion.button>
                    ))}
                </div>

                {selectedLanguages.length > 0 && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="mt-4">
                        <Label className="text-sm font-medium text-blue-700">Selected Languages:</Label>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {selectedLanguages.map((code) => (
                                <span key={code} className="bg-blue-200 text-blue-800 px-2 py-1 rounded-full text-sm flex items-center">
                                    {languageOptions.find((lang) => lang.value === code)?.value}
                                    <button onClick={() => toggleLanguage(code)} className="ml-1 focus:outline-none">
                                        <X size={14} />
                                    </button>
                                </span>
                            ))}
                        </div>
                    </motion.div>
                )}

                {!isValid && <p className="text-sm text-red-500">Please select at least one language for your tour.</p>}
            </motion.div>
        </div>
    );
};

const MobileLocationsStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();
    const { tourLocations, getLocations, meetingPoint, handleAddLocation, handleRemoveLocation, addMeetingPoint, removeMeetingPoint } = useTourLocations(
        tourData.id,
        tourData.tourLocations,
        tourData.meetingPoint
    );

    const locations = getLocations();

    const handleLocationAdd = async (locationData: any) => {
        try {
            const newTourLocation = await handleAddLocation(locationData);
            updateTourData({ tourLocations: [...tourLocations, newTourLocation] });
        } catch (error) {
            console.error("Error adding location:", error);
        }
    };

    const handleLocationRemove = async (locationId: string) => {
        try {
            const success = await handleRemoveLocation(locationId);
            if (success) {
                const updatedTourLocations = tourLocations.filter((tl) => tl.location.id !== locationId);
                updateTourData({ tourLocations: updatedTourLocations });
            }
        } catch (error) {
            console.error("Error removing location:", error);
        }
    };

    const handleMeetingPointChange = async (locationData: any | null) => {
        if (locationData === null) {
            await removeMeetingPoint();
            updateTourData({ meetingPoint: null });
        } else {
            const newMeetingPoint = await addMeetingPoint(locationData);
            updateTourData({ meetingPoint: newMeetingPoint });
        }
    };

    return (
        <div className="flex flex-col h-full bg-white">
            <div className="p-6 space-y-6 flex-grow overflow-y-auto pb-20">
                <div>
                    <MeetingPointDashboard key={meetingPoint?.location?.id} value={meetingPoint?.location} onChange={handleMeetingPointChange} />
                </div>

                <div className="space-y-4">
                    <Label className="text-sm font-medium text-gray-700">Места в туре</Label>
                    <AddLocationMobileDialog
                        onLocationAdd={handleLocationAdd}
                        triggerElement={
                            <Button className="w-full bg-[#ED8560] hover:bg-[#ED8560]/90 text-white">
                                <Plus className="mr-2 h-4 w-4" /> Добавить место
                            </Button>
                        }
                    />
                    {locations.map((location, index) => (
                        <Card key={location.id || index} className="border border-gray-200 hover:border-[#5FB7C5] transition-colors">
                            <CardContent className="p-4">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <h3 className="font-semibold text-[#5FB7C5]">{location.name}</h3>
                                        <p className="text-sm text-gray-500">{location.address}</p>
                                    </div>
                                    <Button variant="ghost" size="sm" onClick={() => handleLocationRemove(location.id ?? "index" + index)}>
                                        <Trash2 className="h-4 w-4 text-gray-400" />
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};
const days_get = (day, len) => {
    console.log("data", day, len);
    if (day - len > 0) {
        const diff = day - len;
        const diff_it: ItineraryDay[] = [];

        for (let i = len; i < diff + len; i++) {
            diff_it.push({
                dayNumber: i + 1,
                title: `Day ${i + 1}`,
                description: "",
                locationIds: [],
            });
        }
        console.log(diff_it, "yess");
        return diff_it;
    }
    return [];
};

import { useMemo } from "react";
import { useTourLocations } from "features/provider/useTourLocations";

const useItineraryDay = (initialDay, onUpdate) => {
    const [day, setDay] = useState(initialDay);

    const updateDay = useCallback(
        (field, value) => {
            setDay((prev) => {
                const updated = { ...prev, [field]: value };
                onUpdate(updated);
                return updated;
            });
        },
        [onUpdate]
    );

    const handleLocationChange = useCallback(
        (locationId) => {
            setDay((prev) => {
                const updatedLocationIds = prev.locationIds.includes(locationId) ? prev.locationIds.filter((id) => id !== locationId) : [...prev.locationIds, locationId];
                const updated = { ...prev, locationIds: updatedLocationIds };
                onUpdate(updated);
                return updated;
            });
        },
        [onUpdate]
    );

    return { day, updateDay, handleLocationChange };
};

const ItineraryDayContent = React.memo(({ day, updateDay, handleLocationChange, tourLocations }) => (
    <div className="space-y-4">
        <Input
            value={day.title}
            onChange={(e) => updateDay("title", e.target.value)}
            placeholder="Day title"
            className="w-full border-primary focus:border-primary-dark focus:ring focus:ring-primary-light"
        />
        <Textarea
            value={day.description}
            onChange={(e) => updateDay("description", e.target.value)}
            placeholder="Day description"
            className="w-full border-primary focus:border-primary-dark focus:ring focus:ring-primary-light"
            rows={4}
        />
        <div>
            <Label className="text-sm font-medium mb-2 text-primary-dark">Locations</Label>
            <div className="space-y-2">
                {tourLocations?.map(({ location }) => (
                    <div key={location.id} className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            id={`location-${day.dayNumber}-${location.id}`}
                            checked={day.locationIds.includes(location.id)}
                            onChange={() => handleLocationChange(location.id)}
                            className="rounded border-primary text-secondary focus:border-primary-dark focus:ring focus:ring-primary-light"
                        />
                        <Label htmlFor={`location-${day.dayNumber}-${location.id}`} className="text-sm text-primary">
                            {location.name}
                        </Label>
                    </div>
                ))}
            </div>
        </div>
    </div>
));

const MobileItineraryStep = () => {
    const { tourData, updateTourData } = useTourCreation();
    console.log(tourData.itinerary[0], "r");
    const [itinerary, setItinerary] = useState(() => {
        if (tourData.durationType === "SINGLE_DAY") {
            return tourData.itinerary.length > 0 ? [tourData.itinerary[0]] : [{ dayNumber: 1, title: "Tour Itinerary", description: "", locationIds: [] }];
        } else {
            const existingDays = tourData.itinerary;
            const totalDays = tourData.duration.days;
            const newDays = Array.from({ length: Math.max(0, totalDays - existingDays.length) }, (_, index) => ({
                dayNumber: existingDays.length + index + 1,
                title: `Day ${existingDays.length + index + 1}`,
                description: "",
                locationIds: [],
            }));
            return [...existingDays, ...newDays];
        }
    });

    const debouncedUpdateTourData = useCallback(
        (data: Partial<typeof tourData>) => {
            updateTourData(data);
        },
        [updateTourData]
    );

    useDebounce(
        () => {
            debouncedUpdateTourData({ itinerary });
        },
        50,
        [itinerary]
    );

    const updateItineraryDay = useCallback((index: number, updatedDay: ItineraryDay) => {
        setItinerary((prev) => prev.map((day, i) => (i === index ? updatedDay : day)));
    }, []);

    const memoizedTourLocations = useMemo(() => tourData.tourLocations, [tourData.tourLocations]);

    const renderItineraryDay = useCallback(
        (day: ItineraryDay, index: number) => {
            const { day: currentDay, updateDay, handleLocationChange } = useItineraryDay(day, (updatedDay) => updateItineraryDay(index, updatedDay));

            console.log(currentDay, "dayyyyyy");
            return (
                <ItineraryDayContent
                    key={day.dayNumber}
                    day={currentDay}
                    updateDay={updateDay}
                    handleLocationChange={handleLocationChange}
                    tourLocations={memoizedTourLocations}
                />
            );
        },
        [memoizedTourLocations, updateItineraryDay]
    );

    return (
        <div className="flex flex-col h-full p-6 bg-background">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-6 flex-grow overflow-y-auto">
                {tourData.durationType === "SINGLE_DAY" ? (
                    <Card>
                        <CardContent className="p-4">
                            <h3 className="text-lg font-semibold mb-4">Tour Itinerary</h3>
                            {renderItineraryDay(itinerary[0], 0)}
                        </CardContent>
                    </Card>
                ) : (
                    <Accordion type="single" collapsible className="w-full space-y-4">
                        {itinerary.map((day, index) => (
                            <AccordionItem key={day.dayNumber} value={`day-${day.dayNumber}`}>
                                <AccordionTrigger className="text-lg font-semibold text-primary">Day {day.dayNumber}</AccordionTrigger>
                                <AccordionContent>
                                    <Card>
                                        <CardContent className="p-4">{renderItineraryDay(day, index)}</CardContent>
                                    </Card>
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}
            </motion.div>
        </div>
    );
};

interface InteractiveItemListProps {
    title: string;
    items: string[];
    icon: React.ReactNode;
    type: "included" | "notIncluded" | "toBring";
}

const InteractiveItemList: React.FC<InteractiveItemListProps> = ({ title, items, icon, type }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const getItemIcon = (type: string) => {
        switch (type) {
            case "included":
                return <Check className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />;
            case "notIncluded":
                return <X className="w-4 h-4 text-red-500 mr-2 flex-shrink-0" />;
            case "toBring":
                return <ShoppingBag className="w-4 h-4 text-blue-500 mr-2 flex-shrink-0" />;
            default:
                return null;
        }
    };

    return (
        <div className="border rounded-lg overflow-hidden">
            <button
                className="w-full px-4 py-3 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <span className="flex items-center font-semibold text-gray-700">
                    {icon}
                    <span className="ml-2">{title}</span>
                </span>
                {isExpanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
            </button>
            <AnimatePresence>
                {isExpanded && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <ul className="px-4 py-2 bg-white">
                            {items.map((item, index) => (
                                <motion.li
                                    key={index}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: index * 0.05 }}
                                    className="flex items-start py-2 border-b last:border-b-0"
                                >
                                    {getItemIcon(type)}
                                    <span className="text-sm text-gray-600">{item}</span>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
export const MobileAdditionalInfoStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        getValues,
    } = useForm({
        resolver: zodResolver(AdditionalInfoSchema),
        defaultValues: {
            ...tourData.additionalInfo,
            categories: tourData.additionalInfo?.categories || [],
        } || {
            highlights: [],
            includedItems: [],
            notIncludedItems: [],
            whatToBring: [],
            accessibility: [],
            seasonality: undefined,
            privateGroupOption: false,
            foodOptions: [],
            categories: [],
        },
    });

    const { fields: whatToBringFields, append: appendWhatToBring, remove: removeWhatToBring } = useFieldArray({ control, name: "whatToBring" });
    const { fields: includedItemsFields, append: appendIncludedItem, remove: removeIncludedItem } = useFieldArray({ control, name: "includedItems" });
    const { fields: notIncludedItemsFields, append: appendNotIncludedItem, remove: removeNotIncludedItem } = useFieldArray({ control, name: "notIncludedItems" });

    const handleFieldChange = (name: string, value: any) => {
        updateTourData({ additionalInfo: { ...getValues(), [name]: value } });
    };

    const handleArrayFieldChange = (name: string, index: number, value: string) => {
        const currentArray = getValues(name as any);
        currentArray[index] = value;
        handleFieldChange(name, currentArray);
    };

    const handleArrayFieldRemove = (name: string, index: number) => {
        const currentArray = getValues(name as any);
        currentArray.splice(index, 1);
        handleFieldChange(name, currentArray);
    };

    const handleArrayFieldAppend = (name: string, value: string) => {
        const currentArray = getValues(name as any);
        handleFieldChange(name, [...currentArray, value]);
    };

    return (
        <div className="flex flex-col h-full p-6 bg-background">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-6 flex-grow overflow-y-auto">
                <h2 className="text-2xl font-bold text-primary mb-4">Additional Information</h2>

                <form>
                    <Accordion type="single" collapsible className="w-full space-y-4">
                        {/* What to Bring */}
                        <AccordionItem value="whatToBring">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <ShoppingBag className="w-5 h-5 mr-2" />
                                What to Bring
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className="space-y-2">
                                    {whatToBringFields.map((field, index) => (
                                        <div key={field.id} className="flex items-center space-x-2">
                                            <Controller
                                                name={`whatToBring.${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        className="flex-grow"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleArrayFieldChange("whatToBring", index, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    removeWhatToBring(index);
                                                    handleArrayFieldRemove("whatToBring", index);
                                                }}
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        variant="outline"
                                        type="button"
                                        onClick={() => {
                                            appendWhatToBring("");
                                            handleArrayFieldAppend("whatToBring", "");
                                        }}
                                        className="w-full mt-2"
                                    >
                                        Add Item
                                    </Button>
                                </div>
                            </AccordionContent>
                        </AccordionItem>

                        {/* Included Items */}
                        <AccordionItem value="includedItems">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <Check className="w-5 h-5 mr-2" />
                                Included Items
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className="space-y-2">
                                    {includedItemsFields.map((field, index) => (
                                        <div key={field.id} className="flex items-center space-x-2">
                                            <Controller
                                                name={`includedItems.${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        className="flex-grow"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleArrayFieldChange("includedItems", index, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => {
                                                    removeIncludedItem(index);
                                                    handleArrayFieldRemove("includedItems", index);
                                                }}
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={() => {
                                            appendIncludedItem("");
                                            handleArrayFieldAppend("includedItems", "");
                                        }}
                                        className="w-full mt-2"
                                    >
                                        Add Item
                                    </Button>
                                </div>
                            </AccordionContent>
                        </AccordionItem>

                        {/* Not Included Items */}
                        <AccordionItem value="notIncludedItems">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <X className="w-5 h-5 mr-2" />
                                Not Included Items
                            </AccordionTrigger>
                            <AccordionContent>
                                <div className="space-y-2">
                                    {notIncludedItemsFields.map((field, index) => (
                                        <div key={field.id} className="flex items-center space-x-2">
                                            <Controller
                                                name={`notIncludedItems.${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        className="flex-grow"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleArrayFieldChange("notIncludedItems", index, e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => {
                                                    removeNotIncludedItem(index);
                                                    handleArrayFieldRemove("notIncludedItems", index);
                                                }}
                                            >
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={() => {
                                            appendNotIncludedItem("");
                                            handleArrayFieldAppend("notIncludedItems", "");
                                        }}
                                        className="w-full mt-2"
                                    >
                                        Add Item
                                    </Button>
                                </div>
                            </AccordionContent>
                        </AccordionItem>

                        {/* Accessibility */}
                        <AccordionItem value="accessibility">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <Accessibility className="w-5 h-5 mr-2" />
                                Accessibility
                            </AccordionTrigger>
                            <AccordionContent>
                                <Controller
                                    name="accessibility"
                                    control={control}
                                    render={({ field }) => {
                                        console.log(accessibilityOptions, field.value);

                                        return (
                                            <MultiSelect
                                                options={accessibilityOptions}
                                                selectedValues={field.value ?? []}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                    handleFieldChange("accessibility", value);
                                                }}
                                                placeholder="Select accessibility options"
                                            />
                                        );
                                    }}
                                />
                            </AccordionContent>
                        </AccordionItem>

                        {/* Seasonality */}
                        <AccordionItem value="seasonality">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <Sun className="w-5 h-5 mr-2" />
                                Seasonality
                            </AccordionTrigger>
                            <AccordionContent>
                                <Controller
                                    name="seasonality"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            onValueChange={(value) => {
                                                field.onChange(value);
                                                handleFieldChange("seasonality", value);
                                            }}
                                            value={field.value}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select seasonality" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.values(SeasonalityEnum.Values).map((option) => (
                                                    <SelectItem key={option} value={option}>
                                                        {option.replace(/_/g, " ")}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            </AccordionContent>
                        </AccordionItem>

                        {/* Food Options */}
                        <AccordionItem value="foodOptions">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <HandPlatter className="w-5 h-5 mr-2" />
                                Food Options
                            </AccordionTrigger>
                            <AccordionContent>
                                <Controller
                                    name="foodOptions"
                                    control={control}
                                    render={({ field }) => (
                                        <FoodOptionsSelect
                                            selectedOptions={field.value ?? []}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleFieldChange("foodOptions", value);
                                            }}
                                        />
                                    )}
                                />
                            </AccordionContent>
                        </AccordionItem>

                        {/* Category Tags */}
                        <AccordionItem value="categories">
                            <AccordionTrigger className="text-lg font-semibold text-primary">
                                <Tag className="w-5 h-5 mr-2" />
                                Category Tags
                            </AccordionTrigger>
                            <AccordionContent>
                                <Controller
                                    name="categories"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <CategoryTagsSelect
                                            selectedCategories={field.value || []}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleFieldChange("categories", value);
                                            }}
                                        />
                                    )}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </form>
            </motion.div>
        </div>
    );
};

export const DraggableImage = ({ image, index, moveImage, removeImage }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: image.id });
    const moveHandleRef = useRef(null);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1000 : 1,
        opacity: isDragging ? 0.6 : 1,
    };

    const handleMoveClick = (direction) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        moveImage(index, index + direction);
    };

    const handleDeleteClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        removeImage(image.id);
    };

    const sharedMotionProps = { initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: 20 }, transition: { duration: 0.2 } };

    useEffect(() => {
        const moveHandle = moveHandleRef.current;
        if (!moveHandle) return;

        const preventScroll = (e) => {
            e.preventDefault();
        };

        const enableScrollLock = () => {
            document.addEventListener("touchmove", preventScroll, { passive: false });
            document.body.style.overflow = "hidden";
        };

        const disableScrollLock = () => {
            document.removeEventListener("touchmove", preventScroll);
            document.body.style.overflow = "";
        };

        moveHandle.addEventListener("mousedown", enableScrollLock);
        moveHandle.addEventListener("touchstart", enableScrollLock);

        document.addEventListener("mouseup", disableScrollLock);
        document.addEventListener("touchend", disableScrollLock);

        return () => {
            moveHandle.removeEventListener("mousedown", enableScrollLock);
            moveHandle.removeEventListener("touchstart", enableScrollLock);
            document.removeEventListener("mouseup", disableScrollLock);
            document.removeEventListener("touchend", disableScrollLock);
            disableScrollLock();
        };
    }, []);

    return (
        <div className="relative">
            <div ref={moveHandleRef} {...attributes} {...listeners} className="absolute top-2 z-[100] left-2 text-white bg-black bg-opacity-50 rounded-full p-1">
                <Move className="h-5 w-5" />
            </div>
            <motion.div ref={setNodeRef} {...sharedMotionProps} className="relative bg-white rounded-lg shadow-md overflow-hidden group">
                <img
                    src={image.url}
                    alt={image.altText || `Tour image ${index + 1}`}
                    className="w-full h-40 object-cover transition-transform duration-200 group-hover:scale-105"
                    draggable={false}
                />

                <div className="absolute inset-0 z-10 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-200  flex items-center justify-center">
                    <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                        <Button variant="ghost" size="icon" className="text-white hover:bg-white hover:bg-opacity-20" onClick={handleMoveClick(-1)}>
                            <ChevronUp className="h-6 w-6" />
                        </Button>
                        <Button variant="ghost" size="icon" className="text-white hover:bg-white hover:bg-opacity-20" onClick={handleMoveClick(1)}>
                            <ChevronDown className="h-6 w-6" />
                        </Button>
                        <Button variant="ghost" size="icon" className="text-white hover:bg-white hover:bg-opacity-20" onClick={handleDeleteClick}>
                            <X className="h-6 w-6" />
                        </Button>
                    </div>
                </div>
            </motion.div>

            <motion.div style={style} {...attributes} {...listeners} {...sharedMotionProps} className="absolute inset-0 w-full h-full bg-transparent" />
        </div>
    );
};

const ImageUploadComponent = ({ onUpload, isUploading }) => {
    const handleChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            onUpload(event);
        }
    };

    return (
        <Card className="bg-white bg-opacity-80 backdrop-blur-sm">
            <CardContent className="p-4">
                <Label htmlFor="image-upload" className="cursor-pointer block">
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} className="border-2 border-dashed border-indigo-300 rounded-lg p-6 text-center">
                        <Upload className="mx-auto h-12 w-12 text-primary" />
                        <p className="mt-2 text-sm text-primary">Tap to upload or drag and drop</p>
                        <p className="text-xs text-primary">PNG, JPG, GIF up to 10MB</p>
                    </motion.div>
                    <input id="image-upload" type="file" multiple accept="image/*" onChange={handleChange} disabled={isUploading} className="hidden" />
                </Label>
            </CardContent>
        </Card>
    );
};

const ImageUploadStatus = ({ image, uploadProgress }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
            <div className="text-white text-center">
                <Loader2 className="animate-spin h-8 w-8 mb-2 mx-auto" />
                <p className="text-sm font-semibold">{uploadProgress}%</p>
            </div>
        </motion.div>
    );
};

export const MobileImagesUploadStep = () => {
    const { tourData, updateTourData } = useTourCreation();
    const [images, setImages] = useState([]);
    const [uploadingImages, setUploadingImages] = useState({});
    const [uploadProgress, setUploadProgress] = useState({});
    const { toast } = useToast();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (tourData.images && Array.isArray(tourData.images)) {
            setImages(tourData.images.sort((a, b) => a.order - b.order));
        }
    }, [tourData.images]);

    const uploadImage = useCallback(
        async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("order", images.length + 1);
            try {
                const response = await instance.post(`/v1/provider/tours/${tourData.id}/images`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prev) => {
                            console.log(`Upload progress for ${file.name}: ${percentCompleted}%`);
                            return { ...prev, [file.name]: percentCompleted };
                        });
                    },
                });
                if (!response.data) {
                    throw new Error("Failed to upload image");
                }

                console.log(`Upload completed for ${file.name}`);
                return response.data;
            } catch (error) {
                console.error("Error uploading image:", error);
                throw error;
            }
        },
        [tourData.id, images.length]
    );

    const handleImageUpload = useCallback(
        async (event) => {
            const files = Array.from(event.target.files);

            console.log(`Uploading ${files.length} files`);

            const newUploadingImages = {};
            const newUploadProgress = {};
            files.forEach((file) => {
                newUploadingImages[file.name] = true;
                newUploadProgress[file.name] = 0;
            });

            setUploadingImages(newUploadingImages);
            setUploadProgress(newUploadProgress);

            try {
                const uploadedImages = await Promise.all(
                    files.map(async (file) => {
                        const result = await uploadImage(file);
                        setUploadingImages((prev) => {
                            const updated = { ...prev, [file.name]: false };
                            console.log("Updated uploadingImages:", updated);
                            return updated;
                        });
                        return result;
                    })
                );

                setImages((prevImages) => {
                    const updatedImages = [...prevImages, ...uploadedImages].sort((a, b) => a.order - b.order);
                    console.log("Updated images:", updatedImages);
                    return updatedImages;
                });
                updateTourData({ images: [...images, ...uploadedImages].sort((a, b) => a.order - b.order) });
                toast({
                    title: "Success",
                    description: "Images uploaded successfully",
                });
            } catch (error) {
                console.error("Error in handleImageUpload:", error);
                toast({ title: "Error", description: "Failed to upload images", variant: "destructive" });
            } finally {
                setUploadingImages({});
                setUploadProgress({});
            }
        },
        [uploadImage, images, updateTourData, toast]
    );

    const removeImage = async (imageId) => {
        try {
            await instance.delete(`/v1/provider/tours/${tourData.id}/images/${imageId}`);

            setImages((prevImages) => {
                const newImages = prevImages.filter((img) => img.id !== imageId).map((img, index) => ({ ...img, order: index + 1 }));
                updateTourData({ images: newImages });
                return newImages;
            });

            toast({ title: "Success", description: "Image deleted successfully" });
        } catch (error) {
            console.error("Error deleting image:", error);
            toast({ title: "Error", description: "Failed to delete image", variant: "destructive" });
        }
    };

    const moveImage = useCallback(
        async (fromIndex, toIndex) => {
            if (toIndex < 0 || toIndex >= images.length) return;

            const newImages = arrayMove(images, fromIndex, toIndex).map((img, index) => ({ ...img, order: index + 1 }));

            setImages(newImages);
            updateTourData({ images: newImages });
        },
        [images, updateTourData]
    );

    const handleDragEnd = useCallback(
        (event) => {
            const { active, over } = event;

            if (active.id !== over.id) {
                const oldIndex = images.findIndex((item) => item.id === active.id);
                const newIndex = images.findIndex((item) => item.id === over.id);

                moveImage(oldIndex, newIndex);
            }
        },
        [images, moveImage]
    );

    const memoizedImages = useMemo(() => images, [images]);

    return (
        <div className="flex flex-col h-full p-4 bg-gradient-to-br from-blue-100 via-indigo-100 to-purple-100">
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-6 flex-grow overflow-y-auto">
                <h2 className="text-2xl font-bold text-primary mb-4">Upload Tour Images</h2>
                <ImageUploadComponent onUpload={handleImageUpload} isUploading={Object.values(uploadingImages).some((status) => status)} />

                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={memoizedImages.map((img) => img.id)}>
                        <AnimatePresence>
                            <motion.div className="grid grid-cols-2 gap-4" layout>
                                {memoizedImages.map((image, index) => (
                                    <div key={image.id} className="relative">
                                        <DraggableImage image={image} index={index} removeImage={removeImage} moveImage={moveImage} />
                                        {uploadingImages[image.name] && <ImageUploadStatus image={image} uploadProgress={uploadProgress[image.name]} />}
                                    </div>
                                ))}
                            </motion.div>
                        </AnimatePresence>
                    </SortableContext>
                </DndContext>
            </motion.div>
        </div>
    );
};

interface PreviewSectionProps {
    title: string;
    icon: React.ReactNode;
    children: React.ReactNode;
}

export const PreviewSection: React.FC<PreviewSectionProps> = ({ title, icon, children }) => {
    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Accordion type="single" collapsible>
                <AccordionItem value={title}>
                    <AccordionTrigger className="text-lg font-semibold">
                        <span className="flex items-center">
                            {icon}
                            <span className="ml-2">{title}</span>
                        </span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <Card>
                            <CardContent className="pt-4">{children}</CardContent>
                        </Card>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </motion.div>
    );
};

interface PreviewNoticeProps {
    children: React.ReactNode;
}

export const PreviewNotice: React.FC<PreviewNoticeProps> = ({ children }) => {
    return <div className="text-center text-gray-500 italic">{children}</div>;
};

const MobileTourReviewStep: React.FC = () => {
    const { tourData } = useTourCreation();
    const [isCarouselOpen, setIsCarouselOpen] = useState(false);
    const [initialCarouselIndex, setInitialCarouselIndex] = useState(0);

    const openImageCarousel = (index: number) => {
        setInitialCarouselIndex(index);
        setIsCarouselOpen(true);
    };

    return (
        <div className="flex flex-col h-full p-6 bg-gradient-to-br overflow-hidden">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="space-y-6 flex-grow overflow-y-auto pb-20"
            >
                <motion.h2
                    className="text-3xl font-bold mb-6 text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                >
                    Review Your Amazing Tour
                </motion.h2>

                <PreviewSection title="Tour Images" icon={<ImageIcon className="w-5 h-5 text-pink-500" />}>
                    {tourData.images && tourData.images.length > 0 ? (
                        <div className="grid grid-cols-3 gap-2">
                            {tourData.images.map((image, index) => (
                                <div key={index} className="aspect-w-1 aspect-h-1">
                                    <img
                                        src={image.preview ? image.preview : image.url}
                                        alt={`Tour image ${index + 1}`}
                                        className="w-full h-full object-cover rounded cursor-pointer"
                                        onClick={() =>
                                            openImageCarousel(
                                                index,
                                                tourData.images.map((img) => ({ src: img.preview ? img.preview : img.url }))
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <PreviewNotice>No tour images available</PreviewNotice>
                    )}
                </PreviewSection>

                {/* <PreviewSection title="Highlights" icon={<Camera className="w-5 h-5 text-purple-500" />}>
                    {tourData.additionalInfo && tourData.additionalInfo.highlights && tourData.additionalInfo.highlights.length > 0 ? (
                        <div className="space-y-6">
                            {tourData.additionalInfo.highlights.map((highlight, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                    className="border-b pb-4 last:border-b-0"
                                >
                                    <img
                                        src={highlight.photo}
                                        alt={`Highlight ${index + 1}`}
                                        className="w-full h-48 object-cover rounded-lg mb-2 cursor-pointer"
                                        onClick={() =>
                                            openImageCarousel(
                                                tourData.additionalInfo.highlights.map((h) => ({ src: h.photo, comment: h.commentary })),
                                                index
                                            )
                                        }
                                    />
                                    <p className="text-sm text-gray-600">{highlight.commentary}</p>
                                </motion.div>
                            ))}
                        </div>
                    ) : (
                        <PreviewNotice>No highlights available</PreviewNotice>
                    )}
                </PreviewSection> */}

                <PreviewSection title="Basic Info" icon={<Calendar className="w-5 h-5 text-blue-500" />}>
                    {tourData.name || tourData.description ? (
                        <div className="space-y-2">
                            {tourData.name && <h3 className="text-xl font-semibold text-blue-700">{tourData.name}</h3>}
                            {tourData.description && <p className="text-gray-600">{tourData.description}</p>}
                            <p>
                                <span className="font-semibold">Active:</span> {tourData.isActive ? "Yes" : "No"}
                            </p>
                        </div>
                    ) : (
                        <PreviewNotice>Basic tour information not available</PreviewNotice>
                    )}
                </PreviewSection>

                <PreviewSection title="Duration & Price" icon={<Clock className="w-5 h-5 text-green-500" />}>
                    {tourData.durationType || tourData.duration || tourData.basePrice ? (
                        <div className="space-y-2">
                            {tourData.durationType && (
                                <p>
                                    <span className="font-semibold">Type:</span> {tourData.durationType}
                                </p>
                            )}
                            {tourData.duration && (
                                <p>
                                    <span className="font-semibold">Duration:</span> {tourData.duration.days} days, {tourData.duration.hours} hours,{" "}
                                    {tourData.duration.minutes} minutes
                                </p>
                            )}
                            {tourData.basePrice && tourData.currency && (
                                <p>
                                    <span className="font-semibold">Price:</span> {tourData.basePrice} {tourData.currency}
                                </p>
                            )}
                        </div>
                    ) : (
                        <PreviewNotice>Duration and price information not available</PreviewNotice>
                    )}
                </PreviewSection>
                <PreviewSection title="Participants" icon={<Users className="w-5 h-5 text-yellow-500" />}>
                    {tourData.maxParticipants || tourData.minAge ? (
                        <div className="space-y-2">
                            {tourData.maxParticipants && (
                                <p>
                                    <span className="font-semibold">Max Participants:</span> {tourData.maxParticipants}
                                </p>
                            )}
                            {tourData.minAge && (
                                <p>
                                    <span className="font-semibold">Minimum Age:</span> {tourData.minAge}+
                                </p>
                            )}
                        </div>
                    ) : (
                        <PreviewNotice>Participant information not available</PreviewNotice>
                    )}
                </PreviewSection>

                <PreviewSection title="Difficulty & Languages" icon={<Mountain className="w-5 h-5 text-red-500" />}>
                    {tourData.difficulty || (tourData.languages && tourData.languages.length > 0) ? (
                        <div className="space-y-2">
                            {tourData.difficulty && (
                                <p>
                                    <span className="font-semibold">Difficulty:</span> {tourData.difficulty}
                                </p>
                            )}
                            {tourData.languages && tourData.languages.length > 0 && (
                                <div className="flex flex-wrap gap-2">
                                    {tourData.languages.map((lang, index) => (
                                        <Badge key={index} variant="secondary">
                                            {lang}
                                        </Badge>
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : (
                        <PreviewNotice>Difficulty and language information not available</PreviewNotice>
                    )}
                </PreviewSection>

                <PreviewSection title="Meeting Point" icon={<MapPin className="w-5 h-5 text-indigo-500" />}>
                    {tourData.meetingPoint ? (
                        <p>{tourData.meetingPoint.location ? tourData.meetingPoint.location.address ?? "" : ""}</p>
                    ) : (
                        <PreviewNotice>Meeting point information not available</PreviewNotice>
                    )}
                </PreviewSection>
                {/* 
                <PreviewSection title="Tags" icon={<Tag className="w-5 h-5 text-purple-500" />}>
                    {tourData.tags && tourData.tags.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                            {tourData.tags.map((tag, index) => (
                                <Badge key={index} variant="outline">
                                    {tag}
                                </Badge>
                            ))}
                        </div>
                    ) : (
                        <PreviewNotice> No tags available</PreviewNotice>
                    )}
                </PreviewSection> */}

                <PreviewSection title="Itinerary" icon={<Globe className="w-5 h-5 text-indigo-500" />}>
                    {tourData.itinerary && tourData.itinerary.length > 0 ? (
                        <div className="space-y-4">
                            {tourData.itinerary.map((day, index) => (
                                <div key={index} className="border-b pb-2 last:border-b-0">
                                    <h4 className="font-semibold">{day.title}</h4>
                                    <p className="text-sm text-gray-600">{day.description}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <PreviewNotice> Itinerary information not available</PreviewNotice>
                    )}
                </PreviewSection>

                <PreviewSection title="Additional Info" icon={<Info className="w-5 h-5 text-purple-500" />}>
                    {tourData.additionalInfo ? (
                        <div className="space-y-6">
                            {tourData.additionalInfo.includedItems && tourData.additionalInfo.includedItems.length > 0 && (
                                <InteractiveItemList
                                    title="Included Items"
                                    items={tourData.additionalInfo.includedItems}
                                    icon={<Check className="w-5 h-5 text-green-500" />}
                                    type="included"
                                />
                            )}

                            {tourData.additionalInfo.notIncludedItems && tourData.additionalInfo.notIncludedItems.length > 0 && (
                                <InteractiveItemList
                                    title="Not Included Items"
                                    items={tourData.additionalInfo.notIncludedItems}
                                    icon={<X className="w-5 h-5 text-red-500" />}
                                    type="notIncluded"
                                />
                            )}

                            {tourData.additionalInfo.whatToBring && tourData.additionalInfo.whatToBring.length > 0 && (
                                <InteractiveItemList
                                    title="What to Bring"
                                    items={tourData.additionalInfo.whatToBring}
                                    icon={<ShoppingBag className="w-5 h-5 text-blue-500" />}
                                    type="toBring"
                                />
                            )}

                            {tourData.additionalInfo.categoryTags && tourData.additionalInfo.categoryTags.length > 0 && (
                                <div>
                                    <h4 className="font-semibold mb-2">Category Tags</h4>
                                    <div className="flex flex-wrap gap-2">
                                        {tourData.additionalInfo.categoryTags.map((cat: CategoryOption, index: number) => (
                                            <Badge key={index} variant="outline">
                                                {cat.name}
                                            </Badge>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {tourData.additionalInfo.accessibility && tourData.additionalInfo.accessibility.length > 0 && (
                                <div>
                                    <h4 className="font-semibold mb-2">Accessibility</h4>
                                    <ul className="list-disc list-inside text-sm text-gray-600">
                                        {tourData.additionalInfo.accessibility.map((item: string, index: number) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {tourData.additionalInfo.seasonality && (
                                <div>
                                    <h4 className="font-semibold flex items-center mb-2">
                                        <Calendar className="w-4 h-4 mr-2" />
                                        Seasonality
                                    </h4>
                                    <p className="text-sm text-gray-600">{tourData.additionalInfo.seasonality}</p>
                                </div>
                            )}

                            {tourData.additionalInfo.foodOptions && tourData.additionalInfo.foodOptions.length > 0 && (
                                <div>
                                    <h4 className="font-semibold flex items-center mb-2">
                                        <Utensils className="w-4 h-4 mr-2" />
                                        Food Options
                                    </h4>
                                    <div className="flex flex-wrap gap-2">
                                        {tourData.additionalInfo.foodOptions.map((option: string, index: number) => (
                                            <Badge key={index} variant="secondary">
                                                {option}
                                            </Badge>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </PreviewSection>

                <PreviewSection title="Pricing" icon={<CreditCard className="w-5 h-5 text-green-500" />}>
                    {tourData.pricing ? (
                        <div className="space-y-4">
                            {tourData.pricing.discountLevels && tourData.pricing.discountLevels.length > 0 && (
                                <div>
                                    <h4 className="font-semibold flex items-center mb-2">
                                        <Users className="w-4 h-4 mr-2 text-blue-500" />
                                        Group Discounts
                                    </h4>
                                    <Badge variant="outline">Available</Badge>
                                    {tourData.pricing.discountLevels.map((level, index) => (
                                        <p key={index} className="mt-2 text-sm text-gray-600">
                                            {level.people}+ people: {level.discount}% discount
                                        </p>
                                    ))}
                                </div>
                            )}

                            {tourData.pricing.earlyBirdDiscount && (
                                <div>
                                    <h4 className="font-semibold flex items-center mb-2">
                                        <Clock className="w-4 h-4 mr-2 text-blue-500" />
                                        Early Bird Discount
                                    </h4>
                                    <Badge variant="outline">Available</Badge>
                                    <p className="mt-2 text-sm text-gray-600">{tourData.pricing.earlyBirdDiscountPolicy}</p>
                                </div>
                            )}

                            {tourData.pricing.cancellationPolicy && (
                                <InteractiveItemList
                                    title="Cancellation Policy"
                                    items={[tourData.pricing.cancellationPolicy]}
                                    icon={<Ban className="w-5 h-5 text-red-500" />}
                                    type="included"
                                />
                            )}

                            {tourData.pricing.refundPolicy && tourData.pricing.refundPolicy.policies && (
                                <InteractiveItemList
                                    title="Refund Policy"
                                    items={tourData.pricing.refundPolicy.policies.map((policy) => policy.replace("_", " ").charAt(0).toUpperCase() + policy.slice(1))}
                                    icon={<RefreshCw className="w-5 h-5 text-green-500" />}
                                    type="included"
                                />
                            )}

                            {tourData.pricing.depositPolicy && (
                                <div>
                                    <h4 className="font-semibold flex items-center mb-2">
                                        <Percent className="w-4 h-4 mr-2 text-blue-500" />
                                        Deposit Policy
                                    </h4>
                                    <p className="text-sm text-gray-600">
                                        {tourData.pricing.depositPolicy.amount}% deposit required in {tourData.pricing.depositPolicy.currency}
                                    </p>
                                </div>
                            )}

                            {tourData.pricing.acceptedPayments && tourData.pricing.acceptedPayments.length > 0 && (
                                <InteractiveItemList
                                    title="Accepted Payments"
                                    items={tourData.pricing.acceptedPayments}
                                    icon={<CreditCard className="w-5 h-5 text-blue-500" />}
                                    type="included"
                                />
                            )}
                        </div>
                    ) : (
                        <PreviewNotice>Pricing information not available</PreviewNotice>
                    )}
                </PreviewSection>
            </motion.div>

            {isCarouselOpen && (
                <InteractiveImageCarousel
                    images={tourData.images.map((img) => ({
                        src: img.preview || img.url,
                    }))}
                    initialIndex={initialCarouselIndex}
                    onClose={() => setIsCarouselOpen(false)}
                />
            )}
        </div>
    );
};

const steps = [
    { component: MobileIntroStep, label: "Welcome" },
    { component: MobileBasicInfoStep, label: "Basic Info" },
    { component: MobileDurationDatesStep, label: "Duration" },
    { component: MobileLocationsStep, label: "Locations" },
    { component: MobileItineraryStep, label: "Itinerary" },
    { component: MobilePricingStep, label: "Pricing" },
    { component: MobileAdditionalInfoStep, label: "Additional info" },
    { component: MobileImagesUploadStep, label: "Images" },
    { component: MobileTourDetailsStep, label: "details" },
    { component: MobileLanguagesStep, label: "Languages" },
    { component: MobileTourReviewStep, label: "Review your tour!" },
];

export { steps };

// import { Button } from "@/components/ui/button";
// import React, { HTMLAttributes, MouseEventHandler, useContext, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";

// import { BaseRoutes } from "shared/helpers/const";
export const blank = "https://s3.timeweb.cloud/4498e8c1-adventurer-public/static/stock.jpg";

// import TourIdLabel from "@/default/tour-id-label";
// import { Label } from "@/components/ui/label";
// import { MoreHorizontal } from "lucide-react";
// import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
// import i18n from "entities/i18n/i18n";
// import {
//     AlertDialog,
//     AlertDialogAction,
//     AlertDialogCancel,
//     AlertDialogContent,
//     AlertDialogDescription,
//     AlertDialogFooter,
//     AlertDialogHeader,
//     AlertDialogTitle,
// } from "@/components/ui/alert-dialog";
// import { useToast } from "@/components/ui/use-toast";
// import { useOverlay } from "@/Overlay";

// type TourPreviewContextTypeDialog = { open: boolean; action: string; open_dialog: (action_: ActionEnum) => void; onOpenChange: () => void };
// type ProviderTourPreviewContextType = { id: string; dialog: TourPreviewContextTypeDialog };

// enum ActionEnum {
//     CHANGE = "CHANGE",
//     DELETE = "DELETE",
//     PREVIEW = "PREVIEW",
// }
// const ProviderTourPreviewDefaultValue: ProviderTourPreviewContextType = {
//     id: "",
//     dialog: { open: false, action: "none", open_dialog: (action_: ActionEnum) => void 1, onOpenChange: () => void 1 },
// };

// const ProviderTourPreviewContext = React.createContext<ProviderTourPreviewContextType>(ProviderTourPreviewDefaultValue);

// const useProviderTourContext = () => useContext(ProviderTourPreviewContext);

// /**
//  * Can be changed to freeze
//  */
// function TourActionDelete({ id }: { id: string }) {
//     const overlay = useOverlay();
//     const validateError = useError();
//     const reload = useReload();
//     const onAction: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
//         overlay.open();

//         try {
//             const del = await delete_tour(id);
//         } catch (error) {
//             validateError(error);
//         }

//         reload();
//         setTimeout(() => {
//             overlay.close();
//         }, 500);
//     };
//     return (
//         <AlertDialogContent>
//             <AlertDialogHeader>
//                 <AlertDialogTitle>{i18n.t("common.sure")}</AlertDialogTitle>
//             </AlertDialogHeader>
//             <AlertDialogDescription>{i18n.t("provider.dialog.DeleteTourAction.description")}</AlertDialogDescription>
//             <AlertDialogFooter>
//                 <AlertDialogCancel>{i18n.t("common.no")}</AlertDialogCancel>
//                 <AlertDialogAction onClick={onAction} className="w-full">
//                     {i18n.t("common.yes")}
//                 </AlertDialogAction>
//             </AlertDialogFooter>
//         </AlertDialogContent>
//     );
// }

// function ProviderTourPreviewActions() {
//     const { id, dialog } = useProviderTourContext();

//     return (
//         <AlertDialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
//             <TourActionDelete id={id} />
//         </AlertDialog>
//     );
// }
// import { useNavigateToTour, useReload } from "shared/hooks/NavigationHooks";
// import { delete_tour } from "shared/api/signature";
// import { useError } from "shared/hooks/useError";
// import { Badge } from "@/components/ui/badge";
// import { cn } from "@/lib/utils";

// function ProviderTourPreviewAction() {
//     const [dropdown, setDropdown] = React.useState(false);
//     const { dialog, id } = useProviderTourContext();
//     const { toast } = useToast();

//     const { navigateToTour } = useNavigateToTour();

//     const click: MouseEventHandler<HTMLButtonElement> = (e) => {
//         e.stopPropagation();
//         e.preventDefault();
//     };

//     const handler: MouseEventHandler<HTMLDivElement> = (e) => {
//         e.preventDefault();
//         e.stopPropagation();

//         const dataset = e.currentTarget.dataset as { value: ActionEnum };
//         switch (dataset.value) {
//             case ActionEnum.DELETE:
//                 dialog.open_dialog(dataset.value);
//                 break;
//             case ActionEnum.CHANGE:
//                 navigateToTour(id);
//                 break;
//             case ActionEnum.PREVIEW:
//                 toast({ title: i18n.t("common.notReady.title"), description: i18n.t("common.notReady.description") });
//         }
//         setDropdown(false);
//     };

//     return (
//         <DropdownMenu open={dropdown} onOpenChange={() => setDropdown((prev) => !prev)} modal={false}>
//             <DropdownMenuTrigger asChild>
//                 <Button onClick={click} className="h-12" variant={"ghost"}>
//                     <MoreHorizontal />
//                 </Button>
//             </DropdownMenuTrigger>
//             <DropdownMenuContent>
//                 <DropdownMenuItem onClick={handler} data-value={ActionEnum.CHANGE}>
//                     изменить
//                 </DropdownMenuItem>
//                 <DropdownMenuItem onClick={handler} data-value={ActionEnum.DELETE}>
//                     удалить
//                 </DropdownMenuItem>
//                 <DropdownMenuItem onClick={handler} data-value={ActionEnum.PREVIEW}>
//                     предпросмотр
//                 </DropdownMenuItem>
//             </DropdownMenuContent>
//         </DropdownMenu>
//     );
// }

// import { TourStatus, TourStatusEnum } from "shared/types/api";
// import { Tour } from "shared/types/api";
// export function getBadgeColor(stage_: TourStatus): React.HTMLProps<HTMLElement>["className"] {
//     switch (stage_) {
//         case TourStatus.DRAFT:
//             return "bg-orange-300";
//         case TourStatus.PROCESSING:
//             return "bg-blue-300";
//         case TourStatus.PUBLISHED:
//             return "bg-green-300";
//         default:
//             return "";
//     }
// }
// function ProviderTourPreview({ tour }: { tour: Tour }) {
//     const { name = "Tour name", id = "test", status } = tour;
//     const ref = useRef<HTMLAnchorElement | null>(null);

//     const click = () => (ref.current ? ref.current.click() : null);
//     const img = tour.images[0] ? tour.images[0].url : blank;
//     const [dialogOpen, setDialogOpen] = React.useState(false);
//     const [action, setAction] = React.useState("none");
//     const open_dialog = (action_: string) => {
//         setDialogOpen(true);
//         setAction(action_);
//     };

//     const dialog: ProviderTourPreviewContextType["dialog"] = {
//         open: dialogOpen,
//         action: action,
//         open_dialog,
//         onOpenChange: () => setDialogOpen((prev) => !prev),
//     };

//     const route = BaseRoutes.PROVIDER_TOUR_DASHBOARD.replace(":id", tour.id ?? "");

//     return (
//         <ProviderTourPreviewContext.Provider value={{ id: tour.id, dialog }}>
//             <Link to={route} ref={ref} className="m-4 select-none">
//                 <div className="w-full h-[335px] max-h-[335px] md:h-full md:w-full md:max-w-[373px]  box-border md:max-h-[496px] bg-white hover:text-white text-black hover:bg-[#5FB7C5] flex flex-col group rounded-[6.76px] p-[14.67px] hover:pointer">
//                     <img
//                         src={img}
//                         className="w-[300px]  h-full max-h-[155px] md:max-w-[329px] md:max-h-[229px] rounded-[6.76px] self-center justify-center bg-sky-500/75"
//                     />

//                     <div className="flex-1 h-full pt-4">
//                         <div className="flex justify-between">
//                             <Label>{name == "" ? i18n.t("provider.TourComponent.unnamed") : name}</Label>
//                             <Badge className={cn("text-black", getBadgeColor(status), "group-hover:bg-white")}>{i18n.t("provider.TourComponent.stage." + status)}</Badge>
//                         </div>
//                         <div className="w-full justify-between flex mt-1">
//                             <TourIdLabel id={id} />
//                         </div>
//                     </div>
//                     <div className="center-h justify-between">
//                         <Button variant={"designed"} onClick={click} className="max-h-[42px] w-[138px] mb-[20px] mt-4 md:w-[205px] md:max-h-[63px]">
//                             Edit
//                         </Button>
//                         <ProviderTourPreviewAction />
//                     </div>
//                 </div>
//             </Link>
//             <ProviderTourPreviewActions />
//         </ProviderTourPreviewContext.Provider>
//     );
// }

// export default ProviderTourPreview;
import React, { HTMLAttributes, MouseEventHandler, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/components/ui/use-toast";
import { useOverlay } from "@/Overlay";
import { MoreHorizontal } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import { BaseRoutes } from "shared/helpers/const";
import TourIdLabel from "@/default/tour-id-label";
import i18n from "entities/i18n/i18n";
import { useNavigateToTour, useReload } from "shared/hooks/NavigationHooks";
import { delete_tour, instance } from "shared/api/signature";
import { useError } from "shared/hooks/useError";
import { cn } from "@/lib/utils";
import { PROVIDER_TOUR_API_ENDPOINTS, Tour, TourStatus, TourStatusEnum } from "shared/types/api";

type TourPreviewContextTypeDialog = { open: boolean; action: string; open_dialog: (action_: ActionEnum) => void; onOpenChange: () => void };
type ProviderTourPreviewContextType = { id: string; dialog: TourPreviewContextTypeDialog };

enum ActionEnum {
    CHANGE = "CHANGE",
    DELETE = "DELETE",
    PREVIEW = "PREVIEW",
}

const ProviderTourPreviewDefaultValue: ProviderTourPreviewContextType = {
    id: "",
    dialog: { open: false, action: "none", open_dialog: (action_: ActionEnum) => {}, onOpenChange: () => {} },
};

const ProviderTourPreviewContext = React.createContext<ProviderTourPreviewContextType>(ProviderTourPreviewDefaultValue);

const useProviderTourContext = () => useContext(ProviderTourPreviewContext);

function TourActionDelete({ id }: { id: string }) {
    const overlay = useOverlay();
    const validateError = useError();
    const reload = useReload();
    const onAction: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        overlay.open();
        try {
            await delete_tour(id);
        } catch (error) {
            validateError(error);
        }
        reload();
        setTimeout(() => {
            overlay.close();
        }, 500);
    };
    return (
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>{i18n.t("common.sure")}</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription>{i18n.t("provider.dialog.DeleteTourAction.description")}</AlertDialogDescription>
            <AlertDialogFooter>
                <AlertDialogCancel>{i18n.t("common.no")}</AlertDialogCancel>
                <AlertDialogAction onClick={onAction} className="w-full">
                    {i18n.t("common.yes")}
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    );
}

function ProviderTourPreviewActions() {
    const { id, dialog } = useProviderTourContext();

    return (
        <AlertDialog open={dialog.open} onOpenChange={dialog.onOpenChange}>
            <TourActionDelete id={id} />
        </AlertDialog>
    );
}

function ProviderTourPreviewAction() {
    const [dropdown, setDropdown] = React.useState(false);
    const { dialog, id } = useProviderTourContext();
    const { toast } = useToast();
    const { navigateToTour } = useNavigateToTour();

    const click: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handler: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const dataset = e.currentTarget.dataset as { value: ActionEnum };
        switch (dataset.value) {
            case ActionEnum.DELETE:
                dialog.open_dialog(dataset.value);
                break;
            case ActionEnum.CHANGE:
                navigateToTour(id);
                break;
            case ActionEnum.PREVIEW:
                toast({ title: i18n.t("common.notReady.title"), description: i18n.t("common.notReady.description") });
        }
        setDropdown(false);
    };

    return (
        <DropdownMenu open={dropdown} onOpenChange={() => setDropdown((prev) => !prev)} modal={false}>
            <DropdownMenuTrigger asChild>
                <Button onClick={click} className="h-12" variant={"ghost"}>
                    <MoreHorizontal />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem onClick={handler} data-value={ActionEnum.CHANGE}>
                    изменить
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handler} data-value={ActionEnum.DELETE}>
                    удалить
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handler} data-value={ActionEnum.PREVIEW}>
                    предпросмотр
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export function getBadgeColor(stage_: TourStatus): React.HTMLProps<HTMLElement>["className"] {
    switch (stage_) {
        case TourStatus.DRAFT:
            return "bg-orange-300";
        case TourStatus.PROCESSING:
            return "bg-blue-300";
        case TourStatus.PUBLISHED:
            return "bg-green-300";
        default:
            return "bg-indigo-300";
    }
}
import { Tooltip } from "@/components/ui/tooltip";

export const PublishButtonStd = ({ tourId }) => {
    const [canBePublished, setCanBePublished] = useState(false);
    const [reason, setReason] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { toast } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const checkPublishStatus = async () => {
            try {
                const response = await instance.get(PROVIDER_TOUR_API_ENDPOINTS.checkPublishStatus.url.replace(":tourId", tourId));
                if (response.data.ok) {
                    setCanBePublished(response.data.data.canBePublished);
                    setReason(response.data.data.reason || "");
                }
            } catch (error) {
                console.error("Error checking publish status:", error);
                toast({ title: "Error", description: "Failed to check publish status", variant: "destructive" });
            }
        };

        checkPublishStatus();
    }, [tourId]);

    const handlePublish = async () => {
        setIsLoading(true);
        try {
            const response = await instance.post(PROVIDER_TOUR_API_ENDPOINTS.publishTour.url.replace(":tourId", tourId));
            if (response.data.ok) {
                toast({ title: "Success", description: "Tour has been published successfully." });
                setTimeout(() => navigate("/provider/tours"), 500);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to publish the tour.",
                    variant: "destructive",
                });
            }
        } catch (error) {
            console.error("Error publishing tour:", error);
            toast({
                title: "Error",
                description: "An error occurred while publishing the tour.",
                variant: "destructive",
            });
            ``;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Tooltip content={reason} disabled={canBePublished}>
            <Button
                onClick={handlePublish}
                disabled={!canBePublished || isLoading}
                className="px-6 py-2 bg-gradient-to-r  from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 transition-all duration-300 transform hover:scale-105"
            >
                {isLoading ? "Publishing..." : "Publish"}
            </Button>
        </Tooltip>
    );
};
export function PublishButton({ id, canBePublished, status, onPublish }) {
    const { toast } = useToast();
    const overlay = useOverlay();

    const handlePublish = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!canBePublished) {
            toast({ title: "Cannot publish", description: "This tour cannot be published at the moment." });
            return;
        }

        overlay.open();

        try {
            const response = await instance.post(PROVIDER_TOUR_API_ENDPOINTS.publishTour.url.replace(":tourId", id));
            if (response.data.ok) {
                toast({ title: "Success", description: "Tour has been published successfully." });
                onPublish(response.data.data.status);
            } else {
                toast({ title: "Error", description: "Failed to publish the tour." });
            }
        } catch (error) {
            toast({ title: "Error", description: "An error occurred while publishing the tour." });
        } finally {
            overlay.close();
        }
    };

    if (status === TourStatus.PUBLISHED) {
        return null;
    }

    return (
        <Button variant={"outline"} onClick={handlePublish} type="button" className="w-1/2 ml-2" disabled={!canBePublished}>
            Publish
        </Button>
    );
}

function ProviderTourPreview({ tour }) {
    const { name = "Tour name", id = "test", status, images } = tour;
    const ref = useRef(null);
    const [currentStatus, setCurrentStatus] = useState(status);
    const [canBePublished, setCanBePublished] = useState(true);

    const click = () => ref.current?.click();
    const img = images[0]?.url || blank;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [action, setAction] = useState("none");

    const openDialog = (action) => {
        setDialogOpen(true);
        setAction(action);
    };

    const dialog = {
        open: dialogOpen,
        action,
        openDialog,
        onOpenChange: () => setDialogOpen((prev) => !prev),
    };

    const route = BaseRoutes.PROVIDER_TOUR_DASHBOARD.replace(":id", tour.id || "");

    const handlePublish = (newStatus) => {
        setCurrentStatus(newStatus);
    };

    return (
        <ProviderTourPreviewContext.Provider value={{ id: tour.id, dialog }}>
            <div className="m-4 w-full max-w-sm bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
                <Link to={route} ref={ref} className="block">
                    <div className="relative h-48">
                        <img src={img} alt={name} className="w-full h-full object-cover" />
                        <div className="absolute top-2 right-2">
                            <Badge
                                className={cn(
                                    "text-xs font-bold",
                                    getBadgeColor(currentStatus),
                                    !canBePublished && currentStatus !== TourStatus.PUBLISHED && "bg-red-100"
                                )}
                            >
                                {canBePublished || currentStatus === TourStatus.PUBLISHED ? i18n.t(`provider.TourComponent.stage.${currentStatus}`) : "Cannot Publish"}
                            </Badge>
                        </div>
                    </div>
                    <div className="p-4">
                        <h3 className="text-xl font-semibold mb-2">{name || i18n.t("provider.TourComponent.unnamed")}</h3>
                        <p className="text-sm text-gray-600 mb-4">ID: {id}</p>
                        <div className="flex justify-between items-center">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    click();
                                }}
                                className="w-1/2 mr-2"
                            >
                                Edit
                            </Button>
                            <PublishButton id={id} canBePublished={canBePublished} status={currentStatus} onPublish={handlePublish} />
                        </div>
                    </div>
                </Link>
            </div>
        </ProviderTourPreviewContext.Provider>
    );
}

export default ProviderTourPreview;

import { Button } from "@/components/ui/button";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { MapPin, Plus } from "lucide-react";
import { Tag, Edit2, Save, Trash2 } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { motion, AnimatePresence } from "framer-motion";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { useMemo } from "react";
import { useTags } from "pages/provider/tour_new_api/Dashboard/hooks/hooks";

/**
 * --------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 */

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface ItineraryDayLocationsProps {
    dayLocationIds: string[];
    allLocations: TourLocation[];
    onAddLocation: (locationId: string) => void;
    onRemoveLocation: (locationId: string) => void;
}

const ItineraryDayLocations: React.FC<ItineraryDayLocationsProps> = ({ dayLocationIds, allLocations = [], onAddLocation, onRemoveLocation }) => {
    const [selectValue, setSelectValue] = useState<string>("");
    const availableLocations = allLocations.filter((location) => !dayLocationIds.some((dayLoc) => dayLoc === location.location.id));

    const handleAddLocation = (locationId: string) => {
        onAddLocation(locationId);
        setSelectValue("");
    };

    return (
        <div className="space-y-2">
            <div className="flex items-center justify-between">
                <h5 className="text-sm font-semibold">Locations</h5>
                <Select value={selectValue} onValueChange={handleAddLocation}>
                    <SelectTrigger className="w-[200px]">
                        <SelectValue placeholder="Add location" />
                    </SelectTrigger>
                    <SelectContent>
                        {availableLocations.map((location) => (
                            <SelectItem key={location.id} value={location.location.id}>
                                {location.location.name}
                            </SelectItem>
                        ))}
                        {availableLocations.length == 0 && (
                            <SelectItem value="no!" disabled>
                                No available locations
                            </SelectItem>
                        )}
                    </SelectContent>
                </Select>
            </div>

            {dayLocationIds.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                    {dayLocationIds.map((id) => {
                        const tourLocation: TourLocation = allLocations.find((loc) => loc.location.id === id);
                        return (
                            <Badge key={tourLocation.id} variant="secondary" className="flex items-center">
                                <MapPin className="h-3 w-3 mr-1" />
                                {tourLocation.location.name}
                                <button type="button" className="ml-1 text-xs" onClick={() => onRemoveLocation(tourLocation.location.id)}>
                                    ×
                                </button>
                            </Badge>
                        );
                    })}
                </div>
            ) : (
                <p className="text-sm text-gray-500 italic">No locations added for this day.</p>
            )}
        </div>
    );
};

/**
 * --------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 */

interface ItineraryDayWrapperProps {
    dayIndex: number;
    dayLocationIds: string[];
    tourLocations: TourLocation[];
    onAddLocation: (dayIndex: number, locationId: string) => void;
    onRemoveLocation: (dayIndex: number, locationId: string) => void;
}

const ItineraryDayWrapper: React.FC<ItineraryDayWrapperProps> = React.memo(({ dayIndex, dayLocationIds, tourLocations, onAddLocation, onRemoveLocation }) => {
    const handleAddLocation = (locationId: string) => {
        onAddLocation(dayIndex, locationId);
    };
    const handleRemoveLocation = (locationId: string) => onRemoveLocation(dayIndex, locationId);
    return (
        <ItineraryDayLocations dayLocationIds={dayLocationIds} allLocations={tourLocations} onAddLocation={handleAddLocation} onRemoveLocation={handleRemoveLocation} />
    );
});

/**
 * --------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 */
/**
 * --------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 */

const TourLocationManagementWrapper = ({ tourId, initialTourLocations, setValue }) => {
    const { tourLocations, handleAddLocation, handleRemoveLocation } = useTourLocations(tourId, initialTourLocations);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [previewLocation, setPreviewLocation] = useState(null);
    const autocompleteRef = useRef(null);

    const handlePlaceSelected = (place) => {
        const newLocation: Location = {
            ...place,
            name: place.name || "",
            address: place.formatted_address || "",
            latitude: place.geometry ? (place.geometry.location ? place.geometry.location.lat().toFixed(6) : "50.00") : "50.00",
            longitude: place.geometry ? (place.geometry.location ? place.geometry.location.lng().toFixed(6) : "50.00") : "50.00",
            category: LocationCategory.OTHER,
            isCustom: false,
            googleTypes: place.types || [],
        };
        setSelectedPlace(newLocation);

        if (place.geometry?.location) {
            setPreviewLocation(newLocation);
        }
        if (autocompleteRef.current) {
            autocompleteRef.current.clearInput();
        }
    };

    const handleManualSearch = () => {
        setIsDialogOpen(true);
    };

    const handleAddSelected = async () => {
        if (previewLocation) {
            try {
                const newTourLocation = await handleAddLocation(previewLocation);
                setValue("tourLocations", { tourLocations: [...tourLocations, newTourLocation] });
                // resetState();
            } catch (error) {
                console.error("Error adding location:", error);
                // Handle error (e.g., show error message to user)
            }
        }
    };

    const handleCancelSelection = () => {
        resetState();
    };

    const handleUpdatePreview = (field, value) => {
        setPreviewLocation((prev) => (prev ? { ...prev, [field]: value } : null));
    };

    const handleDeleteTourLocation = async (locationId) => {
        try {
            const success = await handleRemoveLocation(locationId);
            if (success) {
                const updatedTourLocations = tourLocations.filter((tl) => tl.location.id !== locationId);
                setValue("tourLocations", { tourLocations: updatedTourLocations });
            }
        } catch (error) {
            console.error("Error deleting tour location:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    const resetState = () => {
        setIsDialogOpen(false);
        setSelectedPlace(null);
        setPreviewLocation(null);
        if (autocompleteRef.current) {
            autocompleteRef.current.clearInput();
        }
    };

    const handleAddFromFullScreen = async (newLocation) => {
        try {
            const newTourLocation = await handleAddLocation(newLocation);
            setValue("tourLocations", { tourLocations: [...tourLocations, newTourLocation] });
            resetState();
        } catch (error) {
            console.error("Error adding location from full screen:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <div className="space-y-4">
            <Card className="bg-gradient-to-r from-blue-100 to-green-100">
                <CardContent className="p-4">
                    <div className="flex md:space-x-2 space-y-2 md:space-y-0 flex-col md:flex-row">
                        <div className="flex-grow">
                            <Autocomplete ref={autocompleteRef} onPlaceSelected={handlePlaceSelected} placeholder="Search for a location" className="w-full" />
                        </div>
                        <Button type="button" onClick={handleManualSearch} className="bg-blue-500 hover:bg-blue-600 text-white">
                            <MapPin className="mr-2 h-4 w-4" />
                            Manual Search
                        </Button>
                    </div>

                    {previewLocation && (
                        <div className="mt-4 space-y-2 bg-white p-4 rounded-lg shadow">
                            <h3 className="font-semibold text-lg text-blue-600">{previewLocation.name}</h3>
                            <p className="text-gray-600">{previewLocation.address}</p>

                            <Select value={previewLocation.category} onValueChange={(value) => handleUpdatePreview("category", value)}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select a category" />
                                </SelectTrigger>
                                <SelectContent>
                                    {Object.values(LocationCategory).map((category) => (
                                        <SelectItem key={category} value={category}>
                                            {category}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <div className="flex justify-end space-x-2 mt-4">
                                <Button type="button" onClick={handleCancelSelection} className="bg-gray-500 hover:bg-gray-600 text-white">
                                    Cancel
                                </Button>
                                <Button type="button" onClick={handleAddSelected} className="bg-green-500 hover:bg-green-600 text-white">
                                    <Plus className="mr-2 h-4 w-4" />
                                    Add Selected
                                </Button>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="p-0 w-full max-w-full h-[100vh] max-h-[100vh] sm:max-w-full">
                    <FullScreenLocationInput
                        onLocationAdd={handleAddFromFullScreen}
                        onClose={() => setIsDialogOpen(false)}
                        initialLocation={selectedPlace ? previewLocation : null}
                    />
                </DialogContent>
            </Dialog>

            {tourLocations.map((tourLocation, index) => (
                <Card key={tourLocation.location.id || index} className="border border-gray-200 hover:border-[#5FB7C5] transition-colors">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <h3 className="font-semibold text-[#5FB7C5]">{tourLocation.location.name}</h3>
                                <p className="text-sm text-gray-500">{tourLocation.location.address}</p>
                            </div>
                            <Button variant="ghost" size="sm" onClick={() => handleDeleteTourLocation(tourLocation.location.id)}>
                                <Trash2 className="h-4 w-4 text-gray-400" />
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { LocationCategory } from "shared/types/api";
import Autocomplete from "pages/provider/tour_new_api/Autocomplete/Autocomplete";
import { FullScreenLocationInput } from "../../../locations/full-screen-location-input";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

import { useTourLocations } from "features/provider/useTourLocations";

const OldTourLocationManagementWrapper: React.FC<{
    tour: Tour;
    tourLocations: TourLocation[];
    setTourLocations: (updatedLocations: TourLocation[]) => void;
    globalTags: string[]; // All available tags across all locations
    setGlobalTags: (tags: string[]) => void; // Function to update global tags
}> = ({ tour, tourLocations, setTourLocations, globalTags, setGlobalTags }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [previewLocation, setPreviewLocation] = useState<TourLocation | null>(null);
    const [newTag, setNewTag] = useState("");
    const autocompleteRef = useRef<{ clearInput: () => void }>(null);

    const handleAddLocation = (newLocation: Location) => {
        if (newLocation) {
            const newTourLocation: TourLocation = { ...previewLocation, location: newLocation };
            setTourLocations([...tourLocations, newTourLocation]);

            // Update global tags
            const updatedGlobalTags = Array.from(new Set([...globalTags, ...previewLocation.tags]));
            setGlobalTags(updatedGlobalTags);

            resetState();
        }
    };

    const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
        setSelectedPlace(place);
        if (place.geometry?.location) {
            setPreviewLocation({
                id: place.place_id || "",
                tourId: tour.id,
                description: "",
                tags: [],
                location: {
                    name: place.name || "",
                    address: place.formatted_address || "",
                    latitude: place.geometry.location.lat().toFixed(6),
                    longitude: place.geometry.location.lng().toFixed(6),
                    category: LocationCategory.OTHER,
                    isCustom: false,
                    googleTypes: place.types || [],
                },
            });
        }
        if (autocompleteRef.current) {
            autocompleteRef.current.clearInput();
        }
    };

    const handleManualSearch = () => {
        setIsDialogOpen(true);
    };

    const handleAddSelected = () => {
        if (previewLocation) {
            handleAddLocation(previewLocation.location);
        }
    };

    const handleCancelSelection = () => {
        resetState();
    };

    const handleUpdatePreview = (field: keyof TourLocation, value: any) => {
        setPreviewLocation((prev) => (prev ? { ...prev, [field]: value } : null));
    };

    const handleUpdateTourLocation = (updatedTourLocation: TourLocation) => {
        setTourLocations(tourLocations.map((tl) => (tl.id === updatedTourLocation.id ? updatedTourLocation : tl)));

        // Update global tags
        const allTags = tourLocations.flatMap((tl) => tl.tags);
        const updatedGlobalTags = Array.from(new Set([...allTags, ...updatedTourLocation.tags]));
        setGlobalTags(updatedGlobalTags);
    };

    const handleDeleteTourLocation = (tourLocationId: string) => {
        setTourLocations(tourLocations.filter((tl) => tl.id !== tourLocationId));

        // Update global tags
        const remainingLocations = tourLocations.filter((tl) => tl.id !== tourLocationId);
        const remainingTags = remainingLocations.flatMap((tl) => tl.tags);
        const updatedGlobalTags = Array.from(new Set(remainingTags));
        setGlobalTags(updatedGlobalTags);
    };

    const resetState = () => {
        setIsDialogOpen(false);
        setSelectedPlace(null);
        setPreviewLocation(null);
        setNewTag("");
        if (autocompleteRef.current) {
            autocompleteRef.current.clearInput();
        }
    };

    const handleAddTag = useCallback(() => {
        if (newTag && previewLocation) {
            const updatedTags = [...previewLocation.tags, newTag];
            setPreviewLocation({ ...previewLocation, tags: updatedTags });
            setNewTag("");
        }
    }, [newTag, previewLocation]);

    const handleRemoveTag = useCallback(
        (tagToRemove: string) => {
            if (previewLocation) {
                const updatedTags = previewLocation.tags.filter((tag) => tag !== tagToRemove);
                setPreviewLocation({ ...previewLocation, tags: updatedTags });
            }
        },
        [previewLocation]
    );

    const handleSelectPredefinedTag = useCallback(
        (tag: string) => {
            if (previewLocation && !previewLocation.tags.includes(tag)) {
                const updatedTags = [...previewLocation.tags, tag];
                setPreviewLocation({ ...previewLocation, tags: updatedTags });
            }
        },
        [previewLocation]
    );

    const handleAddFromFullScreen = (newLocation: Location, previewData?: Partial<TourLocation>) => {
        const newTourLocation: TourLocation = {
            id: newLocation.address,
            tourId: tour.id,
            description: previewData?.description || "",
            tags: previewData?.tags || [],
            location: newLocation,
        };
        setTourLocations([...tourLocations, newTourLocation]);

        const updatedGlobalTags = Array.from(new Set([...globalTags, ...(previewData?.tags || [])]));
        setGlobalTags(updatedGlobalTags);

        resetState();
    };

    return (
        <div className="space-y-4">
            <Card className="bg-gradient-to-r from-blue-100 to-green-100">
                <CardContent className="p-4">
                    <div className="flex md:space-x-2 space-y-2 md:space-y-0 flex-col md:flex-row">
                        <div className="flex-grow">
                            <Autocomplete ref={autocompleteRef} onPlaceSelected={handlePlaceSelected} placeholder="Search for a location" className="w-full" />
                        </div>
                        <Button onClick={handleManualSearch} className="bg-blue-500 hover:bg-blue-600 text-white">
                            <MapPin className="mr-2 h-4 w-4" />
                            Manual Search
                        </Button>
                    </div>

                    {previewLocation && (
                        <div className="mt-4 space-y-2 bg-white p-4 rounded-lg shadow">
                            <h3 className="font-semibold text-lg text-blue-600">Selected Location: {previewLocation.location.name}</h3>
                            <p className="text-gray-600">{previewLocation.location.address}</p>
                            <Textarea
                                placeholder="Description"
                                value={previewLocation.description}
                                onChange={(e) => handleUpdatePreview("description", e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                            <Select
                                value={previewLocation.location.category}
                                onValueChange={(value) => handleUpdatePreview("location", { ...previewLocation.location, category: value as LocationCategory })}
                            >
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select a category" />
                                </SelectTrigger>
                                <SelectContent>
                                    {Object.values(LocationCategory).map((category) => (
                                        <SelectItem key={category} value={category}>
                                            {category}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <div className="mt-2">
                                <p className="font-medium text-gray-700 mb-2">Tags:</p>
                                <div className="flex flex-wrap gap-2 mb-2">
                                    {previewLocation.tags.map((tag, index) => (
                                        <Badge key={index} variant="secondary" className="px-2 py-1">
                                            {tag}
                                            <X className="ml-1 h-3 w-3 cursor-pointer" onClick={() => handleRemoveTag(tag)} />
                                        </Badge>
                                    ))}
                                </div>
                                <div className="flex gap-2">
                                    <Input placeholder="Add new tag" value={newTag} onChange={(e) => setNewTag(e.target.value)} className="flex-grow" />
                                    <Button onClick={handleAddTag} className="bg-purple-500 hover:bg-purple-600 text-white">
                                        Add Tag
                                    </Button>
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button className="bg-blue-500 hover:bg-blue-600 text-white">Select Tag</Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-64">
                                            <div className="grid gap-2">
                                                {[...new Set([...predefinedTags, ...globalTags])].map((tag) => (
                                                    <Button key={tag} variant="outline" className="justify-start" onClick={() => handleSelectPredefinedTag(tag)}>
                                                        {tag}
                                                    </Button>
                                                ))}
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>

                            <div className="flex justify-end space-x-2 mt-4">
                                <Button onClick={handleCancelSelection} className="bg-gray-500 hover:bg-gray-600 text-white">
                                    Cancel
                                </Button>
                                <Button onClick={handleAddSelected} className="bg-green-500 hover:bg-green-600 text-white">
                                    <Plus className="mr-2 h-4 w-4" />
                                    Add Selected
                                </Button>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="p-0 w-full max-w-full h-[100vh] max-h-[100vh] sm:max-w-full">
                    <FullScreenLocationInput
                        onLocationAdd={handleAddFromFullScreen}
                        onClose={() => setIsDialogOpen(false)}
                        initialLocation={
                            selectedPlace
                                ? {
                                      name: selectedPlace.name || "",
                                      address: selectedPlace.formatted_address || "",
                                      latitude: selectedPlace.geometry?.location?.lat().toFixed(6) || "",
                                      longitude: selectedPlace.geometry?.location?.lng().toFixed(6) || "",
                                      category: LocationCategory.OTHER,
                                      isCustom: false,
                                      googleTypes: selectedPlace.types || [],
                                  }
                                : null
                        }
                    />
                </DialogContent>
            </Dialog>

            {tourLocations.map((tourLocation) => (
                <TourLocationManager
                    key={tourLocation.id}
                    tourLocation={tourLocation}
                    onUpdate={handleUpdateTourLocation}
                    onDelete={handleDeleteTourLocation}
                    globalTags={globalTags}
                />
            ))}
        </div>
    );
};
export { TourLocationManagementWrapper, ItineraryDayWrapper };

import { useState, useCallback } from "react";
import { instance } from "shared/api/signature";
import { API_ENDPOINTS, LocationSchema, TourLocationSchema } from "shared/types/api";

export const useTourLocations = (tourId: string, initialTourLocations = [], initialMeetingPoint = null) => {
    const [tourLocations, setTourLocations] = useState(initialTourLocations);
    const [meetingPoint, setMeetingPoint] = useState(initialMeetingPoint);

    const getLocations = useCallback(() => tourLocations.map((tl) => tl.location), [tourLocations]);

    const handleAddLocation = async (locationData: any) => {
        try {
            const formattedLocation = LocationSchema.parse({
                name: locationData.name,
                address: locationData.address,
                formattedAddress: locationData.formatted_address,
                placeId: locationData.place_id,
                country: locationData.address_components.find((c: any) => c.types.includes("country"))?.long_name || "",
                locality: locationData.address_components.find((c: any) => c.types.includes("locality"))?.long_name || "",
                types: locationData.types,
                latitude: parseFloat(locationData.latitude),
                longitude: parseFloat(locationData.longitude),
                category: locationData.category,
                addressComponents: locationData.address_components.map((component: any) => ({
                    longName: component.long_name,
                    shortName: component.short_name,
                    types: component.types,
                })),
            });

            const tourLocationData = TourLocationSchema.parse({ order: tourLocations.length + 1, description: "", tags: [], location: formattedLocation });

            const response = await instance.post(API_ENDPOINTS.addLocationToTour.url.replace(":tourId", tourId), tourLocationData);

            setTourLocations((prev) => [...prev, response.data]);
            return response.data;
        } catch (error) {
            console.error("Error adding location:", error);
            throw error;
        }
    };

    const handleRemoveLocation = async (locationId: string) => {
        try {
            const response = await instance.delete(API_ENDPOINTS.removeTourLocation.url.replace(":tourId", tourId).replace(":locationId", locationId));

            if (response.data.ok) {
                setTourLocations((prev) => prev.filter((tl) => tl.location.id !== locationId));
                return true;
            } else {
                throw new Error("Failed to remove location");
            }
        } catch (error) {
            console.error("Error removing location:", error);
            throw error;
        }
    };

    const addMeetingPoint = async (locationData: any) => {
        try {
            const formattedLocation = LocationSchema.parse({
                name: locationData.name,
                address: locationData.address,
                formattedAddress: locationData.formatted_address,
                placeId: locationData.place_id,
                country: locationData.address_components.find((c: any) => c.types.includes("country"))?.long_name || "",
                locality: locationData.address_components.find((c: any) => c.types.includes("locality"))?.long_name || "",
                types: locationData.types,
                latitude: parseFloat(locationData.latitude),
                longitude: parseFloat(locationData.longitude),
                category: locationData.category,
                addressComponents: locationData.address_components.map((component: any) => ({
                    longName: component.long_name,
                    shortName: component.short_name,
                    types: component.types,
                })),
            });

            const tourLocationData = API_ENDPOINTS.updateMeetingPoint.body.parse({ location: formattedLocation, description: "", tags: [] });

            const response = await instance.post(API_ENDPOINTS.updateMeetingPoint.url.replace(":tourId", tourId), tourLocationData);
            setMeetingPoint(response.data);
            return response.data;
        } catch (error) {
            console.error("Error adding meeting point:", error);
            throw error;
        }
    };

    const removeMeetingPoint = async () => {
        try {
            const response = await instance.delete(API_ENDPOINTS.removeMeetingPoint.url.replace(":tourId", tourId));
            if (response.status === 200) {
                setMeetingPoint(null);
                return true;
            }
            return false;
        } catch (error) {
            console.error("Error removing meeting point:", error);
            throw error;
        }
    };

    return { tourLocations, getLocations, meetingPoint, handleAddLocation, handleRemoveLocation, addMeetingPoint, removeMeetingPoint };
};

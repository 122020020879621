import { ModalWindowProps } from "shared/providers/ModalWindow";

import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import GoogleButton from "features/default/GoogleAuth/GoogleAuth";
import LogInForm from "features/user/forms/LogInForm";
import { useGoogleContext } from "shared/providers/GoogleProvider";
import { Skeleton } from "@/components/ui/skeleton";
import { instance } from "shared/api/signature";
import { useToast } from "@/components/ui/use-toast";
import { setUserData } from "entities/store/user";

import { useAppDispatch } from "shared/helpers/utils";
import i18n from "entities/i18n/i18n";

const google_prefix = "https://www.googleapis.com/auth/";
const scopes_arr = ["user.gender.read", "user.phonenumbers.read", "user.birthday.read", "profile.language.read", "profile.agerange.read", "user.addresses.read"];
const scope_str = scopes_arr.map((s) => google_prefix + s).join(" ");

function LogInWithGoogle() {
    const { loaded } = useGoogleContext();
    const { toast } = useToast();
    const dispatch = useAppDispatch();

    const send = async (res: google.accounts.oauth2.CodeResponse) => {
        const req = await instance.post("/v1/auth/google", res);

        if (req.status == 201) {
            dispatch(setUserData(req.data));
        } else toast({ title: "Try other way of autrorization" });
    };
    const handle = () => {
        const t = google.accounts.oauth2.initCodeClient({
            client_id: "1090040288638-1qdkajfe8ivvrorp0usrqgmsdkl9vea0.apps.googleusercontent.com",
            scope: `profile ${scope_str}`,
            callback: (res) => send(res),
        });

        t.requestCode();
    };

    if (!loaded) return <Skeleton className="h-[70px] w-full" />;
    return <GoogleButton id="test" onClick={handle} />;
}

function LogInModal({ close }: ModalWindowProps) {
    return (
        <Dialog open>
            <DialogContent close={close} className="h-full md:h-auto">
                <DialogHeader>
                    <h1 className="text-xl">{i18n.t("header.login")}</h1>
                </DialogHeader>
                <LogInWithGoogle />
                <LogInForm close={close} />
            </DialogContent>
        </Dialog>
    );
}

export default LogInModal;

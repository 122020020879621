import { X } from "lucide-react";
import { getFileIconAndColor } from "../utils";

type UploadedImage = {
    file: File;
    url: string;
};

/**
 * Preview of uploaded image
 */
function UploadedImageComponent({ file, removeFile }: { file: UploadedImage; removeFile?: (file: File) => void }) {
    return (
        <div
            key={file.file.lastModified}
            className="flex justify-between gap-2 rounded-lg overflow-hidden border border-slate-100 group hover:pr-0 pr-2 hover:border-slate-300 transition-all"
        >
            <img className="flex items-center flex-1 p-2 max-w-[95%] max-h-[600px]" src={file.url} />
            {removeFile ? (
                <button onClick={() => removeFile(file.file)} className="bg-red-500 text-white transition-all items-center justify-center px-2 hidden group-hover:flex">
                    <X size={20} />
                </button>
            ) : null}
        </div>
    );
}

/**
 * ### Image preview component
 *
 * - Preview single image
 * - Preview of image list
 */

function UploadedImagePreview({ uploaded, removeFile }: { uploaded: UploadedImage | UploadedImage[]; removeFile?: (file: File) => void }) {
    const arr: UploadedImage[] = [];

    if (!Array.isArray(uploaded)) arr.push(uploaded);
    if (arr.length == 0) return null;
    return (
        <div>
            <p className="font-medium my-2 mt-6 text-muted-foreground text-sm">Uploaded Files</p>
            <div className="space-y-2 pr-3">
                {arr.map((file) => (
                    <UploadedImageComponent key={file.file.lastModified} removeFile={removeFile} file={file} />
                ))}
            </div>
        </div>
    );
}

function UploadedFiles({ uploadedFiles, removeFile }: { uploadedFiles: File[]; removeFile: (file: File) => void }) {
    if (uploadedFiles.length <= 0) return null;
    return (
        <div>
            <p className="font-medium my-2 mt-6 text-muted-foreground text-sm">Uploaded Files</p>
            <div className="space-y-2 pr-3">
                {uploadedFiles.map((file) => {
                    return (
                        <div
                            key={file.lastModified}
                            className="flex justify-between gap-2 rounded-lg overflow-hidden border border-slate-100 group hover:pr-0 pr-2 hover:border-slate-300 transition-all"
                        >
                            <div className="flex items-center flex-1 p-2">
                                <div className="text-white">{getFileIconAndColor(file).icon}</div>
                                <div className="w-full ml-2 space-y-1">
                                    <div className="text-sm flex justify-between">
                                        <p className="text-muted-foreground ">{file.name.slice(0, 25)}</p>
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={() => removeFile(file)}
                                className="bg-red-500 text-white transition-all items-center justify-center px-2 hidden group-hover:flex"
                            >
                                <X size={20} />
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export { UploadedImagePreview, UploadedFiles };

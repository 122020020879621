import SearchBar, { MainSearchBar, useSearchTours } from "@/SearchBar/SearchBar";

import MainPageAdt from "@/MainPage/Heading/MainPageAdt";
import BackgroundLinear from "@/MainPage/BackgroundLinear";
import { useSearchParams } from "react-router-dom";

function MainPageSearchContainer() {
    return (
        <div className="relative w-full flex flex-col justify-start items-center overflow-hidden z-0">
            <MainPageAdt>Wassap</MainPageAdt>
            <MainSearchBar />
            <div className="h-[84px]"></div>
            <BackgroundLinear />
        </div>
    );
}

export default MainPageSearchContainer;

"use client";

import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
/*   */

const labelVariants = cva("leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70", {
    variants: {
        variant: {
            default: "text-sm font-medium",
        },
        size: {
            default: "text-sm font-medium",
            small: "text-xs font-thin",
        },
    },
    defaultVariants: { variant: "default", size: "default" },
});

const Label = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, variant, size, ...props }, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ variant, size }), className)} {...props} />);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };

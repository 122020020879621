import React, { useRef, useEffect, useState, useCallback, useId } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { format, addMonths, startOfMonth, endOfMonth, eachDayOfInterval, isToday, isBefore, isWithinInterval, parseISO } from "date-fns";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { instance } from "shared/api/signature";
import { CurrencyEnum, PROVIDER_TOUR_API_ENDPOINTS, TOUR_LISTINGS_API_ENDPOINTS, TourListingsApiEndpoints, UpdateCreateListingSchema } from "shared/types/api";

const UpdateListingSchema = z.object({
    isActive: z.boolean(),
    maxParticipants: z.number().int().positive(),
    basePrice: z.number().positive(),
    mainCurrency: CurrencyEnum,
    currencyPrices: z.record(z.number().positive()),
});

type UpdateListingData = z.infer<typeof UpdateListingSchema>;

interface BulkUpdateDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (updateData: UpdateListingData) => Promise<void>;
    rangeStart: Date | null;
    rangeEnd: Date | null;
}

const BulkUpdateDialog: React.FC<BulkUpdateDialogProps> = ({ isOpen, onClose, onUpdate, rangeStart, rangeEnd }) => {
    const { control, handleSubmit, formState } = useForm<UpdateListingData>({
        resolver: zodResolver(UpdateListingSchema),
        defaultValues: { isActive: true, maxParticipants: 10, basePrice: 100, mainCurrency: CurrencyEnum.enum.USD, currencyPrices: { USD: 100, EUR: 85, RUB: 7500 } },
    });

    console.log(formState.defaultValues, "this");
    const onSubmit = async (data: UpdateListingData) => {
        await onUpdate(data);
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Bulk Update Tour Dates</DialogTitle>
                    <DialogDescription>
                        Update dates from {rangeStart && format(rangeStart, "MMMM d, yyyy")} to {rangeEnd && format(rangeEnd, "MMMM d, yyyy")}
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <Controller
                            name="isActive"
                            control={control}
                            render={({ field }) => (
                                <div className="flex items-center space-x-2">
                                    <Switch id="bulk-active" checked={field.value} onCheckedChange={field.onChange} />
                                    <Label htmlFor="bulk-active">Active</Label>
                                </div>
                            )}
                        />
                        <Controller
                            name="maxParticipants"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Label htmlFor="bulk-max-participants">Max Participants</Label>
                                    <Input id="bulk-max-participants" type="number" {...field} onChange={(e) => field.onChange(parseInt(e.target.value, 10))} />
                                </div>
                            )}
                        />
                        <Controller
                            name="basePrice"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Label htmlFor="bulk-base-price">Base Price</Label>
                                    <Input id="bulk-base-price" type="number" {...field} onChange={(e) => field.onChange(parseFloat(e.target.value))} step="0.01" />
                                </div>
                            )}
                        />
                        <Controller
                            name="mainCurrency"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Label htmlFor="bulk-main-currency">Main Currency</Label>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger id="bulk-main-currency">
                                            <SelectValue placeholder="Select currency" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value={CurrencyEnum.enum.USD}>USD</SelectItem>
                                            <SelectItem value={CurrencyEnum.enum.EUR}>EUR</SelectItem>
                                            <SelectItem value={CurrencyEnum.enum.RUB}>RUB</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            )}
                        />
                        {Object.values(CurrencyEnum.enum).map((currency) => (
                            <Controller
                                key={currency}
                                name={`currencyPrices.${currency}`}
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <Label htmlFor={`bulk-price-${currency}`}>Price ({currency})</Label>
                                        <Input
                                            id={`bulk-price-${currency}`}
                                            type="number"
                                            {...field}
                                            onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                            step="0.01"
                                        />
                                    </div>
                                )}
                            />
                        ))}
                    </div>
                    <DialogFooter>
                        <Button type="button" onClick={onClose} variant="outline">
                            Cancel
                        </Button>
                        <Button type="submit">Update Dates</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

type Listing = z.infer<TourListingsApiEndpoints["getListingById"]["response"]["shape"]["data"]>;
import { isSameMonth } from "date-fns";
import { cn } from "@/lib/utils";
import { Alert, AlertDescription } from "@/components/ui/alert";

const CompactCalendar: React.FC<{
    currentDate: Date;
    onPrevMonth: () => void;
    onNextMonth: () => void;
    renderMonthGrid: (month: Date) => React.ReactNode;
}> = ({ currentDate, onPrevMonth, onNextMonth, renderMonthGrid }) => {
    const months = Array.from({ length: 4 }, (_, i) => addMonths(currentDate, i));

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <Button onClick={onPrevMonth} variant="outline" size="sm">
                    <ChevronLeft className="h-4 w-4" />
                </Button>
                <h2 className="text-lg font-bold">{format(currentDate, "MMMM yyyy")}</h2>
                <Button onClick={onNextMonth} variant="outline" size="sm">
                    <ChevronRight className="h-4 w-4" />
                </Button>
            </div>
            <div className="grid md:grid-cols-2  md:gap-4">{months.map((month) => renderMonthGrid(month))}</div>
        </div>
    );
};

export const TourDatesManagementTab: React.FC<{ tourId: string }> = ({ tourId }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [isBulkUpdateDialogOpen, setIsBulkUpdateDialogOpen] = useState(false);
    const [isRangeSchedulingDialogOpen, setIsRangeSchedulingDialogOpen] = useState(false);
    const [rangeStart, setRangeStart] = useState<Date | null>(null);
    const [rangeEnd, setRangeEnd] = useState<Date | null>(null);
    const [isSelectingRange, setIsSelectingRange] = useState(false);
    const [listings, setListings] = useState<Listing[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string>("USD");

    const fetchListings = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await instance.get(TOUR_LISTINGS_API_ENDPOINTS.getListingsByDateRange.url, {
                params: { tourId, startDate: startOfMonth(currentDate), endDate: endOfMonth(addMonths(currentDate, 3)) },
            });
            setListings(response.data.data);
        } catch (error) {
            toast({ title: "Error", description: "Failed to fetch listings. Please try again.", variant: "destructive" });
        } finally {
            setIsLoading(false);
        }
    }, [tourId, currentDate]);

    useEffect(() => {
        fetchListings();
    }, [fetchListings]);

    const handleDateClick = (date: Date) => {
        if (isSelectingRange) {
            if (!rangeStart || (rangeStart && rangeEnd)) {
                setRangeStart(date);
                setRangeEnd(null);
            } else if (date > rangeStart) {
                setRangeEnd(date);
                setIsBulkUpdateDialogOpen(true);
            } else {
                setRangeStart(date);
                setRangeEnd(null);
            }
        } else {
            setSelectedDate(date);
        }
    };

    const handleBulkUpdate = async (updateData: Partial<Listing>) => {
        if (rangeStart && rangeEnd) {
            const allDatesInRange = eachDayOfInterval({ start: rangeStart, end: rangeEnd });

            const existingListingsMap = new Map(listings.map((listing) => [format(parseISO(listing.date), "yyyy-MM-dd"), listing]));

            const listingsToUpsert = allDatesInRange.map((date) => {
                const dateString = format(date, "yyyy-MM-dd");
                const existingListing = existingListingsMap.get(dateString);

                if (existingListing) {
                    return {
                        tourId,
                        id: existingListing.id,
                        updates: { ...updateData, date: existingListing.date, startTime: existingListing.startTime, endTime: existingListing.endTime },
                    };
                } else {
                    return { tourId, updates: { ...updateData, date: dateString, startTime: dateString, endTime: dateString } };
                }
            });

            try {
                const res = await instance.post(TOUR_LISTINGS_API_ENDPOINTS.bulkUpdateListings.url, {
                    listings: listingsToUpsert,
                });

                console.log(res.data, "responseeeee");

                await fetchListings();
                toast({ title: "Success", description: "Listings updated and created successfully." });
            } catch (error) {
                console.error("Error updating listings:", error);
                toast({
                    title: "Error",
                    description: "Failed to update listings. Please try again.",
                    variant: "destructive",
                });
            }

            setIsBulkUpdateDialogOpen(false);
            setRangeStart(null);
            setRangeEnd(null);
            setIsSelectingRange(false);
        }
    };

    const handleRangeSchedule = async (scheduledDates: Listing[]) => {
        try {
            for (const listing of scheduledDates) {
                await instance.post(TOUR_LISTINGS_API_ENDPOINTS.createListing.url.replace(":tourId", tourId), listing);
            }
            await fetchListings();
            toast({ title: "Success", description: "New listings scheduled successfully." });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to schedule new listings. Please try again.",
                variant: "destructive",
            });
        }
        setIsRangeSchedulingDialogOpen(false);
    };

    const DateCell = ({ date, month }) => {
        const listing = listings.find((l) => format(parseISO(l.date), "yyyy-MM-dd") === format(date, "yyyy-MM-dd"));
        const isPast = isBefore(date, new Date()) && !isToday(date);
        const isCurrentMonth = isSameMonth(date, month);
        const isInRange = rangeStart && rangeEnd && isWithinInterval(date, { start: rangeStart, end: rangeEnd });

        return (
            <div
                className={cn(
                    "h-12 sm:h-16 border-r border-b p-1 transition duration-100 ease-in-out",
                    isCurrentMonth ? "bg-white" : "bg-gray-50 text-gray-400",
                    isToday(date) && "bg-blue-50",
                    isPast && "bg-gray-100 text-gray-400",
                    listing?.isActive && "hover:bg-green-50",
                    !listing?.isActive && !isPast && "hover:bg-red-50",
                    isInRange && "bg-indigo-50"
                )}
                onClick={() => !isPast && handleDateClick(date)}
            >
                <div className="flex justify-between items-start">
                    <span className={cn("text-xs font-medium", isToday(date) && "text-blue-600")}>{format(date, "d")}</span>
                    {listing && (
                        <Badge variant={listing.isActive ? "success" : "destructive"} className="text-[8px] sm:text-[10px] px-1 py-0">
                            {listing.isActive ? "A" : "I"}
                        </Badge>
                    )}
                </div>
                {listing && (
                    <div className="mt-1 text-[8px] sm:text-[10px]">
                        <div className="font-semibold text-gray-700">
                            {listing.currencyPrices[selectedCurrency]} {selectedCurrency}
                        </div>
                        <div className="text-gray-500 hidden sm:block">
                            {listing.maxParticipants - listing.currentBookings}/{listing.maxParticipants}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const MonthGrid = ({ month }) => {
        const days = eachDayOfInterval({ start: startOfMonth(month), end: endOfMonth(month) });
        const startDayOfWeek = days[0].getDay();
        const id = useId();

        return (
            <div className="mb-4">
                <h3 className="text-sm font-semibold mb-2">{format(month, "MMMM yyyy")}</h3>
                <div className="grid grid-cols-7 border-l border-t">
                    {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
                        <div key={day + month.toString()} className="text-center text-[10px] sm:text-xs py-1 border-r border-b text-gray-500 bg-gray-50">
                            {day}
                        </div>
                    ))}
                    {Array.from({ length: startDayOfWeek }).map((_, index) => (
                        <div key={`empty-${index}-${month.toString()}`} className="border-r border-b" />
                    ))}
                    {days.map((day) => (
                        <DateCell key={day.toString() + id} date={day} month={month} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Tour Dates Management</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="mb-4 space-x-2">
                    <Button onClick={() => setIsSelectingRange(!isSelectingRange)}>{isSelectingRange ? "Cancel Range Selection" : "Select Date Range"}</Button>
                    <Button onClick={() => setIsRangeSchedulingDialogOpen(true)}>Schedule Date Range</Button>
                </div>

                <div className="mb-4">
                    <Label htmlFor="currency-select">Display Currency</Label>
                    <Select onValueChange={setSelectedCurrency} value={selectedCurrency}>
                        <SelectTrigger id="currency-select">
                            <SelectValue placeholder="Select currency" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="USD">USD</SelectItem>
                            <SelectItem value="EUR">EUR</SelectItem>
                            <SelectItem value="RUB">RUB</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <CompactCalendar
                        currentDate={currentDate}
                        onPrevMonth={() => setCurrentDate((prev) => addMonths(prev, -1))}
                        onNextMonth={() => setCurrentDate((prev) => addMonths(prev, 1))}
                        renderMonthGrid={(month) => <MonthGrid key={month.toString()} month={month} />}
                    />
                )}
            </CardContent>

            <DateManagementDialog
                listing={selectedDate ? listings.find((l) => format(parseISO(l.date), "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd")) : null}
                selectedDate={selectedDate}
                tourId={tourId}
                onClose={() => setSelectedDate(null)}
                onUpdate={async (updatedListing) => {
                    try {
                        await instance.put(TOUR_LISTINGS_API_ENDPOINTS.updateOrCreateListing.url.replace(":tourId", tourId), updatedListing);
                        await fetchListings();
                        toast({ title: "Success", description: "Listing updated successfully." });
                    } catch (error) {
                        toast({ title: "Error", description: "Failed to update listing. Please try again.", variant: "destructive" });
                    }
                }}
            />

            <BulkUpdateDialog
                isOpen={isBulkUpdateDialogOpen}
                onClose={() => {
                    setIsBulkUpdateDialogOpen(false);
                    setRangeStart(null);
                    setRangeEnd(null);
                    setIsSelectingRange(false);
                }}
                onUpdate={handleBulkUpdate}
                rangeStart={rangeStart}
                rangeEnd={rangeEnd}
            />

            <RangeSchedulingDialog
                isOpen={isRangeSchedulingDialogOpen}
                onClose={() => setIsRangeSchedulingDialogOpen(false)}
                onSchedule={handleRangeSchedule}
                tourId={tourId}
            />
        </Card>
    );
};

const DateManagementDialog: React.FC<{
    listing: Listing | null;
    selectedDate: Date | null;
    onClose: () => void;
    onUpdate: (listing: Listing) => Promise<void>;
    tourId: string;
}> = ({ listing, selectedDate, onClose, onUpdate, tourId }) => {
    const [bookings, setBookings] = useState<GetTourBookingsResponse["data"]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { control, handleSubmit, reset, setValue, watch } = useForm<Listing>({
        defaultValues: listing || {
            tourId,
            date: selectedDate ? format(selectedDate, "yyyy-MM-dd") : "",
            startTime: selectedDate ? format(selectedDate, "yyyy-MM-dd'T'09:00:00") : "",
            endTime: selectedDate ? format(selectedDate, "yyyy-MM-dd'T'17:00:00") : "",
            maxParticipants: 10,
            basePrice: 100,
            mainCurrency: "USD",
            currencyPrices: { USD: 100, EUR: 85, RUB: 7500 },
            isActive: true,
        },
    });

    const isPastDate = selectedDate ? isBefore(selectedDate, new Date()) : false;

    useEffect(() => {
        if (selectedDate) {
            setValue("date", format(selectedDate, "yyyy-MM-dd"));
            setValue("startTime", format(selectedDate, "yyyy-MM-dd'T'09:00:00"));
            setValue("endTime", format(selectedDate, "yyyy-MM-dd'T'17:00:00"));
        }
    }, [selectedDate, setValue]);

    useEffect(() => {
        if (listing) {
            reset(listing);
        }
    }, [listing, reset]);

    useEffect(() => {
        const fetchBookings = async () => {
            if (!tourId || (!listing?.id && !selectedDate) || isPastDate) return;

            setIsLoading(true);
            setError(null);

            try {
                const params = new URLSearchParams();
                if (listing?.id) params.append("listingId", listing.id);
                if (selectedDate) params.append("date", format(selectedDate, "yyyy-MM-dd"));

                const { data } = await instance.get(`${PROVIDER_TOUR_API_ENDPOINTS.getTourBookings.url.replace(":tourId", tourId)}?${params.toString()}`);

                if (data.ok && data.data) {
                    setBookings(data.data);
                } else {
                    setError("Failed to fetch bookings");
                }
            } catch (err) {
                setError("An error occurred while fetching bookings");
            } finally {
                setIsLoading(false);
            }
        };

        fetchBookings();
    }, [tourId, listing?.id, selectedDate, isPastDate]);

    const onSubmit = async (data: Listing) => {
        if (!isPastDate) {
            await onUpdate(data);
            onClose();
        }
    };

    if (!selectedDate) return null;

    return (
        <Dialog open={!!selectedDate} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>{listing?.id ? "Update Listing" : "Create New Listing"}</DialogTitle>
                    <DialogDescription>{format(selectedDate, "MMMM d, yyyy")}</DialogDescription>
                </DialogHeader>

                {isPastDate ? (
                    <Alert>
                        <AlertDescription>This date is in the past and cannot be edited.</AlertDescription>
                    </Alert>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-4">
                            <Controller
                                name="isActive"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex items-center space-x-2">
                                        <Switch id="availability" checked={field.value} onCheckedChange={field.onChange} />
                                        <Label htmlFor="availability">{field.value ? "Available" : "Not Available"}</Label>
                                    </div>
                                )}
                            />
                            <Controller
                                name="maxParticipants"
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <Label htmlFor="max-participants">Max Participants</Label>
                                        <Input id="max-participants" type="number" {...field} onChange={(e) => field.onChange(parseInt(e.target.value))} />
                                    </div>
                                )}
                            />
                            <Controller
                                name="basePrice"
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <Label htmlFor="base-price">Base Price</Label>
                                        <Input id="base-price" type="number" {...field} onChange={(e) => field.onChange(parseFloat(e.target.value))} step="0.01" />
                                    </div>
                                )}
                            />
                            <Controller
                                name="mainCurrency"
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <Label htmlFor="main-currency">Main Currency</Label>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger id="main-currency">
                                                <SelectValue placeholder="Select currency" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="USD">USD</SelectItem>
                                                <SelectItem value="EUR">EUR</SelectItem>
                                                <SelectItem value="RUB">RUB</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                            />
                            {["USD", "EUR", "RUB"].map((currency) => (
                                <Controller
                                    key={currency}
                                    name={`currencyPrices.${currency}`}
                                    control={control}
                                    render={({ field }) => (
                                        <div>
                                            <Label htmlFor={`price-${currency}`}>Price ({currency})</Label>
                                            <Input
                                                id={`price-${currency}`}
                                                type="number"
                                                {...field}
                                                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                                step="0.01"
                                            />
                                        </div>
                                    )}
                                />
                            ))}
                        </div>
                        {isLoading ? (
                            <div>Loading current bookings...</div>
                        ) : error ? (
                            <Alert variant="destructive">
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        ) : (
                            <div className="mt-4">
                                <h3 className="text-lg font-semibold">Current Bookings</h3>
                                {bookings.length === 0 ? (
                                    <p>No bookings for this date.</p>
                                ) : (
                                    <ul>
                                        {bookings.map((booking) => (
                                            <li key={booking.id}>
                                                {booking.travellerName} - {booking.participants} participant(s)
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        <DialogFooter className="mt-4">
                            <Button type="button" onClick={onClose} variant="outline">
                                Cancel
                            </Button>
                            <Button type="submit">{listing?.id ? "Update" : "Create"} Listing</Button>
                        </DialogFooter>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

const RangeSchedulingDialog: React.FC<{ isOpen: boolean; onClose: () => void; onSchedule: (scheduledDates: Listing[]) => Promise<void>; tourId: string }> = ({
    isOpen,
    onClose,
    onSchedule,
    tourId,
}) => {
    const { control, handleSubmit, watch } = useForm<{
        startDate: string;
        endDate: string;
        basePrice: number;
        maxParticipants: number;
        mainCurrency: "USD" | "EUR" | "RUB";
    }>({
        resolver: zodResolver(
            z.object({
                startDate: z.string(),
                endDate: z.string(),
                basePrice: z.number().positive(),
                maxParticipants: z.number().int().positive(),
                mainCurrency: z.enum(["USD", "EUR", "RUB"]),
            })
        ),
        defaultValues: {
            startDate: format(new Date(), "yyyy-MM-dd"),
            endDate: format(addMonths(new Date(), 1), "yyyy-MM-dd"),
            basePrice: 100,
            maxParticipants: 10,
            mainCurrency: "USD",
        },
    });

    const mainCurrency = watch("mainCurrency");

    const onSubmit = async (data: ReturnType<typeof watch>) => {
        const start = parseISO(data.startDate);
        const end = parseISO(data.endDate);
        const dateRange = eachDayOfInterval({ start, end });

        const scheduledDates: Listing[] = dateRange.map((date) => ({
            id: "", // This will be assigned by the backend
            tourId,
            date: format(date, "yyyy-MM-dd"),
            isActive: true,
            maxParticipants: data.maxParticipants,
            startTime: date,
            endTime: date,
            currentBookings: 0,
            basePrice: data.basePrice,
            mainCurrency: data.mainCurrency,
            currencyPrices: {
                USD: data.mainCurrency === "USD" ? data.basePrice : 1,
                EUR: data.mainCurrency === "EUR" ? data.basePrice : 1,
                RUB: data.mainCurrency === "RUB" ? data.basePrice : 1,
            },
        }));

        await onSchedule(scheduledDates);
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Schedule Tour Date Range</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <div className="flex space-x-4">
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex-1">
                                        <Label htmlFor="start-date">Start Date</Label>
                                        <Input id="start-date" type="date" {...field} />
                                    </div>
                                )}
                            />
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex-1">
                                        <Label htmlFor="end-date">End Date</Label>
                                        <Input id="end-date" type="date" {...field} />
                                    </div>
                                )}
                            />
                        </div>
                        <Controller
                            name="maxParticipants"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Label htmlFor="max-participants">Max Participants</Label>
                                    <Input id="max-participants" type="number" {...field} onChange={(e) => field.onChange(parseInt(e.target.value))} />
                                </div>
                            )}
                        />
                        <Controller
                            name="basePrice"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Label htmlFor="base-price">Base Price</Label>
                                    <Input id="base-price" type="number" {...field} onChange={(e) => field.onChange(parseFloat(e.target.value))} step="0.01" />
                                </div>
                            )}
                        />
                        <Controller
                            name="mainCurrency"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Label htmlFor="main-currency">Main Currency</Label>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <SelectTrigger id="main-currency">
                                            <SelectValue placeholder="Select currency" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="USD">USD</SelectItem>
                                            <SelectItem value="EUR">EUR</SelectItem>
                                            <SelectItem value="RUB">RUB</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            )}
                        />
                    </div>
                    <DialogFooter>
                        <Button type="button" onClick={onClose} variant="outline">
                            Cancel
                        </Button>
                        <Button type="submit">Schedule Tour Dates</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default TourDatesManagementTab;

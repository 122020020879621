import React, { createContext, useContext, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Ban, Compass, CreditCard, Globe, Loader2, Map, Percent, Plus, RefreshCw } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { TourCreationProvider, create_tour_req, useTourCreation } from "../State";
import { Camera } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
    Currency,
    DepositPolicyEnum,
    DifficultyEnum,
    DurationType,
    Language,
    PricingSchema,
    cancellationPolicies,
    difficultyLevels,
    languageOptions,
    paymentOptions,
    refundPolicies,
} from "shared/types/api";
import { Slider, SliderInput } from "@/components/ui/slider";
import { useTourLocations } from "features/provider/useTourLocations";
import { MeetingPointDashboard } from "../../Dashboard/components/entity-management/tour-entity-managent";
import { AddLocationMobileDialog } from "../../Dashboard/components/locations/full-screen-location-input";
import { useDebounce } from "react-use";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { instance } from "shared/api/signature";

import { Home, MapPin, Calendar, DollarSign, Info, Image, Eye, Clock, Users, Mountain, ChevronRight, ChevronLeft, Upload, X } from "lucide-react";
import { ChevronDown, ChevronUp } from "lucide-react";

interface Location {
    id: string;
    name: string;
    address: string;
    latitude: number;
    longitude: number;
    tags: string[];
    description: string;
}

interface ItineraryDay {
    dayNumber: number;
    title: string;
    description: string;
    locations: string[];
}

const steps = [
    { icon: "📝", label: "Basic Info" },
    { icon: "📍", label: "Locations" },
    { icon: "🗓️", label: "Itinerary" },
    { icon: "💰", label: "Pricing" },
    { icon: "🖼️", label: "Media" },
    { icon: "📋", label: "Additional Info" },
    { icon: "👁️", label: "Preview" },
];

const TourCreatorContent: React.FC = () => {
    const { step, setStep, tourData } = useTourCreation();
    const steps = [WelcomeAndOverview, BasicTourInformation, LocationsStep, ItineraryStep, PricingStep, MediaStep, PreviewStep];
    const CurrentStepComponent = steps[step];

    const last = steps.length - 1;
    const navigate = useNavigate();

    const updateUrlParams = (newStep: number) => {
        const params = new URLSearchParams(location.search);
        params.set("step", newStep.toString());
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    };
    const handleNext = () => {
        if (step < last) {
            const newStep = step + 1;
            setStep(newStep);
            updateUrlParams(newStep);
        }
    };

    const handlePrevious = () => {
        if (step > 0) {
            const newStep = step - 1;
            setStep(newStep);
            updateUrlParams(newStep);
        }
    };

    return (
        <div className="h-[100dvh] p-0 m-0 bg-gradient-to-b from-blue-50 to-green-50 flex flex-col">
            <div className="flex h-full">
                <div className="flex flex-col h-full w-full">
                    <ScrollArea className="flex-grow px-8 py-6">
                        <CurrentStepComponent />
                    </ScrollArea>
                    <div className="flex justify-start items-center space-x-4 p-4 pb-6">
                        <Button onClick={handlePrevious} disabled={step === 0} variant="outline" className="w-32">
                            <ChevronLeft className="mr-2 h-4 w-4" />
                            Previous
                        </Button>
                        <Button onClick={handleNext} disabled={step === steps.length - 1} className="w-32">
                            Next
                            <ChevronRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                </div>
                <PreviewPanel />
                <Bottombar />
            </div>

            {/* {step !== 0 && (
                <div className="flex justify-between mt-4 w-full p-4 pr-6 pl-6 absolute bottom-0">
                    <button onClick={handlePrevious} disabled={step === 0} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50">
                        Previous
                    </button>
                    {step !== last ? (
                        <Button onClick={handleNext} disabled={step === steps.length - 1} className="px-4 py-2  rounded-lg disabled:opacity-50">
                            Next
                        </Button>
                    ) : (
                        <motion.div className="fixed bottom-0 right-0 p-4 " initial={{ y: 100 }} animate={{ y: 0 }} transition={{ delay: 0.5, duration: 0.5 }}>
                            <PublishButtonStd tourId={tourData.id} />
                        </motion.div>
                    )}
                </div>
            )} */}
        </div>
    );
};

const DesktopTourCreator: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const initialStep = parseInt(params.get("step") || "0");

    return (
        <TourCreationProvider initialStep={initialStep}>
            <TourCreatorContent />
        </TourCreationProvider>
    );
};

import { Check } from "lucide-react";

const Bottombar: React.FC = () => {
    const { step: currentStep, setStep: setCurrentStep, tourData } = useTourCreation();
    const [hoveredStep, setHoveredStep] = useState<number | null>(null);

    const isStepComplete = (stepIndex: number) => {
        // This is a placeholder logic. Replace with actual completion check logic.
        return stepIndex < currentStep;
    };

    const isStepAccessible = (stepIndex: number) => {
        return stepIndex <= currentStep;
    };

    return (
        <motion.div
            className="z-[100] fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white rounded-full shadow-[0_4px_12px_rgba(94,192,203,0.25)] flex items-center h-14 px-2"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
            {steps.map((step, index) => (
                <motion.div
                    key={index}
                    className={`relative flex items-center h-10 rounded-full cursor-pointer 
            ${currentStep === index ? "bg-[#E1F5F7]" : "hover:bg-[#F0FAFB]"}
            ${!isStepAccessible(index) ? "opacity-50 cursor-not-allowed" : ""}
            transition-colors duration-200 ease-in-out`}
                    onHoverStart={() => setHoveredStep(index)}
                    onHoverEnd={() => setHoveredStep(null)}
                    onClick={() => isStepAccessible(index) && setCurrentStep(index)}
                >
                    <div className="w-10 h-10 flex items-center justify-center text-xl">{step.icon}</div>
                    <AnimatePresence>
                        {(hoveredStep === index || currentStep === index) && (
                            <motion.div
                                initial={{ width: 0, opacity: 0 }}
                                animate={{ width: "auto", opacity: 1 }}
                                exit={{ width: 0, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className="overflow-hidden whitespace-nowrap"
                            >
                                <span className="px-2 text-sm font-medium text-[#5EC0CB]">{step.label}</span>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {isStepComplete(index) && (
                        <div className="absolute -top-1 -right-1 w-4 h-4 bg-[#5EC0CB] rounded-full flex items-center justify-center">
                            <Check size={10} className="text-white" />
                        </div>
                    )}
                </motion.div>
            ))}
        </motion.div>
    );
};

const WelcomeAndOverview: React.FC = () => {
    const { tourData, updateTourData, setStep } = useTourCreation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleStartCreating = async () => {
        setIsLoading(true);

        try {
            const newTour = await create_tour_req();
            updateTourData(newTour.data);
            setStep(1);
            console.log("i was here!!!!!");
            navigate(`/provider/db/constructor/desktop?tourId=${newTour.data.id}&step=1`);
        } catch (error) {
            console.error("Error creating tour:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const isTourInProgress = !!tourData.id;

    return (
        <div className="max-w-4xl mx-auto p-6">
            <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                <h2 className="text-3xl font-bold mb-6 text-center">Welcome to Tour Creator</h2>
            </motion.div>

            <Card>
                <CardContent className="p-6">
                    <h3 className="text-xl font-semibold mb-4">Basic Tour Information</h3>
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="tourName">Tour Name</Label>
                            <Input id="tourName" value={tourData.name} onChange={(e) => updateTourData({ name: e.target.value })} placeholder="Enter your tour name" />
                        </div>

                        <div>
                            <Label htmlFor="tourDescription">Tour Description</Label>
                            <Textarea
                                id="tourDescription"
                                value={tourData.description}
                                onChange={(e) => updateTourData({ description: e.target.value })}
                                placeholder="Provide a description of your tour"
                                rows={4}
                            />
                        </div>
                    </div>
                    {!isTourInProgress && (
                        <Button onClick={handleStartCreating} disabled={isLoading} className="w-full mt-4">
                            {isLoading ? "Creating..." : "Start Creating Your Tour"}
                        </Button>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

const BasicTourInformation: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();
    const [selectedLanguages, setSelectedLanguages] = useState<Language[]>(tourData.languages || []);

    const handleDurationTypeChange = (value: DurationType) => {
        let newDuration = { ...tourData.duration };
        let newItinerary = [...tourData.itinerary];

        if (value === DurationType.SINGLE_DAY) {
            newDuration = { days: 1, hours: 8, minutes: 0 };
            newItinerary = newItinerary.slice(0, 1);
        } else if (tourData.durationType === DurationType.SINGLE_DAY) {
            newDuration = { days: 2, hours: 0, minutes: 0 };
            newItinerary.push({ dayNumber: 2, title: "", description: "", locationIds: [] });
        }

        updateTourData({ durationType: value, duration: newDuration, itinerary: newItinerary });
    };

    const handleDurationChange = (field: "days" | "hours", value: number) => {
        const newDuration = { ...tourData.duration, [field]: value };
        let newItinerary = [...tourData.itinerary];

        if (field === "days") {
            if (value > newItinerary.length) {
                for (let i = newItinerary.length + 1; i <= value; i++) {
                    newItinerary.push({ dayNumber: i, title: "", description: "", locationIds: [] });
                }
            } else if (value < newItinerary.length) {
                newItinerary = newItinerary.slice(0, value);
            }
        }

        updateTourData({ duration: newDuration, itinerary: newItinerary });
    };

    const toggleLanguage = (code: Language) => {
        setSelectedLanguages((prev) => (prev.includes(code) ? prev.filter((lang) => lang !== code) : [...prev, code]));
    };

    useEffect(() => {
        updateTourData({ languages: selectedLanguages });
    }, [selectedLanguages]);
    console.log(tourData, "data???");
    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Basic Tour Information</h2>

                    <div className="space-y-6">
                        <div>
                            <Label htmlFor="tourType" className="flex items-center">
                                <Clock className="w-5 h-5 mr-2" />
                                Tour Type
                            </Label>
                            <RadioGroup
                                value={tourData.durationType}
                                onValueChange={(value: DurationType) => handleDurationTypeChange(value)}
                                className="flex space-x-4 mt-2"
                            >
                                <div className="flex items-center space-x-2">
                                    <RadioGroupItem value={DurationType.SINGLE_DAY} id={DurationType.SINGLE_DAY} />
                                    <Label htmlFor={DurationType.SINGLE_DAY}>Single Day</Label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <RadioGroupItem value={DurationType.MULTI_DAY} id={DurationType.MULTI_DAY} />
                                    <Label htmlFor={DurationType.MULTI_DAY}>Multi-Day</Label>
                                </div>
                            </RadioGroup>
                        </div>

                        <div>
                            <Label className="flex items-center">
                                <Clock className="w-5 h-5 mr-2" />
                                Duration
                            </Label>
                            {tourData.durationType === DurationType.MULTI_DAY ? (
                                <div className="mt-2">
                                    <Label htmlFor="days">Days</Label>
                                    <SliderInput value={tourData.duration.days} min={2} max={30} setState={(v) => handleDurationChange("days", parseInt(v))} />
                                </div>
                            ) : (
                                <div className="mt-2">
                                    <Label htmlFor="hours">Hours</Label>
                                    <SliderInput value={tourData.duration.hours ?? 2} max={23} min={1} setState={(v) => handleDurationChange("hours", v)} />
                                </div>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="difficulty" className="flex items-center">
                                <Users className="w-5 h-5 mr-2" />
                                Difficulty Level
                            </Label>
                            <Select value={tourData.difficulty} onValueChange={(value: any) => updateTourData({ difficulty: value })}>
                                <SelectTrigger id="difficulty" className="mt-2">
                                    <SelectValue placeholder="Select difficulty" />
                                </SelectTrigger>
                                <SelectContent>
                                    {difficultyLevels.map((level) => (
                                        <SelectItem key={level.value} value={level.value}>
                                            {level.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        <div>
                            <Label htmlFor="minAge" className="flex items-center">
                                <Users className="w-5 h-5 mr-2" />
                                Minimum Age
                            </Label>
                            <Input
                                id="minAge"
                                type="number"
                                value={tourData.minAge}
                                onChange={(e) => updateTourData({ minAge: parseInt(e.target.value) })}
                                min={0}
                                className="mt-2"
                            />
                        </div>

                        <div>
                            <Label htmlFor="maxParticipants" className="flex items-center">
                                <Users className="w-5 h-5 mr-2" />
                                Maximum Participants
                            </Label>
                            <Input
                                id="maxParticipants"
                                type="number"
                                value={tourData.maxParticipants}
                                onChange={(e) => updateTourData({ maxParticipants: parseInt(e.target.value) })}
                                min={1}
                                className="mt-2"
                            />
                        </div>

                        <div>
                            <Label htmlFor="basePrice" className="flex items-center">
                                <DollarSign className="w-5 h-5 mr-2" />
                                Base Price
                            </Label>
                            <div className="flex space-x-4 mt-2">
                                <Input
                                    id="basePrice"
                                    type="number"
                                    value={tourData.basePrice}
                                    onChange={(e) => updateTourData({ basePrice: parseFloat(e.target.value) })}
                                    min={0}
                                />
                                <Select value={tourData.currency} onValueChange={(value: string) => updateTourData({ currency: value })}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Currency" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="USD">USD</SelectItem>
                                        <SelectItem value="EUR">EUR</SelectItem>
                                        <SelectItem value="GBP">GBP</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        {/* {Globe} */}
                        <div>
                            <Label className="flex items-center mb-2">
                                <Globe className="w-5 h-5 mr-2" />
                                Languages
                            </Label>
                            <div className="grid grid-cols-2 gap-4">
                                {languageOptions.map((lang) => (
                                    <Button
                                        key={lang.value}
                                        onClick={() => toggleLanguage(lang.value)}
                                        variant={selectedLanguages.includes(lang.value) ? "default" : "outline"}
                                        className="justify-start"
                                    >
                                        {lang.label}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

const useItineraryDay = (initialDay: ItineraryDay, onUpdate: (day: ItineraryDay) => void) => {
    const [day, setDay] = useState(initialDay);

    const updateDay = useCallback(
        (field: keyof ItineraryDay, value: any) => {
            setDay((prev) => {
                const updated = { ...prev, [field]: value };
                onUpdate(updated);
                return updated;
            });
        },
        [onUpdate]
    );

    const handleLocationChange = useCallback(
        (locationId: string) => {
            setDay((prev) => {
                const updatedLocationIds = prev.locationIds.includes(locationId) ? prev.locationIds.filter((id) => id !== locationId) : [...prev.locationIds, locationId];
                const updated = { ...prev, locationIds: updatedLocationIds };
                onUpdate(updated);
                return updated;
            });
        },
        [onUpdate]
    );

    return { day, updateDay, handleLocationChange };
};

const ItineraryDayContent: React.FC<{
    day: ItineraryDay;
    updateDay: (field: keyof ItineraryDay, value: any) => void;
    handleLocationChange: (locationId: string) => void;
    tourLocations: any[];
}> = React.memo(({ day, updateDay, handleLocationChange, tourLocations }) => (
    <div className="space-y-4">
        <Input value={day.title} onChange={(e) => updateDay("title", e.target.value)} placeholder="Day title" className="w-full" />
        <Textarea value={day.description} onChange={(e) => updateDay("description", e.target.value)} placeholder="Day description" className="w-full" rows={4} />
        <div>
            <Label className="text-sm font-medium mb-2">Locations</Label>
            <div className="space-y-2">
                {tourLocations?.map(({ location }) => (
                    <div key={location.id} className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            id={`location-${day.dayNumber}-${location.id}`}
                            checked={day.locationIds.includes(location.id)}
                            onChange={() => handleLocationChange(location.id)}
                            className="rounded"
                        />
                        <Label htmlFor={`location-${day.dayNumber}-${location.id}`} className="text-sm">
                            {location.name}
                        </Label>
                    </div>
                ))}
            </div>
        </div>
    </div>
));

const ItineraryStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();
    const [itinerary, setItinerary] = useState(() => {
        if (tourData.durationType === "SINGLE_DAY") {
            return tourData.itinerary.length > 0 ? [tourData.itinerary[0]] : [{ dayNumber: 1, title: "Tour Itinerary", description: "", locationIds: [] }];
        } else {
            const existingDays = tourData.itinerary;
            const totalDays = tourData.duration.days;
            const newDays = Array.from({ length: Math.max(0, totalDays - existingDays.length) }, (_, index) => ({
                dayNumber: existingDays.length + index + 1,
                title: `Day ${existingDays.length + index + 1}`,
                description: "",
                locationIds: [],
            }));
            return [...existingDays, ...newDays];
        }
    });

    const debouncedUpdateTourData = useCallback(
        (data: Partial<typeof tourData>) => {
            updateTourData(data);
        },
        [updateTourData]
    );

    useDebounce(
        () => {
            debouncedUpdateTourData({ itinerary });
        },
        500,
        [itinerary]
    );

    const updateItineraryDay = useCallback((index: number, updatedDay: ItineraryDay) => {
        setItinerary((prev) => prev.map((day, i) => (i === index ? updatedDay : day)));
    }, []);

    const addItineraryDay = useCallback(() => {
        const newDay: ItineraryDay = {
            dayNumber: itinerary.length + 1,
            title: `Day ${itinerary.length + 1}`,
            description: "",
            locationIds: [],
        };
        setItinerary((prev) => [...prev, newDay]);
    }, [itinerary]);

    const removeItineraryDay = useCallback((index: number) => {
        setItinerary((prev) => prev.filter((_, i) => i !== index));
    }, []);

    const memoizedTourLocations = useMemo(() => tourData.tourLocations, [tourData.tourLocations]);

    const renderItineraryDay = useCallback(
        (day: ItineraryDay, index: number) => {
            const { day: currentDay, updateDay, handleLocationChange } = useItineraryDay(day, (updatedDay) => updateItineraryDay(index, updatedDay));

            return (
                <Card key={day.dayNumber}>
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-semibold">Day {day.dayNumber}</h3>
                            {tourData.durationType !== "SINGLE_DAY" && (
                                <Button variant="ghost" size="sm" onClick={() => removeItineraryDay(index)}>
                                    <X className="h-4 w-4" />
                                </Button>
                            )}
                        </div>
                        <ItineraryDayContent day={currentDay} updateDay={updateDay} handleLocationChange={handleLocationChange} tourLocations={memoizedTourLocations} />
                    </CardContent>
                </Card>
            );
        },
        [memoizedTourLocations, updateItineraryDay, removeItineraryDay, tourData.durationType]
    );

    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Tour Itinerary</h2>

                    <div className="space-y-6">
                        {tourData.durationType === "SINGLE_DAY" ? (
                            renderItineraryDay(itinerary[0], 0)
                        ) : (
                            <Accordion type="single" collapsible className="w-full space-y-4">
                                {itinerary.map((day, index) => (
                                    <AccordionItem key={day.dayNumber} value={`day-${day.dayNumber}`}>
                                        <AccordionTrigger className="text-lg font-semibold">Day {day.dayNumber}</AccordionTrigger>
                                        <AccordionContent>{renderItineraryDay(day, index)}</AccordionContent>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        )}
                        {tourData.durationType !== "SINGLE_DAY" && (
                            <Button onClick={addItineraryDay}>
                                <Plus className="mr-2 h-4 w-4" />
                                Add Day
                            </Button>
                        )}
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import useEmblaCarousel from "embla-carousel-react";
import { DraggableImage } from "../Concept/Provider";

interface Highlight {
    photo: string;
    commentary: string;
}

interface Highlight {
    photo: string;
    commentary: string;
}

const ImageUploadComponent = ({ onUpload, isUploading }) => {
    const handleChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            onUpload(event);
        }
    };

    return (
        <Card>
            <CardContent className="p-4">
                <Label htmlFor="image-upload" className="cursor-pointer block">
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                        <p className="mt-2 text-sm text-gray-600">Click to upload or drag and drop</p>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                    </motion.div>
                    <input id="image-upload" type="file" multiple accept="image/*" onChange={handleChange} disabled={isUploading} className="hidden" />
                </Label>
            </CardContent>
        </Card>
    );
};

const ImageUploadStatus = ({ uploadProgress }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
            <div className="text-white text-center">
                <Loader2 className="animate-spin h-8 w-8 mb-2 mx-auto" />
                <p className="text-sm font-semibold">{uploadProgress}%</p>
            </div>
        </motion.div>
    );
};

export const MediaStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();
    const [images, setImages] = useState(tourData.images || []);
    const [uploadingImages, setUploadingImages] = useState({});
    const [uploadProgress, setUploadProgress] = useState({});
    const { toast } = useToast();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (tourData.images && Array.isArray(tourData.images)) {
            setImages(tourData.images.sort((a, b) => a.order - b.order));
        }
    }, [tourData.images]);

    const uploadImage = useCallback(
        async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("order", images.length + 1);
            try {
                const response = await instance.post(`/v1/provider/tours/${tourData.id}/images`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prev) => ({ ...prev, [file.name]: percentCompleted }));
                    },
                });
                if (!response.data) {
                    throw new Error("Failed to upload image");
                }
                return response.data;
            } catch (error) {
                console.error("Error uploading image:", error);
                throw error;
            }
        },
        [tourData.id, images.length]
    );

    const handleImageUpload = useCallback(
        async (event) => {
            const files = Array.from(event.target.files);
            const newUploadingImages = {};
            const newUploadProgress = {};
            files.forEach((file) => {
                newUploadingImages[file.name] = true;
                newUploadProgress[file.name] = 0;
            });

            setUploadingImages(newUploadingImages);
            setUploadProgress(newUploadProgress);

            try {
                const uploadedImages = await Promise.all(
                    files.map(async (file) => {
                        const result = await uploadImage(file);
                        setUploadingImages((prev) => ({ ...prev, [file.name]: false }));
                        return result;
                    })
                );

                setImages((prevImages) => {
                    const updatedImages = [...prevImages, ...uploadedImages].sort((a, b) => a.order - b.order);
                    return updatedImages;
                });
                updateTourData({ images: [...images, ...uploadedImages].sort((a, b) => a.order - b.order) });
                toast({
                    title: "Success",
                    description: "Images uploaded successfully",
                });
            } catch (error) {
                console.error("Error in handleImageUpload:", error);
                toast({ title: "Error", description: "Failed to upload images", variant: "destructive" });
            } finally {
                setUploadingImages({});
                setUploadProgress({});
            }
        },
        [uploadImage, images, updateTourData, toast]
    );

    const removeImage = async (imageId) => {
        try {
            await instance.delete(`/v1/provider/tours/${tourData.id}/images/${imageId}`);

            setImages((prevImages) => {
                const newImages = prevImages.filter((img) => img.id !== imageId).map((img, index) => ({ ...img, order: index + 1 }));
                updateTourData({ images: newImages });
                return newImages;
            });

            toast({ title: "Success", description: "Image deleted successfully" });
        } catch (error) {
            console.error("Error deleting image:", error);
            toast({ title: "Error", description: "Failed to delete image", variant: "destructive" });
        }
    };

    const moveImage = useCallback(
        async (fromIndex, toIndex) => {
            if (toIndex < 0 || toIndex >= images.length) return;

            const newImages = arrayMove(images, fromIndex, toIndex).map((img, index) => ({ ...img, order: index + 1 }));

            setImages(newImages);
            updateTourData({ images: newImages });
        },
        [images, updateTourData]
    );

    const handleDragEnd = useCallback(
        (event) => {
            const { active, over } = event;

            if (active.id !== over.id) {
                const oldIndex = images.findIndex((item) => item.id === active.id);
                const newIndex = images.findIndex((item) => item.id === over.id);

                moveImage(oldIndex, newIndex);
            }
        },
        [images, moveImage]
    );

    const memoizedImages = useMemo(() => images, [images]);

    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Tour Media</h2>

                    <div className="space-y-6">
                        <ImageUploadComponent onUpload={handleImageUpload} isUploading={Object.values(uploadingImages).some((status) => status)} />

                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={memoizedImages.map((img) => img.id)}>
                                <AnimatePresence>
                                    <motion.div className="grid grid-cols-4 gap-4" layout>
                                        {memoizedImages.map((image, index) => (
                                            <div key={image.id} className="relative">
                                                <DraggableImage image={image} index={index} removeImage={removeImage} moveImage={moveImage} />
                                                {uploadingImages[image.name] && <ImageUploadStatus uploadProgress={uploadProgress[image.name]} />}
                                            </div>
                                        ))}
                                    </motion.div>
                                </AnimatePresence>
                            </SortableContext>
                        </DndContext>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

import { ImageIcon, ShoppingBag, Utensils } from "lucide-react";

const PreviewStep: React.FC = () => {
    const { tourData } = useTourCreation();
    const [isCarouselOpen, setIsCarouselOpen] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState(0);

    const openImageCarousel = (index: number) => {
        setCarouselIndex(index);
        setIsCarouselOpen(true);
    };

    const Section: React.FC<{ title: string; icon: React.ReactNode; children: React.ReactNode }> = ({ title, icon, children }) => (
        <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2 flex items-center">
                {icon}
                <span className="ml-2">{title}</span>
            </h3>
            {children}
        </div>
    );

    const Notice: React.FC<{ children: React.ReactNode }> = ({ children }) => <p className="text-gray-500 italic">{children}</p>;

    const ItemList: React.FC<{ title: string; items: string[]; icon: React.ReactNode }> = ({ title, items, icon }) => (
        <div>
            <h4 className="font-semibold mb-2 flex items-center">
                {icon}
                <span className="ml-2">{title}</span>
            </h4>
            <ul className="list-disc list-inside">
                {items.map((item, index) => (
                    <li key={index} className="text-sm text-gray-600">
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );

    const ImageCarousel: React.FC = () => {
        const images = tourData.images || [];

        const nextImage = () => setCarouselIndex((prevIndex) => (prevIndex + 1) % images.length);
        const prevImage = () => setCarouselIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);

        return (
            <Dialog open={isCarouselOpen} onOpenChange={setIsCarouselOpen}>
                <DialogContent className="sm:max-w-[80vw] sm:max-h-[80vh]">
                    <div className="relative h-full">
                        <img crossOrigin="anonymous" src={images[carouselIndex]?.url} alt={`Tour image ${carouselIndex + 1}`} className="w-full h-full object-contain" />
                        <Button variant="outline" size="icon" className="absolute top-1/2 left-4 transform -translate-y-1/2" onClick={prevImage}>
                            <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button variant="outline" size="icon" className="absolute top-1/2 right-4 transform -translate-y-1/2" onClick={nextImage}>
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Tour Preview</h2>

                    <Section title="Tour Images" icon={<ImageIcon className="w-5 h-5 text-pink-500" />}>
                        {tourData.images && tourData.images.length > 0 ? (
                            <div className="grid grid-cols-4 gap-4">
                                {tourData.images.map((image, index) => (
                                    <div key={index} className="aspect-w-1 aspect-h-1">
                                        <img
                                            src={image.url}
                                            alt={`Tour image ${index + 1}`}
                                            className="w-full h-full object-cover rounded cursor-pointer"
                                            onClick={() => openImageCarousel(index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Notice>No tour images available</Notice>
                        )}
                    </Section>

                    <Section title="Basic Info" icon={<Calendar className="w-5 h-5 text-blue-500" />}>
                        {tourData.name || tourData.description ? (
                            <div className="space-y-2">
                                {tourData.name && <h3 className="text-xl font-semibold text-blue-700">{tourData.name}</h3>}
                                {tourData.description && <p className="text-gray-600">{tourData.description}</p>}
                                <p>
                                    <span className="font-semibold">Active:</span> {tourData.isActive ? "Yes" : "No"}
                                </p>
                            </div>
                        ) : (
                            <Notice>Basic tour information not available</Notice>
                        )}
                    </Section>

                    <Section title="Duration & Price" icon={<Clock className="w-5 h-5 text-green-500" />}>
                        {tourData.durationType || tourData.duration || tourData.basePrice ? (
                            <div className="space-y-2">
                                {tourData.durationType && (
                                    <p>
                                        <span className="font-semibold">Type:</span> {tourData.durationType}
                                    </p>
                                )}
                                {tourData.duration && (
                                    <p>
                                        <span className="font-semibold">Duration:</span> {tourData.duration.days} days, {tourData.duration.hours} hours
                                    </p>
                                )}
                                {tourData.basePrice && (
                                    <p>
                                        <span className="font-semibold">Price:</span> {tourData.basePrice} {tourData.currency}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <Notice>Duration and price information not available</Notice>
                        )}
                    </Section>

                    <Section title="Participants" icon={<Users className="w-5 h-5 text-yellow-500" />}>
                        {tourData.maxParticipants || tourData.minAge ? (
                            <div className="space-y-2">
                                {tourData.maxParticipants && (
                                    <p>
                                        <span className="font-semibold">Max Participants:</span> {tourData.maxParticipants}
                                    </p>
                                )}
                                {tourData.minAge && (
                                    <p>
                                        <span className="font-semibold">Minimum Age:</span> {tourData.minAge}+
                                    </p>
                                )}
                            </div>
                        ) : (
                            <Notice>Participant information not available</Notice>
                        )}
                    </Section>

                    <Section title="Difficulty & Languages" icon={<Mountain className="w-5 h-5 text-red-500" />}>
                        {tourData.difficulty || (tourData.languages && tourData.languages.length > 0) ? (
                            <div className="space-y-2">
                                {tourData.difficulty && (
                                    <p>
                                        <span className="font-semibold">Difficulty:</span> {tourData.difficulty}
                                    </p>
                                )}
                                {tourData.languages && tourData.languages.length > 0 && (
                                    <div className="flex flex-wrap gap-2">
                                        {tourData.languages.map((lang, index) => (
                                            <Badge key={index} variant="secondary">
                                                {lang}
                                            </Badge>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Notice>Difficulty and language information not available</Notice>
                        )}
                    </Section>

                    <Section title="Meeting Point" icon={<MapPin className="w-5 h-5 text-indigo-500" />}>
                        {tourData.meetingPoint ? (
                            <p>{tourData.meetingPoint.location ? tourData.meetingPoint.location.address : ""}</p>
                        ) : (
                            <Notice>Meeting point information not available</Notice>
                        )}
                    </Section>

                    <Section title="Itinerary" icon={<Globe className="w-5 h-5 text-indigo-500" />}>
                        {tourData.itinerary && tourData.itinerary.length > 0 ? (
                            <div className="space-y-4">
                                {tourData.itinerary.map((day, index) => (
                                    <div key={index} className="border-b pb-2 last:border-b-0">
                                        <h4 className="font-semibold">{day.title}</h4>
                                        <p className="text-sm text-gray-600">{day.description}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Notice>Itinerary information not available</Notice>
                        )}
                    </Section>

                    <Section title="Additional Info" icon={<Info className="w-5 h-5 text-purple-500" />}>
                        {tourData.additionalInfo ? (
                            <div className="space-y-6">
                                {tourData.additionalInfo.includedItems && tourData.additionalInfo.includedItems.length > 0 && (
                                    <ItemList title="Included Items" items={tourData.additionalInfo.includedItems} icon={<Check className="w-5 h-5 text-green-500" />} />
                                )}

                                {tourData.additionalInfo.notIncludedItems && tourData.additionalInfo.notIncludedItems.length > 0 && (
                                    <ItemList title="Not Included Items" items={tourData.additionalInfo.notIncludedItems} icon={<X className="w-5 h-5 text-red-500" />} />
                                )}

                                {tourData.additionalInfo.whatToBring && tourData.additionalInfo.whatToBring.length > 0 && (
                                    <ItemList
                                        title="What to Bring"
                                        items={tourData.additionalInfo.whatToBring}
                                        icon={<ShoppingBag className="w-5 h-5 text-blue-500" />}
                                    />
                                )}

                                {tourData.additionalInfo.categoryTags && tourData.additionalInfo.categoryTags.length > 0 && (
                                    <div>
                                        <h4 className="font-semibold mb-2">Category Tags</h4>
                                        <div className="flex flex-wrap gap-2">
                                            {tourData.additionalInfo.categoryTags.map((cat, index) => (
                                                <Badge key={index} variant="outline">
                                                    {cat.name}
                                                </Badge>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {tourData.additionalInfo.accessibility && tourData.additionalInfo.accessibility.length > 0 && (
                                    <ItemList title="Accessibility" items={tourData.additionalInfo.accessibility} icon={<Users className="w-5 h-5 text-purple-500" />} />
                                )}

                                {tourData.additionalInfo.seasonality && (
                                    <div>
                                        <h4 className="font-semibold flex items-center mb-2">
                                            <Calendar className="w-4 h-4 mr-2" />
                                            Seasonality
                                        </h4>
                                        <p className="text-sm text-gray-600">{tourData.additionalInfo.seasonality}</p>
                                    </div>
                                )}

                                {tourData.additionalInfo.foodOptions && tourData.additionalInfo.foodOptions.length > 0 && (
                                    <div>
                                        <h4 className="font-semibold flex items-center mb-2">
                                            <Utensils className="w-4 h-4 mr-2" />
                                            Food Options
                                        </h4>
                                        <div className="flex flex-wrap gap-2">
                                            {tourData.additionalInfo.foodOptions.map((option, index) => (
                                                <Badge key={index} variant="secondary">
                                                    {option}
                                                </Badge>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Notice>Additional information not available</Notice>
                        )}
                    </Section>

                    <Section title="Pricing" icon={<CreditCard className="w-5 h-5 text-green-500" />}>
                        {tourData.pricing ? (
                            <div className="space-y-4">
                                {tourData.pricing.discountLevels && tourData.pricing.discountLevels.length > 0 && (
                                    <div>
                                        <h4 className="font-semibold flex items-center mb-2">
                                            <Users className="w-4 h-4 mr-2 text-blue-500" />
                                            Group Discounts
                                        </h4>
                                        <Badge variant="outline">Available</Badge>
                                        {tourData.pricing.discountLevels.map((level, index) => (
                                            <p key={index} className="mt-2 text-sm text-gray-600">
                                                {level.people}+ people: {level.discount}% discount
                                            </p>
                                        ))}
                                    </div>
                                )}

                                {tourData.pricing.cancellationPolicy && (
                                    <ItemList title="Cancellation Policy" items={[tourData.pricing.cancellationPolicy]} icon={<Ban className="w-5 h-5 text-red-500" />} />
                                )}

                                {tourData.pricing.refundPolicy && tourData.pricing.refundPolicy.length > 0 && (
                                    <ItemList
                                        title="Refund Policy"
                                        items={tourData.pricing.refundPolicy.map((policy) => policy.charAt(0).toUpperCase() + policy.slice(1).replace("_", " "))}
                                        icon={<RefreshCw className="w-5 h-5 text-green-500" />}
                                    />
                                )}

                                {tourData.pricing.depositPolicy && (
                                    <div>
                                        <h4 className="font-semibold flex items-center mb-2">
                                            <Percent className="w-4 h-4 mr-2 text-blue-500" />
                                            Deposit Policy
                                        </h4>
                                        <p className="text-sm text-gray-600">
                                            {tourData.pricing.depositPolicy.type === "PERCENTAGE"
                                                ? `${tourData.pricing.depositPolicy.amount}% deposit required`
                                                : `${tourData.pricing.depositPolicy.amount} ${tourData.pricing.depositPolicy.currency} deposit required`}
                                        </p>
                                    </div>
                                )}

                                {tourData.pricing.acceptedPayments && tourData.pricing.acceptedPayments.length > 0 && (
                                    <ItemList
                                        title="Accepted Payments"
                                        items={tourData.pricing.acceptedPayments}
                                        icon={<CreditCard className="w-5 h-5 text-blue-500" />}
                                    />
                                )}
                            </div>
                        ) : (
                            <Notice>Pricing information not available</Notice>
                        )}
                    </Section>
                </CardContent>
            </Card>

            <ImageCarousel />
        </motion.div>
    );
};

export { DesktopTourCreator };

import { useEffect, useCallback } from "react";
import useGoogleMapsScript from "shared/hooks/hooks";

interface Location {
    id: string;
    name: string;
    address: string;
    latitude: number;
    longitude: number;
}

interface AutocompleteProps {
    onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
    placeholder?: string;
    className?: string;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ onPlaceSelected, placeholder = "Search for a location", className = "" }) => {
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const { loaded, error } = useGoogleMapsScript();

    const autocompleteService = useCallback(() => {
        if (!loaded) return null;
        return new google.maps.places.AutocompleteService();
    }, [loaded]);

    const placesService = useCallback(() => {
        if (typeof window === "undefined" || !loaded) return null;
        return new google.maps.places.PlacesService(document.createElement("div"));
    }, [loaded]);

    useEffect(() => {
        if (!input) {
            setSuggestions([]);
            return;
        }

        const fetchSuggestions = async () => {
            const service = autocompleteService();
            if (!service) return;

            setIsLoading(true);
            const request = { input };

            service.getPlacePredictions(request, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSuggestions(predictions);
                } else {
                    setSuggestions([]);
                }
                setIsLoading(false);
            });
        };

        const timer = setTimeout(fetchSuggestions, 300);
        return () => clearTimeout(timer);
    }, [input, autocompleteService]);

    const handleSelect = (placeId: string) => {
        const service = placesService();
        if (!service) return;

        service.getDetails({ placeId, fields: ["name", "formatted_address", "geometry"] }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.location) {
                onPlaceSelected(place);
                setInput(place.name || "");
                setShowSuggestions(false);
            }
        });
    };

    if (error) {
        return <div>Error loading Google Maps: {error.message}</div>;
    }

    return (
        <div className="relative">
            <div className="relative">
                <Input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onFocus={() => setShowSuggestions(true)}
                    placeholder={loaded ? placeholder : "Loading..."}
                    className={`${className} pr-10`}
                    disabled={!loaded}
                />
                <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
            {showSuggestions && suggestions.length > 0 && (
                <ul className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {suggestions.map((suggestion) => (
                        <li
                            key={suggestion.place_id}
                            className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                            onClick={() => handleSelect(suggestion.place_id)}
                        >
                            <span className="block truncate">{suggestion.description}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const LocationMap: React.FC<{ location: Location; onLocationChange: (location: Location) => void }> = ({ location, onLocationChange }) => {
    const mapRef = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);

    const { loaded, error } = useGoogleMapsScript();

    useEffect(() => {
        if (!loaded || !mapRef.current) return;

        const newMap = new google.maps.Map(mapRef.current, {
            center: { lat: location.latitude, lng: location.longitude },
            zoom: 15,
        });

        const newMarker = new google.maps.Marker({
            position: { lat: location.latitude, lng: location.longitude },
            map: newMap,
            draggable: true,
        });

        newMarker.addListener("dragend", () => {
            const position = newMarker.getPosition();
            if (position) {
                onLocationChange({
                    ...location,
                    latitude: position.lat(),
                    longitude: position.lng(),
                });
            }
        });

        setMap(newMap);
        setMarker(newMarker);

        return () => {
            if (newMarker) newMarker.setMap(null);
        };
    }, [loaded, location, onLocationChange]);

    useEffect(() => {
        if (map && marker) {
            const newPosition = new google.maps.LatLng(location.latitude, location.longitude);
            map.panTo(newPosition);
            marker.setPosition(newPosition);
        }
    }, [location, map, marker]);

    if (error) return <div>Error loading map</div>;
    if (!loaded) return <div>Loading map...</div>;

    return <div ref={mapRef} style={{ width: "100%", height: "300px" }} />;
};

import { Trash2, Search } from "lucide-react";
import { AnimatePresence } from "framer-motion";

const LocationsStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();
    const { tourLocations, getLocations, meetingPoint, handleAddLocation, handleRemoveLocation, addMeetingPoint, removeMeetingPoint } = useTourLocations(
        tourData.id,
        tourData.tourLocations,
        tourData.meetingPoint
    );

    const [selectedLocation, setSelectedLocation] = useState<any | null>(null);

    const locations = getLocations();

    const handleLocationAdd = async (locationData: any) => {
        try {
            const newTourLocation = await handleAddLocation(locationData);
            updateTourData({ tourLocations: [...tourLocations, newTourLocation] });
        } catch (error) {
            console.error("Error adding location:", error);
        }
    };

    const handleLocationRemove = async (locationId: string) => {
        try {
            const success = await handleRemoveLocation(locationId);
            if (success) {
                const updatedTourLocations = tourLocations.filter((tl) => tl.location.id !== locationId);
                updateTourData({ tourLocations: updatedTourLocations });
                if (selectedLocation && selectedLocation.id === locationId) {
                    setSelectedLocation(null);
                }
            }
        } catch (error) {
            console.error("Error removing location:", error);
        }
    };

    const handleMeetingPointChange = async (locationData: any | null) => {
        if (locationData === null) {
            await removeMeetingPoint();
            updateTourData({ meetingPoint: null });
        } else {
            const newMeetingPoint = await addMeetingPoint(locationData);
            updateTourData({ meetingPoint: newMeetingPoint });
        }
    };

    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Tour Locations</h2>

                    <div className="space-y-6">
                        <div>
                            <Label className="text-sm font-medium text-gray-700">Meeting Point</Label>
                            <MeetingPointDashboard value={meetingPoint?.location} onChange={handleMeetingPointChange} />
                        </div>

                        <div>
                            <Label className="text-sm font-medium text-gray-700">Tour Locations</Label>
                            <AddLocationMobileDialog
                                onLocationAdd={handleLocationAdd}
                                triggerElement={
                                    <Button className="w-full bg-[#ED8560] hover:bg-[#ED8560]/90 text-white mt-2">
                                        <Plus className="mr-2 h-4 w-4" /> Add Location
                                    </Button>
                                }
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-4">
                                <AnimatePresence>
                                    {locations.map((location) => (
                                        <motion.div
                                            key={location.id}
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.2 }}
                                        >
                                            <Card
                                                className={`cursor-pointer ${selectedLocation?.id === location.id ? "border-indigo-500" : ""}`}
                                                onClick={() => setSelectedLocation(location)}
                                            >
                                                <CardContent className="p-4">
                                                    <div className="flex justify-between items-start">
                                                        <div>
                                                            <h3 className="font-semibold text-[#5FB7C5]">{location.name}</h3>
                                                            <p className="text-sm text-gray-500">{location.address}</p>
                                                        </div>
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleLocationRemove(location.id);
                                                            }}
                                                        >
                                                            <Trash2 className="h-4 w-4 text-gray-400" />
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </motion.div>
                                    ))}
                                </AnimatePresence>
                            </div>
                            <div>
                                {selectedLocation && (
                                    <Card>
                                        <CardContent className="p-4">
                                            <h3 className="font-semibold mb-2">{selectedLocation.name}</h3>
                                            <LocationMap
                                                location={selectedLocation}
                                                onLocationChange={(updatedLocation) => {
                                                    // Handle location update if needed
                                                    console.log("Location updated:", updatedLocation);
                                                }}
                                            />
                                        </CardContent>
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

type PricingFormData = z.infer<typeof PricingSchema>;
import z from "zod";

const PricingStep: React.FC = () => {
    const { tourData, updateTourData } = useTourCreation();

    const form = useForm<PricingFormData>({
        resolver: zodResolver(PricingSchema),
        defaultValues: {
            basePrice: tourData.pricing?.basePrice ?? 100,
            mainCurrency: (tourData.pricing?.mainCurrency as Currency) || Currency.USD,
            currencyPrices: tourData.pricing?.currencyPrices || {},
            cancellationPolicy: tourData.pricing?.cancellationPolicy || "",
            refundPolicy: tourData.pricing?.refundPolicy || [],
            depositPolicy: tourData.pricing?.depositPolicy ?? { type: "NO_DEPOSIT", amount: 0, currency: Currency.USD },
            acceptedPayments: tourData.pricing?.acceptedPayments || [],
            discountLevels: tourData.pricing?.discountLevels || [],
        },
    });

    const { control, watch } = form;

    const handleFieldChange = (fieldName: keyof PricingFormData, value: any) => {
        const updatedData = { pricing: { ...tourData.pricing, [fieldName]: value } };
        updateTourData(updatedData);
    };

    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Pricing and Policies</h2>

                    <div className="space-y-6">
                        <div className="flex items-center space-x-4">
                            <div className="flex-grow">
                                <Label htmlFor="basePrice" className="text-sm font-medium">
                                    Base Price
                                </Label>
                                <div className="relative mt-1">
                                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                    <Controller
                                        name="basePrice"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                id="basePrice"
                                                type="number"
                                                {...field}
                                                onChange={(e) => {
                                                    const value = parseFloat(e.target.value);
                                                    field.onChange(value);
                                                    handleFieldChange("basePrice", value);
                                                }}
                                                className="pl-8"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label htmlFor="mainCurrency" className="text-sm font-medium">
                                    Currency
                                </Label>
                                <Controller
                                    name="mainCurrency"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            onValueChange={(value) => {
                                                field.onChange(value);
                                                handleFieldChange("mainCurrency", value);
                                            }}
                                            defaultValue={field.value}
                                        >
                                            <SelectTrigger id="mainCurrency" className="w-[100px]">
                                                <SelectValue placeholder="Currency" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.values(Currency).map((curr) => (
                                                    <SelectItem key={curr} value={curr}>
                                                        {curr}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            </div>
                        </div>

                        <Accordion type="single" collapsible className="w-full space-y-4">
                            <AccordionItem value="cancellationPolicy">
                                <AccordionTrigger className="text-lg font-semibold">
                                    <Ban className="w-5 h-5 mr-2" />
                                    Cancellation Policy
                                </AccordionTrigger>
                                <AccordionContent>
                                    <Controller
                                        name="cancellationPolicy"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                onValueChange={(value) => {
                                                    field.onChange(value);
                                                    handleFieldChange("cancellationPolicy", value);
                                                }}
                                                defaultValue={field.value}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select cancellation policy" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {cancellationPolicies.map((policy) => (
                                                        <SelectItem key={policy.id} value={policy.id}>
                                                            {policy.name}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        )}
                                    />
                                    {watch("cancellationPolicy") && (
                                        <p className="mt-2 text-sm text-gray-600">
                                            {cancellationPolicies.find((p) => p.id === watch("cancellationPolicy"))?.description}
                                        </p>
                                    )}
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value="refundPolicy">
                                <AccordionTrigger className="text-lg font-semibold">
                                    <RefreshCw className="w-5 h-5 mr-2" />
                                    Refund Policy
                                </AccordionTrigger>
                                <AccordionContent>
                                    {refundPolicies.map((policy) => (
                                        <div key={policy.id} className="flex items-center space-x-2 mb-2">
                                            <Controller
                                                name="refundPolicy"
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        id={`refund-${policy.id}`}
                                                        checked={field.value.includes(policy.id)}
                                                        onCheckedChange={(checked) => {
                                                            const updatedPolicies = checked ? [...field.value, policy.id] : field.value.filter((p) => p !== policy.id);
                                                            field.onChange(updatedPolicies);
                                                            handleFieldChange("refundPolicy", updatedPolicies);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Label htmlFor={`refund-${policy.id}`}>{policy.name}</Label>
                                        </div>
                                    ))}
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value="depositPolicy">
                                <AccordionTrigger className="text-lg font-semibold">
                                    <Percent className="w-5 h-5 mr-2" />
                                    Deposit Policy
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className="space-y-4">
                                        <Controller
                                            name="depositPolicy.type"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    onValueChange={(value) => {
                                                        field.onChange(value);
                                                        handleFieldChange("depositPolicy", { ...form.getValues("depositPolicy"), type: value });
                                                    }}
                                                    defaultValue={field.value}
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select deposit policy" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value={DepositPolicyEnum.NO_DEPOSIT}>No Deposit</SelectItem>
                                                        <SelectItem value={DepositPolicyEnum.PERCENTAGE}>Percentage</SelectItem>
                                                        <SelectItem value={DepositPolicyEnum.FIXED_AMOUNT}>Fixed Amount</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            )}
                                        />
                                        {watch("depositPolicy.type") !== DepositPolicyEnum.NO_DEPOSIT && (
                                            <div className="flex items-center space-x-4">
                                                <Controller
                                                    name="depositPolicy.amount"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            type="number"
                                                            {...field}
                                                            onChange={(e) => {
                                                                const value = parseFloat(e.target.value);
                                                                field.onChange(value);
                                                                handleFieldChange("depositPolicy", { ...form.getValues("depositPolicy"), amount: value });
                                                            }}
                                                            placeholder={watch("depositPolicy.type") === DepositPolicyEnum.PERCENTAGE ? "Percentage" : "Amount"}
                                                        />
                                                    )}
                                                />
                                                {watch("depositPolicy.type") === DepositPolicyEnum.FIXED_AMOUNT && (
                                                    <Controller
                                                        name="depositPolicy.currency"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                onValueChange={(value) => {
                                                                    field.onChange(value);
                                                                    handleFieldChange("depositPolicy", { ...form.getValues("depositPolicy"), currency: value });
                                                                }}
                                                                defaultValue={field.value}
                                                            >
                                                                <SelectTrigger>
                                                                    <SelectValue placeholder="Currency" />
                                                                </SelectTrigger>
                                                                <SelectContent>
                                                                    {Object.values(Currency).map((curr) => (
                                                                        <SelectItem key={curr} value={curr}>
                                                                            {curr}
                                                                        </SelectItem>
                                                                    ))}
                                                                </SelectContent>
                                                            </Select>
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value="acceptedPayments">
                                <AccordionTrigger className="text-lg font-semibold">
                                    <CreditCard className="w-5 h-5 mr-2" />
                                    Accepted Payments
                                </AccordionTrigger>
                                <AccordionContent>
                                    {paymentOptions.map((method) => (
                                        <div key={method.id} className="flex items-center space-x-2 mb-2">
                                            <Controller
                                                name="acceptedPayments"
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        id={`payment-${method.id}`}
                                                        checked={field.value.includes(method.id)}
                                                        onCheckedChange={(checked) => {
                                                            const updatedPayments = checked ? [...field.value, method.id] : field.value.filter((m) => m !== method.id);
                                                            field.onChange(updatedPayments);
                                                            handleFieldChange("acceptedPayments", updatedPayments);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Label htmlFor={`payment-${method.id}`}>{method.name}</Label>
                                        </div>
                                    ))}
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value="discountLevels">
                                <AccordionTrigger className="text-lg font-semibold">
                                    <Percent className="w-5 h-5 mr-2" />
                                    Discount Levels
                                </AccordionTrigger>
                                <AccordionContent>
                                    <Controller
                                        name="discountLevels"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                {field.value.map((level, index) => (
                                                    <div key={level.id || index} className="flex space-x-4 mt-2">
                                                        <Input
                                                            type="number"
                                                            value={level.people}
                                                            onChange={(e) => {
                                                                const newLevels = [...field.value];
                                                                newLevels[index].people = parseInt(e.target.value);
                                                                field.onChange(newLevels);
                                                                handleFieldChange("discountLevels", newLevels);
                                                            }}
                                                            placeholder="Number of people"
                                                        />
                                                        <Input
                                                            type="number"
                                                            value={level.discount}
                                                            onChange={(e) => {
                                                                const newLevels = [...field.value];
                                                                newLevels[index].discount = parseFloat(e.target.value);
                                                                field.onChange(newLevels);
                                                                handleFieldChange("discountLevels", newLevels);
                                                            }}
                                                            placeholder="Discount percentage"
                                                        />
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            onClick={() => {
                                                                const newLevels = field.value.filter((_, i) => i !== index);
                                                                field.onChange(newLevels);
                                                                handleFieldChange("discountLevels", newLevels);
                                                            }}
                                                        >
                                                            <X className="h-4 w-4" />
                                                        </Button>
                                                    </div>
                                                ))}
                                                <Button
                                                    onClick={() => {
                                                        const newLevel = { id: Date.now().toString(), people: 0, discount: 0 };
                                                        const newLevels = [...field.value, newLevel];
                                                        field.onChange(newLevels);
                                                        handleFieldChange("discountLevels", newLevels);
                                                    }}
                                                    className="mt-2"
                                                >
                                                    <Plus className="mr-2 h-4 w-4" />
                                                    Add Discount Level
                                                </Button>
                                            </>
                                        )}
                                    />
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>

                        {/* Currency Prices */}
                        <div>
                            <Label className="text-lg font-semibold mb-2">Currency Prices</Label>
                            <Controller
                                name="currencyPrices"
                                control={control}
                                render={({ field }) => (
                                    <div className="space-y-2">
                                        {Object.entries(field.value).map(([currency, price]) => (
                                            <div key={currency} className="flex items-center space-x-2">
                                                <Input
                                                    type="number"
                                                    value={price.amount}
                                                    onChange={(e) => {
                                                        const newPrices = {
                                                            ...field.value,
                                                            [currency]: { ...price, amount: parseFloat(e.target.value) },
                                                        };
                                                        field.onChange(newPrices);
                                                        handleFieldChange("currencyPrices", newPrices);
                                                    }}
                                                    className="w-32"
                                                />
                                                <span>{currency}</span>
                                                <Checkbox
                                                    checked={price.isUserSet}
                                                    onCheckedChange={(checked) => {
                                                        const newPrices = {
                                                            ...field.value,
                                                            [currency]: { ...price, isUserSet: checked as boolean },
                                                        };
                                                        field.onChange(newPrices);
                                                        handleFieldChange("currencyPrices", newPrices);
                                                    }}
                                                />
                                                <Label>User Set</Label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            />
                        </div>

                        {/* Submit Button */}
                        <Button
                            onClick={form.handleSubmit((data) => {
                                console.log("Form submitted with data:", data);
                                // Here you would typically send the data to your backend
                                updateTourData({ pricing: data });
                            })}
                            className="w-full mt-4"
                        >
                            Save Pricing Information
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

const PreviewPanel: React.FC = () => {
    const { tourData } = useTourCreation();
    const [activeTab, setActiveTab] = useState("overview");
    const { loaded, error } = useGoogleMapsScript();

    useEffect(() => {
        if (loaded && tourData.tourLocations && tourData.tourLocations.length > 0) {
            const el = document.getElementById("mapparazi");
            if (el) {
                const map = new window.google.maps.Map(el, {
                    center: { lat: tourData.tourLocations[0].location.latitude, lng: tourData.tourLocations[0].location.longitude },
                    zoom: 10,
                });
                tourData.tourLocations.forEach((location) => {
                    new google.maps.Marker({
                        position: { lat: location.location.latitude, lng: location.location.longitude },
                        map: map,
                        title: location.location.name,
                    });
                });
            }
        }
    }, [loaded, tourData.tourLocations]);

    return (
        <div className="w-1/3 bg-gray-100 p-6 overflow-auto">
            <Card className="bg-white rounded-lg shadow-md">
                <CardContent className="p-6">
                    <h3 className="text-2xl font-bold mb-4 text-indigo-800">Tour Preview</h3>

                    {tourData.images && tourData.images.length > 0 && (
                        <div className="mb-4 relative h-48 overflow-hidden rounded-lg">
                            <img src={tourData.images[0].url} alt="Tour preview" className="absolute inset-0 w-full h-full object-cover" />
                            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
                            <div className="absolute bottom-0 left-0 p-4">
                                <h4 className="text-2xl font-bold text-white">{tourData.name || "Untitled Tour"}</h4>
                            </div>
                        </div>
                    )}

                    <Tabs value={activeTab} onValueChange={setActiveTab}>
                        <TabsList className="grid w-full grid-cols-3">
                            <TabsTrigger value="overview">Overview</TabsTrigger>
                            <TabsTrigger value="itinerary">Itinerary</TabsTrigger>
                            <TabsTrigger value="pricing">Pricing</TabsTrigger>
                        </TabsList>

                        <TabsContent value="overview" className="mt-4">
                            <ScrollArea className="h-[calc(100vh-400px)]">
                                <div className="space-y-4">
                                    <p className="text-gray-600">{tourData.description || "No description yet"}</p>

                                    <div className="flex flex-wrap gap-4">
                                        <div className="flex items-center text-gray-700">
                                            <Clock className="w-5 h-5 mr-2 text-indigo-500" />
                                            <span>{tourData.durationType === "SINGLE_DAY" ? `${tourData.duration?.hours}h` : `${tourData.duration?.days} days`}</span>
                                        </div>
                                        <div className="flex items-center text-gray-700">
                                            <Users className="w-5 h-5 mr-2 text-indigo-500" />
                                            <span>Max {tourData.maxParticipants} people</span>
                                        </div>
                                        <div className="flex items-center text-gray-700">
                                            <Calendar className="w-5 h-5 mr-2 text-indigo-500" />
                                            <span>{tourData.minAge}+ years</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap gap-2">
                                        <Badge variant="secondary" className="bg-indigo-100 text-indigo-800">
                                            {tourData.difficulty}
                                        </Badge>
                                        {tourData.languages &&
                                            tourData.languages.map((lang, index) => (
                                                <Badge key={index} variant="secondary" className="bg-blue-100 text-blue-800">
                                                    {lang}
                                                </Badge>
                                            ))}
                                    </div>

                                    <div id="mapparazi" style={{ width: "100%", height: "200px" }}></div>

                                    <Accordion type="single" collapsible>
                                        <AccordionItem value="locations">
                                            <AccordionTrigger>Locations</AccordionTrigger>
                                            <AccordionContent>
                                                <ul className="mt-2 space-y-1">
                                                    {tourData.tourLocations &&
                                                        tourData.tourLocations.map((location) => (
                                                            <li key={location.id} className="flex items-start">
                                                                <MapPin className="w-4 h-4 mr-2 mt-1 text-indigo-500 flex-shrink-0" />
                                                                <div>
                                                                    <p className="font-medium">{location.location.name}</p>
                                                                    <p className="text-sm text-gray-500">{location.location.address}</p>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </AccordionContent>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </ScrollArea>
                        </TabsContent>

                        <TabsContent value="itinerary" className="mt-4">
                            <ScrollArea className="h-[calc(100vh-400px)]">
                                {tourData.itinerary &&
                                    tourData.itinerary.map((day, index) => (
                                        <div key={index} className="mb-4">
                                            <h5 className="text-lg font-semibold text-indigo-600">
                                                Day {day.dayNumber}: {day.title}
                                            </h5>
                                            <p className="text-gray-600 mt-1">{day.description}</p>
                                        </div>
                                    ))}
                            </ScrollArea>
                        </TabsContent>

                        <TabsContent value="pricing" className="mt-4">
                            <ScrollArea className="h-[calc(100vh-400px)]">
                                <div className="space-y-4">
                                    <div>
                                        <h5 className="text-lg font-semibold text-indigo-600 mb-2">Base Price</h5>
                                        <p className="text-2xl font-bold text-gray-900 flex items-center">
                                            <DollarSign className="w-6 h-6 mr-1 text-green-500" />
                                            {tourData.pricing?.basePrice} {tourData.pricing?.mainCurrency}
                                        </p>
                                    </div>

                                    {tourData.pricing?.discountLevels && tourData.pricing.discountLevels.length > 0 && (
                                        <div>
                                            <h5 className="text-lg font-semibold text-indigo-600 mb-2">Group Discounts</h5>
                                            {tourData.pricing.discountLevels.map((discount, index) => (
                                                <p key={index} className="text-gray-600">
                                                    {discount.people}+ people: {discount.discount}% discount
                                                </p>
                                            ))}
                                        </div>
                                    )}

                                    {tourData.pricing?.cancellationPolicy && (
                                        <div>
                                            <h5 className="text-lg font-semibold text-indigo-600 mb-2">Cancellation Policy</h5>
                                            <p className="text-gray-600">{tourData.pricing.cancellationPolicy}</p>
                                        </div>
                                    )}

                                    {tourData.pricing?.refundPolicy && (
                                        <div>
                                            <h5 className="text-lg font-semibold text-indigo-600 mb-2">Refund Policy</h5>
                                            <ul className="list-disc list-inside text-gray-600">
                                                {tourData.pricing.refundPolicy.map((policy, index) => (
                                                    <li key={index}>{policy}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {tourData.pricing?.depositPolicy && (
                                        <div>
                                            <h5 className="text-lg font-semibold text-indigo-600 mb-2">Deposit Policy</h5>
                                            <p className="text-gray-600">
                                                {tourData.pricing.depositPolicy.type === "PERCENTAGE"
                                                    ? `${tourData.pricing.depositPolicy.amount}% deposit required`
                                                    : `${tourData.pricing.depositPolicy.amount} ${tourData.pricing.depositPolicy.currency} deposit required`}
                                            </p>
                                        </div>
                                    )}

                                    {tourData.pricing?.acceptedPayments && (
                                        <div>
                                            <h5 className="text-lg font-semibold text-indigo-600 mb-2">Accepted Payments</h5>
                                            <div className="flex flex-wrap gap-2">
                                                {tourData.pricing.acceptedPayments.map((method, index) => (
                                                    <Badge key={index} variant="outline">
                                                        {method}
                                                    </Badge>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ScrollArea>
                        </TabsContent>
                    </Tabs>
                </CardContent>
            </Card>
        </div>
    );
};

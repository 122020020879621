import * as axios from "axios";
import Joi from "joi";
import React from "react";
import { instance } from "shared/api/signature";
import { validate } from "shared/helpers/utils";

export enum ActionTypes {
    SET_LOADING = "SET_LOADING",
    SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
}
// Define action types
const actionTypes: Record<ActionTypes, string> = {
    SET_LOADING: "SET_LOADING",
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
};

type State = { error: boolean; loading: boolean; errorMessage: string };
type Action = { type: ActionTypes.SET_ERROR_MESSAGE; payload: string } | { type: ActionTypes.SET_LOADING; payload: boolean };

const initialState: State = { error: false, loading: false, errorMessage: "" };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case ActionTypes.SET_ERROR_MESSAGE:
            return { ...state, errorMessage: action.payload };
        default:
            return state;
    }
};

/**
 * Type for any object
 */
type AnyObject = { [key: string]: any } & object;

const isObject = <K extends AnyObject>(obj: K) => obj && typeof obj === "object" && !Array.isArray(obj);

/**
 *  First object is init
 *  Second is to send to server -> be careful
 */
function getChangedFieldsDeep<T extends AnyObject>(init: T, input: T): Partial<T> {
    const changedFields: Partial<T> = {};

    for (const key in input) {
        if (input.hasOwnProperty(key)) {
            if (isObject(input[key]) && isObject(init[key])) {
                const nestedChanges = getChangedFieldsDeep(init[key], input[key]);
                if (Object.keys(nestedChanges).length > 0) {
                    changedFields[key] = nestedChanges as any;
                }
            } else if (init[key] !== input[key]) {
                changedFields[key] = input[key];
            }
        }
    }

    return changedFields;
}
type useHandleFormOptionsType<RequestBody, V> = { setResponse?: (data: V) => void; close?: () => void; initData?: RequestBody };
type useHandleFormRequest<RequestBody, V> = (input: Partial<RequestBody>, options?: axios.AxiosRequestConfig) => Promise<axios.AxiosResponse<V, any>>;

const useSubmit = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const handler = async () => {
        dispatch({ type: ActionTypes.SET_ERROR_MESSAGE, payload: "" });
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });

        try {
            const request = await instance.get("/");
            if (request.status == 201) {
            }
        } catch (error) {
            const { delay, message } = handleAxiosError(error);
            setTimeout(() => {
                dispatch({ type: ActionTypes.SET_ERROR_MESSAGE, payload: message });
                dispatch({ type: ActionTypes.SET_LOADING, payload: false });
            }, delay);
        }
    };

    return { state, handler };
};

/**
 * Set
 */
const useHandleForm = <RequestBody extends {} = any, V extends {} = any>(
    req: useHandleFormRequest<RequestBody, V>,
    options?: useHandleFormOptionsType<RequestBody, V>
) => {
    let setResponse: useHandleFormOptionsType<RequestBody, V>["setResponse"] | undefined;
    let close: useHandleFormOptionsType<RequestBody, V>["close"] | undefined;
    let init: useHandleFormOptionsType<RequestBody, V>["initData"] | undefined;

    if (options) {
        setResponse = options.setResponse;
        close = options.close;
        init = options.initData;
    }

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const handler = async (input: Partial<RequestBody>, options?: axios.AxiosRequestConfig) => {
        if (init) input = getChangedFieldsDeep(init, input);

        dispatch({ type: ActionTypes.SET_ERROR_MESSAGE, payload: "" });
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });

        try {
            const request = await req(input, options);
            if (request.status == 201) {
                if (setResponse) setResponse(request.data);
                if (close) close();
            }
        } catch (error) {
            const { delay, message } = handleAxiosError(error);

            setTimeout(() => {
                dispatch({ type: ActionTypes.SET_ERROR_MESSAGE, payload: message });
                dispatch({ type: ActionTypes.SET_LOADING, payload: false });
            }, delay);
        }
    };

    return { state, handler };
};

type ErrorResponse = { ok: boolean; message: string };

const handleAxiosError = (error: unknown) => {
    let delay = 0;
    let message = "";
    if (error instanceof axios.AxiosError) {
        if (error.response) {
            const response = error.response;

            if (response.status == 400) {
                const data = response.data as ErrorResponse;
                message = data.message;
                delay = response.config.metadata!.delay;
            }
        }
    }

    return { message, delay };
};

export type { ErrorResponse };
export { actionTypes, initialState, reducer, handleAxiosError, useHandleForm, useSubmit };

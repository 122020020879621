import React, { useEffect } from "react";

const GoogleContext = React.createContext({
    loaded: false,
});

const GoogleProvider = ({ children }: React.PropsWithChildren) => {
    const [loaded, setLoaded] = React.useState(false);
    useEffect(() => {
        const init = () => {
            window.google.accounts.id.initialize({
                client_id: "1090040288638-1qdkajfe8ivvrorp0usrqgmsdkl9vea0.apps.googleusercontent.com",
            });

            setLoaded(true);
        };

        const checkLoad = () => {
            if (window.google) {
                init();
            } else setTimeout(checkLoad, 250);
        };

        checkLoad();
    }, []);
    return <GoogleContext.Provider value={{ loaded }}>{children}</GoogleContext.Provider>;
};

const useGoogleContext = () => React.useContext(GoogleContext);

export { useGoogleContext };
export default GoogleProvider;

import React, { forwardRef } from "react";

const Heart = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
    <svg ref={ref} width="229" height="250" viewBox="0 0 229 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M25.8053 8.90331C78.1346 -1.81272 99.8112 126.415 99.8112 126.415C99.8112 126.415 184.529 41.1155 217.225 83.677C249.922 126.238 81.4636 256.047 71.6694 241.024C32.246 180.554 -21.1847 18.5259 25.8053 8.90331Z"
            stroke="#4BB061"
            strokeWidth="15"
            strokeLinecap="round"
        />
    </svg>
));

Heart.displayName = "Heart";

export default Heart;

import React from "react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Currency, DepositPolicy, TourConstants } from "shared/data/tour_schema";
import { constructNow } from "date-fns";
import { DepositPolicyEnum, DepositPolicyType, depositPolicyOptions } from "shared/types/api";

interface DepositPolicyPanelProps {
    depositPolicy: DepositPolicy;
    updateDepositPolicy: (depositPolicy: DepositPolicy) => void;
}

const DepositPolicyPanel: React.FC<DepositPolicyPanelProps> = ({ depositPolicy, updateDepositPolicy }) => {
    const handlePolicyChange = (policyType: DepositPolicyType) => {
        updateDepositPolicy({
            ...depositPolicy,
            type: policyType,
            amount: policyType === DepositPolicyEnum.NO_DEPOSIT ? undefined : depositPolicy.amount,
            currency: policyType === DepositPolicyEnum.FIXED_AMOUNT ? depositPolicy.currency : undefined,
        });
    };

    const handleDepositAmountChange = (amount: number) => {
        updateDepositPolicy({ ...depositPolicy, amount });
    };

    const handleCurrencyChange = (currency: Currency) => {
        updateDepositPolicy({ ...depositPolicy, currency });
    };

    return (
        <Card className="w-full">
            <CardContent>
                <CardHeader>Deposit Policy</CardHeader>
                <div className="space-y-6">
                    <RadioGroup value={depositPolicy.type} onValueChange={handlePolicyChange} className="space-y-4">
                        {depositPolicyOptions.map((policy) => (
                            <div key={policy.id} className="flex items-center space-x-2">
                                <RadioGroupItem value={policy.id} id={policy.id} />
                                <Label htmlFor={policy.id}>{policy.name}</Label>
                                <p className="text-sm text-muted-foreground">{policy.description}</p>
                            </div>
                        ))}
                    </RadioGroup>

                    {depositPolicy.type !== DepositPolicyEnum.NO_DEPOSIT && (
                        <div className="mt-2">
                            <Label htmlFor="deposit-amount">{depositPolicy.type === DepositPolicyEnum.FIXED_AMOUNT ? "Deposit Amount" : "Deposit percent"}</Label>
                            <div className="flex space-x-2">
                                <input
                                    type="number"
                                    id="deposit-amount"
                                    value={depositPolicy.amount || ""}
                                    onChange={(e) => handleDepositAmountChange(parseFloat(e.target.value))}
                                    placeholder={depositPolicy.type === DepositPolicyEnum.PERCENTAGE ? "Enter percentage" : "Enter fixed amount"}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                {depositPolicy.type === DepositPolicyEnum.FIXED_AMOUNT && (
                                    <Select value={depositPolicy.currency} onValueChange={handleCurrencyChange}>
                                        <SelectTrigger className="w-[180px]">
                                            <SelectValue placeholder="Select a currency" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {Object.values(Currency).map((currency) => (
                                                <SelectItem key={currency} value={currency}>
                                                    {currency}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default DepositPolicyPanel;

import { createContext, useContext, useCallback, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { instance } from "shared/api/signature";
import { z } from "zod";
import Loading from "@/loading/Loading";
import { PROVIDER_TOUR_API_ENDPOINTS, UpdateBodyType } from "shared/types/api";

interface TourCreationContextType {
    step: number;
    setStep: (step: number) => void;
    tourData: any;
    updateTourData: (data: Partial<any>) => void;
}

const TourCreationContext = createContext<TourCreationContextType | undefined>(undefined);

export const get_tour_by_id = async (tourId: string) => {
    try {
        const response = await instance.get<z.infer<typeof PROVIDER_TOUR_API_ENDPOINTS.getTour.response>>(
            PROVIDER_TOUR_API_ENDPOINTS.getTour.url.replace(":tourId", tourId)
        );
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching tour:", error);
        throw error;
    }
};

export const useTourCreation = () => {
    const context = useContext(TourCreationContext);
    if (context === undefined) {
        throw new Error("useTourCreation must be used within a TourCreationProvider");
    }
    return context;
};

export const updateTour = async (id: string, body: UpdateBodyType) => {
    try {
        body.id = id;
        const req = await instance.request({ url: PROVIDER_TOUR_API_ENDPOINTS.updateTour.url, method: PROVIDER_TOUR_API_ENDPOINTS.updateTour.method, data: body });
        return req.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

type Action =
    | { type: "SET_STEP"; payload: number }
    | { type: "SET_TOUR_DATA"; payload: any }
    | { type: "UPDATE_TOUR_DATA"; payload: any }
    | { type: "SET_LOADING"; payload: boolean }
    | { type: "SET_ERROR"; payload: string | null }
    | { type: "SET_INITIAL_DATA_LOADED"; payload: boolean }
    | { type: "CLEAR_DIRTY_FIELDS" };

interface State {
    step: number;
    tourData: any;
    dirtyFields: Record<string, any>;
    isLoading: boolean;
    error: string | null;
    isInitialDataLoaded: boolean;
}

function tourCreationReducer(state: State, action: Action): State {
    switch (action.type) {
        case "SET_STEP":
            return { ...state, step: action.payload };
        case "SET_TOUR_DATA":
            return { ...state, tourData: action.payload, dirtyFields: {} };
        case "UPDATE_TOUR_DATA":
            const newDirtyFields = { ...state.dirtyFields };
            Object.keys(action.payload).forEach((key) => {
                if (JSON.stringify(state.tourData[key]) !== JSON.stringify(action.payload[key])) {
                    newDirtyFields[key] = action.payload[key];
                }
            });
            return {
                ...state,
                tourData: { ...state.tourData, ...action.payload },
                dirtyFields: newDirtyFields,
            };
        case "SET_LOADING":
            return { ...state, isLoading: action.payload };
        case "SET_ERROR":
            return { ...state, error: action.payload };
        case "SET_INITIAL_DATA_LOADED":
            return { ...state, isInitialDataLoaded: action.payload };
        case "CLEAR_DIRTY_FIELDS":
            return { ...state, dirtyFields: {} };
        default:
            return state;
    }
}
export const create_tour_req = async () => {
    const req = await instance.post(PROVIDER_TOUR_API_ENDPOINTS.createTour.url);
    console.log(req.data, "this is  what i get back");
    return req.data;
};

export const TourCreationProvider: React.FC<{ children: React.ReactNode; initialStep: number }> = ({ children, initialStep }) => {
    const [state, dispatch] = useReducer(tourCreationReducer, {
        step: initialStep,
        tourData: {},
        dirtyFields: {},
        isLoading: false,
        error: null,
        isInitialDataLoaded: false,
    });

    const location = useLocation();

    const updateTourData = useCallback((newData: Partial<any>) => {
        dispatch({ type: "UPDATE_TOUR_DATA", payload: newData });
    }, []);

    const mergeAndUpdateTourData = useCallback(async () => {
        if (state.tourData.id && Object.keys(state.dirtyFields).length > 0) {
            dispatch({ type: "SET_LOADING", payload: true });
            try {
                const updatedTour = await updateTour(state.tourData.id, state.dirtyFields);
                dispatch({ type: "SET_TOUR_DATA", payload: { ...state.tourData, ...updatedTour } });
                dispatch({ type: "CLEAR_DIRTY_FIELDS" });
            } catch (error) {
                console.error("Failed to update tour:", error);
                dispatch({ type: "SET_ERROR", payload: "Failed to update tour" });
            } finally {
                dispatch({ type: "SET_LOADING", payload: false });
            }
        }
    }, [state.tourData.id, state.dirtyFields]);

    const handleStepChange = useCallback(
        async (newStep: number) => {
            if (newStep !== state.step) {
                await mergeAndUpdateTourData();
                dispatch({ type: "SET_STEP", payload: newStep });
            }
        },
        [mergeAndUpdateTourData, state.step]
    );

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tourId = params.get("tourId");

        if (tourId && !state.isInitialDataLoaded) {
            dispatch({ type: "SET_LOADING", payload: true });
            get_tour_by_id(tourId)
                .then((tour) => {
                    dispatch({ type: "SET_TOUR_DATA", payload: tour.data });
                    dispatch({ type: "SET_INITIAL_DATA_LOADED", payload: true });
                })
                .catch((error) => {
                    console.error("Failed to fetch tour:", error);
                    dispatch({ type: "SET_ERROR", payload: "Failed to fetch tour" });
                })
                .finally(() => {
                    dispatch({ type: "SET_LOADING", payload: false });
                });
        } else if (!state.isInitialDataLoaded) {
            dispatch({ type: "SET_INITIAL_DATA_LOADED", payload: true });
        }
    }, [location.search, handleStepChange, state.isInitialDataLoaded, state.step]);

    return (
        <TourCreationContext.Provider
            value={{
                step: state.step,
                setStep: handleStepChange,
                tourData: state.tourData,
                updateTourData,
            }}
        >
            {state.isInitialDataLoaded ? children : <Loading />}
        </TourCreationContext.Provider>
    );
};

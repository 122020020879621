import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useTranslation } from "react-i18next";
import { MouseEvent } from "react";
import { ModalWindowProps } from "shared/providers/ModalWindow";

const languages: Array<[string, string]> = [
    ["Русский", "ru"],
    ["English", "en"],
];

function LanguageModal({ close }: ModalWindowProps) {
    const { changeLanguage } = useTranslation().i18n;
    const handle = (e: MouseEvent<HTMLButtonElement>) => {
        changeLanguage(e.currentTarget.id);
        close();
    };

    return (
        <Dialog onOpenChange={close} open>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Выбор языка</DialogTitle>
                </DialogHeader>

                {languages.map((l) => (
                    <Button key={l[0]} id={l[1]} variant={"designed"} className="w-full max-w-full md:w-full md:max-w-full" onClick={handle}>
                        {l[0]}
                    </Button>
                ))}
            </DialogContent>
        </Dialog>
    );
}

export default LanguageModal;

import React, { useState } from "react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Backpack, PlusCircle, X } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader } from "@/components/ui/card";
import { TourConstants } from "shared/data/tour_schema";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { Check } from "lucide-react";

type ArrayName = "includedItems" | "notIncludedItems" | "whatToBring";

interface TourItemsPanelProps {
    initialIncludedItems: string[];
    initialNotIncludedItems: string[];
    initialWhatToBring: string[];
    onUpdate: (items: { includedItems: string[]; notIncludedItems: string[]; whatToBring: string[] }) => void;
}

const TourItemsPanel: React.FC<TourItemsPanelProps> = ({ initialIncludedItems, initialNotIncludedItems, initialWhatToBring, onUpdate }) => {
    const [includedItems, setIncludedItems] = useState<string[]>(initialIncludedItems || []);
    const [notIncludedItems, setNotIncludedItems] = useState<string[]>(initialNotIncludedItems || []);
    const [whatToBring, setWhatToBring] = useState<string[]>(initialWhatToBring || []);

    const handleItemChange = (arrayName: ArrayName, index: number, value: string) => {
        const updatedArray = [...getArrayByName(arrayName)];
        updatedArray[index] = value;
        updateItems(arrayName, updatedArray);
    };

    const handleItemRemove = (arrayName: ArrayName, index: number) => {
        const updatedArray = [...getArrayByName(arrayName)];
        updatedArray.splice(index, 1);
        updateItems(arrayName, updatedArray);
    };

    const handleItemAdd = (arrayName: ArrayName) => {
        const updatedArray = [...getArrayByName(arrayName), ""];
        updateItems(arrayName, updatedArray);
    };

    const getArrayByName = (arrayName: ArrayName): string[] => {
        switch (arrayName) {
            case "includedItems":
                return includedItems;
            case "notIncludedItems":
                return notIncludedItems;
            case "whatToBring":
                return whatToBring;
        }
    };

    const updateItems = (arrayName: ArrayName, newArray: string[]) => {
        switch (arrayName) {
            case "includedItems":
                setIncludedItems(newArray);
                break;
            case "notIncludedItems":
                setNotIncludedItems(newArray);
                break;
            case "whatToBring":
                setWhatToBring(newArray);
                break;
        }
        onUpdate({
            includedItems: arrayName === "includedItems" ? newArray : includedItems,
            notIncludedItems: arrayName === "notIncludedItems" ? newArray : notIncludedItems,
            whatToBring: arrayName === "whatToBring" ? newArray : whatToBring,
        });
    };

    const renderItems = (arrayName: ArrayName, items: string[]) => (
        <div>
            <h3 className="text-lg font-semibold mb-2">
                {arrayName === "includedItems" && <Check className="w-5 h-5 mr-2 inline" />}
                {arrayName === "notIncludedItems" && <X className="w-5 h-5 mr-2 inline" />}
                {arrayName === "whatToBring" && <Backpack className="w-5 h-5 mr-2 inline" />}
                {arrayName === "includedItems" ? "Included Items" : arrayName === "notIncludedItems" ? "Not Included Items" : "What to Bring"}
            </h3>
            <div className="space-y-2">
                {items.map((item, index) => (
                    <div key={index} className="flex items-center space-x-2">
                        <Input
                            value={item}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleItemChange(arrayName, index, e.target.value)}
                            className="flex-grow"
                        />
                        <Button type="button" variant="ghost" size="sm" onClick={() => handleItemRemove(arrayName, index)}>
                            <X className="h-4 w-4" />
                        </Button>
                    </div>
                ))}
                <Button type="button" variant="outline" onClick={() => handleItemAdd(arrayName)} className="w-full mt-2">
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Add {arrayName === "includedItems" ? "Included" : arrayName === "notIncludedItems" ? "Not Included" : "What to Bring"} Item
                </Button>
            </div>
        </div>
    );

    return (
        <Card className="w-full">
            <CardHeader>
                <h2 className="text-2xl font-bold">Tour Items</h2>
            </CardHeader>
            <CardContent>
                <CardDescription>
                    <div className="space-y-6">
                        {renderItems("includedItems", includedItems)}
                        {renderItems("notIncludedItems", notIncludedItems)}
                        {renderItems("whatToBring", whatToBring)}
                    </div>
                </CardDescription>
            </CardContent>
        </Card>
    );
};

// const TourItemsPanel = ({ initialIncludedItems, initialNotIncludedItems, onUpdate }: TourItemsPanelProps) => {
//     const [includedItems, setIncludedItems] = useState(initialIncludedItems || []);
//     const [notIncludedItems, setNotIncludedItems] = useState(initialNotIncludedItems || []);
//     const [whatToBring, setWhatToBring] = useState(initialNotIncludedItems || []);

//     const handleItemChange = (arrayName: ArrayName, index: number, value: string) => {
//         const updatedArray = arrayName === "includedItems" ? [...includedItems] : [...notIncludedItems];
//         updatedArray[index] = value;
//         updateItems(arrayName, updatedArray);
//     };

//     const handleItemRemove = (arrayName: ArrayName, index: number) => {
//         const updatedArray = arrayName === "includedItems" ? [...includedItems] : [...notIncludedItems];
//         updatedArray.splice(index, 1);
//         updateItems(arrayName, updatedArray);
//     };

//     const handleItemAdd = (arrayName: ArrayName) => {
//         const updatedArray = arrayName === "includedItems" ? [...includedItems, ""] : [...notIncludedItems, ""];
//         updateItems(arrayName, updatedArray);
//     };

//     const updateItems = (arrayName: ArrayName, newArray: string[]) => {
//         if (arrayName === "includedItems") {
//             setIncludedItems(newArray);
//         } else if (arrayName == "notIncludedItems") {
//             setNotIncludedItems(newArray);
//         } else {
//             setWhatToBring(newArray);
//         }
//         onUpdate({
//             includedItems: arrayName === "includedItems" ? newArray : includedItems,
//             notIncludedItems: arrayName === "notIncludedItems" ? newArray : notIncludedItems,
//             whatToBring: arrayName === "whatToBring" ? newArray : whatToBring,
//         });
//     };

//     const renderItems = (arrayName: ArrayName, items: string[]) => (
//         <div>
//             <h3 className="text-lg font-semibold mb-2">
//                 {arrayName === "includedItems" ? <Check className="w-5 h-5 mr-2 inline" /> : <X className="w-5 h-5 mr-2 inline" />}
//                 {arrayName === "includedItems" ? "Included Items" : "Not Included Items"}
//             </h3>
//             <div className="space-y-2">
//                 {items.map((item, index) => (
//                     <div key={index} className="flex items-center space-x-2">
//                         <Input
//                             value={item}
//                             onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleItemChange(arrayName, index, e.target.value)}
//                             className="flex-grow"
//                         />
//                         <Button type="button" variant="ghost" size="sm" onClick={() => handleItemRemove(arrayName, index)}>
//                             <X className="h-4 w-4" />
//                         </Button>
//                     </div>
//                 ))}
//                 <Button type="button" variant="outline" onClick={() => handleItemAdd(arrayName)} className="w-full mt-2">
//                     <PlusCircle className="h-4 w-4 mr-2" />
//                     Add {arrayName === "includedItems" ? "Included" : "Not Included"} Item
//                 </Button>
//             </div>
//         </div>
//     );

//     return (
//         <Card className="w-full">
//             <CardHeader>
//                 <h2 className="text-2xl font-bold">Tour Items</h2>
//             </CardHeader>
//             <CardContent>
//                 <CardDescription>
//                     <div className="space-y-6">
//                         {renderItems("includedItems", includedItems)}
//                         {renderItems("notIncludedItems", notIncludedItems)}
//                         {renderItems("whatToBring", whatToBring)}
//                     </div>
//                 </CardDescription>
//             </CardContent>
//         </Card>
//     );
// };

export default TourItemsPanel;

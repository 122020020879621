import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "shared/helpers/utils";
import { UserSelector } from "entities/store/user";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { BaseRoutes, profilePageRoutes, providerProfileRoutes } from "shared/helpers/const";
import { User, Settings, Mail, Shield, CreditCard, Key, Users, Bell } from "lucide-react";
import { NavigationRoute } from "shared/types/types";

function NavigateButton({ path, name, icon }: NavigationRoute) {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === path;

    return (
        <Button
            onClick={() => navigate(path)}
            className={cn("justify-start cursor-pointer w-full", isActive ? "bg-primary text-primary-foreground" : "hover:bg-primary/10")}
            variant={isActive ? "default" : "ghost"}
        >
            {icon ? icon : null}
            <span className="ml-2">{name}</span>
        </Button>
    );
}

function ProfilePageNavBar() {
    const userRole = useAppSelector(UserSelector.role);
    const allRoutes = userRole === "PROVIDER" ? [...profilePageRoutes, ...providerProfileRoutes] : profilePageRoutes;

    return (
        <nav className="h-full flex-1 flex-col hidden md:flex md:w-64 p-4 border-r">
            <h2 className="text-xl font-bold mb-4">Profile Settings</h2>
            <div className="space-y-1">
                {allRoutes.map((route) => (
                    <NavigateButton key={route.path} {...route} />
                ))}
            </div>
        </nav>
    );
}

export default ProfilePageNavBar;

import { Outlet } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import { ReactNode } from "react";

type LayoutProps = { children?: ReactNode };

function Layout({ children }: LayoutProps) {
    return (
        <>
            <Header />
            <div className="component items-center bg-DEFAULT flex-1 flex-col pb-16">{children ? children : <Outlet />}</div>
            <Footer />
        </>
    );
}

export default Layout;

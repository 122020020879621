import React, { useState, useCallback, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useEmblaCarousel from "embla-carousel-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Card, CardContent } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Plus, X, ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
interface MediaStepProps {
    tour: any;
    updateTour: (updater: Partial<any> | ((prevState: any) => any)) => void;
}

interface MediaStepProps {
    tour: any;
    updateTour: (updater: Partial<any> | ((prevState: any) => any)) => void;
}

const MediaStep: React.FC<MediaStepProps> = ({ tour, updateTour }) => {
    const [images, setImages] = useState<{ url: string }[]>(tour.images || []);
    const [highlights, setHighlights] = useState<TourHighlight[]>(tour.additionalInfo?.highlights || []);
    const [isAddingHighlight, setIsAddingHighlight] = useState(false);
    const [newHighlight, setNewHighlight] = useState<TourHighlight>({ photo: "", commentary: "" });
    const [carouselIndex, setCarouselIndex] = useState<number | null>(null);
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        const newImages = files.map((file) => ({
            url: URL.createObjectURL(file),
        }));
        const updatedImages = [...images, ...newImages];
        setImages(updatedImages);
    };

    const removeImage = (index: number) => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    const addHighlight = () => {
        if (newHighlight.photo && newHighlight.commentary) {
            const updatedHighlights = [...highlights, newHighlight];
            setHighlights(updatedHighlights);
            updateTour((prevTour) => ({
                ...prevTour,
                additionalInfo: { ...prevTour.additionalInfo, highlights: updatedHighlights } as any,
            }));
            setNewHighlight({ photo: "", commentary: "" });
            setIsAddingHighlight(false);
        }
    };

    const removeHighlight = (index: number) => {
        const updatedHighlights = highlights.filter((_, i) => i !== index);
        setHighlights(updatedHighlights);
        updateTour((prevTour) => ({
            ...prevTour,
            additionalInfo: {
                ...prevTour.additionalInfo,
                highlights: updatedHighlights,
            } as any,
        }));
    };

    const handleHighlightImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewHighlight({ ...newHighlight, photo: reader.result as string });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="space-y-8">
            <Card>
                <CardContent className="p-6">
                    <h2 className="text-3xl font-bold mb-6">Tour Media</h2>

                    <div className="space-y-8">
                        <section>
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-2xl font-semibold">Tour Images</h3>
                                <label htmlFor="imageUpload" className="cursor-pointer">
                                    <Input id="imageUpload" type="file" accept="image/*" multiple onChange={handleImageUpload} className="hidden" />
                                    <Button variant="outline" size="sm" asChild>
                                        <span>
                                            <Plus className="mr-2 h-4 w-4" /> Add Photos
                                        </span>
                                    </Button>
                                </label>
                            </div>

                            <div className="grid grid-cols-4 gap-4">
                                {images.map((image, index) => (
                                    <div key={index} className="relative group">
                                        <img
                                            src={image.url}
                                            alt={`Tour preview ${index + 1}`}
                                            className="w-full h-32 object-cover rounded-md transition-opacity group-hover:opacity-75 cursor-pointer"
                                            onClick={() => setCarouselIndex(index)}
                                        />
                                        <Button
                                            variant="destructive"
                                            size="icon"
                                            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                                            onClick={() => removeImage(index)}
                                        >
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </section>

                        <section>
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-2xl font-semibold">Highlights</h3>
                                <Button variant="outline" size="sm" onClick={() => setIsAddingHighlight(true)}>
                                    <Plus className="mr-2 h-4 w-4" /> Add Highlight
                                </Button>
                            </div>
                            <div className="space-y-4">
                                <AnimatePresence>
                                    {highlights.map((highlight, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.2 }}
                                            className="flex items-start space-x-4 bg-gray-50 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                                        >
                                            <img src={highlight.photo} alt="Highlight" className="w-32 h-32 object-cover rounded-md cursor-pointer" />
                                            <div className="flex-grow">
                                                <p className="text-gray-700 text-sm">{highlight.commentary}</p>
                                            </div>
                                            <Button variant="ghost" size="sm" onClick={() => removeHighlight(index)} className="text-red-500 hover:text-red-700">
                                                <X className="h-4 w-4" />
                                            </Button>
                                        </motion.div>
                                    ))}
                                </AnimatePresence>
                            </div>
                        </section>
                    </div>
                </CardContent>
            </Card>

            <Dialog open={isAddingHighlight} onOpenChange={setIsAddingHighlight}>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Add New Highlight</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 mt-4">
                        <div>
                            <Label htmlFor="highlightPhoto">Photo</Label>
                            {newHighlight.photo ? (
                                <div className="relative mt-2">
                                    <img src={newHighlight.photo} alt="New highlight" className="w-full h-40 object-cover rounded-md" />
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        className="absolute top-2 right-2 bg-white/80 hover:bg-white"
                                        onClick={() => setNewHighlight({ ...newHighlight, photo: "" })}
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            ) : (
                                <div className="mt-2 space-y-2">
                                    <Input id="highlightPhoto" type="file" accept="image/*" onChange={handleHighlightImageUpload} />
                                    <p className="text-sm text-gray-500">Or choose from uploaded images:</p>
                                    <div className="grid grid-cols-3 gap-2">
                                        {images.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.url}
                                                alt={`Tour image ${index + 1}`}
                                                className="w-full h-20 object-cover rounded cursor-pointer"
                                                onClick={() => setNewHighlight({ ...newHighlight, photo: image.url })}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <Label htmlFor="highlightCommentary">Commentary</Label>
                            <Textarea
                                id="highlightCommentary"
                                value={newHighlight.commentary}
                                onChange={(e) => setNewHighlight({ ...newHighlight, commentary: e.target.value })}
                                placeholder="Describe this highlight"
                                rows={3}
                            />
                        </div>
                        <Button onClick={addHighlight} disabled={!newHighlight.photo || !newHighlight.commentary} className="w-full">
                            Add Highlight
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={carouselIndex !== null} onOpenChange={() => setCarouselIndex(null)}>
                <DialogContent className="max-w-[90vw] max-h-[90vh] p-0">
                    <div className="relative h-full" ref={emblaRef}>
                        <div className="overflow-hidden h-full">
                            <div className="flex h-full">
                                {images.map((image, index) => (
                                    <div key={index} className="flex-[0_0_100%] h-full flex items-center justify-center">
                                        <img src={image.url} alt={`Tour image ${index + 1}`} className="max-w-full max-h-full object-contain" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Button variant="outline" size="icon" className="absolute top-1/2 left-4 transform -translate-y-1/2" onClick={scrollPrev}>
                            <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button variant="outline" size="icon" className="absolute top-1/2 right-4 transform -translate-y-1/2" onClick={scrollNext}>
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </motion.div>
    );
};

import { CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { MultiSelect } from "@/multi-select";

import { TourHighlight, Seasonality, FoodOption } from "shared/data/tour_schema";
import TourItemsPanel from "./included-items";
import { AdditionalInfoSchema, PROVIDER_TOUR_API_ENDPOINTS, accessibilityOptions, foodOptions, seasonalityOptions } from "shared/types/api";
import CategoryTagsSelect from "./category-tags-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateTour } from "pages/provider/tour_new_api/Constructor/State";
import { useParams } from "react-router-dom";
import { instance } from "shared/api/signature";

interface AdditionalInfoTabProps {
    tour: any;
    updateTour: (updater: Partial<any> | ((prevState: any) => any)) => void;
}

const AdditionalInfoTab: React.FC = () => {
    const params = useParams<{ id: string }>();
    const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
        highlights: [],
        includedItems: [],
        notIncludedItems: [],
        whatToBring: [],
        categories: [],
        accessibility: [],
        seasonality: undefined,
        privateGroupOption: false,
        foodOptions: [],
    });
    const [loading, setLoading] = useState(true);
    const [newItem, setNewItem] = useState<string>("");
    const [newHighlight, setNewHighlight] = useState<TourHighlight>({ photo: "", commentary: "" });
    const [isAddingHighlight, setIsAddingHighlight] = useState(false);

    useEffect(() => {
        const fetchTour = async (id: string) => {
            try {
                const { tourId } = PROVIDER_TOUR_API_ENDPOINTS["getTour"].params.parse({ tourId: id });
                const url = PROVIDER_TOUR_API_ENDPOINTS["getTour"].url.replace(":tourId", tourId);
                const response = await instance.get(url);
                console.log(response.data.data);
                setAdditionalInfo({ ...response.data.data.additionalInfo, categories: response.data.data.additionalInfo.categoryTagIds });
                setLoading(false);
            } catch (error) {
                console.error("Error fetching tour:", error);
                setLoading(false);
            }
        };
        if (params.id) {
            fetchTour(params.id);
        }
    }, [params.id]);

    const handleAdditionalInfoChange = (field: keyof AdditionalInfo, value: any) => {
        setAdditionalInfo((prev) => ({ ...prev, [field]: value }));
        ``;
    };

    const handleHighlightAdd = () => {
        if (newHighlight.photo && newHighlight.commentary) {
            setAdditionalInfo((prev) => ({
                ...prev,
                highlights: [...prev.highlights, newHighlight],
            }));
            setNewHighlight({ photo: "", commentary: "" });
            setIsAddingHighlight(false);
        }
    };

    const handleHighlightRemove = (index: number) => {
        setAdditionalInfo((prev) => ({
            ...prev,
            highlights: prev.highlights.filter((_, i) => i !== index),
        }));
    };

    const handleHighlightImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewHighlight({ ...newHighlight, photo: reader.result as string });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleItemAdd = (field: "includedItems" | "notIncludedItems" | "whatToBring") => {
        if (newItem) {
            setAdditionalInfo((prev) => ({
                ...prev,
                [field]: [...prev[field], newItem],
            }));
            setNewItem("");
        }
    };

    const handleItemRemove = (field: "includedItems" | "notIncludedItems" | "whatToBring", index: number) => {
        setAdditionalInfo((prev) => ({ ...prev, [field]: prev[field].filter((_, i) => i !== index) }));
    };

    const updateTourItems = ({ includedItems, notIncludedItems, whatToBring }: { includedItems: string[]; notIncludedItems: string[]; whatToBring: string[] }) => {
        setAdditionalInfo((prev) => ({ ...prev, includedItems, notIncludedItems, whatToBring }));
    };

    const handleSave = async () => {
        try {
            const { tourId } = PROVIDER_TOUR_API_ENDPOINTS["updateTour"].params.parse({ tourId: params.id });
            const url = PROVIDER_TOUR_API_ENDPOINTS["updateTour"].url.replace(":tourId", tourId);
            await updateTour(tourId, { additionalInfo });
            // await instance.put(url, { additionalInfo });
        } catch (error) {
            console.error("Error saving changes:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Card className="w-full border-0 shadow-none">
            <CardHeader>
                <CardTitle>Additional Information</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
                {/* Highlights */}
                <div>
                    <Label>Highlights</Label>
                    <div className="space-y-2 mt-2">
                        {additionalInfo.highlights.map((highlight, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <img src={highlight.photo} alt="Highlight" className="w-12 h-12 object-cover rounded" />
                                <p className="flex-grow">{highlight.commentary}</p>
                                <Button variant="ghost" size="sm" onClick={() => handleHighlightRemove(index)}>
                                    <X className="h-4 w-4" />
                                </Button>
                            </div>
                        ))}
                    </div>
                    <Button variant="outline" size="sm" onClick={() => setIsAddingHighlight(true)} className="mt-2">
                        <Plus className="mr-2 h-4 w-4" /> Add Highlight
                    </Button>
                </div>

                <TourItemsPanel
                    initialIncludedItems={additionalInfo.includedItems}
                    initialNotIncludedItems={additionalInfo.notIncludedItems}
                    initialWhatToBring={additionalInfo.whatToBring}
                    onUpdate={updateTourItems}
                />

                {/* Accessibility */}
                <div>
                    <Label htmlFor="accessibility">Accessibility Options</Label>
                    <MultiSelect
                        options={accessibilityOptions}
                        selectedValues={additionalInfo.accessibility}
                        onChange={(values) => handleAdditionalInfoChange("accessibility", values as AccessibilityOption[])}
                        placeholder="Select accessibility options"
                    />
                </div>

                {/* Seasonality */}
                <div>
                    <Label htmlFor="seasonality">Seasonality</Label>
                    <Select value={additionalInfo.seasonality} onValueChange={(value) => handleAdditionalInfoChange("seasonality", value as Seasonality)}>
                        <SelectTrigger id="seasonality">
                            <SelectValue placeholder="Select seasonality" />
                        </SelectTrigger>
                        <SelectContent>
                            {seasonalityOptions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                {/* Food Options */}
                <div>
                    <Label htmlFor="foodOptions">Food Options</Label>
                    <MultiSelect
                        options={foodOptions}
                        selectedValues={additionalInfo.foodOptions}
                        onChange={(values) => handleAdditionalInfoChange("foodOptions", values as FoodOption[])}
                        placeholder="Select food options"
                    />
                </div>

                {/* Category Tags */}
                <div>
                    <Label htmlFor="categoryTags">Category Tags</Label>
                    <CategoryTagsSelect
                        selectedCategories={additionalInfo.categories || []}
                        onChange={(categories) => handleAdditionalInfoChange("categories", categories)}
                    />
                </div>

                {/* Save Button */}
                <Button onClick={handleSave} className="w-full">
                    Save Changes
                </Button>
            </CardContent>

            {/* Add Highlight Dialog */}
            <Dialog open={isAddingHighlight} onOpenChange={setIsAddingHighlight}>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Add New Highlight</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 mt-4">
                        <div>
                            <Label htmlFor="highlightPhoto">Photo</Label>
                            {newHighlight.photo ? (
                                <div className="relative mt-2">
                                    <img src={newHighlight.photo} alt="New highlight" className="w-full h-40 object-cover rounded-md" />
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        className="absolute top-2 right-2 bg-white/80 hover:bg-white"
                                        onClick={() => setNewHighlight({ ...newHighlight, photo: "" })}
                                    >
                                        <X className="h-4 w-4" />
                                    </Button>
                                </div>
                            ) : (
                                <div className="mt-2 space-y-2">
                                    <Input id="highlightPhoto" type="file" accept="image/*" onChange={handleHighlightImageUpload} />
                                </div>
                            )}
                        </div>
                        <div>
                            <Label htmlFor="highlightCommentary">Commentary</Label>
                            <Textarea
                                id="highlightCommentary"
                                value={newHighlight.commentary}
                                onChange={(e) => setNewHighlight({ ...newHighlight, commentary: e.target.value })}
                                placeholder="Describe this highlight"
                                rows={3}
                            />
                        </div>
                        <Button onClick={handleHighlightAdd} disabled={!newHighlight.photo || !newHighlight.commentary} className="w-full">
                            Add Highlight
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default AdditionalInfoTab;

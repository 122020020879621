import { Accordion } from "@/components/ui/accordion";

import UtilityBench from "./components/benches/UtilityBench";
import UploadBench from "./components/benches/UploadBench";
import UiBench from "./components/benches/UiBench";
import { TestBenchHeader, FullSizeComponents, UserState } from "./components/headers/headers";
import React from "react";
import { Button } from "@/components/ui/button";

enum BenchAccordionTypes {
    UTILITY = "UTILITY",
    UI_BENCH = "UIBENCH",
    UPLOAD_BENCH = "UPLOADBENCH",
}
function TestBenchAccordion() {
    const [value, setValue] = React.useState<string | BenchAccordionTypes.UPLOAD_BENCH>(BenchAccordionTypes.UPLOAD_BENCH);
    return (
        <Accordion className="w-full mb-12 mt-4" value={value} type="single" onValueChange={setValue} collapsible>
            <UtilityBench value={BenchAccordionTypes.UTILITY} />
            <UiBench value={BenchAccordionTypes.UI_BENCH} />
            <UploadBench value={BenchAccordionTypes.UPLOAD_BENCH} />
        </Accordion>
    );
}

const ColorPaletteShowcase = () => {
    return (
        <div className="p-6 bg-white">
            <h1 className="text-3xl font-bold mb-6 text-neutral-dark">Color Palette Showcase</h1>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-primary-main">Primary Colors</h2>
                <div className="flex space-x-4">
                    <div className="w-24 h-24 bg-primary-main flex items-center justify-center text-primary-text">Main</div>
                    <div className="w-24 h-24 bg-primary-light flex items-center justify-center text-primary-text">Light</div>
                    <div className="w-24 h-24 bg-primary-dark flex items-center justify-center text-primary-text">Dark</div>
                </div>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-secondary-main">Secondary Colors</h2>
                <div className="flex space-x-4">
                    <div className="w-24 h-24 bg-secondary-main flex items-center justify-center text-secondary-text">Main</div>
                    <div className="w-24 h-24 bg-secondary-light flex items-center justify-center text-secondary-text">Light</div>
                    <div className="w-24 h-24 bg-secondary-dark flex items-center justify-center text-secondary-text">Dark</div>
                </div>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-neutral-dark">Neutral Colors</h2>
                <div className="flex space-x-4">
                    <div className="w-24 h-24 bg-neutral-white border border-neutral-medium flex items-center justify-center text-neutral-dark">White</div>
                    <div className="w-24 h-24 bg-neutral-light flex items-center justify-center text-neutral-dark">Light</div>
                    <div className="w-24 h-24 bg-neutral-medium flex items-center justify-center text-neutral-dark">Medium</div>
                    <div className="w-24 h-24 bg-neutral-dark flex items-center justify-center text-neutral-white">Dark</div>
                </div>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-neutral-dark">Typography</h2>
                <p className="text-text-primary mb-2">Primary Text</p>
                <p className="text-text-secondary mb-2">Secondary Text</p>
                <p className="text-text-disabled">Disabled Text</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-neutral-dark">Buttons</h2>
                <div className="flex space-x-4">
                    <Button className="bg-primary-main text-primary-text hover:bg-primary-dark">Primary</Button>
                    <Button className="bg-secondary-main text-secondary-text hover:bg-secondary-dark">Secondary</Button>
                    <Button className="bg-neutral-medium text-neutral-dark hover:bg-neutral-dark hover:text-neutral-white">Neutral</Button>
                </div>
            </section>
        </div>
    );
};

function TestBench() {
    return (
        <div className="page flex-col pt-4 space-y-5">
            <ColorPaletteShowcase />
            <TestBenchHeader />
            <FullSizeComponents />
            <UserState />
            <TestBenchAccordion />
        </div>
    );
}

export default TestBench;

import React, { useCallback, useMemo, useRef, useState } from "react";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { instance } from "shared/api/signature";
import { CATEGORY_API_ENDPOINTS } from "shared/types/api";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Check, ChevronsUpDown, Plus, X } from "lucide-react";

interface Category {
    id: string;
    name: string;
}

const useDebounce = <T,>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};
interface UseCategoriesResult {
    categories: Category[];
    isLoading: boolean;
    hasMore: boolean;
    fetchCategories: (resetList?: boolean) => Promise<void>;
}

const useCategories = (searchTerm: string): UseCategoriesResult => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const fetchCategories = async (search, resetList: boolean = false) => {
        if (isLoading || (!hasMore && !resetList)) return;

        setIsLoading(true);
        try {
            const { data } = await instance.get(CATEGORY_API_ENDPOINTS.getCategories.url, {
                params: { page: resetList ? 1 : page, limit: 10, search: search },
            });
            const parsedData = CATEGORY_API_ENDPOINTS.getCategories.response.parse(data);
            setCategories((prev) => (resetList ? [...parsedData.data] : [...prev, ...parsedData.data]));
            setHasMore(parsedData.pagination.currentPage < parsedData.pagination.totalPages);
            setPage((prev) => (resetList ? 2 : prev + 1));
        } catch (error) {
            console.error("Error fetching categories:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories(searchTerm);
    }, [searchTerm]);

    return { categories, isLoading, hasMore, fetchCategories };
};

interface CategoryTagsSelectProps {
    selectedCategories: string[];
    onChange: (categories: string[]) => void;
}

const CategoryTagsSelect: React.FC<CategoryTagsSelectProps> = ({ selectedCategories, onChange }: CategoryTagsSelectProps) => {
    console.log(selectedCategories, "what is selected even");
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 300);
    const [customCategory, setCustomCategory] = useState("");

    const { categories, isLoading, hasMore, fetchCategories } = useCategories(debouncedSearch);

    const categoryMap = useMemo(() => Object.fromEntries(categories.map((cat) => [cat.id, cat.name])), [categories]);

    const handleCategoryChange = useCallback(
        (selectedCategory: string) => {
            onChange(
                selectedCategories.includes(selectedCategory) ? selectedCategories.filter((cat) => cat !== selectedCategory) : [...selectedCategories, selectedCategory]
            );
        },
        [selectedCategories, onChange]
    );

    const removeCategory = useCallback(
        (categoryToRemove: string) => {
            onChange(selectedCategories.filter((cat) => cat !== categoryToRemove));
        },
        [selectedCategories, onChange]
    );

    const addCustomCategory = useCallback(() => {
        if (customCategory.trim() && !selectedCategories.includes(customCategory.trim())) {
            onChange([...selectedCategories, customCategory.trim()]);
            setCustomCategory("");
        }
    }, [customCategory, selectedCategories, onChange]);

    const handleSearchChange = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const ref = useRef<HTMLButtonElement>(null);
    const width = ref.current?.offsetWidth ?? 500;

    return (
        <div className="space-y-2 ">
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button ref={ref} variant="outline" role="combobox" aria-expanded={open} className="w-full justify-between">
                        Select categories
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className={`max-w-full p-0 `} style={{ width: width + "px" }}>
                    <Command className="w-full max-w-full">
                        <CommandInput placeholder="Search categories..." value={search} onValueChange={handleSearchChange} />
                        <CommandEmpty>No category found.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-y-auto  ">
                            {categories.length > 0
                                ? categories.map((option) => (
                                      <CommandItem key={option.id} onSelect={() => handleCategoryChange(option.id)}>
                                          <Check className={cn("mr-2 h-4 w-4", selectedCategories.includes(option.id) ? "opacity-100" : "opacity-0")} />
                                          {option.name}
                                      </CommandItem>
                                  ))
                                : !isLoading && <CommandItem>No categories found</CommandItem>}
                            {isLoading && <CommandItem>Loading...</CommandItem>}
                            {!isLoading && hasMore && <CommandItem onSelect={() => fetchCategories()}>Load more...</CommandItem>}
                        </CommandGroup>
                        <CommandGroup>
                            <CommandItem>
                                <div className="flex items-center space-x-2">
                                    <input
                                        className="flex-grow"
                                        placeholder="Add custom category"
                                        value={customCategory}
                                        onChange={(e) => setCustomCategory(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                addCustomCategory();
                                            }
                                        }}
                                    />
                                    <Button size="sm" onClick={addCustomCategory}>
                                        <Plus className="h-4 w-4" />
                                    </Button>
                                </div>
                            </CommandItem>
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>

            <div className="flex flex-wrap gap-2 mt-2 ">
                {selectedCategories.map((category) => (
                    <Badge key={category} variant="secondary" className="flex items-center gap-1">
                        {categoryMap[category] || category}
                        <button onClick={() => removeCategory(category)} className="text-xs">
                            <X size={12} />
                        </button>
                    </Badge>
                ))}
            </div>
        </div>
    );
};

export default CategoryTagsSelect;

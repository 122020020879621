import Logo from "@/Logo/Logo";
import { Button } from "@/components/ui/button";
import SliderProvider, { FooterProgressBar } from "shared/providers/SliderProvider";
import { BaseRoutes } from "shared/helpers/const";
import { Outlet, useLoaderData, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React from "react";

function CreateTourHeader() {
    const nav = useNavigate();
    const navigate = () => nav(BaseRoutes.PROVIDERS_TOURS);
    return (
        <header className="h-[60px] shadow-sm w-full md:h-[100px] flex justify-between items-center content-padding">
            <Logo />
            <Button onClick={navigate}>Закончить позже</Button>
        </header>
    );
}

function CreateTourFooter() {
    return (
        <footer className="w-full h-[65px] md:h-[80px] border-4 flex flex-col ">
            <FooterProgressBar />
            <div className="w-full h-full flex justify-between items-center content-padding">
                <Button>Prev</Button>
                <Button>Next</Button>
            </div>
        </footer>
    );
}

/**
 * PATH: "/tours/create"
 */

export const getSearchParamsAsObject = (search: URLSearchParams): Record<string, string> => {
    const params: Record<string, string> = {};
    for (const [key, value] of search.entries()) {
        params[key] = value;
    }
    return params;
};

const useTourLoader = () => {
    const loader: unknown | { id: string } = useLoaderData();
    const count = React.useRef(0);
    const [search, setSearchParams] = useSearchParams();

    const t = getSearchParamsAsObject(search);
    return t;
};

function CreateTour({}) {
    const t = useTourLoader();

    console.log(t);

    return (
        <SliderProvider>
            <div className="w-full flex flex-col min-h-[100dvh]     ">
                <>
                    <CreateTourHeader />
                    <Outlet />
                    <CreateTourFooter />
                </>
            </div>
        </SliderProvider>
    );
}

export default CreateTour;

import React from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { refundPolicies } from "shared/types/api";

const RefundPolicyPanel = ({ refundPolicy, updateRefundPolicy }) => {
    const handlePolicyChange = (policyId: string, checked: boolean) => {
        let updatedPolicies;
        if (checked) {
            updatedPolicies = [...refundPolicy, policyId];
        } else {
            updatedPolicies = refundPolicy.filter((id) => id !== policyId);
        }
        updateRefundPolicy(updatedPolicies);
    };

    return (
        <Card className="w-full">
            <CardContent>
                <CardHeader>Refund Policy</CardHeader>
                <div className="space-y-6">
                    {refundPolicies.map((policy) => (
                        <div key={policy.id} className="flex items-start space-x-2">
                            <Checkbox
                                id={policy.id}
                                checked={refundPolicy.includes(policy.id)}
                                onCheckedChange={(checked) => handlePolicyChange(policy.id, !!checked)}
                                className="mt-1"
                            />
                            <div className="grid gap-1.5 leading-none">
                                <Label htmlFor={policy.id}>{policy.name}</Label>
                                <p className="text-sm text-muted-foreground">{policy.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default RefundPolicyPanel;

import React from "react";

type EllipsisProps = {
    color?: string;
};

export default function Ellipsis({ color }: EllipsisProps) {
    color = color ?? "black";
    const id = React.useId();
    const dots = [id + "first", id + "second", id + "third"];

    return (
        <div className="loader">
            {dots.map((d) => (
                <div key={d} style={{ backgroundColor: color }} className="dot" />
            ))}
        </div>
    );
}

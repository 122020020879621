import { UploadCloud } from "lucide-react";
import { DropzoneState } from "react-dropzone";
import i18n from "entities/i18n/i18n";

import { acceptMimeTypes } from "../utils";
import { Input } from "@/components/ui/input";

type DragUpload = { dropzone: DropzoneState };

function DragLabel({ dropzone }: DragUpload) {
    const { getRootProps, getInputProps, isDragAccept, isDragReject, open } = dropzone;

    return (
        <div
            {...getRootProps()}
            onClick={(e) => {
                e.stopPropagation();
                open();
            }}
            className={`relative flex flex-col items-center justify-center w-full py-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors duration-200 ease-in-out ${
                isDragAccept ? "border-green-500" : isDragReject ? "border-red-500" : "border-gray-300"
            }`}
        >
            <div className="text-center">
                <div className="border p-2 rounded-md max-w-min mx-auto">
                    <UploadCloud size={20} />
                </div>
                <p className="mt-2 text-sm text-gray-600">
                    <span className="font-semibold">{i18n.t("common.DragLabel.drag")}</span>
                </p>
                <p className="text-xs text-gray-500">{i18n.t("common.DragLabel.click")}</p>
            </div>
            <Input accept={acceptMimeTypes} {...getInputProps()} id="dropzone-file" type="file" className="hidden" onClick={(e) => e.stopPropagation()} />
            {isDragReject && <p className="mt-2 text-sm text-red-500">{"Drag don't work, please tell about it support. support@adventu.ru"}</p>}
        </div>
    );
}

export { DragLabel };

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ModalWindowProps } from "shared/providers/ModalWindow";
import SignUpForm from "features/user/forms/SignUpForm";

function SignUpModal({ close }: ModalWindowProps) {
    return (
        <Dialog open>
            <DialogContent close={close} className="h-full overflow-scroll md:h-auto md:overflow-hidden">
                <DialogHeader>
                    <DialogTitle>Регистрация</DialogTitle>
                </DialogHeader>
                <SignUpForm close={close} />
            </DialogContent>
        </Dialog>
    );
}

export default SignUpModal;

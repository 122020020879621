import { Button } from "@/components/ui/button";
import { useRef } from "react";
import { Link } from "react-router-dom";

import { BaseRoutes } from "shared/helpers/const";
import { proxy_route } from "shared/api/signature";
import { getFormattedDate } from "shared/helpers/utils";

type TourListComponent = { name?: string; description?: string; daysCount?: number; date?: Date; id?: string; image?: string };

function TourListPreview({ name = "Tour name", description = "Description of the tour", daysCount = 3, date = new Date(), id = "test", image }: TourListComponent) {
    const route = BaseRoutes.TOUR + id;
    const date_ = getFormattedDate(date);
    const ref = useRef<HTMLAnchorElement | null>(null);

    const click = () => (ref.current ? ref.current.click() : null);
    const img = image ?? proxy_route + "/static/stock.jpg";
    return (
        <Link to={route} ref={ref} className=" m-4 select-none">
            <div className="w-full h-[335px] max-h-[335px] md:h-full md:w-full md:max-w-[373px]  box-border md:max-h-[496px] bg-white hover:text-white text-black hover:bg-[#5FB7C5] flex flex-col group rounded-[6.76px] p-[14.67px] hover:pointer">
                <img src={img} className="w-[300px]  h-full max-h-[155px] md:max-w-[329px] md:max-h-[229px] rounded-[6.76px] self-center justify-center bg-sky-500/75" />

                <div className="flex-1 h-full pt-4">
                    <h3>{name}</h3>
                    <h4>{description}</h4>
                    <div className="w-full justify-between flex mt-1">
                        <p>{date_}</p> <p>{daysCount} дня в туре</p>
                    </div>
                </div>
                <Button variant={"designed"} onClick={click} className="max-h-[42px] w-[138px] mb-[20px] mt-4 md:w-[205px] md:max-h-[63px]">
                    Buy
                </Button>
            </div>
        </Link>
    );
}

export default TourListPreview;

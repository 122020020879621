import { createContext, useContext, useCallback, useEffect, useRef, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { steps } from "./Provider";
import { motion } from "framer-motion";
import { TourCreationProvider, useTourCreation } from "../State";
import { PublishButtonStd } from "@/TourPreview/ProvidersTourPreview";

const TourCreatorContent: React.FC = () => {
    const { step, setStep, tourData } = useTourCreation();
    const CurrentStep = steps[step].component;
    const last = steps.length - 1;
    const navigate = useNavigate();

    const updateUrlParams = (newStep: number) => {
        const params = new URLSearchParams(location.search);
        params.set("step", newStep.toString());
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    };

    const handleNext = () => {
        if (step < last) {
            const newStep = step + 1;
            setStep(newStep);
            updateUrlParams(newStep);
        }
    };

    const handlePrevious = () => {
        if (step > 0) {
            const newStep = step - 1;
            setStep(newStep);
            updateUrlParams(newStep);
        }
    };

    return (
        <div className="h-[100dvh] p-0 m-0 bg-gradient-to-b from-blue-50 to-green-50 flex flex-col">
            <div className="mb-4 p-4 pb-1">
                <h2 className="text-2xl font-bold text-blue-700">{steps[step].label}</h2>
                <div className="mt-2 bg-gray-200 h-2 rounded-full">
                    <div
                        className="bg-blue-500 h-full rounded-full transition-all duration-300 ease-in-out"
                        style={{ width: `${((step + 1) / steps.length) * 100}%` }}
                    ></div>
                </div>
            </div>

            <div className="flex-grow overflow-y-auto">
                <CurrentStep />
            </div>

            {step !== 0 && (
                <div className="flex justify-between mt-4 w-full p-4 pr-6 pl-6 absolute bottom-0">
                    <button onClick={handlePrevious} disabled={step === 0} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg disabled:opacity-50">
                        Previous
                    </button>
                    {step !== last ? (
                        <Button onClick={handleNext} disabled={step === steps.length - 1} className="px-4 py-2  rounded-lg disabled:opacity-50">
                            Next
                        </Button>
                    ) : (
                        <motion.div className="fixed bottom-0 right-0 p-4 " initial={{ y: 100 }} animate={{ y: 0 }} transition={{ delay: 0.5, duration: 0.5 }}>
                            <PublishButtonStd tourId={tourData.id} />
                        </motion.div>
                    )}
                </div>
            )}
        </div>
    );
};

export const TourCreator: React.FC = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const initialStep = parseInt(params.get("step") || "0");

    return (
        <TourCreationProvider initialStep={initialStep}>
            <TourCreatorContent />
        </TourCreationProvider>
    );
};

import { createSelector, createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AxiosError } from "axios";
import { Construction } from "lucide-react";
import React from "react";
import { API_ROUTES, ResponseCodes, instance, logoutReq } from "shared/api/signature";
import { useAppDispatch } from "shared/helpers/utils";
import { AUTH_API_ENDPOINTS, ResponseUserDataSchema, UpdateUserSettingsData } from "shared/types/api";
import { z } from "zod";

export type User = z.infer<typeof ResponseUserDataSchema>;

type UserSlice = { user: User | null; loggedIn: boolean };

type RegistrationForm = z.infer<typeof AUTH_API_ENDPOINTS.signup.body>;
type ResponseUserData = z.infer<typeof AUTH_API_ENDPOINTS.signup.response>;

const defaultSlice: UserSlice = { user: null, loggedIn: false };

const sessionCheckThunk = createAsyncThunk("user/session", async (_, thunk) => {
    try {
        const response = await instance.get(API_ROUTES.userSession);
        return response.data.data;
    } catch (e) {
        console.error("Session check failed:", e);
        return thunk.rejectWithValue(false);
    }
});
export const updateUserImage = createAsyncThunk("user/updateImage", async ({ imageData, img }: { imageData: FormData; img: string }, { rejectWithValue }) => {
    try {
        const updateProfilePicture = AUTH_API_ENDPOINTS.updateProfilePicture;
        const response = await instance.request({
            ...updateProfilePicture,
            url: updateProfilePicture.url,
            data: imageData,
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.ok) {
            return img;
        } else {
            throw new Error("Failed to update profile picture");
        }
    } catch (error) {
        if (error instanceof AxiosError) return rejectWithValue(error.response?.data || "An error occurred while updating the profile picture");
    }
});
const logoutThunk = createAsyncThunk("user/logout", async (_, thunk) => {
    try {
        const response = await logoutReq();
        if (response.status === ResponseCodes.SUCCESS) return true;
        return false;
    } catch (error) {
        console.error("Logout failed:", error);
        return false;
    }
});

export const updateUserSettings = createAsyncThunk("user/updateSettings", async (updates: UpdateUserSettingsData, { rejectWithValue }) => {
    try {
        const response = await instance.post<typeof AUTH_API_ENDPOINTS.updateUserSettings.response>(AUTH_API_ENDPOINTS.updateUserSettings.path, updates);
        return response.data.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "An error occurred");
    }
});

const user = createSlice({
    name: "user",
    initialState: defaultSlice,
    reducers: {
        setUserData: (state, action: PayloadAction<ResponseUserData["data"]>) => {
            state.loggedIn = true;
            state.user = action.payload;
        },
        logout: () => defaultSlice,
        setImage: (state, action: PayloadAction<{ imageLocation: string }>) => {
            if (state.user) {
                state.user.imageLocation = action.payload.imageLocation;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sessionCheckThunk.rejected, (state) => {
                state.loggedIn = false;
                state.user = null;
            })
            .addCase(sessionCheckThunk.fulfilled, (state, action: PayloadAction<ResponseUserData["data"]>) => {
                state.loggedIn = true;
                state.user = action.payload;
            })
            .addCase(logoutThunk.fulfilled, (state, action) => {
                console.log("im here", state, "ad");
                return defaultSlice;
            })
            .addCase(updateUserImage.fulfilled, (state, action: PayloadAction<string>) => {
                if (state.user) {
                    state.user.imageLocation = action.payload;
                }
            });
    },
});

const userSelect = (state: RootState) => state.user;

const role = createSelector(userSelect, (state) => state.user?.role);

export const isLogged = createSelector(userSelect, (state) => state.loggedIn);
const name = createSelector(userSelect, (state) => state.user?.name);
const data = createSelector(userSelect, (state) => state.user);
const email = createSelector(userSelect, (state) => state.user?.email);
const verification = createSelector(userSelect, (state) => state.user?.verification);
const preview = createSelector(userSelect, (state) => {
    if (!state.user) return null;
    const { verification, role, phone, email, imageLocation, ...user } = state.user;
    return user;
});

const image = createSelector(userSelect, (state) => state.user?.imageLocation);

export const UserSelector = { isLogged, image, preview, name, role, data, email, verification };

export type { UserSlice, RegistrationForm };
export const { setUserData, logout, setImage } = user.actions;
export { sessionCheckThunk, logoutThunk };
export default user.reducer;

const useUserSession = () => {
    const ref = React.useRef(false);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!ref.current) {
            ref.current = true;
            dispatch(sessionCheckThunk());
        }
    }, [dispatch]);
    return null;
};

export { useUserSession };

import { z } from 'zod';

/**
 *  SCHEMAS AND TYPES FOR API
 */

const errorKeys = {
    nameMinLength: 'validation.nameMinLength',
    surnameMinLength: 'validation.surnameMinLength',
    invalidEmail: 'validation.invalidEmail',
    passwordMinLength: 'validation.passwordMinLength',
    invalidPhone: 'validation.invalidPhone',
    invalidRole: 'validation.invalidRole',
};

/**
 * BE CAREFUL, IF YOU SEE THIS FILE IN UI PART
 * IT CAN'T BE CHANGED BECAUSE EVERYTHING HERE WILL BE OVERWRITTEN
 */
const flexibleDateSchema = z.union([z.string(), z.date()]).transform((val) => {
    if (typeof val === 'string') {
        return new Date(val);
    }
    return val;
});
const objectIdRegex = /^[0-9a-fA-F]{24}$/;
export const uuid = z.string().uuid();
export const ObjectIdSchema = z.string().refine((val) => val === undefined || isValidObjectId(val), { message: 'Invalid ObjectId format' });
const ReservationStatusEnum = z.enum(['EXPIRED', 'CONFIRMED', 'PENDING', 'CANCELLED']);
const PaymentStatusEnum = z.enum(['PENDING', 'COMPLETED', 'FAILED', 'REFUNDED']);
export type PaymentStatus = z.infer<typeof PaymentStatusEnum>;
export const PaymentStatus = PaymentStatusEnum.enum;

export const BookingStatusEnum = z.enum(['CONFIRMED', 'CANCELLED', 'PENDING']);
export const ListingStatusEnum = z.enum(['AVAILABLE', 'FULLY_BOOKED']);
export const DurationTypeEnum = z.enum(['SINGLE_DAY', 'MULTI_DAY']);
export const DifficultyEnum = z.enum(['EASY', 'MODERATE', 'CHALLENGING']);
export const LanguageEnum = z.enum(['EN', 'ES', 'FR', 'DE', 'IT', 'RU', 'ZH', 'JA', 'AR']);
export const SeasonalityEnum = z.enum(['YEAR_ROUND', 'SPRING', 'SUMMER', 'AUTUMN', 'WINTER']);
export const RefundPolicyEnum = z.enum(['NO_REFUND', 'PARTIAL_REFUND', 'FULL_REFUND']);
export const CancellationPolicyEnum = z.enum(['FLEXIBLE', 'MODERATE', 'STRICT']);
export const DepositPolicyTypeEnum = z.enum(['NO_DEPOSIT', 'PERCENTAGE', 'FIXED_AMOUNT']);
export const PaymentMethodEnum = z.enum(['CASH', 'CREDIT_CARD', 'ONLINE_PAYMENT', 'BANK_TRANSFER']);
export const TourStatusEnum = z.enum(['DRAFT', 'PROCESSING', 'PUBLISHED', 'ARCHIVED']);
export const NotificationMethodEnum = z.enum(['EMAIL', 'PUSH', 'SMS']);
export const TourStatusSchema = z.enum(['DRAFT', 'PROCESSING', 'PUBLISHED', 'ARCHIVED']);
export const CurrencyEnum = z.enum(['USD', 'EUR', 'RUB']);
export const RoleEnum = z.enum(['TRAVELLER', 'PROVIDER', 'ADMIN', 'GUIDE']);

export type UserBooking = z.infer<typeof UserBookingSchema>;
export type UserBookingsResponse = z.infer<typeof UserBookingsResponseSchema>;

export const LocationCategoryEnum = z.enum([
    'HISTORICAL_SITE',
    'MUSEUM',
    'LANDMARK',
    'NATURAL_ATTRACTION',
    'RELIGIOUS_SITE',
    'ENTERTAINMENT_VENUE',
    'SHOPPING_AREA',
    'NEIGHBORHOOD',
    'RESTAURANT',
    'PARK',
    'THEATER',
    'OTHER',
]);

const AccessibilityOptionEnum = z.enum([
    'HEARING_IMPAIRED_FRIENDLY',
    'VISUALLY_IMPAIRED_FRIENDLY',
    'MOBILITY_FRIENDLY',
    'SENSORY_FRIENDLY',
    'COGNITIVE_FRIENDLY',
    'SERVICE_ANIMALS_ALLOWED',
    'SIGN_LANGUAGE_AVAILABLE',
    'ACCESSIBLE_TRANSPORT',
]);

const FoodOptionEnum = z.enum(['VEGETARIAN', 'VEGAN', 'GLUTEN_FREE', 'KOSHER', 'HALAL']);

export type FoodOption = z.infer<typeof FoodOptionEnum>;
export type Currency = z.infer<typeof CurrencyEnum>;
export type BookingStatus = z.infer<typeof BookingStatusEnum>;
export type ListingStatus = z.infer<typeof ListingStatusEnum>;
export type DurationType = z.infer<typeof DurationTypeEnum>;
export type Difficulty = z.infer<typeof DifficultyEnum>;
export type Language = z.infer<typeof LanguageEnum>;
export type Seasonality = z.infer<typeof SeasonalityEnum>;
export type RefundPolicy = z.infer<typeof RefundPolicyEnum>;
export type CancellationPolicy = z.infer<typeof CancellationPolicyEnum>;
export type DepositPolicyType = z.infer<typeof DepositPolicyTypeEnum>;
export type PaymentMethod = z.infer<typeof PaymentMethodEnum>;
export type TourStatus = z.infer<typeof TourStatusEnum>;
export type AccessibilityOption = z.infer<typeof AccessibilityOptionEnum>;

// Exporting enum values for use in TypeScript
export const FoodOption = FoodOptionEnum.enum;
export const Currency = CurrencyEnum.enum;
export const BookingStatus = BookingStatusEnum.enum;
export const ListingStatus = ListingStatusEnum.enum;
export const DurationType = DurationTypeEnum.enum;
export const Difficulty = DifficultyEnum.enum;
export const Language = LanguageEnum.enum;
export const Seasonality = SeasonalityEnum.enum;
export const RefundPolicy = RefundPolicyEnum.enum;
export const CancellationPolicy = CancellationPolicyEnum.enum;
export const DepositPolicyEnum = DepositPolicyTypeEnum.enum;
export const PaymentMethod = PaymentMethodEnum.enum;
export const TourStatus = TourStatusEnum.enum;
export const AccessibilityOption = AccessibilityOptionEnum.enum;

export type LocationCategory = z.infer<typeof LocationCategoryEnum>;
export const LocationCategory = LocationCategoryEnum.enum;
export type NotificationMethod = z.infer<typeof NotificationMethodEnum>;
export const NotificationMethod = NotificationMethodEnum.enum;

export const PaginationSchema = z.object({
    totalItems: z.number(),
    totalPages: z.number(),
    currentPage: z.number(),
    itemsPerPage: z.number(),
});

const stringToNumber = z.string().transform((val, ctx) => {
    const parsed = parseInt(val, 10);
    if (isNaN(parsed)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Not a number' });
        return z.NEVER;
    }
    return parsed;
});

const CategorySchema = z.object({ id: z.string(), name: z.string() });

export const UserBookingSchema = z.object({
    specialRequests: z.string().optional().nullable(),
    participants: z.number(),
    currency: CurrencyEnum,
    tourListingId: uuid,
    tourId: uuid,
});

const UserVerificationSchema = z.object({
    isEmailVerified: z.boolean(),
    isPhoneVerified: z.boolean(),
    isRegistrationDone: z.boolean(),
    isUserActive: z.boolean(),
});

const EmailPreferencesSchema = z.object({
    marketingEmails: z.boolean().optional(),
    newsletterEmails: z.boolean().optional(),
    bookingConfirmations: z.boolean().optional(),
    tourUpdates: z.boolean().optional(),
    accountNotifications: z.boolean().optional(),
});

const NotificationSettingsSchema = z.object({
    preferredMethod: NotificationMethodEnum.optional(),
    bookingNotifications: z.boolean().optional(),
    promoNotifications: z.boolean().optional(),
    systemNotifications: z.boolean().optional(),
});

const UpdateUserSettingsSchema = z.object({
    name: z.string().min(3, { message: 'validation.nameMinLength' }).optional(),
    surname: z.string().min(3, { message: 'validation.surnameMinLength' }).optional(),
    patronymic: z.string().optional(),
    emailPreferences: EmailPreferencesSchema.optional(),
    notificationSettings: NotificationSettingsSchema.optional(),
});

export const AddressComponentSchema = z.object({
    id: uuid.optional(),
    longName: z.string(),
    shortName: z.string(),
    types: z.array(z.string()),
});
export const ReservationSchema = z.object({
    id: uuid,
    tourListingId: uuid,
    travellerId: uuid.optional(),
    status: ReservationStatusEnum,
    totalAmount: z.number(),
    currency: CurrencyEnum,
    participants: z.number().int().positive(),
    expiresAt: z.date(),
    createdAt: z.date(),
});

export const BookingSchema = z.object({
    id: uuid,
    tourListingId: uuid,
    providerId: uuid.optional(),
    travellerId: uuid.optional(),
    tourDate: z.date().optional(),
    participants: z.number().int().positive(),
    totalAmount: z.number(),
    currency: CurrencyEnum,
    exchangeRate: z.number().optional(),
    specialRequests: z.string().optional(),
    reservationId: uuid.optional(),
    status: BookingStatusEnum.default('PENDING'),
    paymentStatus: PaymentStatusEnum.default('PENDING'),
    paymentMethod: PaymentMethodEnum,

    createdAt: flexibleDateSchema.optional(),
    updatedAt: flexibleDateSchema.optional(),
    expiresAt: flexibleDateSchema.optional(),
});

export const CreateBookingResponseSchema = z.object({
    ok: z.boolean(),
    data: z.object({
        reservation: ReservationSchema,
        booking: BookingSchema,
    }),
});
export const UserBookingsResponseSchema = z.object({
    ok: z.boolean(),
    data: z.array(UserBookingSchema),
    pagination: PaginationSchema,
});

export const LocationSchema = z.object({
    id: uuid.optional(),
    name: z.string().nullable(),
    address: z.string(),
    formattedAddress: z.string().nullable(),
    placeId: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    locality: z.string().nullable().optional(),
    sublocality: z.string().nullable().optional(),
    types: z.array(z.string()).optional(),
    utcOffset: z.number().nullable().optional(),
    vicinity: z.string().nullable().optional(),
    latitude: z.number(),
    longitude: z.number(),
    category: LocationCategoryEnum.optional().nullable(),
    addressComponents: z.array(AddressComponentSchema).optional(),
    createdAt: flexibleDateSchema.optional(),
    updatedAt: flexibleDateSchema.optional(),
});

export const TourLocationSchema = z.object({
    id: uuid.optional(),
    tourId: uuid.optional(),
    locationId: uuid.optional(),
    order: z.number().optional(),
    description: z.string().optional(),
    tags: z.array(z.string()).optional(),
    location: LocationSchema,
});

export type TourLocation = z.infer<typeof TourLocationSchema>;

export const ResponseUserDataSchema = z.object({
    name: z.string(),
    surname: z.string(),
    patronymic: z.string().optional(),
    email: z.string().email(),
    phone: z.string(),
    imageLocation: z.string().nullable(),
    verification: UserVerificationSchema,
    role: RoleEnum,
});

export const AUTH_API_ENDPOINTS = {
    signup: {
        method: 'POST',
        url: '/v1/auth/signup',
        body: z.object({
            name: z.string().min(3, { message: errorKeys.nameMinLength }),
            surname: z.string().min(3, { message: errorKeys.surnameMinLength }),
            patronymic: z.string().optional(),
            email: z.string().email(),
            password: z.string().min(8),
            phone: z
                .string()
                .regex(/^\+[1-9]\d{1,14}$/)
                .min(7),

            role: z.enum(['TRAVELLER', 'PROVIDER']),
        }),
        response: z.object({ ok: z.boolean(), data: ResponseUserDataSchema }),
    },
    login: {
        method: 'POST' as const,
        url: '/v1/auth/login',
        body: z.object({
            email: z.string().email({ message: 'validation.invalidEmail' }),
            password: z.string().min(8, { message: 'validation.passwordMinLength' }),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                user: ResponseUserDataSchema,
                token: z.string(),
            }),
        }),
    },
    updateUserSettings: {
        method: 'POST',
        url: '/v1/user/settings',
        body: UpdateUserSettingsSchema,
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                id: uuid,
                name: z.string(),
                surname: z.string(),
                patronymic: z.string().optional(),
                email: z.string().email(),
                emailPreferences: EmailPreferencesSchema,
                notificationSettings: NotificationSettingsSchema,
                phone: z.string().optional().nullable(),
                imageLocation: z.string().optional().nullable(),
                verification: z.any(),
            }),
        }),
    },

    updateProfilePicture: {
        method: 'POST',
        url: '/v1/user/photo',
        response: z.object({ ok: z.boolean() }),
    },

    finishRegistration: {
        method: 'POST',
        url: '/v1/user/finish-registration',
        body: z.object({ provider: z.number().min(0).max(1), phone: z.string().regex(/^\+[1-9]\d{1,14}$/), companyName: z.string().optional() }),
        response: z.object({ ok: z.boolean(), user: ResponseUserDataSchema }),
    },
};

type AccessibilityOptionInput = {
    value: AccessibilityOption;
    label: string;
}[];

type LanguageOption = { value: Language; label: string };

const isValidObjectId = (value: string): boolean => {
    return objectIdRegex.test(value);
};

const DurationSchema = z.object({
    days: z.number().int().min(0),
    hours: z.number().int().min(0).max(23),
});

export type Duration = z.infer<typeof DurationSchema>;

const ItineraryDaySchema = z.object({
    id: uuid.optional(),
    dayNumber: z.number().int().positive(),
    title: z.string().min(0, { message: 'validation.titleRequired' }).optional(),
    description: z.string().optional(),
    locationIds: z.array(z.string()),
});

const HighlightSchema = z.object({
    photo: z.string().url(),
    commentary: z.string().min(1, { message: 'validation.commentaryRequired' }),
});

export const DepositPolicySchema = z.object({
    id: z.string().uuid().optional(),
    type: DepositPolicyTypeEnum.default('NO_DEPOSIT'),
    amount: z.number().nullable().optional().default(0.0),
    currency: CurrencyEnum.nullable().optional().default('USD'),
    pricingId: z.string().uuid().optional(),
});

export const DiscountLevelSchema = z.object({
    id: z.string().uuid().optional(),
    people: z.number().int().positive().default(1),
    discount: z.number().min(0).max(100).default(1),
    pricingId: z.string().uuid().optional(),
});

export const PricingSchema = z.object({
    id: z.string().uuid().optional(),
    basePrice: z.number().positive().default(1.0),
    mainCurrency: CurrencyEnum.default('USD'),
    currencyPrices: z
        .record(z.object({ amount: z.number().positive(), isUserSet: z.boolean() }))
        .nullable()
        .optional(),
    cancellationPolicy: CancellationPolicyEnum.nullable().optional().default('STRICT'),
    refundPolicy: z.array(RefundPolicyEnum).nullable().optional(),
    depositPolicy: DepositPolicySchema.nullable().optional(),
    acceptedPayments: z.array(PaymentMethodEnum).nullable().optional(),
    discountLevels: z.array(DiscountLevelSchema).nullable().optional(),
    tourId: z.string().uuid().optional(),
});

export type ItineraryDay = z.infer<typeof ItineraryDaySchema>;
export type TourHighlight = z.infer<typeof HighlightSchema>;
export type DepositPolicy = z.infer<typeof DepositPolicySchema>;
export type DiscountLevel = z.infer<typeof DiscountLevelSchema>;

export const AdditionalInfoSchema = z.object({
    highlights: z.array(HighlightSchema).optional(),
    includedItems: z.array(z.string()).optional(),
    notIncludedItems: z.array(z.string()).optional(),
    whatToBring: z.array(z.string()).optional(),
    categories: z.array(z.string()).optional(),
    accessibility: z.array(AccessibilityOptionEnum).optional(),
    seasonality: SeasonalityEnum.optional(),
    privateGroupOption: z.boolean().optional().nullable(),
    foodOptions: z.array(FoodOptionEnum).optional(),
});

export type AdditionalInfo = z.infer<typeof AdditionalInfoSchema>;
export const TourImageSchema = z.object({
    id: z.string(),
    url: z.string(),
    altText: z.string().nullable(),
    width: z.number().nullable(),
    height: z.number().nullable(),
    size: z.number().nullable(),
    format: z.string().nullable(),
    srcset: z.string().nullable(),
    placeholder: z.string().nullable(),
    provider: z.string(),
    bucket: z.string(),
    key: z.string(),
    order: z.number().optional().nullable(),
    tourId: z.string().nullable(),
    createdAt: flexibleDateSchema.optional(),
    updatedAt: flexibleDateSchema.optional(),
});

export const TourSchema = z.object({
    id: uuid,
    name: z.string().optional(),
    description: z.string().optional(),
    durationType: DurationTypeEnum.optional(),
    maxParticipants: z.number().int().positive().optional(),
    minAge: z.number().int().min(0).optional(),
    difficulty: DifficultyEnum.optional(),
    languages: z.array(LanguageEnum).optional(),
    categoryTags: z.array(z.string()).optional(),
    isActive: z.boolean().optional(),
    status: TourStatusEnum.optional(),
    categoriesId: z.array(z.string()).optional(),
    images: z.array(TourImageSchema).optional(),
    duration: DurationSchema.optional(),
    tourLocations: z.array(TourLocationSchema).optional(),
    itinerary: z.array(ItineraryDaySchema).optional(),
    additionalInfo: AdditionalInfoSchema.optional(),
    pricing: PricingSchema.optional(),
});

export type UpdateBodyType = z.infer<typeof TourSchema>;

const TourListingSchema = z.object({
    id: uuid.optional(),
    tourId: uuid,
    date: flexibleDateSchema.optional(),
    startTime: flexibleDateSchema.optional(),
    endTime: flexibleDateSchema.optional(),
    maxParticipants: z.number().int().positive(),
    currentBookings: z.number().int().min(0),
    basePrice: z.number().positive(),
    mainCurrency: CurrencyEnum,
    currencyPrices: z.record(z.number().positive()),
    isActive: z.boolean(),
    status: ListingStatusEnum,
});

const ListingsFilterSchema = z.object({
    tourId: uuid,
    startDate: flexibleDateSchema.optional(),
    endDate: flexibleDateSchema.optional(),
    status: ListingStatusEnum.optional(),
});

const UpdateListingSchema = z.object({
    date: flexibleDateSchema,
    startTime: flexibleDateSchema,
    endTime: flexibleDateSchema,
    maxParticipants: z.number().int().positive().optional(),
    basePrice: z.number().positive().optional(),
    mainCurrency: z.enum(['USD', 'EUR', 'RUB']).optional(),
    currencyPrices: z.record(z.number().positive()).optional(),
    isActive: z.boolean().optional(),
});

const BulkUpsertListingsSchema = z.object({
    listings: z.array(
        z.object({
            id: uuid.optional(),
            tourId: uuid,
            updates: UpdateListingSchema,
        })
    ),
});

const PaymentDetails = z.object({
    cardType: z.string().optional(),
    last4: z.string().length(4).optional(),
    expirationMonth: z.number().min(1).max(12).optional(),
    expirationYear: z.number().optional(),
    bankAccountLast4: z.string().length(4).optional(),
    bankName: z.string().optional(),
});

const CustomerSchema = z.object({
    id: uuid.optional(),
    name: z.string(),
    email: z.string().email(),
    phone: z.string().optional(),
});

const BookingDetailsSchema = BookingSchema.extend({
    tour: z.object({
        id: uuid.optional(),
        name: z.string(),
        description: z.string(),
        duration: z.object({
            days: z.number().int().nonnegative(),
            hours: z.number().int().nonnegative(),
        }),
        meetingPoint: z.object({
            address: z.string(),
            latitude: z.number(),
            longitude: z.number(),
        }),
    }),
    cancellationPolicy: z.string(),
    refundAmount: z.number().nonnegative().optional(),
});

type ENDPOINT = {
    method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;
    params: z.ZodType<any, any>;
    query: z.ZodType<any, any>;
    body: z.ZodType<any, any>;
    response: z.ZodType<any, any>;
};

class EndpointFactory {
    static create(config: Partial<ENDPOINT> & Pick<ENDPOINT, 'method' | 'url' | 'response'>): ENDPOINT {
        return { params: z.any(), query: z.any(), body: z.any(), ...config };
    }
}

const createTourSchema: ENDPOINT = EndpointFactory.create({
    method: 'POST',
    url: '/v1/provider/tour',
    response: z.object({ ok: z.boolean(), data: TourSchema.extend({ id: uuid, createdAt: z.string().optional() }) }),
});

export const GetToursQuerySchema = z.object({
    page: z.string().optional().default('0').transform(Number),
    limit: z.string().optional().default('10').transform(Number),
    status: z
        .string()
        .optional()
        .transform((val) => (val === 'all' ? ['all'] : val ? (val.split(',') as TourStatus[]) : undefined)),
});

export type ProviderGetToursQuery = z.infer<(typeof PROVIDER_TOUR_API_ENDPOINTS)['getTours']['query']>;
export type ProviderGetToursResponse = z.infer<(typeof PROVIDER_TOUR_API_ENDPOINTS)['getTours']['response']>;
export const FieldSelectionSchema = z.object({
    fields: z.string().optional(),
});

export type FieldSelectionQuery = z.infer<typeof FieldSelectionSchema>;

export const PROVIDER_TOUR_API_ENDPOINTS = {
    createTour: createTourSchema,
    updateTour: EndpointFactory.create({
        method: 'PUT',
        url: '/v1/provider/tours/:tourId',
        params: z.object({ tourId: uuid }),
        body: TourSchema,
        response: z.object({ ok: z.boolean(), data: TourSchema.extend({ id: z.string(), updatedAt: z.string() }) }),
    }),
    checkPublishStatus: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/tours/:tourId/publish-status',
        params: z.object({ tourId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                canBePublished: z.boolean(),
                reason: z.string().optional(),
            }),
        }),
    }),

    publishTour: EndpointFactory.create({
        method: 'POST',
        url: '/v1/provider/tours/:tourId/publish',
        params: z.object({ tourId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                id: uuid,
                status: TourStatusEnum,
                updatedAt: z.string(),
            }),
        }),
    }),

    getTour: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/tours/:tourId',
        params: z.object({ tourId: uuid }),
        query: FieldSelectionSchema,
        response: z.object({
            ok: z.boolean(),
            data: TourSchema.extend({
                meetingPoint: LocationSchema.optional(),
                images: z.array(z.object({ url: z.string() })),
                itinerary: z.array(
                    z.object({ id: uuid, dayNumber: z.number(), title: z.string(), description: z.string(), locations: z.array(LocationSchema) })
                ),
                tourLocations: z.array(z.object({ location: LocationSchema })),
                additionalInfo: z.object({ categoryTags: z.array(z.object({ id: z.string(), name: z.string() })) }).optional(),
                pricing: PricingSchema.optional(),
                listings: z.array(TourListingSchema),
                duration: DurationSchema,
            }),
        }),
    }),

    getTours: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/tours',
        query: GetToursQuerySchema,
        response: z.object({
            ok: z.boolean(),
            data: z.object({ tours: z.array(TourSchema.extend({ duration: DurationSchema })), totalPages: z.number(), currentPage: z.number() }),
            pagination: PaginationSchema,
        }),
    }),
    deleteTour: EndpointFactory.create({
        method: 'DELETE',
        url: '/v1/provider/tours/:tourId',
        params: z.object({ tourId: uuid }),
        response: z.object({ ok: z.boolean() }),
    }),

    updateTourStatus: EndpointFactory.create({
        method: 'PATCH',
        url: '/v1/provider/tours/:tourId/status',
        params: z.object({ tourId: uuid }),
        body: z.object({ status: TourStatusEnum }),
        response: z.object({ ok: z.boolean(), data: TourSchema.extend({ id: z.string(), updatedAt: z.string() }) }),
    }),

    getBookings: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/bookings',
        query: z.object({
            page: z.number().int().positive().optional(),
            limit: z.number().int().positive().optional(),
            search: z.string().optional(),
            status: BookingStatusEnum.optional(),
            dateFilter: z.enum(['all', 'upcoming', 'past']).optional(),
            sortBy: z.enum(['tourDate', 'bookingDate', 'customer', 'status', 'totalAmount']).optional(),
            sortOrder: z.enum(['asc', 'desc']).optional(),
        }),
        response: z.object({
            bookings: z.array(BookingSchema),
            pagination: PaginationSchema,
        }),
    }),

    getBookingById: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/bookings/:bookingId',
        params: z.object({
            bookingId: z.string().uuid(),
        }),
        response: z.object({
            booking: BookingDetailsSchema,
        }),
    }),

    updateBookingStatus: EndpointFactory.create({
        method: 'PATCH',
        url: '/v1/provider/bookings/:bookingId/status',
        params: z.object({
            bookingId: z.string().uuid(),
        }),
        body: z.object({
            status: BookingStatusEnum,
        }),
        response: z.object({
            booking: BookingSchema,
        }),
    }),

    cancelBooking: EndpointFactory.create({
        method: 'POST',
        url: '/v1/provider/bookings/:bookingId/cancel',
        params: z.object({
            bookingId: z.string().uuid(),
        }),
        body: z.object({
            reason: z.string(),
        }),
        response: z.object({
            booking: BookingSchema,
            refundAmount: z.number().nonnegative(),
        }),
    }),

    sendMessageToCustomer: EndpointFactory.create({
        method: 'POST',
        url: '/v1/provider/bookings/:bookingId/messages',
        params: z.object({
            bookingId: z.string().uuid(),
        }),
        body: z.object({
            message: z.string(),
        }),
        response: z.object({
            success: z.boolean(),
            messageId: z.string().uuid(),
        }),
    }),

    getBookingMessages: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/bookings/:bookingId/messages',
        params: z.object({
            bookingId: z.string().uuid(),
        }),
        query: z.object({
            page: z.number().int().positive().optional(),
            limit: z.number().int().positive().optional(),
        }),
        response: z.object({
            messages: z.array(
                z.object({
                    id: z.string().uuid(),
                    sender: z.enum(['provider', 'customer']),
                    content: z.string(),
                    timestamp: z.string(),
                })
            ),
            pagination: PaginationSchema,
        }),
    }),

    createTourListing: EndpointFactory.create({
        method: 'POST',
        url: '/v1/provider/tours/:tourId/listings',
        params: z.object({
            tourId: uuid,
        }),
        body: TourListingSchema,
        response: z.object({
            ok: z.boolean(),
            data: TourListingSchema.extend({ id: z.string() }),
        }),
    }),

    updateTourListing: EndpointFactory.create({
        method: 'PUT',
        url: '/v1/provider/listings/:listingId',
        params: z.object({
            listingId: uuid.optional(),
        }),
        body: TourListingSchema,
        response: z.object({
            ok: z.boolean(),
            data: TourListingSchema.extend({ id: z.string() }),
        }),
    }),
    getTourBookings: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/tours/:tourId/bookings',
        params: z.object({
            tourId: uuid,
        }),
        query: z.object({
            listingId: uuid.optional(),
            date: z.string().optional(),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(
                z.object({
                    id: uuid,
                    travellerName: z.string(),
                    participants: z.number().int().positive(),
                })
            ),
        }),
    }),

    getProviderListings: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/listings',
        params: z.object({}),
        query: z.object({
            tourId: uuid.optional(),
            startDate: z.coerce.date().optional(),
            endDate: z.coerce.date().optional(),
            status: ListingStatusEnum.optional(),
            page: stringToNumber.optional().default('1'),
            limit: stringToNumber.optional().default('20'),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(
                z.object({
                    id: uuid,
                    tourId: uuid,
                    tourName: z.string(),
                    date: z.string(),
                    startTime: z.string(),
                    endTime: z.string(),
                    maxParticipants: z.number(),
                    currentBookings: z.number(),
                    basePrice: z.number(),
                    mainCurrency: CurrencyEnum,
                    status: ListingStatusEnum,
                })
            ),
            pagination: PaginationSchema,
        }),
    }),
};

// Booking Creation Request Schema
export const CreateBookingRequestSchema = BookingSchema.omit({
    id: true,
    status: true,
    paymentStatus: true,
    createdAt: true,
    updatedAt: true,
    expiresAt: true,
});

// Booking Update Schema (for the 15-minute edit window)
export const UpdateBookingRequestSchema = BookingSchema.partial().omit({
    id: true,
    tourListingId: true,
    status: true,
    paymentStatus: true,
    createdAt: true,
    updatedAt: true,
    expiresAt: true,
});

// API Endpoints
export const BOOKING_API_ENDPOINTS = {
    createBooking: {
        method: 'POST' as const,
        url: '/v1/bookings',
        body: CreateBookingRequestSchema,
        response: z.object({
            ok: z.boolean(),
            data: BookingSchema,
        }),
    },
    getBooking: {
        method: 'GET' as const,
        url: '/v1/bookings/:bookingId',
        params: z.object({ bookingId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: BookingSchema,
        }),
    },
    updateBooking: {
        method: 'PATCH' as const,
        url: '/v1/bookings/:bookingId',
        params: z.object({ bookingId: uuid }),
        body: UpdateBookingRequestSchema,
        response: z.object({
            ok: z.boolean(),
            data: BookingSchema,
        }),
    },
    cancelBooking: {
        method: 'POST' as const,
        url: '/v1/bookings/:bookingId/cancel',
        params: z.object({ bookingId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: BookingSchema,
        }),
    },
};

export type GetTourBookingsParams = z.infer<typeof PROVIDER_TOUR_API_ENDPOINTS.getTourBookings.params>;
export type GetTourBookingsQuery = z.infer<typeof PROVIDER_TOUR_API_ENDPOINTS.getTourBookings.query>;
export type GetTourBookingsResponse = z.infer<typeof PROVIDER_TOUR_API_ENDPOINTS.getTourBookings.response>;

export const UpdateCreateListingSchema = z.object({
    id: uuid.optional(), // Optional for creating new listings
    tourId: uuid,
    date: flexibleDateSchema,
    startTime: flexibleDateSchema,
    endTime: flexibleDateSchema,
    maxParticipants: z.number().int().positive(),
    basePrice: z.number().positive(),
    mainCurrency: CurrencyEnum,
    currencyPrices: z.record(z.number().positive()),
    isActive: z.boolean(),
});

export const TOUR_LISTINGS_API_ENDPOINTS = {
    getListings: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/listings',
        query: ListingsFilterSchema.extend({
            page: z.number().int().positive().optional().default(1),
            limit: z.number().int().positive().optional().default(20),
            search: z.string().optional(),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(TourListingSchema),
            pagination: PaginationSchema,
        }),
    }),

    getListingById: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/listings/:listingId',
        params: z.object({ listingId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: TourListingSchema,
        }),
    }),

    updateOrCreateListing: EndpointFactory.create({
        method: 'PUT',
        url: '/v1/provider/tours/:tourId/listings',
        params: z.object({ tourId: uuid }),
        body: UpdateCreateListingSchema,
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                id: uuid.optional(),
                tourId: uuid,
                date: z.string(),
                startTime: z.string(),
                endTime: z.string(),
                maxParticipants: z.number(),
                currentBookings: z.number(),
                basePrice: z.number(),
                mainCurrency: CurrencyEnum,
                currencyPrices: z.record(z.number()),
                isActive: z.boolean(),
                status: ListingStatusEnum,
                tourName: z.string(),
            }),
        }),
    }),

    createListing: EndpointFactory.create({
        method: 'POST',
        url: '/v1/provider/tours/:tourId/listings',
        params: z.object({ tourId: uuid }),
        body: TourListingSchema.omit({ id: true, tourId: true, currentBookings: true, status: true }),
        response: z.object({
            ok: z.boolean(),
            data: TourListingSchema,
        }),
    }),

    updateListing: EndpointFactory.create({
        method: 'PUT',
        url: '/v1/provider/listings/:listingId',
        params: z.object({ listingId: uuid }),
        body: UpdateListingSchema,
        response: z.object({
            ok: z.boolean(),
            data: TourListingSchema,
        }),
    }),

    deleteListing: EndpointFactory.create({
        method: 'DELETE',
        url: '/v1/provider/listings/:listingId',
        params: z.object({ listingId: uuid }),
        response: z.object({
            ok: z.boolean(),
        }),
    }),

    bulkUpdateListings: EndpointFactory.create({
        method: 'POST',
        url: '/v1/provider/listings/bulk-update',
        body: BulkUpsertListingsSchema,
        response: z.object({
            ok: z.boolean(),
            data: z.array(TourListingSchema),
        }),
    }),

    getListingAvailability: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/listings/:listingId/availability',
        params: z.object({ listingId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                availableSpots: z.number().int().min(0),
                isFullyBooked: z.boolean(),
            }),
        }),
    }),

    updateListingStatus: EndpointFactory.create({
        method: 'PATCH',
        url: '/v1/provider/listings/:listingId/status',
        params: z.object({ listingId: uuid }),
        body: z.object({ status: ListingStatusEnum }),
        response: z.object({
            ok: z.boolean(),
            data: TourListingSchema,
        }),
    }),

    getListingsByDateRange: EndpointFactory.create({
        method: 'GET',
        url: '/v1/provider/listings/date-range',
        query: z.object({
            tourId: uuid.optional(),
            startDate: z.coerce.date(),
            endDate: z.coerce.date(),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(TourListingSchema),
        }),
    }),
};

export type TourListingsApiEndpoints = typeof TOUR_LISTINGS_API_ENDPOINTS;

export const LocationApiSchema = {
    autocomplete: {
        method: 'GET',
        url: '/v1/locations/autocomplete',
        query: z.object({
            query: z.string(),
            limit: z.string().transform(Number).optional(),
        }),
        response: z.object({
            success: z.boolean(),
            suggestions: z
                .array(
                    z.object({
                        suggestion: z.string(),
                        confidence: z.number(),
                    })
                )
                .optional(),
            error: z.string().optional(),
        }),
    },
};

export const AvailabilitySchema = z.object({ date: z.string(), availableSpots: z.number().int().min(0), price: z.number().min(0) });

export const CreateBookingSchema = z.object({
    tourId: uuid,
    listingId: uuid,
    participants: z.number().int().positive(),
    specialRequests: z.string().optional(),
});

export const TravellerSearchQuerySchema = z.object({
    query: z.string().optional(),
    date: z.string().optional(),
    minPrice: z.number().optional(),
    maxPrice: z.number().optional(),
    difficulty: z.array(DifficultyEnum).optional(),
    languages: z.array(LanguageEnum).optional(),
    page: stringToNumber.optional().default('0'),
    limit: stringToNumber.optional().default('10'),
});

export const ToursListQuerySchema = z
    .object({
        page: z.number().int().min(0).default(0),
        take: z.number().int().min(1).default(15),
    })
    .default({ page: 0, take: 15 });

export const TravellerSearchResponseSchema = z.object({
    ok: z.boolean(),
    data: z.array(TourSchema),
    pagination: PaginationSchema,
});

export const TRAVELLER_API_ENDPOINTS = {
    searchTours: {
        method: 'GET',
        url: '/v1/traveller/tours/search',
        query: TravellerSearchQuerySchema,
        response: TravellerSearchResponseSchema,
    },
    getToursList: EndpointFactory.create({
        method: 'GET',
        url: '/v1/traveller/tours',
        query: ToursListQuerySchema,
        response: z.object({
            ok: z.boolean(),
            data: z.array(
                TourSchema.extend({
                    id: uuid,
                    createdAt: z.string().optional(),
                    updatedAt: z.string().optional(),
                    provider: z.object({
                        id: uuid,
                        companyName: z.string(),
                        rating: z.number().min(0).max(5).optional(),
                    }),
                    // You might want to include a summary of reviews or availability here
                    averageRating: z.number().min(0).max(5).optional(),
                    totalReviews: z.number().int().min(0).optional(),
                })
            ),
            pagination: PaginationSchema,
        }),
    }),

    createBooking: {
        method: 'POST',
        url: '/v1/travellers/bookings/:tourId/',
        params: z.object({ tourId: uuid }),
        body: BookingSchema,
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                id: uuid,
                tourName: z.string(),
                date: z.string(),
                startTime: z.string(),
                endTime: z.string(),
                status: BookingStatusEnum,
                participants: z.number(),
                totalAmount: z.number(),
                currency: CurrencyEnum,
            }),
        }),
    },

    getUserBookings: {
        method: 'GET',
        url: '/v1/traveller/bookings',
        query: z.object({
            page: stringToNumber.optional().default('0'),
            limit: stringToNumber.optional().default('20'),
            status: BookingStatusEnum.optional().nullable(),
        }),
        response: UserBookingsResponseSchema,
    },

    getAvailableTourDates: EndpointFactory.create({
        method: 'GET',
        url: '/v1/tours/:tourId/availability',
        params: z.object({
            tourId: uuid,
        }),
        query: z.object({ startDate: z.coerce.date().optional(), endDate: z.coerce.date().optional() }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(
                z.object({
                    date: z.string(),
                    listings: z.array(
                        z.object({
                            id: uuid,
                            startTime: z.string(),
                            endTime: z.string(),
                            availableSpots: z.number(),
                            basePrice: z.number(),
                            currency: CurrencyEnum,
                        })
                    ),
                })
            ),
        }),
    }),
    checkTourAvailability: EndpointFactory.create({
        method: 'GET',
        url: '/v1/traveller/tours/:tourId/availability',
        params: z.object({ tourId: uuid }),
        query: z.object({
            date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
            participants: z.number().int().positive(),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                available: z.boolean(),
                availableSpots: z.number().int().min(0),
                price: z.number().min(0),
                currency: CurrencyEnum,
            }),
        }),
    }),

    createReservation: EndpointFactory.create({
        method: 'POST',
        url: '/v1/traveller/reservations',
        body: z.object({
            tourId: uuid,
            listingId: uuid,
            date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
            participants: z.number().int().positive(),
            travellerInfo: z.object({
                name: z.string(),
                email: z.string().email(),
                phone: z.string().optional(),
            }),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                reservationId: uuid,
                expiresAt: z.string(),
                tourName: z.string(),
                date: z.string(),
                participants: z.number(),
                totalAmount: z.number(),
                currency: CurrencyEnum,
            }),
        }),
    }),

    confirmBooking: EndpointFactory.create({
        method: 'POST',
        url: '/v1/traveller/bookings',
        body: z.object({
            reservationId: uuid,
            paymentInfo: z.object({
                method: PaymentMethodEnum,
                cardNumber: z.string().optional(),
                expirationDate: z.string().optional(),
                cvv: z.string().optional(),
            }),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.object({
                bookingId: uuid,
                status: BookingStatusEnum,
                tourName: z.string(),
                date: z.string(),
                participants: z.number(),
                totalAmount: z.number(),
                currency: CurrencyEnum,
                paymentStatus: z.enum(['PENDING', 'COMPLETED', 'FAILED']),
            }),
        }),
    }),

    getTourById: EndpointFactory.create({
        method: 'GET',
        url: '/v1/traveller/tours/:tourId',
        params: z.object({ tourId: uuid }),
        response: z.object({
            ok: z.boolean(),
            data: TourSchema.extend({
                id: uuid,
                createdAt: z.string().optional(),
                updatedAt: z.string().optional(),
                provider: z.object({
                    id: uuid,
                    companyName: z.string(),
                    rating: z.number().min(0).max(5).optional(),
                }),
                reviews: z
                    .array(
                        z.object({
                            id: uuid,
                            rating: z.number().min(1).max(5),
                            comment: z.string().optional(),
                            createdAt: z.string().optional(),
                            traveller: z.object({ id: uuid, name: z.string() }),
                        })
                    )
                    .optional(),
                availability: z.array(AvailabilitySchema).optional(),
            }),
        }),
    }),
};
// API Endpoints

export const REVIEWS_API_ENDPOINTS = {
    submitReview: {
        method: 'POST',
        url: '/v1/travellers/:travellerId/tours/:tourId/reviews',
        params: z.object({ travellerId: uuid, tourId: uuid }),
        body: z.object({ rating: z.number().min(1).max(5), comment: z.string() }),

        response: z.object({
            ok: z.boolean(),
            data: z.object({
                id: uuid,
                tourId: uuid,
                travellerId: uuid,
                rating: z.number(),
                comment: z.string(),
                createdAt: z.string().optional(),
            }),
        }),
    },

    getTourReviews: {
        method: 'GET',
        url: '/v1/tours/:tourId/reviews',
        params: z.object({
            tourId: uuid,
        }),
        query: z.object({
            page: stringToNumber.optional().default('1'),
            limit: stringToNumber.optional().default('10'),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(
                z.object({
                    id: uuid,
                    tourId: uuid,
                    travellerId: uuid,
                    travellerName: z.string(),
                    rating: z.number(),
                    comment: z.string(),
                    createdAt: z.string().optional(),
                })
            ),
            pagination: PaginationSchema,
        }),
    },
};

export const CATEGORY_API_ENDPOINTS = {
    getCategories: EndpointFactory.create({
        method: 'GET',
        url: '/categories',
        query: z.object({
            search: z.string().optional(),
            page: z.string().optional().transform(Number).default('1'),
            limit: z.string().optional().transform(Number).default('10'),
        }),
        response: z.object({
            ok: z.boolean(),
            data: z.array(CategorySchema),
            pagination: PaginationSchema,
        }),
    }),

    addCategory: {
        method: 'POST',
        url: '/categories',
        body: z.object({ name: z.string() }),
        response: z.object({ ok: z.boolean(), data: CategorySchema }),
    },
};

export const API_ENDPOINTS = {
    addLocationToTour: {
        method: 'POST' as const,
        url: '/v1/provider/tours/:tourId/locations',
        params: z.object({
            tourId: z.string(),
        }),
        body: TourLocationSchema,
        response: z.object({
            id: z.string(),
            tourId: z.string(),
            locationId: z.string(),
            order: z.number().nullable().optional(),
            description: z.string().nullable().optional(),
            tags: z.array(z.string()).optional(),
            location: LocationSchema,
        }),
    },
    getLocationDetails: {
        method: 'GET' as const,
        url: '/api/locations/:locationId',
        params: z.object({
            locationId: z.string(),
        }),
        response: LocationSchema,
    },

    searchLocations: {
        method: 'GET' as const,
        url: '/api/locations/search',
        query: z.object({
            input: z.string(),
            sessiontoken: z.string().optional(),
        }),
        response: z.array(
            z.object({
                place_id: z.string(),
                description: z.string(),
            })
        ),
    },

    createLocation: {
        method: 'POST' as const,
        url: '/api/locations',
        body: LocationSchema.omit({ id: true, createdAt: true, updatedAt: true }),
        response: LocationSchema,
    },
    updateMeetingPoint: {
        method: 'PUT' as const,
        url: '/v1/provider/tours/:tourId/meeting-point',
        params: z.object({
            tourId: z.string(),
        }),
        body: z.object({
            location: LocationSchema,
            description: z.string().optional(),
            tags: z.array(z.string()).optional(),
        }),
        response: z.object({
            id: z.string(),
            tourId: z.string(),
            locationId: z.string(),
            description: z.string().nullable(),
            tags: z.array(z.string()),
            location: LocationSchema,
        }),
    },
    removeMeetingPoint: {
        method: 'DELETE' as const,
        url: '/v1/provider/tours/:tourId/meeting-point',
        params: z.object({
            tourId: z.string(),
        }),
        response: z.object({
            ok: z.boolean(),
        }),
    },

    createTour: {
        method: 'POST' as const,
        url: '/api/tours',
        body: z.object({
            name: z.string(),
            description: z.string(),
            // Add other tour fields as needed
        }),
        response: TourSchema,
    },

    removeTourLocation: {
        method: 'DELETE' as const,
        url: '/v1/provider/tours/:tourId/locations/:locationId',
        params: z.object({
            tourId: z.string(),
            locationId: z.string(),
        }),
        response: z.object({
            ok: z.boolean(),
        }),
    },

    getTourLocations: {
        method: 'GET' as const,
        url: '/api/tours/:tourId/locations',
        params: z.object({ tourId: z.string() }),
        response: z.array(TourLocationSchema),
    },
};

export type ProviderApiEndpoints = typeof PROVIDER_TOUR_API_ENDPOINTS;
export type ReviewsApiEndpoints = typeof REVIEWS_API_ENDPOINTS;

// Export types for schemas
export type Tour = z.infer<typeof TourSchema>;
export type TourListing = z.infer<typeof TourListingSchema>;
export type Booking = z.infer<typeof BookingSchema>;
export type User = z.infer<typeof ResponseUserDataSchema>;
export type AuthApiEndpoints = typeof AUTH_API_ENDPOINTS;
export type CategoryApiEndpoints = typeof CATEGORY_API_ENDPOINTS;
export type UpdateUserSettingsData = z.infer<typeof UpdateUserSettingsSchema>;
export type EmailPreferences = z.infer<typeof EmailPreferencesSchema>;
export type NotificationSettings = z.infer<typeof NotificationSettingsSchema>;

export const accessibilityPolicies = [
    {
        id: AccessibilityOption.HEARING_IMPAIRED_FRIENDLY,
        name: 'Hearing Impaired Friendly',
        description: 'The tour provides accommodations for hearing impaired individuals.',
    },
    {
        id: AccessibilityOption.VISUALLY_IMPAIRED_FRIENDLY,
        name: 'Visually Impaired Friendly',
        description: 'The tour provides accommodations for visually impaired individuals.',
    },
    {
        id: AccessibilityOption.MOBILITY_FRIENDLY,
        name: 'Mobility Friendly',
        description: 'The tour is suitable for individuals with limited mobility.',
    },
    {
        id: AccessibilityOption.SENSORY_FRIENDLY,
        name: 'Sensory Friendly',
        description: 'The tour is designed to be comfortable for individuals with sensory sensitivities.',
    },
    {
        id: AccessibilityOption.COGNITIVE_FRIENDLY,
        name: 'Cognitive Friendly',
        description: 'The tour is suitable for individuals with cognitive disabilities.',
    },
];

export const seasonalityOptions: Array<{ value: Seasonality; label: string }> = [
    { value: Seasonality.YEAR_ROUND, label: 'Year-round' },
    { value: Seasonality.SUMMER, label: 'Summer' },
    { value: Seasonality.WINTER, label: 'Winter' },
    { value: Seasonality.SPRING, label: 'Spring' },
    { value: Seasonality.AUTUMN, label: 'Autumn' },
];
export const depositPolicyOptions = [
    { id: 'NO_DEPOSIT', name: 'No Deposit', description: 'No deposit required for booking.' },
    { id: 'PERCENTAGE', name: 'Percentage', description: 'A percentage of the total cost is required as a deposit.' },
    { id: 'FIXED_AMOUNT', name: 'Fixed Amount', description: 'A fixed amount is required as a deposit.' },
];

export const foodOptions = [
    { value: FoodOption.VEGETARIAN, label: 'Vegetarian' },
    { value: FoodOption.VEGAN, label: 'Vegan' },
    { value: FoodOption.GLUTEN_FREE, label: 'Gluten Free' },
    { value: FoodOption.KOSHER, label: 'Kosher' },
    { value: FoodOption.HALAL, label: 'Halal' },
];

export const paymentOptions = Object.values(PaymentMethod).map((value) => ({ id: value, name: value.toLowerCase().replace('_', ' ') }));

export const refundPolicies = [
    {
        id: RefundPolicy.NO_REFUND,
        name: 'No Refund',
        description: 'No refunds will be issued for cancellations.',
    },
    {
        id: RefundPolicy.PARTIAL_REFUND,
        name: 'Partial Refund',
        description: 'A partial refund will be issued based on the cancellation date.',
    },
    {
        id: RefundPolicy.FULL_REFUND,
        name: 'Full Refund',
        description: 'A full refund will be issued for cancellations made within a certain timeframe.',
    },
];

export const cancellationPolicies = [
    {
        id: CancellationPolicy.FLEXIBLE,
        name: 'Flexible',
        description: 'Full refund up to 24 hours before the start of the tour. No refund after that.',
    },
    {
        id: CancellationPolicy.MODERATE,
        name: 'Moderate',
        description:
            'Full refund up to 5 days before the start of the tour. 50% refund between 2-5 days before the tour. No refund within 48 hours of the tour.',
    },
    {
        id: CancellationPolicy.STRICT,
        name: 'Strict',
        description:
            'Full refund up to 7 days before the start of the tour. 50% refund between 3-7 days before the tour. No refund within 72 hours of the tour.',
    },
];

export const languageOptions: LanguageOption[] = [
    { value: Language.EN, label: 'English' },
    { value: Language.ES, label: 'Español' },
    { value: Language.FR, label: 'Français' },
    { value: Language.DE, label: 'Deutsch' },
    { value: Language.IT, label: 'Italiano' },
    { value: Language.RU, label: 'Русский' },
    { value: Language.ZH, label: '中文' },
    { value: Language.JA, label: '日本語' },
    { value: Language.AR, label: 'Arabic' },
];

export const LanguageMap: Record<Language, string> = {
    [Language.EN]: 'English',
    [Language.ES]: 'Español',
    [Language.FR]: 'Français',
    [Language.DE]: 'Deutsch',
    [Language.IT]: 'Italiano',
    [Language.RU]: 'Русский',
    [Language.ZH]: '中文',
    [Language.JA]: '日本語',
    [Language.AR]: 'Arabic',
};

export const accessibilityOptions: AccessibilityOptionInput = [
    { value: 'HEARING_IMPAIRED_FRIENDLY', label: 'Hearing Impaired Friendly' },
    { value: 'VISUALLY_IMPAIRED_FRIENDLY', label: 'Visually Impaired Friendly' },
    { value: 'MOBILITY_FRIENDLY', label: 'Mobility Friendly' },
    { value: 'SENSORY_FRIENDLY', label: 'Sensory Friendly' },
    { value: 'COGNITIVE_FRIENDLY', label: 'Cognitive Friendly' },
    { value: 'SERVICE_ANIMALS_ALLOWED', label: 'Service Animals Allowed' },
    { value: 'SIGN_LANGUAGE_AVAILABLE', label: 'Sign Language Available' },
    { value: 'ACCESSIBLE_TRANSPORT', label: 'Accessible Transport' },
];

export const difficultyLevels: Array<{ value: Difficulty; label: string }> = [
    { value: Difficulty.EASY, label: 'Easy' },
    { value: Difficulty.MODERATE, label: 'Moderate' },
    { value: Difficulty.CHALLENGING, label: 'Challenging' },
];

export function getCancellationPolicyDescription(policy: CancellationPolicy): string {
    const foundPolicy = cancellationPolicies.find((p) => p.id === policy);
    return foundPolicy ? foundPolicy.description : 'Custom cancellation policy';
}

import { AlertCircle } from "lucide-react";

const ErrorDisplay = ({ errorData }: { errorData: object }) => {
    return (
        <div className="bg-gray-900 rounded-lg p-4 font-mono text-sm text-white">
            <div className="flex items-center mb-2">
                <AlertCircle className="text-red-500 mr-2" size={20} />
                <span className="text-red-500 font-semibold">Error</span>
            </div>
            <pre className="whitespace-pre-wrap">{JSON.stringify(errorData, null, 2)}</pre>
        </div>
    );
};

export { ErrorDisplay };

import { useEffect, useRef, useState, FC } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "../../app/store";
import Joi from "joi";
import i18n from "entities/i18n/i18n";
import { TranslationKey } from "shared/types/translationTypes";

const saveToClipboard = (text: string) => (navigator.clipboard ? navigator.clipboard.writeText(text) : null);

export const getErrorMessage = (error: any) => {
    if (typeof error === "string") {
        return i18n.t(error as TranslationKey);
    }
    return error?.message;
};

/**
 * Attach array of events to ref
 */
interface RefEvents<K extends keyof HTMLElementEventMap = keyof HTMLElementEventMap> {
    type: K;
    cb: (e: any) => void;
}

/**
 * Add event listeners to ref in parent component
 */
function useRefEvents<T extends HTMLElement>(evtListeners: RefEvents[]) {
    const ref = React.useRef<T>(null);
    const interval = React.useRef(100);

    React.useEffect(() => {
        const check = () => {
            if (ref.current) {
                for (const evt of evtListeners) {
                    ref.current.addEventListener(evt.type, evt.cb);
                }
            } else {
                if (interval.current <= 20 * 1000) {
                    interval.current *= 2;
                    setTimeout(check, interval.current / 2);
                } else {
                    const err = new Error("No ref in useRefEvents");
                    console.error(err);
                }
            }
        };

        check();

        return () => {
            if (ref.current) {
                for (const evt of evtListeners) {
                    ref.current.addEventListener(evt.type, evt.cb);
                }
            }
        };
    }, []);
    return { ref: ref };
}
export type { RefEvents };
export { useRefEvents };
/**
 * Utils hooks
 * TODO:
 *      - need types
 */

function throttle(cb: () => void, ms: number) {
    let lastTime = 0;
    return () => {
        const now = Date.now();
        if (now - lastTime >= ms) {
            cb();
            lastTime = now;
        }
    };
}

function useDebounce<K>(value: K, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [value, delay]);

    return debouncedValue;
}

type DebouncedFunction<T extends any[]> = (...args: T) => void;
function useDebounceCallback<T extends any[]>(callback: DebouncedFunction<T>, delay: number): DebouncedFunction<T> {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const debouncedFunction = React.useCallback((...args: T) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => callback(...args), delay);
        setTimeoutId(newTimeoutId);
    }, []);

    return debouncedFunction;
}

const useClickOutside = (action: () => void, ...deps: any) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current == null) return;
        const element = ref.current;

        const listener = (evt: MouseEvent) => {
            const target = evt.target as Node;
            if (target && !element.contains(target)) {
                action();
            }
        };
        document.addEventListener("mousedown", listener);

        return () => document.removeEventListener("mousedown", listener);
    }, deps);

    return ref;
};

const useClickOutsideRef = <K extends HTMLElement>(ref: React.RefObject<K>, action: () => void, ...deps: any) => {
    useEffect(() => {
        if (ref.current == null) return;
        const element = ref.current;

        const listener = (evt: MouseEvent) => {
            const target = evt.target as Node;
            if (target && !element.contains(target)) {
                action();
            }
        };
        document.addEventListener("mousedown", listener);

        return () => document.removeEventListener("mousedown", listener);
    }, []);

    return null;
};

/**
 * Hook for screen width
 */
const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Cleanup function to remove the event listener when component unmounts
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty dependency array ensures the effect runs only once after the initial render

    return screenWidth;
};

const useScreenHeight = () => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setScreenHeight(window.innerHeight);
        window.addEventListener("resize", handleResize);
        // window.addEventListener('')
        // Cleanup function to remove the event listener when component unmounts
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty dependency array ensures the effect runs only once after the initial render

    return screenHeight;
};

function isTouchEvent({ nativeEvent }: React.SyntheticEvent) {
    return window.TouchEvent ? nativeEvent instanceof TouchEvent : "touches" in nativeEvent;
}

function isMouseEvent(event: React.SyntheticEvent) {
    return event.nativeEvent instanceof MouseEvent;
}

function smoothScrollToBottom(element: HTMLDivElement) {
    const start = element.scrollTop;
    const end = element.scrollHeight - element.clientHeight;
    const change = end - start;
    const duration = 500; // duration in ms
    let startTime: number | null = null;

    function animateScroll(timestamp: number) {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const easeInOutQuad = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
        const easedT = easeInOutQuad(progress / duration);
        element.scrollTop = start + change * easedT;

        if (progress < duration) requestAnimationFrame(animateScroll);
    }

    requestAnimationFrame(animateScroll);
}

function useScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => window.scrollTo(0, 0), [pathname]);
    return null;
}

function removeNullProperties(obj: Record<string, object | string | null>): Record<string, object | string> {
    const newObj: Record<string, object | string> = {};

    for (const [key, value] of Object.entries(obj)) {
        if (value !== null) {
            if (typeof value === "object" && value !== null && Object.entries(value as Record<string, unknown>).length > 0) {
                const nestedObj = removeNullProperties(value as Record<string, object | string>);
                newObj[key] = nestedObj;
            } else newObj[key] = value;
        }
    }
    return newObj;
}

function useThrottle<T>(value: T, interval = 500): T {
    const [throttledValue, setThrottledValue] = useState<T>(value);
    const lastExecuted = useRef<number>(Date.now());

    useEffect(() => {
        if (Date.now() >= lastExecuted.current + interval) {
            lastExecuted.current = Date.now();
            setThrottledValue(value);
        } else {
            const timerId = setTimeout(() => {
                lastExecuted.current = Date.now();
                setThrottledValue(value);
            }, interval);

            return () => clearTimeout(timerId);
        }
    }, [value, interval]);

    return throttledValue;
}

const useScreenSize = () => {
    const w = useScreenWidth();
    const h = useScreenHeight();

    const [size, setSize] = useState({ w: w + "px", h: h + "px" });
    useEffect(() => setSize({ w: w + "px", h: h + "px" }), [w, h]);

    return { ...size };
};

type ymaps_ = typeof ymaps3;

import * as React from "react";
import * as ReactDOM from "react-dom";
import { BaseModule, ReactifiedModule } from "@yandex/ymaps3-types/reactify/reactify";
import { logger } from "shared/api/signature";

interface StateReturn<K> {
    state: K;
    subscribe: (s: K) => void;
    update: (v: K) => void;
}

function State<K>(state_: K) {
    let state: K = state_;
    const subs: Array<(s: K) => void> = [];

    const update = (v: K) => {
        state = v;
        for (const sub of subs) sub(state);
    };
    return {
        get state() {
            return state;
        },
        set state(v: K) {
            update(v);
        },
        set subscribe(f: (s: K) => void) {
            subs.push(f);
        },
        update,
    };
}

export class ExternalLib<T> {
    protected lib: T | undefined;
    public load: StateReturn<boolean>;

    constructor(link: string, name: keyof Window) {
        this.load = State<boolean>(false);
        if (!window[name]) {
            const script = document.createElement("script");
            script.src = link;

            script.onload = () => {
                this.lib = window[name];
                this.loadScript();
            };

            script.onerror = () => {
                const error = new Error();
                error.message = `Error on loading External lib can't load lib ${name} from ${link}`;
                const errorMessage = {
                    message: "Error while loading lib",
                    e: error,
                    level: "crit" as "crit",
                };

                logger(errorMessage);
            };
            document.body.appendChild(script);
        } else {
            this.lib = window[name];
            this.load.update(true);
        }
    }

    public useLoader() {
        const [state, setState] = useState(this.load.state);
        const f = (s: boolean) => setState(s);
        this.load.subscribe = f;
        return state;
    }

    // This is action for script on load
    protected loadScript() {
        this.load.state = true;
    }
}

// doing this to shake the tree
// ReactifiedModule<BaseModule & typeof import("/home/saidmukhamad/adv/ui/node_modules/@yandex/ymaps3-types/index") & { YMapReactContainer: typeof YMapReactContainer; }>'

export class YandexMap extends ExternalLib<ymaps_> {
    private static _instance: YandexMap;
    public yR!: ReactifiedModule<BaseModule & ymaps_>;
    private constructor() {
        super("https://api-maps.yandex.ru/v3/?apikey=92a84ca3-1b4c-4030-99de-4c9cac0fc6b6&lang=en_US", "ymaps3");
    }

    protected loadScript(): void {
        const check = () => {
            if (window.ymaps3) {
                const reactify = async () => {
                    const ymaps3React = await ymaps3.import("@yandex/ymaps3-reactify");
                    const react = ymaps3React.reactify.bindTo(React, ReactDOM);
                    this.yR = react.module(ymaps3);
                };

                reactify()
                    .then(() => (this.load.state = true))
                    .catch((e) => console.log(e));
            } else setTimeout(check, 500);
        };
        check();
    }

    public static get instance(): YandexMap {
        return YandexMap._instance || (YandexMap._instance = new YandexMap());
    }
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const getFormattedDate = (date: Date) => date.toLocaleString().split(",")[0].split(".").join("-");

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

/**
 * Validation schemas
 */
const validate = {
    email: Joi.string()
        .required()
        .min(3)
        .email({ tlds: { allow: false } }),
    string: Joi.string().required().min(3),
    stringNotRequired: Joi.string(),
    password: Joi.string().required().min(8),
    phone: Joi.string().pattern(/^\+[1-9]\d{1,14}$/),
    tomorrow: Joi.date().greater(tomorrow).required(),
};

/**
 * Get dirty values from form
 */

function dirtyValues<K>(dirtyFields: Partial<Record<keyof K, boolean | boolean[] | object>>, allValues: K): Partial<K> {
    const obj: Partial<K> = {};
    for (const field in dirtyFields) obj[field] = allValues[field];
    return obj;
}

export {
    dirtyValues,
    smoothScrollToBottom,
    useAppDispatch,
    useAppSelector,
    useDebounceCallback,
    useThrottle,
    useClickOutside,
    useScrollToTop,
    validate,
    removeNullProperties,
    useScreenWidth,
    useScreenHeight,
    useScreenSize,
    saveToClipboard,
    getFormattedDate,
};
